import React from "react";
import { useState } from "react";
import { Button, Col, Container, FormGroup, Modal, Row, Spinner } from "react-bootstrap";
import { Styles } from "./../pages/login/styles/userDetails.js";
import { signupTutor } from "../actions/webRedActions.js";
import { toast } from "react-toastify";

const TutorSignupForm = (props) => {
  const { showPopup, setshowPopup } = props;
  const [name, setname] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [password, setpassword] = useState();
  const [gender, setgender] = useState("");
  const [b_date, setb_date] = useState("");
  const [b_month, setb_month] = useState("");
  const [b_year, setb_year] = useState("");
  const [formError, setformError] = useState(false);
  const [email, setemail] = useState("");
  const [loader, setloader] = useState(false);

  const genderClass = {
    background: "transparent",
    borderColor: "#11B67A",
    margin: "0 4px",
    color: "#000",
  };

  const genderActive = {
    background: "#11B67A",
    color: "#fff",
    borderColor: "#fff",
    margin: "0 4px",
  };

  const tutorSigupFunc = () => {
    setloader(true);
    if (
      name === "" ||
      phoneNumber === "" ||
      email === "" ||
      password === "" ||
      gender === "" ||
      b_date === "" ||
      b_month === "" ||
      b_year === ""
    ) {
      setformError(true);
      setloader(false);
    } else {
      setformError(false);
      const dob = `${b_year}-${b_month}-${b_date}`;
      signupTutor(name, email, password, phoneNumber, parseInt(gender), dob).then((res) => {
        debugger;
        if (res?.status === 201) {
          toast.success("Successfully Registered", {
            position: "bottom-center",
            autoClose: 3000,
          });
          setshowPopup(false);
          setloader(false);
          setTimeout(() => {
            window.location.href = `https://feeds.lincolnmethod.com/#/Teacherdashboard/RecruitmentTab/${res?.data?.user?.user_loginname}/${password}`;
          }, 3000);
        } else {
          toast.error(res?.data?.message, {
            position: "bottom-center",
            autoClose: 3000,
          });
          setloader(false);
        }
      });
    }
  };
  return (
    <Modal
      size="sm"
      className="loginPopupMain"
      show={showPopup}
      onHide={() => setshowPopup(false)}
      centered
    >
      <Modal.Body style={{ padding: 0 }}>
        <div>
          <Container style={{ padding: 0 }}>
            {
              <p className="closeModalLogin" onClick={() => setshowPopup(false)}>
                X
              </p>
            }
            <Styles>
              <div style={{ padding: "1vh 0" }} className="UserDetails">
                <Container>
                  <Row>
                    <Col style={{ textAlign: "center" }}>
                      <h3 style={{ margin: "50px 0px 50px 0px" }}>Create Account</h3>
                    </Col>
                  </Row>
                  <Row className="phoneStyle">
                    <Col md="12">
                      <FormGroup style={{ position: "relative" }}>
                        <label>Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          value={name}
                          onChange={(evt) => setname(evt.target.value)}
                          style={{
                            borderRadius: "10px",
                            borderColor: "#11B67A",
                          }}
                        />
                      </FormGroup>

                      <FormGroup style={{ position: "relative" }}>
                        <label>Phone number</label>
                        <input
                          className="form-control"
                          type="number"
                          name="number"
                          value={phoneNumber}
                          onChange={(evt) => setphoneNumber(evt.target.value)}
                          style={{
                            borderRadius: "10px",
                            borderColor: "#11B67A",
                          }}
                        />
                      </FormGroup>

                      <FormGroup style={{ position: "relative" }}>
                        <label>Email</label>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          value={email}
                          onChange={(evt) => setemail(evt.target.value)}
                          style={{
                            borderRadius: "10px",
                            borderColor: "#11B67A",
                          }}
                        />
                      </FormGroup>

                      <FormGroup style={{ position: "relative" }}>
                        <label>Password</label>
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          value={password}
                          onChange={(evt) => setpassword(evt.target.value)}
                          style={{
                            borderRadius: "10px",
                            borderColor: "#11B67A",
                          }}
                        />
                      </FormGroup>

                      <FormGroup
                        style={{
                          position: "relative",
                          textAlign: "center",
                          width: "90%",
                          margin: "auto",
                        }}
                      >
                        <label style={{ marginLeft: 0, paddingLeft: "8px" }}>Gender</label>
                        <br />

                        <Button
                          value={"1"}
                          name="gender"
                          onClick={(evt) => setgender(evt.target.value)}
                          style={gender === "1" ? genderActive : genderClass}
                          size="md"
                          color="secondary"
                        >
                          Male
                        </Button>

                        <Button
                          value={"2"}
                          name="gender"
                          onClick={(evt) => setgender(evt.target.value)}
                          style={gender === "2" ? genderActive : genderClass}
                          size="md"
                          color="secondary"
                        >
                          Female
                        </Button>

                        <Button
                          value={"0"}
                          name="gender"
                          onClick={(evt) => setgender(evt.target.value)}
                          style={gender === "0" ? genderActive : genderClass}
                          size="md"
                          color="secondary"
                        >
                          Non-binary
                        </Button>

                        {/* <p style={errorStyle}>{errors.gender}</p> */}
                      </FormGroup>

                      <FormGroup
                        style={{ position: "relative", padding: "0 15px" }}
                        className="mt-2"
                      >
                        <label
                          style={{
                            paddingLeft: "7px",
                            // borderBottom: '1px #fafafa solid'
                          }}
                        >
                          Date of birth
                        </label>
                        <Row>
                          <Col xs="4" style={{ paddingRight: "5px" }}>
                            <select
                              className="form-control"
                              type="select"
                              name="b_date"
                              id="b_date"
                              style={{
                                padding: 0,
                                textAlign: "center",
                                borderColor: "#11B67A",
                              }}
                              onChange={(evt) => setb_date(evt.target.value)}
                            >
                              <option value="" disabled selected>
                                day
                              </option>
                              <option value="01">1</option>
                              <option value="02">2</option>
                              <option value="03">3</option>
                              <option value="04">4</option>
                              <option value="05">5</option>
                              <option value="06">6</option>
                              <option value="07">7</option>
                              <option value="08">8</option>
                              <option value="09">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                            </select>
                            {/* <label>Day</label> */}
                          </Col>
                          <Col xs="4" style={{ padding: 0 }}>
                            <select
                              className="form-control"
                              type="select"
                              name="b_month"
                              id="b_month"
                              style={{
                                padding: 0,
                                textAlign: "center",
                                borderColor: "#11B67A",
                              }}
                              onChange={(evt) => setb_month(evt.target.value)}
                            >
                              <option value="" disabled selected>
                                month
                              </option>
                              <option value="01">1</option>
                              <option value="02">2</option>
                              <option value="03">3</option>
                              <option value="04">4</option>
                              <option value="05">5</option>
                              <option value="06">6</option>
                              <option value="07">7</option>
                              <option value="08">8</option>
                              <option value="09">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                            {/* <label>Month</label> */}
                          </Col>
                          <Col xs="4" style={{ paddingLeft: "5px" }}>
                            <select
                              className="form-control"
                              type="select"
                              name="b_year"
                              id="b_year"
                              style={{
                                padding: 0,
                                textAlign: "center",
                                borderColor: "#11B67A",
                              }}
                              onChange={(evt) => setb_year(evt.target.value)}
                            >
                              <option value="" disabled selected>
                                year
                              </option>

                              <option value="2020">2020</option>
                              <option value="2019">2019</option>
                              <option value="2018">2018</option>
                              <option value="2017">2017</option>
                              <option value="2016">2016</option>
                              <option value="2015">2015</option>
                              <option value="2014">2014</option>
                              <option value="2013">2013</option>
                              <option value="2012">2012</option>
                              <option value="2011">2011</option>
                              <option value="2010">2010</option>
                              <option value="2009">2009</option>
                              <option value="2008">2008</option>
                              <option value="2007">2007</option>
                              <option value="2006">2006</option>
                              <option value="2005">2005</option>
                              <option value="2004">2004</option>
                              <option value="2003">2003</option>
                              <option value="2002">2002</option>
                              <option value="2001">2001</option>
                              <option value="2000">2000</option>
                              <option value="1999">1999</option>
                              <option value="1998">1998</option>
                              <option value="1997">1997</option>
                              <option value="1996">1996</option>
                              <option value="1995">1995</option>
                              <option value="1994">1994</option>
                              <option value="1993">1993</option>
                              <option value="1992">1992</option>
                              <option value="1991">1991</option>
                              <option value="1990">1990</option>
                              <option value="1989">1989</option>
                              <option value="1988">1988</option>
                              <option value="1987">1987</option>
                              <option value="1986">1986</option>
                              <option value="1985">1985</option>
                              <option value="1984">1984</option>
                              <option value="1983">1983</option>
                              <option value="1982">1982</option>
                              <option value="1981">1981</option>
                              <option value="1980">1980</option>
                              <option value="1979">1979</option>
                              <option value="1978">1978</option>
                              <option value="1977">1977</option>
                              <option value="1976">1976</option>
                              <option value="1975">1975</option>
                              <option value="1973">1973</option>
                              <option value="1972">1972</option>
                              <option value="1971">1971</option>
                              <option value="1974">1974</option>
                              <option value="1973">1973</option>
                              <option value="1972">1972</option>
                              <option value="1971">1971</option>
                              <option value="1970">1970</option>
                              <option value="1969">1969</option>
                              <option value="1968">1968</option>
                              <option value="1967">1967</option>
                              <option value="1966">1966</option>
                              <option value="1965">1965</option>
                              <option value="1964">1964</option>
                              <option value="1963">1963</option>
                              <option value="1962">1962</option>
                              <option value="1961">1961</option>
                              <option value="1960">1960</option>
                            </select>
                            {/* <label>Year</label> */}
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>

                    <Col md={{ size: 12 }}>
                      {formError ? (
                        <p style={{ color: "red", textAlign: "center" }}>
                          All fields are required.
                        </p>
                      ) : null}
                      {loader ? (
                        <div className="text-center mt-3">
                          <Spinner
                            className="centered-spinner"
                            animation="border"
                            variant="success"
                          />
                        </div>
                      ) : (
                        <Button
                          type="submit"
                          className="nextButtonNew"
                          style={{ margin: "0px", width: "100%" }}
                          onClick={() => tutorSigupFunc()}
                          size="lg"
                          color="secondary"
                          block
                        >
                          Sign Up
                          <i className="fas fa-arrow-right" color="#fff" />
                        </Button>
                      )}
                      <br />
                      <div className="text-center">
                        <a href="https://feeds.lincolnmethod.com/#/">Already have account?</a>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Styles>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TutorSignupForm;
