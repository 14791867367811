import React, { Component } from 'react';
import HeaderTwo from './components/HeaderTwo';
// import HeroImage from './components/HeroImage';
import ServiceBox from './components/ServiceBox';
import AboutUsTwo from './components/AboutUsTwo';
// import CourseSlider from './components/CourseSlider';
import NumberCounter from './components/NumberCounter';
// import FreeCourse from './components/FreeCourse';
import TeamSlider from './components/TeamSlider';
import TestimonialSlider from './components/TestimonialSlider';
// import HomeBlog from './components/HomeBlog';
// import ImageGallery from './components/ImageGallery';
import FooterTwo from './components/FooterTwo';
import FeatureTwo from './components/FeatureTwo';
import StarTeachers from './components/StarTeachers';
// import SignupCounter from './components/SignupCounter';
import AcademicAdvisory from './components/AcademicsAdvisory';
import InPress from './components/InPress';
// import PopularCourse from './pages/courses/components/PopularCourse';
import { isMobileBrowser } from "./actions/utility";
import FeaturesMobileView from './components/FeaturesMobileView';
import GradeSubjectSelection from './components/GradeSubjectSelection';
import NeedTutor from './components/NeedTutor';
import SuccessStories from './pages/SuccessStories/SuccessStories';
import VideoTestimonial from './pages/VideoTestimonials/VideoTestimonial';
import AllVideosTestimonials from './components/AllVideoTestimonials';
import NeedKGSTutor from './components/NeedKGSTutor';

export default class KGSHome extends Component {
  render() {
    return (
      <div className="main-wrapper">

        {/* Header 2 */}
        <HeaderTwo />

        <NeedKGSTutor />

        {/* Grade Subject */}
        {/* <GradeSubjectSelection /> */}

        {/* <VideoTestimonial /> */}
        <AllVideosTestimonials />

        <SuccessStories />

        {/* Counter Area */}
        <NumberCounter />

        {/* About Us 2 */}
        <AboutUsTwo />

        <NeedKGSTutor />

        {/* Star Teachers */}
        {/* <StarTeachers /> */}

        {/* Features Two */}
        {/* {isMobileBrowser() ?

          <FeaturesMobileView />
          :
          <FeatureTwo />
        } */}

        {/* Academic Advisory */}
        {/* <AcademicAdvisory /> */}

        {/* Team Slider */}
        {/* <TeamSlider /> */}


        {/* Testimonial Slider */}
        {/* <TestimonialSlider /> */}


        {/* Course Slider */}
        {/* <CourseSlider /> */}

        {/* Sgnup Counter */}
        {/* <SignupCounter /> */}

        {/* In Press */}
        {/* <InPress /> */}

        {/* Service Box */}
        {/* <ServiceBox /> */}


        {/* Hero Image */}
        {/* <HeroImage /> */}


        {/* Features Area */}
        {/* <Features /> */}

        {/* Free Course Area */}
        {/* <FreeCourse /> */}

        {/* Blog Area */}
        {/* <HomeBlog /> */}

        {/* Image Gallery Area */}
        {/* <ImageGallery /> */}

        {/* Footer 2 */}
        <FooterTwo />

      </div>
    )
  }
}
