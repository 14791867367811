import React, { Component } from 'react';
import Datas from '../data/testimonial/testimonial-slider.json';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from "./HeaderTwo";
import { Styles } from "./styles/testimonialSlider.js";

class AllTestimonials extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Styles>
                <div className="main-wrapper unitednations-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    <section className="testimonial-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/Background/${Datas.backgroundImage})` , backgroundAttachment: 'fixed' }}>
                        <Container>
                            <Row>
                                <Col md="12">
                                    <div className="sec-title text-center">
                                        <h4>{Datas.secTitle}</h4>
                                    </div>
                                </Col>

                                {
                                    Datas.dataList.map((data, i) => (
                                        <Col md="4" className="testimonial-slider">
                                            <div className="slider-item" key={i} style={i >= 3 ? {paddingTop: 90} : {paddingTop: 10}}>
                                                <div className="desc" style={{minHeight: 300}}>
                                                    <h5>{data.testimonialTitleAll}</h5>
                                                    {/* <p>{data.testimonialDesc}</p> */}

                                                </div>
                                                <div className="writer">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/LM/Parents/${data.authorImg}`} className="slider-image" alt={data.authorImg} />
                                                    <h6>{data.authorName}</h6>
                                                    <p>{data.authorTitle}</p>
                                                    <img className="authorCountry" src={`/assets/images/LM/${data.authorCountry}`} />
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }

                                {/* <Col md="6">
                                    <div className="testimonial-desc">
                                        <p>
                                            <span>
                                                <img src="/assets/images/LM/Parents/p-avatar5.jpg" />

                                                <label>Hamza</label>
                                                Both my kids love taking their online classes with Lincoln Method. They love working on their dashboard and I can immediate results and reports that help identify their weak areas and improve!
                                            </span>
                                        </p>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </section>
                </div>
            </Styles>
        );
    }
}

export default AllTestimonials;