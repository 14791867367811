import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
// import CourseSearch from './CourseSearch';
// import CoursePrice from './CoursePrice';
// import PopularCourse from './PopularCourse';
// import CourseTag from './CourseTag';
// import CourseCategory from './CourseCategory';
import { Styles } from '../../courses/styles/courseCategory';
import { isMobileBrowser } from "../../../actions/utility";



class OnlineSchoolSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            applyGrid: false,
            showFiltersOnMobile: false,
        }
    }
    componentDidMount(){
        if (isMobileBrowser()) {
            this.setState({
                showFiltersOnMobile: true
            })
        }
            window.addEventListener("scroll", () => {
                const stickyMenu = document.querySelector(".course-category-sticky");
                if(stickyMenu != null){

                
                if (window.scrollY > 600) {
                    stickyMenu.classList.add("sticky");
                } else {
                    stickyMenu.classList.remove("sticky");
                    stickyMenu.classList.add("course-category-sticky");
                }
            }
                
            });
    }
    render() {
        const { grades , filterCourses , visibleGradeList} = this.props
        return (
            <Styles>
                <div className="course-sidebar-sticky">
                    <Row>
                       
                        {/* <Col md="12">
                            <div className="course-category-sticky">
                                <h5>Filter Courses</h5>
                                <ul className="category-item list-unstyled">
                                    {grades.length && grades.map((grade, key) =>
                                        <li className="check-btn" key={key} onClick={(e) => filterCourses(e, grade.grade)}>
                                            <label><input checked={visibleGradeList.includes(grade.grade)} type="checkbox" className="check-box" /> {grade.grade}</label>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </Col> */}
                        <Col md="12">
                            <div className="course-category-sticky">
                                {!this.state.showFiltersOnMobile ? 
                                <React.Fragment>
                                    <div className="d-flex justify-content-between">
                                <h5>Filter Courses</h5> <span className="onlyMobile font-weight-bold" onClick={() => this.setState({showFiltersOnMobile: !this.state.showFiltersOnMobile})}><i className="fas fa-times fa-1x mr-2"></i>Filter</span>
                                </div>
                                <div className="tag-box">
                                    {grades.length && grades.map((grade, key) =>
                                       
                                            <a onClick={(e) => filterCourses(e, grade)} className={visibleGradeList.includes(grade) ? "selected" : null}dangerouslySetInnerHTML={{ __html: grade}}  ></a>
                                    )}
                                </div>
                                </React.Fragment>
                                : <div className="text-center"> <span className="" onClick={() => this.setState({showFiltersOnMobile: !this.state.showFiltersOnMobile})}> <i className="fas fa-filter fa-2x"></i>Filter Courses <i className="fas fa-caret-down"></i></span> </div>}
                            </div>
                        </Col>
                       
                    </Row>
                </div>
            </Styles>
        )
    }
}

export default OnlineSchoolSidebar;