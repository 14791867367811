import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/bgImg";

const BackgroundImageWithText2 = () => {
  return (
    <Styles>
      <div
        className="bgImg"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/LM/usbg2.jpg')`,
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container>
          <Row>
            <Col xs={8} md={6}>
              <div className="d-flex">
                <i className="starColor fa fa-star fa-3x" />
                <i className="starColor fa fa-star fa-3x" />
                <i className="starColor fa fa-star fa-3x" />
                <i className="starColor fa fa-star fa-3x" />
                <i className="starColor fa fa-star fa-3x" />
              </div>
              <h2 className="bgText text-white">
                Students learn how to use the latest tools in artificial
                intelligence{" "}
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    </Styles>
  );
};

export default BackgroundImageWithText2;
