import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  footer.footer2 {
      margin-top: 40px;
    p.demo-box {
      position: fixed;
      bottom: 40px;
      right: 20px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: ${colors.gr_bg2};
      padding-top: 20px;
      color: #fff;
      text-align : center;
      cursor: pointer;
      // border: 1px solid #000;

      &:hover {
        background: ${colors.yellow};
      }
       
      @media (max-width: 767px) {
        display: none;
      }
    }
    .fixedDropdowns {
      background: ${colors.gr_bg};
      border: none;
      display: none;
      padding: 15px;

      color: #fff;
      font-size: 17px;
      -webkit-box-shadow: -1px -3px 6px 0px rgba(50, 50, 50, 0.75);
      -moz-box-shadow:    -1px -3px 6px 0px rgba(50, 50, 50, 0.75);
      box-shadow:         -1px -3px 6px 0px rgba(50, 50, 50, 0.75);
      transition: all 0.3s ease-out;

      @media(max-width: 767px) {
          display: block;
          position: fixed;
          left: 0;
          bottom: 0;
          margin: 0;
          border-radius: 0;
          z-index: 999;
          width: 100%;

      }
  }

    // .googleTranslator {
    //   position: absolute;
    //  bottom: calc(53px + 16px);
    //     right: 16px!important;
    // }
    p.translateText {
      color: #fff;
      margin-top: 10px;
    }
    .goog-logo-link {
      display: none !important;
    }

    .goog-te-gadget {
      color: transparent !important;
    }

    .goog-te-gadget .goog-te-combo {
      margin: 0px !important;
      color: blue !important;
    }

    #google_translate_element {
      //   position: absolute;
      bottom: calc(53px + 16px);
      right: 16px !important;
      // z-index: 99999;
    }
    .goog-te-gadget {
      font-family: Roboto, "Open Sans", sans-serif !important;
      text-transform: uppercase;
    }
    .goog-te-gadget-simple {
      //   background-color: rgba(255,255,255,0.20)!important;
      border: 1px solid rgba(255, 255, 255, 0.5) !important;
      padding: 8px !important;
      border-radius: 4px !important;
      font-size: 1rem !important;
      margin-top: 15px !important;
      //   line-height:2rem!important;
      display: inline-block;
      cursor: pointer;
      zoom: 1;
      width: 100%;
      text-align: center;
    }
    .goog-te-menu2 {
      max-width: 100%;
    }
    .goog-te-menu-value {
      color: #fff !important;
      &:before {
        font-family: "Material Icons";
        // content: "\E927";
        margin-right: 16px;
        font-size: 2rem;
        vertical-align: -10px;
        // width:32px!important;
      }
    }
    .goog-te-menu-value span:nth-child(5) {
      display: none;
    }
    .goog-te-menu-value span:nth-child(3) {
      border: none !important;
      font-family: "Material Icons";
      &:after {
        font-family: "Material Icons";
        content: "\E5C5";
        font-size: 1.5rem;
        vertical-align: -6px;
      }
    }

    .goog-te-gadget-icon {
      background-image: url(https://placehold.it/32) !important;
      background-position: 0px 0px;
      height: 32px !important;
      width: 32px !important;
      margin-right: 8px !important;
      //     OR
      display: none;
    }

    // ============ HIDE TOP BAR ============
    .goog-te-banner-frame.skiptranslate {
      display: none !important;
    }
    body {
      top: 0px !important;
    }

    /* ================================== *\
    Mediaqueries
\* ================================== */
@media (max-width: 667px) {
	#google_translate_element {
		bottom: calc(100% - 50% - 53px);
		left: 16px!important;
		width: 100%!important;
		goog-te-gadget {
			width:100%!important;
		}
		.skiptranslate {
			width:100%!important;			
		}
		.goog-te-gadget-simple {
			// width: calc(100% - 32px)!important;
			text-align: center;
		}	
	}
}
        background-size    : cover;
        background-position: center;
        background-repeat  : no-repeat;
        position           : relative;
        padding            : 70px 0 80px;

        &:before {
            position  : absolute;
            content   : "";
            background: ${colors.bg1};
            opacity   : 0.98;
            width     : 100%;
            height    : 100%;
            top       : 0;
            left      : 0;
        }
        .goog-te-gadget-simple {
          // width: calc(100% - 32px)!important;
          text-align: center;
        }
      }
    }
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    // padding: 70px 0 80px;

    &:before {
      position: absolute;
      content: "";
      background: ${colors.bg1};
      opacity: 0.98;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .footer-logo-info {
      img {
        max-width: 100%;
        margin-bottom: 30px;

        @media (max-width: 767px) {
          margin-bottom: 18px;
        }
      }

      p {
        font-size: 14px;
        color: ${colors.text3};
        line-height: 28px;
        margin-bottom: 20px;

        @media (max-width: 991px) {
          line-height: 25px;
          margin-bottom: 12px;
        }
      }

      ul {
        li {
          color: ${colors.text3};
          margin-bottom: 12px;

          i {
            font-size: 18px;
            color: ${colors.green};
            width: 35px;
            vertical-align: top;

            @media (max-width: 991px) {
              width: 20px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }

    .f-links {
      padding-left: 25px;

      h5 {
        color: ${colors.border1};
        text-transform: uppercase;
        margin-top: 8px;
        margin-bottom: 35px;

        @media (max-width: 991px) {
          font-size: 18px;
          margin-bottom: 29px;
        }

        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }

      ul {
        li {
          a {
            font-size: 14px;
            color: ${colors.text3};
            line-height: 39px;

            i {
              font-size: 12px;
              color: ${colors.green};
              margin-right: 10px;

              @media (max-width: 991px) {
                margin-right: 5px;
              }
            }

            &:hover {
              color: ${colors.green};
            }
          }
        }
      }
      p {
        color: ${colors.text3};
      }

      @media (max-width: 991px) {
        padding-left: 0;
      }

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }

    .f-post {
      h5 {
        color: ${colors.border1};
        text-transform: uppercase;
        margin-top: 8px;
        margin-bottom: 42px;

        @media (max-width: 991px) {
          font-size: 18px;
          margin-bottom: 39px;
        }

        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }

      .post-box {
        margin-bottom: 22px;

        .po-icon {
          i {
            font-size: 26px;
            color: ${colors.green};
            line-height: 30px;
            margin-right: 10px;
          }
        }

        .po-content {
          a {
            font-size: 14px;
            color: ${colors.text3};
            display: inline-block;
            margin-bottom: 3px;

            &:hover {
              color: ${colors.green};
            }
          }

          span {
            display: block;
            color: ${colors.text2};
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        @media (max-width: 991px) {
          margin-bottom: 10px;
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }

    .f-newsletter {
      h5 {
        color: ${colors.border1};
        text-transform: uppercase;
        margin-top: 8px;
        margin-bottom: 38px;

        @media (max-width: 991px) {
          font-size: 18px;
          margin-bottom: 37px;
        }

        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 14px;
        color: ${colors.text3};
        line-height: 25px;
        margin-bottom: 22px;
      }

      form.form {
        p.form-control {
          padding: 0;
          width: auto;
          height: auto;
          background: transparent;
          border: none;
          margin-bottom: 25px;
          position: relative;

          input {
            width: 100%;
            height: 40px;
            border: 1px solid ${colors.text2};
            background: transparent;
            color: ${colors.border1};
            padding-left: 15px;
            border-radius: 5px;

            &::placeholder {
              font-style: italic;
              color: ${colors.text3};
            }
          }

          span {
            font-size: 13px;
            color: ${colors.green};
            font-weight: 300;
            position: absolute;
            bottom: -22px;
            left: 0;
            visibility: hidden;
          }
        }

        p.form-control.success {
          input {
            border: 2px solid ${colors.green};
          }

          &::before {
            position: absolute;
            content: "\f058";
            font-family: "Line Awesome Free";
            font-size: 24px;
            color: ${colors.green};
            font-weight: 900;
            top: 8px;
            right: 10px;
          }
        }

        p.form-control.error {
          input {
            border: 2px solid ${colors.red};
          }

          &::before {
            position: absolute;
            content: "\f06a";
            font-family: "Line Awesome Free";
            font-size: 24px;
            color: ${colors.red};
            font-weight: 900;
            top: 8px;
            right: 10px;
          }
        }

        p.form-control.error {
          span {
            visibility: visible;
          }
        }

        button {
          font-size: 15px;
          color: ${colors.border1};
          background: ${colors.gr_bg};
          width: 100%;
          height: 40px;
          font-weight: 500;
          border: none;
          border-radius: 5px;

          &:hover {
            background: ${colors.gr_bg2};
          }
        }
      }
    }

    .copytext-area {
      border-top: 1px solid ${colors.black2};
      padding-top: 45px;
      margin-top: 40px;

      p {
        font-size: 14px;
        color: ${colors.text3};
        margin-bottom: 25px;

        i {
          color: ${colors.green};
          margin: 0 2px;
        }

        a {
          color: ${colors.green};

          &:hover {
            text-decoration: underline;
          }
        }
      }

      ul.social {
        li {
          a {
            text-align: center;
            position: relative;
            height: 18px;
            display: inline-block;

            &:before {
              content: "";
              position: absolute;
              border-width: 9px 17px;
              border-style: solid;
              border-top-color: transparent;
              border-right-color: transparent;
              border-left-color: transparent;
              top: -18px;
              left: 0;
              z-index: 1;
              transition: all 0.2s ease;
            }

            &:after {
              content: "";
              position: absolute;
              border-width: 9px 17px;
              border-style: solid;
              border-right-color: transparent;
              border-bottom-color: transparent;
              border-left-color: transparent;
              bottom: -18px;
              left: 0;
              z-index: 1;
              transition: all 0.2s ease;
            }

            i {
              font-size: 14px;
              color: #ffffff;
              width: 34px;
            }

            &:hover {
              background-color: ${colors.green} !important;

              &:before {
                border-bottom-color: ${colors.green} !important;
              }

              &:after {
                border-top-color: ${colors.green} !important;
              }
            }
          }

          &:nth-child(1) {
            a {
              background-color: #4267b2;

              &:before {
                border-bottom-color: #4267b2;
              }

              &:after {
                border-top-color: #4267b2;
              }
            }
          }

          &:nth-child(2) {
            a {
              background-color: #1da1f2;

              &:before {
                border-bottom-color: #1da1f2;
              }

              &:after {
                border-top-color: #1da1f2;
              }
            }
          }

          &:nth-child(3) {
            a {
              background-color: #2867b2;

              &:before {
                border-bottom-color: #2867b2;
              }

              &:after {
                border-top-color: #2867b2;
              }
            }
          }

          &:nth-child(4) {
            a {
              background-color: #dd1343;

              &:before {
                border-bottom-color: #dd1343;
              }

              &:after {
                border-top-color: #dd1343;
              }
            }
          }

          &:nth-child(5) {
            a {
              background-color: #ea4c89;

              &:before {
                border-bottom-color: #ea4c89;
              }

              &:after {
                border-top-color: #ea4c89;
              }
            }
          }
        }
      }
    }

    // @media (max-width: 767px) {
    //   padding: 50px 0;
    // }
  }
`;
