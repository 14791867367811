import React, { useState, useEffect } from "react";
import { doneWithUserDetails, doneWithUserEmail } from "./../../actions/webRedActions";
import { connect } from "react-redux";
import { Container, Row, Col, Button, FormGroup, Form } from "react-bootstrap";
import { Styles } from "./styles/userDetails.js";
import { onTrainTutorLink } from "../../actions/utility";
import countryData from "../../actions/countryData";

const UserDetails = (props) => {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [gender, setgender] = useState("");
  const [birthDate, setbirthDate] = useState("");
  const [b_date, setb_date] = useState("");
  const [b_month, setb_month] = useState("");
  const [b_year, setb_year] = useState("");
  const [formError, setformError] = useState(false);
  const [email, setemail] = useState("");
  const [teacherName, setteacherName] = useState("");
  const [teacherQualififcation, setteacherQualififcation] = useState("");
  const [teacherExperience, setteacherExperience] = useState("");
  const [teacherSchoolName, setteacherSchoolName] = useState("");
  const [teacherCurriculum, setteacherCurriculum] = useState("");
  const [teacherGrades, setteacherGrades] = useState("");
  const [teacherSubjects, setteacherSubjects] = useState([]);
  const [tutorCountry, settutorCountry] = useState("");
  const [errors, seterrors] = useState({
    firstname: "",
    lastname: "",
    gender: "",
    b_date: "",
    b_month: "",
    b_year: "",
    email: "",
  });

  const subjectList = [
    {
      id: 1,
      subjectName: "Mathematics",
    },
    {
      id: 3,
      subjectName: "Islamiat",
    },
    {
      id: 4,
      subjectName: "History",
    },
    {
      id: 5,
      subjectName: "Geography",
    },
    {
      id: 6,
      subjectName: "Urdu",
    },
    {
      id: 7,
      subjectName: "English",
    },
    {
      id: 16,
      subjectName: "Creative Writing",
    },
    {
      id: 8,
      subjectName: "Computer Studies",
    },
    {
      id: 9,
      subjectName: "Science",
    },
    {
      id: 10,
      subjectName: "Biology",
    },
    {
      id: 11,
      subjectName: "Chemistry",
    },
    {
      id: 12,
      subjectName: "Physics",
    },
    {
      id: 13,
      subjectName: "Accounts",
    },
    {
      id: 14,
      subjectName: "Economics",
    },
    {
      id: 15,
      subjectName: "Business Studies",
    },
    {
      id: 2,
      subjectName: "Other Language",
    },
  ];
  const curriculumList = [
    {
      id: 1,
      curriculumName: "UK: Cambridge (O & A Level)",
    },
    {
      id: 2,
      curriculumName: "UK: GSCE",
    },
    {
      id: 3,
      curriculumName: "UK: IGSCE",
    },
    {
      id: 4,
      curriculumName: "US: Common Core",
    },
    {
      id: 5,
      curriculumName: "Canada: Cambridge",
    },
    {
      id: 6,
      curriculumName: "China: Chinese National Curriculum",
    },
    {
      id: 7,
      curriculumName: "France: The Common Foundation of Knowledge, Skills and Culture",
    },
    {
      id: 8,
      curriculumName: "India: CBSE",
    },
    {
      id: 9,
      curriculumName: "India: CISCE",
    },
    {
      id: 10,
      curriculumName: "Pakistan: Matric",
    },
    {
      id: 11,
      curriculumName: "Phillipines",
    },
    {
      id: 12,
      curriculumName: "Poland: Matura",
    },
    {
      id: 13,
      curriculumName: "UAE: KHDA",
    },
  ];

  const { newUserName, userEmail } = props;

  const errorStyle = {
    color: "#fff",
    fontSize: "12px",
    textAlign: "right",
    paddingRight: "10px",
  };

  const genderClass = {
    background: "transparent",
    borderColor: "#11B67A",
    margin: "0 4px",
    color: "#000",
  };

  const genderActive = {
    background: "#11B67A",
    color: "#fff",
    borderColor: "#fff",
    margin: "0 4px",
  };

  useEffect(() => {
    if (userEmail !== "") {
      setemail(userEmail);
    }
  }, [props.userEmail]);
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case "firstname":
        errors.firstname = value.length < 1 ? "This field is required" : "";
        setfirstname(value);
        break;
      case "lastname":
        errors.lastname = value.length < 1 ? "This field is required" : "";
        setlastname(value);
        break;
      case "gender":
        errors.gender = value.length < 1 ? "gender field is required" : "";
        setgender(value);
        break;

      case "b_date":
        errors.birthDate = value.length < 1 ? "Date is required" : "";
        setb_date(value);
        break;

      case "b_month":
        errors.birthDate = value.length < 1 ? "Month is required" : "";
        setb_month(value);
        break;

      case "b_year":
        errors.birthDate = value.length < 1 ? "Year is required" : "";
        setb_year(value);
        break;
      case "email":
        errors.email = value.length < 1 ? "Email address is required" : "";
        setemail(value);
        break;
      //   case 'birthDate':
      //     errors.birthDate =
      //         value.length < 1
      //             ? 'Date is required'
      //             : '';
      //     break;

      default:
        break;
    }

    seterrors(errors);
    console.log("ERRORS", errors);
    console.log("fist", firstname);
    console.log("lastname", lastname);
    console.log("email", email);
    console.log("b_date", b_date);
    console.log("b_month", b_month);
    console.log("b_year", b_year);
    console.log("gender", gender);
  };

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  useEffect(() => {
    debugger;
    if (birthDate !== "") {
      var userDetails = {
        firstname: firstname,
        lastname: lastname,
        gender: gender,
        birthDate: birthDate,
      };
      console.log("User Details", userDetails);
      const tutorDetails = {
        teacherQualififcation,
        teacherExperience,
        teacherSchoolName,
        teacherCurriculum,
        teacherGrades,
        teacherSubjects,
        tutorCountry,
      };
      localStorage.setItem("tutorDetails", JSON.stringify(tutorDetails));
      props.doneWithUserDetails(userDetails);
      props.doneWithUserEmail(email);
    }
  }, [birthDate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      firstname.length &&
      lastname.length &&
      gender.length &&
      b_date.length &&
      b_month.length &&
      b_year.length &&
      email.length
    ) {
      // hence, form is validated. now call the action to submit
      setformError(false);
      setbirthDate(b_month + "/" + b_date + "/" + b_year);

      // var userDetails = {
      //     firstname: firstname,
      //     lastname: lastname,
      //     gender: gender,
      //     birthDate: birthDate
      // };
      // console.log("User Details" , userDetails);
      // props.doneWithUserDetails(userDetails);
      // props.doneWithUserEmail(email);
    } else {
      console.error("Invalid Form");
      setformError(true);
    }
  };

  // const handleSubmit = (event) => {
  //     event.preventDefault();
  //     // alert('asdsad')
  //     if (validateForm(errors)) {

  //         // hence, form is validated. now call the action to submit
  //         setformError(false);
  //         // setbirthDate(b_month + "/" + b_date + "/" + b_year);

  //         // var userDetails = {
  //         //     firstname: firstname,
  //         //     lastname: lastname,
  //         //     gender: gender,
  //         //     birthDate: birthDate
  //         // };
  //         // console.log("User Details" , userDetails);
  //         // props.doneWithUserDetails(userDetails);
  //         // props.doneWithUserEmail(email);

  //     } else {
  //         console.error('Invalid Form');
  //         setformError(true);
  //     }
  // }
  const selectedSubjectsFunction = (checkValue, data) => {
    if (checkValue) {
      setteacherSubjects([...teacherSubjects, data]);
    } else {
      var filterData = teacherSubjects.filter((item) => item.id !== data.id);
      setteacherSubjects(filterData);
    }
  };

  let teacher = false;
  if (onTrainTutorLink()) {
    teacher = true;
    debugger;
  } else {
    teacher = false;
  }
  return (
    <Styles>
      <div style={{ padding: "1vh 0" }} className="UserDetails">
        <div className="wrapper"></div>
        {!teacher ? (
          <Container>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <h3 style={{ margin: "50px 0px 50px 0px" }}>Who is {newUserName} ?</h3>
              </Col>
            </Row>

            <Form onSubmit={handleSubmit}>
              <Row className="phoneStyle">
                <Col xs="12">
                  <FormGroup style={{ position: "relative" }}>
                    <label>First name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="firstname"
                      id="firstname"
                      value={firstname}
                      onChange={(evt) => handleChange(evt)}
                      style={{
                        borderRadius: "10px",
                        marginTop: "35px",
                        borderColor: "#11B67A",
                      }}
                    />
                    <p style={errorStyle}>{errors.firstname}</p>
                  </FormGroup>

                  <FormGroup style={{ position: "relative" }}>
                    <label>Last name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="lastname"
                      id="lastname"
                      value={lastname}
                      onChange={(evt) => handleChange(evt)}
                      style={{
                        borderRadius: "10px",
                        marginTop: "35px",
                        borderColor: "#11B67A",
                      }}
                    />
                    <p style={errorStyle}>{errors.lastname}</p>
                  </FormGroup>

                  <FormGroup
                    style={{
                      position: "relative",
                      textAlign: "center",
                      width: "90%",
                      margin: "auto",
                    }}
                  >
                    <label style={{ marginLeft: 0, paddingLeft: "8px" }}>Gender</label>
                    <br />

                    <Button
                      value={"Male"}
                      name="gender"
                      onClick={(evt) => handleChange(evt)}
                      style={gender === "Male" ? genderActive : genderClass}
                      size="md"
                      color="secondary"
                    >
                      Male
                    </Button>

                    <Button
                      value={"Female"}
                      name="gender"
                      onClick={(evt) => handleChange(evt)}
                      style={gender === "Female" ? genderActive : genderClass}
                      size="md"
                      color="secondary"
                    >
                      Female
                    </Button>

                    <Button
                      value={"Non-binary"}
                      name="gender"
                      onClick={(evt) => handleChange(evt)}
                      style={gender === "Non-binary" ? genderActive : genderClass}
                      size="md"
                      color="secondary"
                    >
                      Non-binary
                    </Button>

                    <p style={errorStyle}>{errors.gender}</p>
                  </FormGroup>

                  <FormGroup style={{ position: "relative", padding: "0 15px" }}>
                    <label
                      style={{
                        paddingLeft: "7px",
                        // borderBottom: '1px #fafafa solid'
                      }}
                    >
                      Date of birth
                    </label>
                    <Row>
                      <Col xs="4" style={{ paddingRight: "5px" }}>
                        <select
                          className="form-control"
                          type="select"
                          name="b_date"
                          id="b_date"
                          style={{
                            padding: 0,
                            textAlign: "center",
                            borderColor: "#11B67A",
                          }}
                          onChange={(evt) => handleChange(evt)}
                        >
                          <option value="" disabled selected>
                            day
                          </option>
                          <option value="01">1</option>
                          <option value="02">2</option>
                          <option value="03">3</option>
                          <option value="04">4</option>
                          <option value="05">5</option>
                          <option value="06">6</option>
                          <option value="07">7</option>
                          <option value="08">8</option>
                          <option value="09">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </select>
                        {/* <label>Day</label> */}
                      </Col>
                      <Col xs="4" style={{ padding: 0 }}>
                        <select
                          className="form-control"
                          type="select"
                          name="b_month"
                          id="b_month"
                          style={{
                            padding: 0,
                            textAlign: "center",
                            borderColor: "#11B67A",
                          }}
                          onChange={(evt) => handleChange(evt)}
                        >
                          <option value="" disabled selected>
                            month
                          </option>
                          <option value="01">1</option>
                          <option value="02">2</option>
                          <option value="03">3</option>
                          <option value="04">4</option>
                          <option value="05">5</option>
                          <option value="06">6</option>
                          <option value="07">7</option>
                          <option value="08">8</option>
                          <option value="09">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                        {/* <label>Month</label> */}
                      </Col>
                      <Col xs="4" style={{ paddingLeft: "5px" }}>
                        <select
                          className="form-control"
                          type="select"
                          name="b_year"
                          id="b_year"
                          style={{
                            padding: 0,
                            textAlign: "center",
                            borderColor: "#11B67A",
                          }}
                          onChange={(evt) => handleChange(evt)}
                        >
                          <option value="" disabled selected>
                            year
                          </option>
                          {/* <option value="1920">1920</option>
                                                <option value="1921">1921</option>
                                                <option value="1922">1922</option>
                                                <option value="1923">1923</option>
                                                <option value="1924">1924</option>
                                                <option value="1925">1925</option>
                                                <option value="1926">1926</option>
                                                <option value="1927">1927</option>
                                                <option value="1928">1928</option>
                                                <option value="1929">1929</option>
                                                <option value="1930">1930</option>
                                                <option value="1931">1931</option>
                                                <option value="1932">1932</option>
                                                <option value="1933">1933</option>
                                                <option value="1934">1934</option>
                                                <option value="1935">1935</option>
                                                <option value="1936">1936</option>
                                                <option value="1937">1937</option>
                                                <option value="1938">1938</option>
                                                <option value="1939">1939</option>
                                                <option value="1940">1940</option>
                                                <option value="1941">1941</option>
                                                <option value="1942">1942</option>
                                                <option value="1943">1943</option>
                                                <option value="1944">1944</option>
                                                <option value="1945">1945</option>
                                                <option value="1946">1946</option>
                                                <option value="1947">1947</option>
                                                <option value="1948">1948</option>
                                                <option value="1949">1949</option>
                                                <option value="1950">1950</option>
                                                <option value="1951">1951</option>
                                                <option value="1952">1952</option>
                                                <option value="1953">1953</option>
                                                <option value="1954">1954</option>
                                                <option value="1955">1955</option>
                                                <option value="1956">1956</option>
                                                <option value="1957">1957</option>
                                                <option value="1958">1958</option>
                                                <option value="1959">1959</option> */}
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                          <option value="2015">2015</option>
                          <option value="2014">2014</option>
                          <option value="2013">2013</option>
                          <option value="2012">2012</option>
                          <option value="2011">2011</option>
                          <option value="2010">2010</option>
                          <option value="2009">2009</option>
                          <option value="2008">2008</option>
                          <option value="2007">2007</option>
                          <option value="2006">2006</option>
                          <option value="2005">2005</option>
                          <option value="2004">2004</option>
                          <option value="2003">2003</option>
                          <option value="2002">2002</option>
                          <option value="2001">2001</option>
                          <option value="2000">2000</option>
                          <option value="1999">1999</option>
                          <option value="1998">1998</option>
                          <option value="1997">1997</option>
                          <option value="1996">1996</option>
                          <option value="1995">1995</option>
                          <option value="1994">1994</option>
                          <option value="1993">1993</option>
                          <option value="1992">1992</option>
                          <option value="1991">1991</option>
                          <option value="1990">1990</option>
                          <option value="1989">1989</option>
                          <option value="1988">1988</option>
                          <option value="1987">1987</option>
                          <option value="1986">1986</option>
                          <option value="1985">1985</option>
                          <option value="1984">1984</option>
                          <option value="1983">1983</option>
                          <option value="1982">1982</option>
                          <option value="1981">1981</option>
                          <option value="1980">1980</option>
                          <option value="1979">1979</option>
                          <option value="1978">1978</option>
                          <option value="1977">1977</option>
                          <option value="1976">1976</option>
                          <option value="1975">1975</option>
                          <option value="1973">1973</option>
                          <option value="1972">1972</option>
                          <option value="1971">1971</option>
                          <option value="1974">1974</option>
                          <option value="1973">1973</option>
                          <option value="1972">1972</option>
                          <option value="1971">1971</option>
                          <option value="1970">1970</option>
                          <option value="1969">1969</option>
                          <option value="1968">1968</option>
                          <option value="1967">1967</option>
                          <option value="1966">1966</option>
                          <option value="1965">1965</option>
                          <option value="1964">1964</option>
                          <option value="1963">1963</option>
                          <option value="1962">1962</option>
                          <option value="1961">1961</option>
                          <option value="1960">1960</option>
                        </select>
                        {/* <label>Year</label> */}
                      </Col>
                    </Row>
                    {/* <input type="date" name="birthDate" id="birthDate" value={birthDate}
                                    onChange={evt => this.handleChange(evt)}
                                    style={{
                                        borderRadius: '10px',
                                        marginTop: '35px'
                                    }}
                                /> */}
                    <p style={errorStyle}>{errors.birthDate}</p>
                  </FormGroup>
                  <hr />
                  <FormGroup style={{ position: "relative" }}>
                    <label style={{ color: "#ff89bc" }}>
                      Where should we <strong> email </strong> class results?
                    </label>
                    <input
                      placeholder="Type email address"
                      className="form-control"
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(evt) => handleChange(evt)}
                      style={{
                        borderRadius: "10px",
                        marginTop: "35px",
                        borderColor: "#ff89bc",
                      }}
                    />
                    <p style={errorStyle}>{errors.lastname}</p>
                  </FormGroup>
                </Col>

                <Col md={{ size: 12 }}>
                  {formError ? (
                    <p style={{ color: "red", textAlign: "center" }}>All fields are required.</p>
                  ) : null}

                  <Button
                    type="submit"
                    className="nextButtonNew"
                    style={{ margin: "35px auto" }}
                    // onClick={(e) => {
                    //     this.handleSubmit(e);
                    // }}
                    size="lg"
                    color="secondary"
                    block
                  >
                    Next
                    <i className="fas fa-arrow-right" color="#fff" />
                  </Button>

                  {/* {
                                    firstname.length && lastname.length && gender.length && b_date.length && b_month.length && b_year.length && email.length ?

                                        <Button
                                            type="submit"
                                            className="nextButtonNew"
                                            style={{ margin: '35px auto' }}
                                            // onClick={(e) => {
                                            //     this.handleSubmit(e);
                                            // }}
                                            size="lg" color="secondary" block>
                                            Next
                                        <i className="fas fa-arrow-right" color="#fff" />
                                        </Button>

                                        : null
                                } */}
                </Col>
              </Row>
            </Form>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <h3 style={{ margin: "50px 0px 10px 0px" }}>Details about {newUserName}</h3>
              </Col>
            </Row>
            <Form onSubmit={handleSubmit}>
              <Row className="phoneStyle">
                <Col xs="12">
                  <FormGroup style={{ position: "relative" }}>
                    <label>First Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="firstname"
                      id="firstname"
                      value={firstname}
                      onChange={(evt) => setfirstname(evt.target.value)}
                      style={{
                        borderRadius: "10px",
                        marginTop: "35px",
                        borderColor: "#11B67A",
                      }}
                    />
                  </FormGroup>

                  <FormGroup style={{ position: "relative" }}>
                    <label>Last Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="firstname"
                      id="firstname"
                      value={lastname}
                      onChange={(evt) => setlastname(evt.target.value)}
                      style={{
                        borderRadius: "10px",
                        marginTop: "35px",
                        borderColor: "#11B67A",
                      }}
                    />
                  </FormGroup>

                  <FormGroup style={{ position: "relative" }}>
                    <label>Email</label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(evt) => handleChange(evt)}
                      style={{
                        borderRadius: "10px",
                        marginTop: "35px",
                        borderColor: "#11B67A",
                      }}
                    />
                    <p style={errorStyle}>{errors.email}</p>
                  </FormGroup>
                  <FormGroup style={{ position: "relative" }}>
                    <label>Highest education received?</label>
                    <div className="d-flex justify-content-center">
                      <select
                        className=""
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={teacherQualififcation}
                        onChange={(evt) => setteacherQualififcation(evt.target.value)}
                        style={{
                          borderRadius: "10px",
                          width: "90%",
                          borderColor: "#11B67A",
                          padding: "12px 5px",
                        }}
                      >
                        <option value="">Select</option>
                        <option value="A Levels">A Levels</option>
                        <option value="Bachelors">Bachelors</option>
                        <option value="Masters">Masters</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    {/* <p style={errorStyle}>{errors.lastname}</p> */}
                  </FormGroup>
                  <FormGroup style={{ position: "relative" }}>
                    <label>Country</label>
                    <div className="d-flex justify-content-center">
                      <select
                        className=""
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={tutorCountry}
                        onChange={(evt) => settutorCountry(evt.target.value)}
                        style={{
                          borderRadius: "10px",
                          width: "90%",
                          borderColor: "#11B67A",
                          padding: "12px 5px",
                        }}
                      >
                        <option value="">Select</option>
                        {countryData.map((item, index) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <p style={errorStyle}>{errors.lastname}</p> */}
                  </FormGroup>
                  <FormGroup style={{ position: "relative" }}>
                    <label>Do you have any previous experience in teaching?</label>
                    <div className="d-flex justify-content-center">
                      <select
                        className=""
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={teacherExperience}
                        onChange={(evt) => setteacherExperience(evt.target.value)}
                        style={{
                          borderRadius: "10px",
                          width: "90%",
                          borderColor: "#11B67A",
                          padding: "12px 5px",
                        }}
                      >
                        <option value="">Select</option>
                        <option value="1 to 2 Years">1 to 2 Years</option>
                        <option value="2 to 3 Years">2 to 3 Years</option>
                        <option value="More than 3 years">More than 3 years</option>
                        <option value="No Experience">No Experience</option>
                      </select>
                    </div>
                    {/* <p style={errorStyle}>{errors.lastname}</p> */}
                  </FormGroup>
                  {teacherExperience != "No Experience" && teacherExperience != "" && (
                    <FormGroup style={{ position: "relative" }}>
                      <label>School Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={teacherSchoolName}
                        onChange={(evt) => setteacherSchoolName(evt.target.value)}
                        style={{
                          borderRadius: "10px",
                          marginTop: "35px",
                          borderColor: "#11B67A",
                        }}
                      />
                      {/* <p style={errorStyle}>{errors.lastname}</p> */}
                    </FormGroup>
                  )}
                  <FormGroup style={{ position: "relative" }}>
                    <label>
                      Which curriculum(s) are you currently teaching or have taught in past?
                    </label>
                    <div className="d-flex justify-content-center">
                      <select
                        className=""
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={teacherCurriculum}
                        onChange={(evt) => setteacherCurriculum(evt.target.value)}
                        style={{
                          borderRadius: "10px",
                          width: "90%",
                          borderColor: "#11B67A",
                          padding: "12px 5px",
                        }}
                      >
                        <option value="">Select</option>
                        {curriculumList.map((item, index) => (
                          <option value={item.curriculumName} key={index}>
                            {item.curriculumName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <p style={errorStyle}>{errors.lastname}</p> */}
                  </FormGroup>
                  <FormGroup style={{ position: "relative" }}>
                    <label>
                      {" "}
                      Which grade(s) are you currently teaching or have taught in past?
                    </label>
                    <div className="d-flex justify-content-center">
                      <select
                        className=""
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={teacherGrades}
                        onChange={(evt) => setteacherGrades(evt.target.value)}
                        style={{
                          borderRadius: "10px",
                          width: "90%",
                          borderColor: "#11B67A",
                          padding: "12px 5px",
                        }}
                      >
                        <option value="">Select</option>
                        <option value="Primary (KG - Grade 5)">Primary (KG - Grade 5)</option>
                        <option value="Secondary (Grade 6 - Grade 11)">
                          Secondary (Grade 6 - Grade 11)
                        </option>
                      </select>
                    </div>
                    {/* <p style={errorStyle}>{errors.lastname}</p> */}
                  </FormGroup>
                  <FormGroup style={{ position: "relative" }}>
                    <label className="mb-2">
                      {" "}
                      Which subjects are you currently teaching or have taught in the past?
                    </label>
                    {subjectList.map((item, index) => (
                      <div key={index} className="d-flex" style={{ padding: "2px 20px" }}>
                        <input
                          onClick={(e) => selectedSubjectsFunction(e.target.checked, item)}
                          type="checkbox"
                          className="check-box"
                          style={{ appearance: "auto", width: "auto" }}
                        />
                        <label className="pl-3">{item.subjectName}</label>
                      </div>
                    ))}
                  </FormGroup>
                  <FormGroup
                    style={{
                      position: "relative",
                      textAlign: "center",
                      width: "90%",
                      margin: "auto",
                    }}
                  >
                    <label style={{ marginLeft: 0, paddingLeft: "8px" }}>Gender</label>
                    <br />

                    <Button
                      value={"Male"}
                      name="gender"
                      onClick={(evt) => handleChange(evt)}
                      style={gender === "Male" ? genderActive : genderClass}
                      size="md"
                      color="secondary"
                    >
                      Male
                    </Button>

                    <Button
                      value={"Female"}
                      name="gender"
                      onClick={(evt) => handleChange(evt)}
                      style={gender === "Female" ? genderActive : genderClass}
                      size="md"
                      color="secondary"
                    >
                      Female
                    </Button>

                    <Button
                      value={"Non-binary"}
                      name="gender"
                      onClick={(evt) => handleChange(evt)}
                      style={gender === "Non-binary" ? genderActive : genderClass}
                      size="md"
                      color="secondary"
                    >
                      Non-binary
                    </Button>

                    <p style={errorStyle}>{errors.gender}</p>
                  </FormGroup>

                  <FormGroup style={{ position: "relative", padding: "0 15px" }}>
                    <label
                      style={{
                        paddingLeft: "7px",
                        // borderBottom: '1px #fafafa solid'
                      }}
                    >
                      Date of birth
                    </label>
                    <Row>
                      <Col xs="4" style={{ paddingRight: "5px" }}>
                        <select
                          className="form-control"
                          type="select"
                          name="b_date"
                          id="b_date"
                          style={{
                            padding: 0,
                            textAlign: "center",
                            borderColor: "#11B67A",
                          }}
                          onChange={(evt) => handleChange(evt)}
                        >
                          <option value="" disabled selected>
                            day
                          </option>
                          <option value="01">1</option>
                          <option value="02">2</option>
                          <option value="03">3</option>
                          <option value="04">4</option>
                          <option value="05">5</option>
                          <option value="06">6</option>
                          <option value="07">7</option>
                          <option value="08">8</option>
                          <option value="09">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </select>
                      </Col>
                      <Col xs="4" style={{ padding: 0 }}>
                        <select
                          className="form-control"
                          type="select"
                          name="b_month"
                          id="b_month"
                          style={{
                            padding: 0,
                            textAlign: "center",
                            borderColor: "#11B67A",
                          }}
                          onChange={(evt) => handleChange(evt)}
                        >
                          <option value="" disabled selected>
                            month
                          </option>
                          <option value="01">1</option>
                          <option value="02">2</option>
                          <option value="03">3</option>
                          <option value="04">4</option>
                          <option value="05">5</option>
                          <option value="06">6</option>
                          <option value="07">7</option>
                          <option value="08">8</option>
                          <option value="09">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </Col>
                      <Col xs="4" style={{ paddingLeft: "5px" }}>
                        <select
                          className="form-control"
                          type="select"
                          name="b_year"
                          id="b_year"
                          style={{
                            padding: 0,
                            textAlign: "center",
                            borderColor: "#11B67A",
                          }}
                          onChange={(evt) => handleChange(evt)}
                        >
                          <option value="" disabled selected>
                            year
                          </option>
                          <option value="1970">1970</option>
                          <option value="1971">1971</option>
                          <option value="1972">1972</option>
                          <option value="1973">1973</option>
                          <option value="1974">1974</option>
                          <option value="1971">1971</option>
                          <option value="1972">1972</option>
                          <option value="1973">1973</option>
                          <option value="1975">1975</option>
                          <option value="1976">1976</option>
                          <option value="1977">1977</option>
                          <option value="1978">1978</option>
                          <option value="1979">1979</option>
                          <option value="1980">1980</option>
                          <option value="1981">1981</option>
                          <option value="1982">1982</option>
                          <option value="1983">1983</option>
                          <option value="1984">1984</option>
                          <option value="1985">1985</option>
                          <option value="1986">1986</option>
                          <option value="1987">1987</option>
                          <option value="1988">1988</option>
                          <option value="1989">1989</option>
                          <option value="1990">1990</option>
                          <option value="1991">1991</option>
                          <option value="1992">1992</option>
                          <option value="1993">1993</option>
                          <option value="1994">1994</option>
                          <option value="1995">1995</option>
                          <option value="1996">1996</option>
                          <option value="1997">1997</option>
                          <option value="1998">1998</option>
                          <option value="1999">1999</option>
                          <option value="2000">2000</option>
                          <option value="2001">2001</option>
                          <option value="2002">2002</option>
                          <option value="2003">2003</option>
                          <option value="2004">2004</option>
                          <option value="2005">2005</option>
                          <option value="2006">2006</option>
                          <option value="2007">2007</option>
                          <option value="2008">2008</option>
                          <option value="2009">2009</option>
                          <option value="2010">2010</option>
                          <option value="2011">2011</option>
                          <option value="2012">2012</option>
                          <option value="2013">2013</option>
                          <option value="2014">2014</option>
                          <option value="2015">2015</option>
                          <option value="2016">2016</option>
                          <option value="2017">2017</option>
                          <option value="2018">2018</option>
                          <option value="2019">2019</option>
                          <option value="2020">2020</option>
                        </select>
                      </Col>
                    </Row>

                    <p style={errorStyle}>{errors.birthDate}</p>
                  </FormGroup>
                </Col>

                <Col md={{ size: 12 }}>
                  {formError ? (
                    <p style={{ color: "red", textAlign: "center" }}>All fields are required.</p>
                  ) : null}

                  <Button
                    type="submit"
                    className="nextButtonNew"
                    style={{ margin: "35px auto" }}
                    // onClick={(e) => {
                    //     this.handleSubmit(e);
                    // }}
                    size="lg"
                    color="secondary"
                    block
                  >
                    Next
                    <i className="fas fa-arrow-right" color="#fff" />
                  </Button>

                  {/* {
                                    firstname.length && lastname.length && gender.length && b_date.length && b_month.length && b_year.length && email.length ?

                                        <Button
                                            type="submit"
                                            className="nextButtonNew"
                                            style={{ margin: '35px auto' }}
                                            // onClick={(e) => {
                                            //     this.handleSubmit(e);
                                            // }}
                                            size="lg" color="secondary" block>
                                            Next
                                        <i className="fas fa-arrow-right" color="#fff" />
                                        </Button>

                                        : null
                                } */}
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      </div>
    </Styles>
  );
};

export default connect(null, { doneWithUserDetails, doneWithUserEmail })(UserDetails);
