import React, { Component } from 'react';
import Datas from '../../data/partnership/unitedNation.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/unitedNations.js';
import ModalVideo from 'react-modal-video';

class UnitedNationsComponent extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isOpen2: false,
            isOpen3: false,
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }
    render() {

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper unitednations-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="UN’s" backgroundImage="/assets/images/LM/partnerships/UN_Pic_Banner.png" />

                    {/* Gallery Area */}
                    <section className="unitednations-page-area">
                        <Container>
                            <Row>
                                <Col lg="12">
                                    <div className="heading">
                                        <h4>Lincoln Method presents it’s vision for Quality Education at the United Nations in Geneva (SDG #4)</h4>
                                    </div>

                                    <br />

                                    <div className="heading-text">
                                        <p>Lincoln Method is a proud Goalkeeper of the UN’s Sustainable Development Goal #4, which endorses Quality Education.</p>

                                        <p>Achieving inclusive and quality education for all reaffirms the belief that education is one of the most powerful and proven vehicles for sustainable development.</p>

                                        <p>Lincoln Method is one of the few institutions that is promoting digital education in Pakistan.</p>

                                        <p>Affordable, reliable and context-sensitive digital education, can promote equal opportunities for students and reduce inequalities by ensuring every child has access to high quality content. Digital education technologies improves fundamental skills such as collaboration, problem solving and global awareness.</p>
                                    </div>
                                </Col>
                                {
                                    Datas.map((data, i) => (
                                        <Col lg="4" sm="6" key={i}>
                                            <div className="gallery-box">
                                                <ModalImage small={`/assets/images/LM/partnerships/twitter/${data.galleryImage}`} large={`/assets/images/LM/partnerships/twitter/${data.galleryImage}`} alt="" />
                                            </div>
                                        </Col>
                                    ))
                                }

                            </Row>

                        </Container>
                        <div className="row-divider">
                            <Container>
                                <Row>
                                    <Col lg="3">
                                        <ModalImage small={`/assets/images/LM/partnerships/united/un2.jpg`} large={`/assets/images/LM/partnerships/united/un2.jpg`} alt="" />
                                    </Col>
                                    <Col lg="3" md="6">
                                        <div>
                                            <ModalImage className="" small={`/assets/images/LM/partnerships/united/un1.jpg`} large={`/assets/images/LM/partnerships/united/un1.jpg`} alt="" />
                                        </div>
                                        <div>
                                            <ModalImage className="smallImage" small={`/assets/images/LM/partnerships/united/un5.jpg`} large={`/assets/images/LM/partnerships/united/un5.jpg`} alt="" />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <ModalImage small={`/assets/images/LM/partnerships/united/un3.jpg`} large={`/assets/images/LM/partnerships/united/un3.jpg`} alt="" />
                                    </Col>
                                    <Col lg="3">
                                        <ModalImage small={`/assets/images/LM/partnerships/united/un7.jpg`} large={`/assets/images/LM/partnerships/united/un7.jpg`} alt="" />
                                    </Col>
                                    {/* </Row>

                            <Row style={{ padding: "5vh 0" }}> */}
                                    <Col lg="4">
                                        <ModalImage small={`/assets/images/LM/partnerships/united/un4.jpg`} large={`/assets/images/LM/partnerships/united/un4.jpg`} alt="" />
                                    </Col>
                                    <Col lg="4">
                                        <ModalImage small={`/assets/images/LM/partnerships/united/un6.jpg`} large={`/assets/images/LM/partnerships/united/un6.jpg`} alt="" />
                                    </Col>
                                    <Col lg="4">
                                        <ModalImage small={`/assets/images/LM/partnerships/united/un8.jpg`} large={`/assets/images/LM/partnerships/united/un8.jpg`} alt="" />
                                    </Col>
                                </Row>

                                <Row style={{ padding: "5vh 0" }}>
                                    <Col lg="6">
                                        <div className="video-player" style={{ backgroundImage: `url(/assets/images/LM/partnerships/video1.png)` }}>
                                            <ModalVideo channel='custom' isOpen={this.state.isOpen} url="/assets/images/LM/video1.mp4" onClose={() => this.setState({ isOpen: false })} />
                                            <button onClick={this.openModal} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="video-player" style={{ backgroundImage: `url(/assets/images/LM/partnerships/video2.png)` }}>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen2} videoId='3IB4GREDpKc' onClose={() => this.setState({ isOpen2: false })} />
                                            <button onClick={() => this.setState({ isOpen2: true })} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                    <div className="video-player" style={{ backgroundImage: `url(/assets/images/LM/partnerships/video3.png)` }}>
                                            <ModalVideo channel='custom' isOpen={this.state.isOpen3} url="/assets/images/LM/video2.mp4" onClose={() => this.setState({ isOpen3: false })} />
                                            <button onClick={() => this.setState({ isOpen3: true })} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default UnitedNationsComponent