import React, { Component } from 'react';
import Datas from '../../data/walloffame/wallOfFame.json';
import DatasNew from '../../data/walloffame/wallOfFameNew.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Link } from "react-router-dom";
import { Styles } from '../walloffame/styles/gallery.js';

class SuccessStories extends Component {
    render() {

        const DataForImages = [
            {
                galleryImage: "6.jpg"
            },

            {
                galleryImage: "32.jpeg"
            },
            {
                galleryImage: "8.jpeg"
            },
            {
                galleryImage: "7.jpg"
            },
            {
                galleryImage: "17.jpeg"
            },
            {
                galleryImage: "18.jpeg"
            },
            {
                galleryImage: "20.jpeg"
            },
            {
                galleryImage: "27.jpeg"
            },
            {
                galleryImage: "38.jpeg"
            },
            {
                galleryImage: "62.jpeg"
            },
            {
                galleryImage: "63.jpeg"
            },

            {
                galleryImage: "45.jpeg"
            },
        ]
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper gallery-page">

                    {/* Header 2 */}
                    {/* <HeaderTwo /> */}

                    {/* Breadcroumb */}
                    {/* <BreadcrumbBox title="Success Stories" /> */}

                    {/* Gallery Area */}
                    <section className="gallery-page-area">
                        <Container>
                            <Row>
                                <Col md="12" className="text-center" >
                                    <h3>Success Stories</h3>
                                </Col>
                                {
                                    DataForImages.map((data, i) => (
                                        <Col lg="3" sm="6" key={i}>
                                            <div className="gallery-box">
                                                <ModalImage small={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/${data.galleryImage}`} large={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/${data.galleryImage}`} alt="" />
                                            </div>
                                        </Col>
                                    ))
                                }
                                {/* {
                                    DataForImages.map((data, i) => (
                                        <Col lg="3" sm="6" key={i}>
                                            <div className="gallery-box">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/${data.galleryImage}`} alt="" />
                                            </div>
                                        </Col>
                                    ))
                                } */}
                                <Col md="12">
                                    <div className="buttonAllView">
                                        <Link to="/wall-of-fame">
                                            <button className="">View All Success Stories</button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    {/* <FooterTwo /> */}

                </div>
            </Styles>
        )
    }
}

export default SuccessStories