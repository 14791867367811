import React, { Component } from "react";
import store from './../../store';
import { doRegister, validateIfEmailExists } from './../../actions/webRedActions';
import { connect } from 'react-redux';

import { validateEmailAddress, validatePhone, getCurrentClient, getGradeId } from './../../actions/utility';

import {
    Container,
    Row,
    Col,
    Button,
    Spinner,
    FormGroup
} from "react-bootstrap";
import { Styles } from './styles/signupForm.js';


let additionalFields = []; // extrafields array global\

class SignUpForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            firstName: '',
            firstName_Error: false,
            lastName: '',
            lastName_Error: false,
            childFirstName: '',
            childFirstName_Error: false,
            childLastName: '',
            childLastName_Error: false,
            childEmail: '',
            childEmail_Error: false,
            childPassword: '',
            childPassword_Error: false,
            childCPassword: '',
            childCPassword_Error: false,
            parentFullName: '',
            parentFullName_Error: false,
            parentEmail: '',
            parentEmail_Error: false,
            parentPhone: '',
            parentPhone_Error: false,
            gender: "Male",
            phone: '',
            phone_Error: false,
            email: '',
            email_Error: false,
            email_Error_already: false,
            password: '',
            password_Error: false,
            c_password: '',
            c_password_Error: false,
            dob: '',
            dob_Error: false,
            terms: false,
            terms_Error: false,
            verifyEmailMessage: false,
            form_Error: false,
            emailTemporary: '',
            emailTemporaryParent: '',
            allowEmailNotExist: true
            // unableRegisterButton : false
        };

        this.submitRegisterForm = this.submitRegisterForm.bind(this);
        // this.validateField = this.validateField.bind(this);

    }

    componentDidMount() {
        // console.log(this.props.data)
    }

    componentWillReceiveProps(nextProps, thisProps) {
        // console.log(nextProps)

        if (this.props.signupSuccess != nextProps.signupSuccess) {

            if (typeof nextProps.signupSuccess === 'object' && nextProps.signupSuccess.showPage) {
                // showing here the incorrect credentials msg
                this.setState({
                    showLoader: false,
                    form_Error: false
                },
                    () => {
                        if (nextProps.signupSuccess.showPage == "emailVerification") {
                            this.setState({
                                emailTemporary: this.state.email,
                                emailTemporaryParent: this.state.parentEmail
                            }, () => {
                                this.setState({
                                    verifyEmailMessage: true,
                                    email: '',
                                    childEmail: '',
                                    parentEmail: ''
                                })
                            })
                        }
                    })
            }
            else {
                var errorMessage = nextProps.signupSuccess.split('_r_');
                this.setState({
                    showLoader: false,
                    form_Error: errorMessage[0],
                    verifyEmailMessage: false
                })
            }
        }


        if (this.props.validateEmailExistsRes != nextProps.validateEmailExistsRes) {
            if (typeof nextProps.validateEmailExistsRes.data === 'object' && nextProps.validateEmailExistsRes.data.data && nextProps.validateEmailExistsRes.data.data.status == "success") {
                var checkEmailParam = nextProps.validateEmailExistsRes.data.data.result;
                var formType = nextProps.validateEmailExistsRes.formType;

                this.setState({
                    email_Error_already: false
                }, () => {

                    if (formType == 'child') {
                        if (this.state.childEmail == '') {
                            this.setState({
                                childEmail_Error: "required"
                            })
                        }
                        else if (!validateEmailAddress(this.state.childEmail)) {
                            this.setState({
                                childEmail_Error: "not valid email address"
                            })
                        }
                        else if (checkEmailParam !== 0 && checkEmailParam !== '0') {
                            this.setState({
                                childEmail_Error: "This email is already in use",
                                email_Error_already: true
                            })
                        }
                        else {
                            this.setState({
                                childEmail_Error: ""
                            })
                        }
                    } else {
                        if (this.state.email == '') {
                            this.setState({
                                email_Error: "required"
                            })
                        }
                        else if (!validateEmailAddress(this.state.email)) {
                            this.setState({
                                email_Error: "not valid email address"
                            })
                        }
                        else if (checkEmailParam !== 0 && checkEmailParam !== '0') {
                            this.setState({
                                email_Error: "This email is already in use",
                                email_Error_already: true
                            })
                        }
                        else {
                            this.setState({
                                email_Error: ""
                            })
                        }
                    }

                })


            }
        }


    }

    submitRegisterForm(type, isCredentialActive) {

        if (type == "child") { // if signup type is child

            if (this.state.childFirstName == '') {
                this.setState({
                    childFirstName_Error: "required"
                })
            }
            else if (this.state.childFirstName.length < 3) {
                this.setState({
                    childFirstName_Error: "too short"
                })
            }
            else {
                this.setState({
                    childFirstName_Error: ""
                })
            }

            if (this.state.childLastName == '') {
                this.setState({
                    childLastName_Error: "required"
                })
            }
            else if (this.state.childLastName.length < 3) {
                this.setState({
                    childLastName_Error: "too short"
                })
            }
            else {
                this.setState({
                    childLastName_Error: ""
                })
            }

            if (!this.state.email_Error_already) {
                if (this.state.childEmail == '') {
                    this.setState({
                        childEmail_Error: "required"
                    })
                }
                else if (!validateEmailAddress(this.state.childEmail)) {
                    this.setState({
                        childEmail_Error: "not valid email address"
                    })
                }
                else {
                    this.setState({
                        childEmail_Error: ""
                    })
                }
            }

            if (this.state.childPassword == '') {
                this.setState({
                    childPassword_Error: "required"
                })
            }
            else if (this.state.childPassword.length < 3) {
                this.setState({
                    childPassword_Error: "password is too short"
                })
            }
            else {
                this.setState({
                    childPassword_Error: ""
                })
            }

            if (this.state.childCPassword == '') {
                this.setState({
                    childCPassword_Error: "required"
                })
            }
            else if (this.state.childCPassword.length < 3) {
                this.setState({
                    childCPassword_Error: "password is too short"
                })
            }
            else if (this.state.childCPassword != this.state.childPassword) {
                this.setState({
                    childCPassword_Error: "confirm password should be same"
                })
            }
            else {
                this.setState({
                    childCPassword_Error: ""
                })
            }

            if (this.state.parentFullName == '') {
                this.setState({
                    parentFullName_Error: "required"
                })
            }
            else if (this.state.parentFullName.length < 3) {
                this.setState({
                    parentFullName_Error: "Fullname is too short"
                })
            }
            else {
                this.setState({
                    parentFullName_Error: ""
                })
            }

            if (this.state.parentPhone == '') {
                this.setState({
                    parentPhone_Error: "required"
                })
            }
            else if (!validatePhone(this.state.parentPhone)) {
                this.setState({
                    parentPhone_Error: "not valid phone number"
                })
            }
            else {
                this.setState({
                    parentPhone_Error: ""
                })
            }

            if (this.state.parentEmail == '') {
                this.setState({
                    parentEmail_Error: "required"
                })
            }
            else if (!validateEmailAddress(this.state.parentEmail)) {
                this.setState({
                    parentEmail_Error: "not valid email address"
                })
            }
            else {
                this.setState({
                    parentEmail_Error: ""
                })
            }

            if (!this.state.terms) {
                this.setState({
                    terms_Error: "Terms & conditions should be checked."
                })
            }
            else {
                this.setState({
                    terms_Error: ""
                },
                    () => {

                        const { childFirstName_Error,
                            childLastName_Error,
                            childEmail_Error,
                            childPassword_Error,
                            childCPassword_Error,
                            parentFullName_Error,
                            parentPhone_Error,
                            parentEmail_Error,
                            terms_Error } = this.state; // getting all states for child form submission

                        if (isCredentialActive == 1) { // checking if isCredential required for child
                            if (
                                childFirstName_Error == '' &&
                                childLastName_Error == '' &&
                                childEmail_Error == '' &&
                                childPassword_Error == '' &&
                                childCPassword_Error == '' &&
                                parentFullName_Error == '' &&
                                parentPhone_Error == '' &&
                                parentEmail_Error == '' &&
                                terms_Error == ''
                            ) {
                                // calls the doRegister action here
                                this.registerMethodCall(type);
                            }
                            else {
                                this.setState({
                                    verifyEmailMessage: false,
                                    form_Error: false
                                })
                            }
                        }
                        else {
                            if (
                                childFirstName_Error == '' &&
                                childLastName_Error == '' &&
                                parentFullName_Error == '' &&
                                parentPhone_Error == '' &&
                                parentEmail_Error == '' &&
                                terms_Error == ''
                            ) {
                                // calls the doRegister action here
                                this.registerMethodCall(type);
                            }
                            else {
                                this.setState({
                                    verifyEmailMessage: false,
                                    form_Error: false
                                })
                            }
                        }

                    })
            }

        }
        else { // if it's normal type signup
            if (this.state.firstName == '') {
                this.setState({
                    firstName_Error: "required"
                })
            }
            else if (this.state.firstName.length < 3) {
                this.setState({
                    firstName_Error: "too short"
                })
            }
            else {
                this.setState({
                    firstName_Error: ""
                })
            }

            if (this.state.lastName == '') {
                this.setState({
                    lastName_Error: "required"
                })
            }
            else if (this.state.lastName.length < 3) {
                this.setState({
                    lastName_Error: "too short"
                })
            }
            else {
                this.setState({
                    lastName_Error: ""
                })
            }

            if (this.state.phone == '') {
                this.setState({
                    phone_Error: "required"
                })
            }
            else if (!validatePhone(this.state.phone)) {
                this.setState({
                    phone_Error: "not valid phone number"
                })
            }
            else {
                this.setState({
                    phone_Error: ""
                })
            }

            if (!this.state.email_Error_already) {
                if (this.state.email == '') {
                    this.setState({
                        email_Error: "required"
                    })
                }
                else if (!validateEmailAddress(this.state.email)) {
                    this.setState({
                        email_Error: "not valid email address"
                    })
                }
                else {
                    this.setState({
                        email_Error: ""
                    })
                }
            }

            if (this.state.password == '') {
                this.setState({
                    password_Error: "required"
                })
            }
            else if (this.state.password.length < 3) {
                this.setState({
                    password_Error: "password is too short"
                })
            }
            else {
                this.setState({
                    password_Error: ""
                })
            }

            if (this.state.c_password == '') {
                this.setState({
                    c_password_Error: "required"
                })
            }
            else if (this.state.c_password.length < 3) {
                this.setState({
                    c_password_Error: "password is too short"
                })
            }
            else if (this.state.c_password != this.state.password) {
                this.setState({
                    c_password_Error: "confirm password should be same"
                })
            }
            else {
                this.setState({
                    c_password_Error: ""
                })
            }

            if (this.state.dob == '') {
                this.setState({
                    dob_Error: "required"
                })
            }
            else {
                this.setState({
                    dob_Error: ""
                })
            }

            if (!this.state.terms) {
                this.setState({
                    terms_Error: "Terms & conditions should be checked."
                })
            }
            else {
                this.setState({
                    terms_Error: ""
                },
                    () => {
                        const { firstName_Error,
                            lastName_Error,
                            phone_Error,
                            email_Error,
                            password_Error,
                            c_password_Error,
                            dob_Error,
                            terms_Error } = this.state; // getting all states for child form submission

                        if (
                            firstName_Error == '' &&
                            lastName_Error == '' &&
                            phone_Error == '' &&
                            email_Error == '' &&
                            password_Error == '' &&
                            c_password_Error == '' &&
                            dob_Error == '' &&
                            terms_Error == ''
                        ) {
                            // calls the doRegister action here
                            this.registerMethodCall(type);
                        }
                        else {
                            this.setState({
                                verifyEmailMessage: false,
                                form_Error: false
                            })
                        }
                    })
            }

        }

    }

    registerMethodCall = (type) => {

        this.setState({
            showLoader: true
        },
            () => {

                var formTypeHere = '2',
                    gender = 0;

                var clientData = getCurrentClient();
                var courseId = 0,
                    gradeId = 0,
                    isPurchase = 0,
                    clientUrl = window.location.host, // client host url for white label
                    clientUrlProtocol = window.location.protocol;
                var loginType = localStorage.getItem('loginType');
                var candidateEmail = this.state.email;
                var candidatePass = this.state.password;
                var FirstName = this.state.firstName;
                var LastName = this.state.lastName;

                if (type == 'child') {
                    formTypeHere = '2';
                    candidateEmail = this.state.childEmail;
                    candidatePass = this.state.childPassword;
                    FirstName = this.state.childFirstName;
                    LastName = this.state.childLastName;
                }
                else if (formTypeHere == 'adult') {
                    formTypeHere = '1';
                }
                else {
                    formTypeHere = '3';
                }
                if (this.state.gender == "Male") {
                    gender = 0;
                }
                else {
                    gender = 1;
                }

                if (localStorage.getItem('loginType') && localStorage.getItem('loginType') != null) {
                    if (loginType == 'course') {
                        courseId = store.getState().webRed.courseDetail.course.id;
                        gradeId = getGradeId(courseId);
                        isPurchase = 1;
                    }
                }

                this.props.doRegister(
                    formTypeHere,
                    candidateEmail,
                    candidatePass,
                    this.state.parentEmail,
                    FirstName,
                    LastName,
                    this.state.parentFullName,
                    this.state.parentPhone,
                    0,
                    gender,
                    this.state.dob,
                    gradeId,
                    this.state.phone,
                    clientData.id, // storeId
                    JSON.stringify(additionalFields),
                    courseId,
                    isPurchase,
                    clientUrl,
                    clientUrlProtocol
                )

            })

    }

    setExtraFieldValue = (valueHere, field_id, index) => {
        additionalFields[index].extra_field_id = field_id;
        additionalFields[index].value = valueHere;
        // console.log(additionalFields)
    }

    // validateField = (state, errorState, value) => {
    //     this.setState({
    //         [state] : value
    //     },
    //     () => {
    //         if(value == ''){
    //             this.setState({
    //                 [errorState] : "required"
    //             })
    //         }
    //         else if(value.length < 3){
    //             this.setState({
    //                 [errorState] : "invalid length"
    //             })
    //         }
    //     })

    // }

    render() {

        const formType = this.props.formType;
        const isCredentialActive = this.props.isCredentialActive;
        const extraFields = this.props.extraFields;
        const fieldsKeys = Object.keys(extraFields);
        const course = store.getState().webRed.courseDetail.course;

        // code for adding extra fields to array to send it to action - starts here
        if (additionalFields.length < 1) {
            formType !== 'child' && fieldsKeys.map((field) => {
                var fieldValue = extraFields[field];
                additionalFields.push({
                    extra_field_id: fieldValue.id,
                    value: ''
                })
            })
        }
        // else{
        //     formType !== 'child' && fieldsKeys.map((field, key)=> {
        //         additionalFields[key].extra_field_id = '';
        //         additionalFields[key].value = '';
        //     })
        // }
        // code for adding extra fields to array to send it to action - ends here

        return (
            <Styles>


                <div style={{ padding: '1vh 0' }} className="SignUpForm">

                    {/* <Form className="SignUpForm"> */}

                    {
                        this.state.verifyEmailMessage ?
                            <div style={{ color: '#182b49', padding: '12vh 0' }}>
                                <h2>Sign Up Successful!</h2>
                                <h4>We have sent you an email on <strong>{formType == 'child' ? this.state.emailTemporaryParent : this.state.emailTemporary}</strong> to verify your account.</h4>
                            </div>
                            :
                            <Container style={{ padding: '0' }}>
                                <Row>
                                    <Col md={12}>

                                        {
                                            formType == 'normal' ?
                                                <p>I am registering myself</p>
                                                :
                                                formType == 'child' ?
                                                    <p>I am a parent/guardian registering my child for <strong>{course.course_name}</strong></p>
                                                    :
                                                    <h6 className="mb-4">I am registering myself for <strong style={{color: "#11B67A"}}>{course.course_name}</strong></h6>
                                        }
                                        {
                                            formType == 'child' ?
                                                <h6 style={{ marginTop: '20px' }}>Child's information</h6>
                                                : null
                                        }
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            {this.state.firstName_Error != false ? <p className="error">{this.state.firstName_Error}</p> : null}
                                            {this.state.childFirstName_Error != false ? <p className="error">{this.state.childFirstName_Error}</p> : null}
                                            {
                                                formType == 'child' ?
                                                    <input className="form-control" type="text" name="child_first_name" id="child_first_name" placeholder="Child's first name *" onChange={(evt) => {
                                                        this.setState({ childFirstName: evt.target.value })
                                                    }} />
                                                    :
                                                    <input className="form-control" type="text" name="first_name" id="first_name" placeholder="First Name *" onChange={(evt) => {
                                                        this.setState({ firstName: evt.target.value })
                                                    }} />
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            {this.state.lastName_Error != false ? <p className="error">{this.state.lastName_Error}</p> : null}
                                            {this.state.childLastName_Error != false ? <p className="error">{this.state.childLastName_Error}</p> : null}
                                            {
                                                formType == 'child' ?
                                                    <input type="text" name="child_last_name" id="child_last_name" placeholder="Child's last name *" onChange={(evt) => {
                                                        this.setState({ childLastName: evt.target.value })
                                                    }} />
                                                    :
                                                    <input className="form-control" type="text" name="last_name" id="last_name" placeholder="Last Name *" onChange={(evt) => {
                                                        this.setState({ lastName: evt.target.value })
                                                    }} />
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup className="genderBox">
                                            {/* { this.state.gender != '' ? <p className="error">{this.state.gender_Error}</p> : null } */}
                                            <label>
                                                <input checked={this.state.gender == "Male"} type="radio" id="genderMale" name="gender" label="Male" onChange={(evt) => {
                                                    this.setState({ gender: "Male" })
                                                }} />
                                                Male
                                            </label>
                                            <label>
                                                <input checked={this.state.gender == "Female"} type="radio" id="genderFemale" name="gender" label="Female" onChange={(evt) => {
                                                    this.setState({ gender: "Female" })
                                                }} />
                                                Female
                                            </label>
                                        </FormGroup>
                                    </Col>
                                    {
                                        formType == 'child' ?
                                            isCredentialActive == 1 ?

                                                <Col md={12}>
                                                    <Row style={{ padding: '0' }}>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                {this.state.childEmail_Error != false ? <p className="error">{this.state.childEmail_Error}</p> : null}

                                                                <input className="form-control" type="email" name="child_email" id="child_email" placeholder="Child's login email *" value={this.state.childEmail}
                                                                    onChange={(evt) => {
                                                                        this.setState({ childEmail: evt.target.value })
                                                                    }}
                                                                    onBlur={(evt) => {
                                                                        this.props.validateIfEmailExists(evt.target.value, formType);
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                {this.state.childPassword_Error != false ? <p className="error">{this.state.childPassword_Error}</p> : null}

                                                                <input className="form-control" type="password" name="child_password" id="child_password" placeholder="Login Password *" onChange={(evt) => {
                                                                    this.setState({ childPassword: evt.target.value })
                                                                }} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                {this.state.childCPassword_Error != false ? <p className="error">{this.state.childCPassword_Error}</p> : null}

                                                                <input className="form-control" type="password" name="child_c_password" id="child_c_password" placeholder="Confirm Login Password *" onChange={(evt) => {
                                                                    this.setState({ childCPassword: evt.target.value })
                                                                }} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                : null
                                            : null
                                    }
                                    {
                                        formType == 'child' ?
                                            <Col md={12} style={{ marginTop: '20px' }}>
                                                <h6>Parent/Gaurdians's information</h6>
                                            </Col>
                                            : null
                                    }
                                    {
                                        formType == 'child' ?
                                            <Col md={6}>
                                                <FormGroup>
                                                    {this.state.parentFullName_Error != false ? <p className="error">{this.state.parentFullName_Error}</p> : null}

                                                    <input type="text" name="parent_full_name" id="parent_full_name" placeholder="Parent's Full Name *" onChange={(evt) => {
                                                        this.setState({ parentFullName: evt.target.value })
                                                    }} />
                                                </FormGroup>
                                            </Col>
                                            : null
                                    }

                                    <Col md={6}>
                                        <FormGroup>

                                            {this.state.email_Error != false ? <p className="error">{this.state.email_Error}</p> : null}
                                            {this.state.parentEmail_Error != false ? <p className="error">{this.state.parentEmail_Error}</p> : null}

                                            {
                                                formType == 'child' ?
                                                    <input type="email" name="parent_email" id="parent_email" placeholder="Parent's Email *" onChange={(evt) => {
                                                        this.setState({ parentEmail: evt.target.value })
                                                    }} />
                                                    :
                                                    <input className="form-control" type="email" name="email" id="email" placeholder="Email *" value={this.state.email}
                                                        onChange={(evt) => {
                                                            this.setState({ email: evt.target.value })
                                                        }}
                                                        onBlur={(evt) => {
                                                            this.props.validateIfEmailExists(evt.target.value, formType);
                                                        }}
                                                    />
                                            }
                                        </FormGroup>
                                    </Col>
                                    {
                                        formType !== 'child' ?
                                            <Col md={6}>
                                                <FormGroup>
                                                    {this.state.password_Error != false ? <p className="error">{this.state.password_Error}</p> : null}

                                                    <input className="form-control" type="password" name="password" id="password" placeholder="Password *" onChange={(evt) => {
                                                        this.setState({ password: evt.target.value })
                                                    }} />
                                                </FormGroup>
                                            </Col>
                                            : null
                                    }
                                    {
                                        formType !== 'child' ?
                                            <Col md={6}>
                                                <FormGroup>
                                                    {this.state.c_password_Error != false ? <p className="error">{this.state.c_password_Error}</p> : null}

                                                    <input className="form-control" type="password" name="c_password" id="c_password" placeholder="Confirm Password *" onChange={(evt) => {
                                                        this.setState({ c_password: evt.target.value })
                                                    }} />
                                                </FormGroup>
                                            </Col>
                                            : null
                                    }


                                    <Col md={6}>
                                        <FormGroup>

                                            {this.state.phone_Error != false ? <p className="error">{this.state.phone_Error}</p> : null}
                                            {this.state.parentPhone_Error != false ? <p className="error">{this.state.parentPhone_Error}</p> : null}

                                            {
                                                formType == 'child' ?
                                                    <input type="text" name="parent_phone" id="parent_phone" placeholder="Parent's Phone *" onChange={(evt) => {
                                                        this.setState({ parentPhone: evt.target.value })
                                                    }} />
                                                    :
                                                    <input className="form-control" type="text" name="phone" id="phone" placeholder="Phone *" onChange={(evt) => {
                                                        this.setState({ phone: evt.target.value })
                                                    }} />
                                            }
                                        </FormGroup>
                                    </Col>

                                    {
                                        formType !== 'child' ?
                                            <Col md={6}>
                                                <FormGroup>
                                                    {this.state.dob_Error != false ? <p className="error">{this.state.dob_Error}</p> : null}

                                                    <p style={{ position: 'relative', bottom: '-14px', left: '2px', fontSize: '12px' }}>Date of birth</p>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="dateofbirth"
                                                        id="dateofbirth"
                                                        placeholder="Date of birth *"
                                                        onChange={(evt) => {
                                                            this.setState({ dob: evt.target.value })
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            : null
                                    }


                                    {
                                        formType !== 'child' && fieldsKeys && fieldsKeys.length > 0 ?
                                            <Col md={12} style={{ marginTop: '20px' }}>
                                                <h6>Additional information</h6>
                                            </Col>
                                            : null
                                    }
                                    {
                                        formType !== 'child' && fieldsKeys.map((field, key) => {
                                            // console.log(field);
                                            var fieldValue = extraFields[field];

                                            return (
                                                <Col md={6}>
                                                    <FormGroup>
                                                        {
                                                            fieldValue.type == "text" ?
                                                                <input type="text" name={fieldValue.field_name} id={fieldValue.field_name} placeholder={fieldValue.field_label} onChange={(evt) => {
                                                                    this.setExtraFieldValue(evt.target.value, fieldValue.id, key)
                                                                }} />
                                                                : null
                                                        }
                                                        {
                                                            fieldValue.type == "dropdown" ?
                                                                <input type="select" name={fieldValue.field_name} id={fieldValue.field_name} bsSize="md" onChange={(evt) => {
                                                                    this.setExtraFieldValue(evt.target.value, fieldValue.id, key)
                                                                }}>
                                                                    {
                                                                        fieldValue.options && fieldValue.options.map((opt, key) =>
                                                                            <option key={key} value={opt.option_value}>{opt.text}</option>
                                                                        )
                                                                    }
                                                                </input>
                                                                : null
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            )
                                        })
                                    }

                                    <Col md={12}>
                                        <FormGroup className="termsBox">
                                            {this.state.terms_Error != false ? <p className="error">{this.state.terms_Error}</p> : null}

                                            <label>
                                                <input type="checkbox" name="terms" id="terms" label="" onChange={(evt) => {
                                                    this.setState({ terms: !this.state.terms })
                                                }} /> I agree to the <a target="_new" href="/license-agreement">Terms & Conditions</a>
                                            </label>
                                        </FormGroup>
                                        {
                                            this.state.form_Error != false ?
                                                <h5 style={{ color: 'red' }}>{this.state.form_Error}</h5>
                                                : null
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Button onClick={e => this.submitRegisterForm(formType, isCredentialActive)} size="md" color="dark" className="btn btn-dark">Register</Button>
                                            {
                                                this.state.showLoader ?
                                                    <Spinner style={{ width: '1.5rem', height: '1.5rem', position: 'absolute', margin: '8px' }} color="dark" /> : null
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                    }
                    {/* </Form> */}

                </div>
            </Styles>
        );
    }
}


const mapStatesToProps = state => ({
    signupSuccess: state.webRed.signupSuccess,
    validateEmailExistsRes: state.webRed.validateEmailExistsRes
    // extraSignUpFields:state.webRed.extraSignUpFields
})

export default connect(mapStatesToProps, { doRegister, validateIfEmailExists })(SignUpForm);