import React, { useState, useEffect } from "react";
import { triggerNewUserType, doLogin } from "./../../actions/webRedActions";
import { Container, Row, Col, Button, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { Styles } from "./styles/usernameListing.js";

const UsernamesListings = (props) => {
  const [askForChild, setaskForChild] = useState(false);
  const [isChild, setisChild] = useState(false);
  const { phonenumber, newuser, selectedCourse, registeredUsers } = props;

  // const registeredUsers = [{
  //     user_loginname: "Hamza Test",
  //     user_name: "hamzatest1"
  // },
  // {
  //     user_loginname: "Hamza Test",
  //     user_name: "hamzatest1"
  // }]
  const myChildFunc = () => {
    setaskForChild(false);
    setisChild(true);
    props.triggerNewUserType("child");
  };

  const mySelfFunc = () => {
    setaskForChild(false);
    setisChild(false);
    props.triggerNewUserType("myself");
  };

  useEffect(() => {
    if (props.newuser === true) {
      props.triggerNewUserType("child");
    }
  }, []);
  console.log("Registered Users", registeredUsers);
  return (
    <Styles>
      <div
        style={{ padding: "1vh 0", background: "f4eb49", color: "75a9f9" }}
        className="UsernamesListings"
      >
        <div className="wrapper"></div>

        {newuser || askForChild ? (
          <Container>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <h3>Who is this for?</h3>
                <p style={{ margin: "15px 0" }}>{selectedCourse.course_name}</p>
              </Col>
            </Row>

            <Row className="phoneStyle" style={{ marginTop: "90px" }}>
              <Col xs={{ size: 10 }}>
                <Button
                  style={{ margin: "20px 0", padding: "15px" }}
                  // onClick={myChildFunc}
                  size="lg"
                  block
                  color="primary"
                >
                  My child
                </Button>

                <Button
                  style={{ margin: "20px 0", padding: "15px" }}
                  // onClick={mySelfFunc}
                  size="lg"
                  block
                  color="primary"
                >
                  Myself
                </Button>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col style={{ textAlign: "center" }}>
                {/* <h3>Who is this for?</h3> */}
                <p style={{ margin: "50px 2px", fontSize: "22px" }}>Who is this course for ?</p>
              </Col>
            </Row>

            <Row className="phoneStyle" style={{ marginTop: "90px" }}>
              <Col xs={{ size: 12 }}>
                <ul className="registeredUsersList">
                  {registeredUsers.map(
                    (user, key) => (
                      <ListGroup>
                        <ListGroup.Item
                          variant="info"
                          className="myDiv mb-2"
                          onClick={(e) => {
                            var loginType = "course";
                            var courseId = selectedCourse.id;
                            var isPurchase = 1;
                            var isPassRequired = 0;

                            props.doLogin(
                              user.user_loginname,
                              user.user_password,
                              courseId,
                              isPurchase,
                              loginType,
                              isPassRequired
                            ); // calling the action to login in now
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <div className="justify-content-between d-flex">
                            <div>
                              <label>{key + 1}.</label> <label>{user.user_loginname}</label>
                            </div>
                            <div className="hide text-right">
                              {/* <i className="fas fa-plus" style={{ color: "#fff", marginLeft: 20 }}></i> */}
                              <label>Use This</label>
                            </div>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    )

                    // <li key={key} onClick={(e) => {

                    //     var loginType = 'course';
                    //     var courseId = selectedCourse.id;
                    //     var isPurchase = 1;
                    //     var isPassRequired = 0;

                    //     props.doLogin(user.user_loginname, user.user_password, courseId, isPurchase, loginType, isPassRequired); // calling the action to login in now

                    // }}>

                    //     <Button color="secondary"
                    //         onClick={(e) => console.log(e)}
                    //     >use<br />this</Button>
                    //     <p>
                    //         {user.user_loginname} <br />
                    //         <span>{user.user_name}</span>
                    //     </p>
                    // </li>
                  )}

                  <li className="text-center">
                    <p className="seperator text-dark">or</p>
                  </li>
                  <li>
                    <Button
                      color="primary"
                      size="lg"
                      block
                      onClick={() => props.triggerNewUserType("child")}
                    >
                      <i className="fas fa-plus"></i>{" "}
                      {/* <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faPlus} size="1x" color="#fff" /> */}
                      Add a new user
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </Styles>
  );
};

export default connect(null, { triggerNewUserType, doLogin })(UsernamesListings);
