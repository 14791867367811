import React, { Component } from 'react';
import Datas from '../../data/reports/reports.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from '../gallery/styles/gallery.js';

class Reports extends Component {
    componentDidMount() {
        document.title = "Reports - Lincoln Method";
    }
    render() {

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper gallery-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Reports" />

                    {/* Gallery Area */}
                    <section className="gallery-page-area">
                        <Container>
                            <Row>
                                {
                                    Datas.map((data, i) => (
                                        <Col lg="4" sm="6" key={i}>
                                            <div className="gallery-box">
                                                <ModalImage small={process.env.PUBLIC_URL + `/assets/images/LM/reports/${data.galleryImage}`} large={process.env.PUBLIC_URL + `/assets/images/LM/reports/${data.galleryImage}`} alt="" />
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default Reports