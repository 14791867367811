import React, { Component } from 'react';
import Datas from '../data/testimonial/video-testimonial.json';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from "./HeaderTwo";
import { Link } from "react-router-dom";
import ModalVideo from 'react-modal-video';
import { Styles } from "./styles/testimonialSlider.js";

class AllVideosTestimonials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [
                {
                    "id": "5",
                    "testimonialTitle": "Our kids improved their Math skills",
                    "testimonialTitleAll": "I enrolled my kids in Lincoln Method and their effective teaching methods have brought remarkable changes to my kids. The dashboard is amazing as it provides before and after results which brings confidence and motivates them to give their best.",
                    "testimonialDesc": "",
                    "authorImg": "p-avatar7.jpeg",
                    "authorName": "Maida Affan",
                    "authorTitle": "Aqsa's & Awais's Mom",
                    "authorCountry": "pakFlag.png",
                    "isOpen": false,
                    videoId: "mOXLxDYKk88",
                },
                {
                    "id": "6",
                    "testimonialTitle": "My son learned everything before it was taught in school",
                    "testimonialTitleAll": "Both my kids love taking their online classes with Lincoln Method. They love working on their dashboard and I can immediate results and reports that help identify their weak areas and improve!",
                    "testimonialDesc": "",
                    "authorImg": "p-avatar8.jpeg",
                    "authorName": "Sadaf Salamullah",
                    "authorTitle": "Mikael's & Mashael's Mom",
                    "authorCountry": "pakFlag.png",
                    "isOpen": false,
                    videoId: "B9GK71C77aY",
                },
                {
                    "id": "1",
                    "testimonialTitle": "The Tutors are young & trained exceptionally well on how to engage the child",
                    "testimonialTitleAll": "It was a great experience for my children’s knowledge and development to choose Lincoln Method. The teachers helped them to get admission in ADNOC International School in UAE.",
                    "testimonialDesc": "",
                    "authorImg": "p-avatar9.jpeg",
                    "authorName": "Amna",
                    "authorTitle": "Haniya's & Nabiha's Mom",
                    "authorCountry": "uae.png",
                    "isOpen": false,
                    videoId: "SleYSUSOlik",
                    
                },
                {
                    "id": "2",
                    "testimonialTitle": "The Tutors are young & trained exceptionally well on how to engage the child",
                    "testimonialTitleAll": "The experience has been great so far. Classes are interactive and help Ramin through brainstorming.",
                    "testimonialDesc": "",
                    "authorImg": "p-avatar2.jpg",
                    "authorName": "Sibgha Ahmad",
                    "authorTitle": "Ramin's Mom",
                    "authorCountry": "pakFlag.png",
                    "isOpen": false,
                    videoId: "TG0vUAvvdek",
                },
                {
                    "id": "3",
                    "testimonialTitle": "The Tutors helped my son advance in Math",
                    "testimonialTitleAll": "My daughter is taking online classes and they are going really well! All the teachers have great energy and enthusiasm. I would strongly recommend Lincoln Method.",
                    "testimonialDesc": "",
                    "authorImg": "p-avatar3.jpg",
                    "authorName": "Uzma Lalani",
                    "authorTitle": "Ayra's Mom",
                    "authorCountry": "pakFlag.png",
                    "isOpen": false,
                    videoId: "HeNKoxMDXYY",
                },
                {
                    "id": "4",
                    "testimonialTitle": "The Tutors sent us a structured learning plan",
                    "testimonialTitleAll": "I registered Yusuf, and I's very happy with the decision. He is learning daily and making friends. The teachers are very good - they check & track Yusuf's progress too.",
                    "testimonialDesc": "",
                    "authorImg": "p-avatar4.jpg",
                    "authorName": "Sadaf Ahmed",
                    "authorTitle": "Yusuf's Mom",
                    "authorCountry": "pakFlag.png",
                    "isOpen": false,
                    videoId: "hDZFWRC0Ymw",
                }
            ],
            showAll: false
        }
    }
    openVideo = (id) => {
        this.state.dataList.map((item, index) => {
            if (item.id === id) {
                debugger;
                item.isOpen = !item.isOpen
            }
        })

        this.setState({
            dataList: [...this.state.dataList]
        })
    }
    render() {
        const { dataList } = this.state;
        return (
            <Styles>
                <div className="main-wrapper unitednations-page">

                    {/* Header 2 */}
                    {/* <HeaderTwo /> */}

                    <section className="testimonial-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/Background/${Datas.backgroundImage})`, backgroundAttachment: 'fixed' }}>
                        <Container>
                            <Row>
                                <Col md="12">
                                    <div className="sec-title text-center">
                                        <h4>Parents say this...</h4>
                                    </div>
                                </Col>

                                {
                                    dataList.map((data, i) => (
                                        <Col md="4" className="testimonial-slider">
                                            {i > 2 ? null :
                                                <div className="slider-item" key={i} style={{ marginTop: 60 }}>
                                                    <div className="desc" style={{ minHeight: 200 }}>
                                                        <h5>{data.testimonialTitle}</h5>
                                                        {/* <p>{data.testimonialDesc}</p> */}
                                                        <ModalVideo channel='youtube' videoId={data.videoId} isOpen={data.isOpen === true} onClose={() => this.openVideo(data.id)} />
                                                        <button onClick={() => this.openVideo(data.id)} className="watchBtn">Watch video <i className="fas fa-play ml-2"></i> </button>
                                                    </div>
                                                    <div className="writer">
                                                        <img src={process.env.PUBLIC_URL + `/assets/images/LM/Parents/${data.authorImg}`} className="slider-image" alt={data.authorImg} />
                                                        <h6>{data.authorName}</h6>
                                                        {/* <p>{data.authorTitle}</p> */}
                                                        <img className="authorCountry" src={`/assets/images/LM/${data.authorCountry}`} />
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                    ))
                                }

                                <Col md="12">
                                    <div className="buttonAllView">
                                        <Link to="/all-testimonials">
                                            <button className="">Watch more testimonials</button>
                                        </Link>
                                    </div>
                                </Col>
                                {/* <Col md="6">
                                    <div className="testimonial-desc">
                                        <p>
                                            <span>
                                                <img src="/assets/images/LM/Parents/p-avatar5.jpg" />

                                                <label>Hamza</label>
                                                Both my kids love taking their online classes with Lincoln Method. They love working on their dashboard and I can immediate results and reports that help identify their weak areas and improve!
                                            </span>
                                        </p>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </section>
                </div>
            </Styles>
        );
    }
}

export default AllVideosTestimonials;