import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardElement,
    Elements,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { sendStripeInfo } from './../../actions/webRedActions';
import { connect } from 'react-redux';
import { Container, Row, Col, Image, Form } from "react-bootstrap";

import "./stripeCheckout.css";
import { Button } from "react-bootstrap";

const CARD_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
        base: {
            iconColor: "#182b49",
            color: "#122340",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                color: "#fce883"
            },
            "::placeholder": {
                color: "#b4b9c1"
            }
        },
        invalid: {
            iconColor: "#ff0000",
            color: "#ff0000"
        }
    }
};

const CardField = ({ onChange }) => (
    <div className="FormRow">
        <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
);

const Field = ({
    label,
    id,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange
}) => (
        <div className="FormRow">
            <label htmlFor={id} className="FormRowLabel">
                {label}
            </label>
            <input
                className="FormRowInput"
                id={id}
                type={type}
                placeholder={placeholder}
                required={required}
                autoComplete={autoComplete}
                value={value}
                onChange={onChange}
            />
        </div>
    );

const SubmitButton = ({ processing, error, children, disabled }) => (
    <button
        className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
        type="submit"
        disabled={processing || disabled}
    >
        {processing ? "Processing..." : children}
    </button>
);

const ErrorMessage = ({ children }) => (
    <div className="ErrorMessage" role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
            <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
        </svg>
        {children}
    </div>
);

const ResetButton = ({ onClick }) => (
    <button type="button" className="ResetButton" onClick={onClick}>
        <svg width="32px" height="32px" viewBox="0 0 32 32">
            <path
                fill="#122340"
                d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
            />
        </svg>
    </button>
);

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [anotherCard, setanotherCard] = useState(false);
    const [billingDetails, setBillingDetails] = useState({
        email: "",
        phone: "",
        name: ""
    });
    const [selectedPmkey, setselectedPmkey] = useState("");
    // data: {
    //     message: "Required Authentication for your 3D secure card.",
    //     status: "next_actio",
    //     url: "https://hooks.stripe.com/redirect/authenticate/src_1JAVg9Ld5IbOjzZnlW9sDae6?client_secret=src_client_secret_1H02U9AEOwIanbcKtow5nKzB&source_redirect_slug=test_YWNjdF8xRWgxeDdMZDVJYk9qelpuLF9Kbzd3MVh5Sm9YUXY4WFNvUlpESnR1MktrR0JkYk9G0100fEAPGC8g"
    // }
    const { allCards , couponid , courseId , couponCode } = props;
    console.log("paymentMethod", paymentMethod);
    console.log("Stripe Props", props);
    debugger;

    const payUsingoldCard = (e) => {
        e.preventDefault();
        var loginStatus = localStorage.getItem('loginData');
        if (loginStatus != null && loginStatus != '' && typeof JSON.parse(loginStatus) === 'object') {
            sendStripeInfo(selectedPmkey, JSON.parse(loginStatus).user.id, props.total_amount, "", "", "", "PKR" , couponid , couponCode , courseId)
                .then(res => {
                    setPaymentMethod(res);
                    debugger;
                })

                .catch(err => {
                    setPaymentMethod(err);
                    debugger;
                })
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (error) {
            elements.getElement("card").focus();
            return;
        }

        if (cardComplete) {
            setProcessing(true);
        }

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: billingDetails
        });

        setProcessing(false);

        if (payload.error) {
            setError(payload.error);
        } else {
            var pmKey = payload['paymentMethod'].id;
            var loginStatus = localStorage.getItem('loginData');
            if (loginStatus != null && loginStatus != '' && typeof JSON.parse(loginStatus) === 'object') {
                sendStripeInfo(pmKey, JSON.parse(loginStatus).user.id, props.total_amount, billingDetails.email, billingDetails.name, billingDetails.phone, "PKR")
                    .then(res => {
                        setPaymentMethod(res);
                        debugger;
                    })

                    .catch(err => {
                        setPaymentMethod(err);
                        debugger;
                    })
            }

            console.log("payload:", payload);
            return false
            // Default options are marked with *
            const response = await fetch('http://localhost:4000/payment', {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({ 'id': payload.paymentMethod.id, 'amount': 600 }) // body data type must match "Content-Type" header
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            //console.log('Node server response:',response.json())
            //setPaymentMethod(payload.paymentMethod);
        }
    };

    const reset = () => {
        setError(null);
        setProcessing(false);
        setPaymentMethod(null);
        // setBillingDetails({
        //     email: "",
        //     phone: "",
        //     name: ""
        // });
    };

    return paymentMethod ? (
        <div className="Result">
            {/* <div className="ResultTitle" role="alert">
                <i className="fas fa-info-circle"></i> Payment Info
            </div> */}
            {paymentMethod.data.status === "success" ?
                <div className="ResultMessage">
                    <i className="fas fa-check-circle fa-2x"></i>
                    <br />
                    {paymentMethod.data.message}
                </div>

                : paymentMethod.data.status === "next_action" ?
                    <div className="twoDMessage">
                        <div className="iconTwo">
                            <i className="fas fa-info-circle"></i>
                        </div>
                        <br />
                        {paymentMethod.data.message}
                        <br />
                        <i className="linkIcon fas fa-link"></i> <a href={paymentMethod.data.url}>Click here to authenticate.</a>
                    </div>
                    :
                    <div className="ErrorMessageStripe">
                        <i className="fas fa-exclamation-triangle fa-2x"></i>
                        <br />
                        {paymentMethod.data.message}
                    </div>}
            {/* <div className="ResultMessage">
                Thanks for trying Stripe Elements. No money was charged, but we
        generated a PaymentMethod: {paymentMethod}
            </div> */}
            <ResetButton onClick={reset} />
        </div>
    ) : (

            allCards && allCards.length && anotherCard === false > 0 ?
                <div>
                    <div className="mainDivForPrev">
                        <Container>
                            <h6 className="headingForPrev">Select to pay with previously added cards:</h6>
                            {allCards.map((item, key) =>
                                <Row className={selectedPmkey === item.pm_id ? "selectedCard" : "PrevCardsDetails"} onClick={() => setselectedPmkey(item.pm_id)}>
                                    <Col xs={12} md={12} key={key} className="">
                                        <div>
                                            <label>  <input checked={selectedPmkey === item.pm_id} type="radio" className="check-box" /> </label>
                                            <label>{item.brand.charAt(0).toUpperCase() + item.brand.slice(1)} <span>****</span> {item.last4}</label>
                                            {item.brand === "visa" ?
                                                <Image src={process.env.PUBLIC_URL + "/assets/images/visa2.png"} />
                                                : item.brand === "unionpay" ?
                                                    <Image src={process.env.PUBLIC_URL + "/assets/images/union2.png"} />
                                                    : item.brand === "amex" ?
                                                        <Image src={process.env.PUBLIC_URL + "/assets/images/amex.png"} />
                                                        : item.brand === "mastercard" ?
                                                            <Image src={process.env.PUBLIC_URL + "/assets/images/master.png"} />
                                                            : null
                                            }
                                            {/* <i className="fas fa-trash"></i>        */}
                                            {/* <span>Expires Apr 2056</span> */}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <button
                                className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
                                onClick={payUsingoldCard}
                                disabled={selectedPmkey !== "" ? false : true}
                            >
                                {processing ? "Processing..." : `Pay PKR ${props.total_amount}`}
                            </button>
                            <Row className="AnotherCardDiv">
                                <Col xs={12} md={12} className="text-dark">
                                    <label>OR</label>
                                    <br />
                                    <button className="AnotherCardAddBtn" onClick={() => setanotherCard(!anotherCard)}>Pay with a New Card</button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                :
                <form className="Form" onSubmit={handleSubmit}>
                    <h5 className="headingForForm">Card Details</h5>
                    <fieldset className="FormGroup">
                        <Field
                            label="Name"
                            id="name"
                            type="text"
                            placeholder="Jane Doe"
                            required
                            autoComplete="name"
                            value={billingDetails.name}
                            onChange={(e) => {
                                setBillingDetails({ ...billingDetails, name: e.target.value });
                            }}
                        />
                        <Field
                            label="Email"
                            id="email"
                            type="email"
                            placeholder="janedoe@gmail.com"
                            required
                            autoComplete="email"
                            value={billingDetails.email}
                            onChange={(e) => {
                                setBillingDetails({ ...billingDetails, email: e.target.value });
                            }}
                        />
                        <Field
                            label="Phone"
                            id="phone"
                            type="tel"
                            placeholder="(941) 555-0123"
                            required
                            autoComplete="tel"
                            value={billingDetails.phone}
                            onChange={(e) => {
                                setBillingDetails({ ...billingDetails, phone: e.target.value });
                            }}
                        />
                    </fieldset>
                    <fieldset className="FormGroup">
                        <CardField
                            onChange={(e) => {
                                setError(e.error);
                                setCardComplete(e.complete);
                            }}
                        />
                    </fieldset>
                    {error && <ErrorMessage>{error.message}</ErrorMessage>}
                    <SubmitButton processing={processing} error={error} disabled={!stripe}>
                        Pay PKR {props.total_amount}
                    </SubmitButton>

                    {/* <button className="AnotherCardAddBtn" onClick={() => setanotherCard(!anotherCard)}>Pay with Added Cards</button> */}

                    <hr />
                </form>
        );
};
const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
        }
    ]
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_DJwyidZ088oDtabIUyuG6jxn00WbMrfS6R");
const StripeCheckout = (props) => {
    return (
        <div className="AppWrapper">
            <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <CheckoutForm total_amount={props.total_amount} allCards={props.allCards} courseId={props.courseId} couponCode={props.couponCode} couponid={props.couponid} />
            </Elements>
        </div>
    );
};

export default connect(null, { sendStripeInfo })(StripeCheckout);