import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CountUp from 'react-countup';
import { Styles } from "./styles/numberCounter.js";
import Marquee from './Marquee';
import { getClassesConducted } from "../actions/webRedActions";

class NumberCounter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classesConduct: 0,
            classesSlide: [],
            startCount: true
        }
    }

    componentDidMount() {
        //Counter & Ticker API

        getClassesConducted("/getlastfiveclases")
            .then((res) => {
                this.setState({
                    classesConduct: res.result.totalClasses[0].number,
                    classesSlide: res.result.slide
                }, () => {

                })
            })
            .catch((err) => {
                debugger;
            })
        // window.addEventListener("scroll", () => {
        //     if (window.scrollY > 30) {
        //         this.setState({
        //             startCount: true
        //         })
        //     } else {
        //         this.setState({
        //             startCount: false
        //         })
        //     }
        // });
    }

    render() {
        console.log("Count Up" , this.state.classesConduct);
        return (
            <Styles>
                {/* Counter Area */}
                <section className="counter-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Images for Homepage//)` }}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="3" className="text-center">
                                <div className="counter-box">
                                    <div className="counter-icon text-center">
                                        <i className="las la-school"></i>
                                    </div>

                                    <div className="counter-number text-center">
                                        {this.state.startCount ?
                                            <h4><CountUp end={this.state.classesConduct} duration={3} delay={1} separator=",">
                                            </CountUp></h4> : null}
                                    </div>
                                </div>
                            </Col>

                            <Col md="12" className="">
                                <div className="sec-title text-center">
                                    {/* <h4>{Datas.secTitle}</h4> */}
                                    <h4>online Tutoring classes conducted</h4>
                                </div>
                            </Col>
                            {/* <Col md="3" xs="6">
                                <div className="counter-box">
                                    <div className="counter-icon">
                                        <i className="las la-graduation-cap"></i>
                                    </div>
                                    <div className="counter-number">
                                        <h4><CountUp end={2309} duration={5} delay={1.5} />+</h4>
                                        <p>Total Students</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3" xs="6">
                                <div className="counter-box">
                                    <div className="counter-icon">
                                        <i className="las la-award"></i>
                                    </div>
                                    <div className="counter-number">
                                        <h4><CountUp end={590} duration={5} delay={1.5} />+</h4>
                                        <p>Awards Wins</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3" xs="6">
                                <div className="counter-box">
                                    <div className="counter-icon">
                                        <i className="las la-book-reader"></i>
                                    </div>
                                    <div className="counter-number">
                                        <h4><CountUp end={3790} duration={5} delay={1.5} />+</h4>
                                        <p>Library Books</p>
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                    </Container>
                </section>
                <Marquee classesSlide={this.state.classesSlide} />
            </Styles>
        )
    }
}

export default NumberCounter
