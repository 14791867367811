import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .bgImg {
    padding: 300px 80px;

    .starColor {
        color: #FFDE59; 
        margin-bottom: 20px;
    }

    @media(max-width: 767px) {
        padding: 14vh 0 14vh 35px;

        .starColor {
            color: #FFDE59; 
            font-size: 14px;
            margin-bottom: 10px;
        }
        
    }

    .bgText {
        font-size: 42px;
        @media(max-width: 767px) {
            font-size: 16px;
            
        }
    }
  }
`;
