import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { Styles } from "./styles/mobileMenu.js";
import { triggerLoginPopup } from "../../actions/webRedActions.js";
import { logOut } from "./../../actions/utility";
import { connect } from "react-redux";

function MobileMenu(props) {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [loggedInUserName, setloggedInUserName] = useState("");
  const [hideForApp, sethideForApp] = useState(false);
  // const [loginSuccessNormal, setloginSuccessNormal] = useState("");

  useEffect(() => {
    const mobileView = localStorage.getItem("mobileapp");
    if (mobileView) {
      sethideForApp(true);
    }
  }, []);

  const setLoggedInUser = () => {
    var loginStatus = localStorage.getItem("loginData");
    if (loginStatus && loginStatus != null && typeof JSON.parse(loginStatus) === "object") {
      setisLoggedIn(true);
      setloggedInUserName(JSON.parse(loginStatus).user.user_name);
    }
  };
  const openLoginModal2 = () => {
    // this.toggle(); // closes the menu
    // localStorage.setItem("loginType", "normal"); // set loginType normal to load login popup with normal forms
    // try {
    //   var loginStatus = localStorage.getItem("loginData");
    //   if (loginStatus != null && typeof JSON.parse(loginStatus) === "object") {
    //     setisLoggedIn(true);
    //   } else {
    //     props.triggerLoginPopup(); // triggers the login modal
    //   }
    // } catch (err) {
    //   props.triggerLoginPopup(); // triggers the login modal
    // }
    window.open(
      "https://feeds.lincolnmethod.com/",
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  const logout = () => {
    if (logOut()) {
      // calls logout from utility
      setisLoggedIn(false);
    }
  };
  useEffect(() => {
    // Mobile Menu
    setLoggedInUser();
    const hmBtn = document.getElementById("mb-sidebar-btn");

    if (hmBtn) {
      const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
      const mdSidebarBody = document.getElementById("mb-sidebar-body");
      const mdSidebarExit = document.getElementById("close-mb-sidebar");

      hmBtn.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.toggle("visible");
        mdSidebarBody.classList.toggle("opened");
      });

      mdSidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });

      mdSidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });
    }

    // Menu Accordion -----------------
    const menuButton = document.querySelectorAll(".btnForAccordion");
    menuButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "mb-menu-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "mb-menu-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  const menuBar = () => {
    const hmBtn = document.getElementById("mb-sidebar-btn");

    if (hmBtn) {
      const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
      const mdSidebarBody = document.getElementById("mb-sidebar-body");
      const mdSidebarExit = document.getElementById("close-mb-sidebar");

      hmBtn.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.toggle("visible");
        mdSidebarBody.classList.toggle("opened");
      });

      mdSidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });

      mdSidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });
    }
  };

  // if(props.loginSuccessNormal !== loginSuccessNormal){
  //     debugger;
  //     setloginSuccessNormal(props.loginSuccessNormal);
  //     setLoggedInUser();
  // }
  return (
    <Styles>
      {/* Mobile Menu */}
      <section className="mobile-menu-area">
        <Container>
          <Row>
            <Col md="0" sm="12">
              {/* <div className="mb-topbar d-flex justify-content-between">
                                <div className="topbar-item">
                                    <p><i className="las la-phone"></i><a style={{ color: 'inherit' }} href="tel:+923008296228">+92 300 829 6228</a></p>
                                </div>
                                <div className="topbar-item">
                                    {isLoggedIn ?
                                        <div className="userDropdown">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    {loggedInUserName}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        :
                                        <ul className="list-unstyled list-inline">

                                            <li className="list-inline-item"><button onClick={openLoginModal2}>Log In</button></li>
                                            <li className="list-inline-item">/</li>
                                                <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/registration"}>Register</Link></li>

                                        </ul>
                                    }
                                </div>
                            </div> */}
              <div className="mb-logo-area d-flex justify-content-between">
                <div className="mb-logo-box d-flex">
                  <div className="mb-logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <img src={"/assets/images/LM/logo.png"} alt="" />
                    </Link>
                  </div>
                </div>
                {!hideForApp && (
                  <div className="hm-button d-flex">
                    {isLoggedIn ? (
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {loggedInUserName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <button onClick={openLoginModal2}>Log In</button>
                    )}
                    <a onClick={menuBar} id="mb-sidebar-btn">
                      <i className="las la-bars"></i>
                    </a>
                  </div>
                )}
                {/* <div className="mb-search-box">
                                    <form action="#">
                                        <input type="text" name="search" placeholder="Search Here" />
                                        <button type="submit"><i className="las la-search"></i></button>
                                    </form>
                                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mobile Menu Sidebar */}
      <section className="mb-sidebar" id="mb-sidebar-body">
        <div className="mb-sidebar-heading d-flex justify-content-between">
          <div>
            <h5>Menu</h5>
          </div>
          <div>
            <a href={process.env.PUBLIC_URL + "/"} id="close-mb-sidebar">
              <i className="las la-times"></i>
            </a>
          </div>
        </div>
        <div className="mb-sidebar-menu">
          <div className="mb-menu-item">
            <button className="mb-menu-button">
              <Link to="/">
                <p>Home</p>
              </Link>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
                </li>
                {/* <li><Link to={process.env.PUBLIC_URL + "/home-two"}>Home Style 2</Link></li> */}
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button">
              <Link to="/courses">
                <p>Courses</p>
              </Link>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link>
                </li>
                {/* <li><Link to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li> */}
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button">
              <Link to="/instructor">
                <p>Tutors</p>
              </Link>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/instructor"}>Instructors</Link>
                </li>
                {/* <li><Link to={process.env.PUBLIC_URL + "/instructor-details"}>Instructor Details</Link></li> */}
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button">
              <Link to="/education-mama">
                <p>Education Mama</p>
              </Link>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/instructor"}>Instructors</Link>
                </li>
                {/* <li><Link to={process.env.PUBLIC_URL + "/instructor-details"}>Instructor Details</Link></li> */}
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button">
              <Link to="/research">
                <p>Research</p>
              </Link>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/research"}>Research</Link>
                </li>
                {/* <li><Link to={process.env.PUBLIC_URL + "/instructor-details"}>Instructor Details</Link></li> */}
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button btnForAccordion">
              <p>
                Partnership <i className="las la-plus"></i>
              </p>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li className="nav-item">
                  <Link className="nav-link" to="/un-nations-sdg">
                    United Nations SDG
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/slum-block-millionaire"}>
                    Slum-Block Millionaire
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/fashion-design-shout-outs"}>
                    Fashion & Design shout-outs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/educate-compton"}>
                    Educate Compton
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://info.lincolnmethod.com/NTU-survey/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Textile Industry - Survey
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://info.lincolnmethod.com/NTU-results/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Textile Industry - Results
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.mparsec.com/"
                    target="_blank"
                  >
                    mPARSEC
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>Pages <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/about"}>About Us</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/gallery"}>Gallery</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/login"}>Log In</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/registration"}>Registration</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/faq"}>Faq</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/404"}>404</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/coming-soon"}>Coming Soon</Link></li>
                            </ul>
                        </div>
                    </div> */}
          {/* <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Event <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/events"}>Events</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/event-details"}>Event Details</Link></li>
                            </ul>
                        </div>
                    </div> */}
          {/* <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Blog <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/blog-classic"}>Blog Classic</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog Grid</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/blog-details"}>Blog Details</Link></li>
                            </ul>
                        </div>
                    </div> */}
          {/* <div className="mb-menu-item">
                        <button className="mb-menu-button btnForAccordion">
                            <p>Workbooks <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/products"}>Products</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/product-details"}>Product Details</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/cart"}>Cart</Link></li>
                            </ul>
                        </div>
                    </div> */}
          <div className="mb-menu-item">
            <button className="mb-menu-button">
              <Link to="/wall-of-fame">
                <p>Success Stories</p>
              </Link>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/wall-of-fame"}>Success Stories</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button">
              <Link to="/OurTeam">
                <p>Our Team</p>
              </Link>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/wall-of-fame"}>Success Stories</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button">
              <a href="https://edu.lincolnmethod.com/" target="__blank">
                <p>Blogs</p>
              </a>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/wall-of-fame"}>Success Stories</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
    </Styles>
  );
}

const mapStateToProps = (state) => ({
  loginSuccessNormal: state.webRed.loginSuccessNormal,
});
export default connect(mapStateToProps, { triggerLoginPopup })(MobileMenu);
