import React, { Component } from 'react';
import { Container, Row, Col, ButtonGroup, Spinner } from 'react-bootstrap';
// import * as Actions from "../actions/webRedActions";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Styles } from "../components/styles/fixedDemoComponent.js";


class FixedDemoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gradesData: "",
            grades: [],
            filteredCourses: {},
            sortedCourses: "",
            selectedGrade: "",
            showSubjectDropdown: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.gradesData !== nextProps.coursesDataOnlineSchool) {
            const { coursesDataOnlineSchool } = nextProps;
            var sortedCoursesArray = [];
            var coursesKeys = Object.keys(coursesDataOnlineSchool.coursesData.filteredCourses);
            coursesKeys.map(course => {

                var gradeArray = [];
                var courseKey = Object.keys(coursesDataOnlineSchool.coursesData.filteredCourses[course]);
                var gradeName = coursesDataOnlineSchool.coursesData.filteredCourses[course][courseKey[0]].grade;
                courseKey.map(_course => {
                    var thisCourse = coursesDataOnlineSchool.coursesData.filteredCourses[course][_course];
                    gradeArray.push(thisCourse);
                })
                sortedCoursesArray.push({
                    grade: gradeName,
                    courses: gradeArray,
                    visible: true
                })

            })
            return {
                gradesData: coursesDataOnlineSchool,
                grades: coursesDataOnlineSchool.grades,
                filteredCourses: coursesDataOnlineSchool.coursesData.filteredCourses,
                sortedCourses: sortedCoursesArray
            };
        }
        return null;
    }

    gradeClicked = (e) => {
        if (e.target.value !== "") {
            this.setState({
                showSubjectDropdown: true,
                selectedGrade: e.target.value
            })
        }
        else {
            this.setState({
                showSubjectDropdown: false,
                selectedGrade: e.target.value
            })
        }
    }

    courseCLicked = (e) => {
        debugger;
        this.state.sortedCourses.map(_grade => {
            // console.log(grade);
            if (_grade.grade == this.state.selectedGrade) {

                console.log(_grade)

                _grade.courses.map(course => {
                    if (course.course_name.indexOf(e.target.value) > -1) {
                        this.props.history.push('/course-details/' + course.id);
                    }
                })
            }
        })

    }
    render() {
        console.log("Grades Data", this.state.gradesData);
        console.log("Sorted Courses", this.state.sortedCourses);
        console.log("selectedGrade", this.state.selectedGrade);
        const { grades, showSubjectDropdown } = this.state;
        return (
            <Styles>
                <div className="fixedDemoComponent">
                    <div className="fixedDropdowns">
                        <div className="">
                            <label> Enroll in a free class:</label>
                            {grades.length !== 0 ?
                                <select
                                    className="custom-select"
                                    name="staffId"
                                    onChange={this.gradeClicked}
                                >
                                    <option value="">
                                        Select a Grade
                                </option>
                                    {grades.length && grades.map((grade, index) =>
                                        <option key={index} value={grade}>{grade}</option>
                                    )}

                                </select>

                                :
                                <React.Fragment>
                                    <br />
                                    <Spinner className="centered-spinner" animation="border" variant="light" />
                                </React.Fragment>
                            }

                            {showSubjectDropdown ?
                                <select
                                    style={{ marginTop: 10 }}
                                    className="custom-select"
                                    onChange={this.courseCLicked}
                                >
                                    <option>
                                        Select a Subject
                            </option>
                                    <option value="English">English</option>
                                    <option value="Math">Math</option>
                                    {
                                        this.state.selectedGrade == "Grade 2" ||
                                            this.state.selectedGrade == "Grade 3" ||
                                            this.state.selectedGrade == "Grade 4" ||
                                            this.state.selectedGrade == "Grade 5" ||
                                            this.state.selectedGrade == "Grade 6" ||
                                            this.state.selectedGrade == "Grade 7" ||
                                            this.state.selectedGrade == "Grade 8" ||
                                            this.state.selectedGrade == "Grade 9" ?
                                            <option value="Creative Writing">Creative Writing</option>
                                            : null
                                    }

                                    {
                                        this.state.selectedGrade == "Grade 9" ||
                                            this.state.selectedGrade == "Grade 10" ?
                                            <option value="Chemistry">Chemistry</option>

                                            : null

                                    }

                                    {
                                        this.state.selectedGrade == "Kindergarten" ||
                                            this.state.selectedGrade == "Grade 1" ||
                                            this.state.selectedGrade == "Grade 2" ||
                                            this.state.selectedGrade == "Grade 3" ||
                                            this.state.selectedGrade == "Grade 4" ||
                                            this.state.selectedGrade == "Grade 5" ||
                                            this.state.selectedGrade == "Grade 6" ||
                                            this.state.selectedGrade == "Grade 7" ||
                                            this.state.selectedGrade == "Grade 8" ?
                                            <option value="Science">Science</option> : null
                                    }


                                </select>

                                : null}

                        </div>
                    </div>
                </div>
            </Styles>
        );
    }
}

const mapStateToProps = state => ({
    coursesDataOnlineSchool: state.webRed.coursesDataOnlineSchool
})
export default withRouter(connect(mapStateToProps, null)(FixedDemoComponent));