import React, { Component } from "react";
import HeaderTwo from "../components/HeaderTwo";
import { BreadcrumbBox } from "../components/common/Breadcrumb";
import FooterTwo from "../components/FooterTwo";
import { Styles } from "./styles/privacyPolicy.js";
import { Container, Row, Col } from "react-bootstrap";

class PrivacyPolicy extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper privacy-policy-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcroumb */}
          <BreadcrumbBox title="Privacy Policy" />

          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="12" md="12" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>Privacy Policy Lincoln Method</h4>
                    </div>
                    <div className="heading-text">
                      <p>
                        These terms and conditions outline the agreement between Lincoln Method
                        (referred to as "we," "our," or "us") and the parents or legal guardians of
                        enrolled students (referred to as "you" or "your") regarding the use of
                        children's photographs, videos, and school work on our official social media
                        platforms and website.
                      </p>
                    </div>

                    <div className="event-details-overview">
                      <h4>Information Collection And Use</h4>
                      <p>
                        We collect several different types of information for various purposes to
                        provide and improve our Service to you. By enrolling your child in Lincoln
                        Method and continuing their participation in our online educational
                        programs, you hereby grant us the right to use your child's photographs,
                        videos, and school work for promotional and educational purposes on our
                        official social media platforms, website, and related marketing materials.
                      </p>

                      <h4 className="mt-4">Types of Data Collected:</h4>
                      <h5>Our policy on recording classes:</h5>
                      <p>
                        We have a policy to sporadically record classes for quality assurance checks
                        only. These recordings are then reviewed and audited by our internal
                        consultants and scored on effectiveness and quality of our teaching
                        methodology. Not all classes are recorded. Since we use Zoom as our service
                        provider to deliver the classes, we leverage Zoom's consent mechanism.
                        Before a class is being recorded, the student (and their parents) are asked
                        if they want to continue in a recorded session. The student and their
                        parents are given a choice to participate or not to participate in a
                        recorded session. The classes that are recorded are never shared outside our
                        company as it might violate privacy related issues of the students. The
                        recorded classes are stored internally and we leverage paid services of
                        third party providers like Google and AWS. We periodically delete recorded
                        classes once we have processed our audits on the effectiveness and the
                        quality of our teaching methodology. We rely on our third party service
                        providers and their paid services to provide protection of our data to us
                        and our customers. Given that cyber attacks are increasing everyday and
                        hackers are learning how to outsmart the systems put up by our third party
                        service providers, there is no guarantee that a Cyber attack might take
                        place because of which access of the recorded classes might be opened to
                        others outside the company. We do not take any financial, legal or any other
                        responsibility if a recorded session that has been saved on our third party
                        service providers, is compromised. If you disagree with our policy on
                        recording, then please inform your tutor that you would not like to be a
                        part of a recorded session.
                      </p>
                      <h5>Personal Data</h5>
                      <p>
                        hile using our Service, we may ask you to provide us with certain personally
                        identifiable information that can be used to contact or identify you
                        ("Personal Data"). Personally identifiable information may include, but is
                        not limited to:
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i> First name and last name.
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Cookies and Usage Data.
                        </li>
                      </ul>
                      <br />

                      <h5>Usage Data</h5>

                      <p>
                        We may also collect information how the Service is accessed and used ("Usage
                        Data"). This Usage Data may include information such as your computer's
                        Internet Protocol address (e.g. IP address), browser type, browser version,
                        the pages of our Service that you visit, the time and date of your visit,
                        the time spent on those pages, unique device identifiers and other
                        diagnostic data.
                      </p>

                      <h5>Tracking & Cookies Data</h5>

                      <p>
                        We use cookies and similar tracking technologies to track the activity on
                        our Service and hold certain information.
                      </p>

                      <br />

                      <p>
                        Cookies are files with small amount of data which may include an anonymous
                        unique identifier. Cookies are sent to your browser from a website and
                        stored on your device. Tracking technologies also used are beacons, tags,
                        and scripts to collect and track information and to improve and analyze our
                        Service.
                      </p>

                      <br />

                      <p>
                        You can instruct your browser to refuse all cookies or to indicate when a
                        cookie is being sent. However, if you do not accept cookies, you may not be
                        able to use some portions of our Service.
                      </p>

                      <br />

                      <p>Examples of Cookies we use:</p>

                      <br />

                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i> <span> Session Cookies. </span> We use
                          Session Cookies to operate our Service.
                        </li>
                        <li>
                          <i className="fa fa-check"></i> <span>Preference Cookies.</span> We use
                          Preference Cookies to remember your preferences and various settings.
                        </li>
                        <li>
                          <i className="fa fa-check"></i> <span>Security Cookies.</span> We use
                          Security Cookies for security purposes.
                        </li>
                      </ul>

                      <br />

                      <h5>Use of Data</h5>

                      <p>Lincoln Method uses the collected data for various purposes:</p>

                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i>Showcasing student achievements,
                          engagement, and learning experiences on LM's official social media
                          platforms and website
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Illustrating the quality and diversity of
                          educational activities provided by LM.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Sharing educational content and insights
                          with the LM community and the wider public.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>To provide and maintain the Service
                        </li>
                        <li>
                          <i className="fa fa-check"></i> To notify you about changes to our Service{" "}
                        </li>
                        <li>
                          <i className="fa fa-check"></i> To allow you to participate in interactive
                          features of our Service when you choose to do so{" "}
                        </li>
                        <li>
                          <i className="fa fa-check"></i> To provide customer care and support{" "}
                        </li>
                        <li>
                          <i className="fa fa-check"></i> To provide analysis or valuable
                          information so that we can improve the Service{" "}
                        </li>
                        <li>
                          <i className="fa fa-check"></i> To monitor the usage of the Service{" "}
                        </li>
                        <li>
                          <i className="fa fa-check"></i> To detect, prevent and address technical
                          issues{" "}
                        </li>
                      </ul>

                      <h5>Transfer Of Data</h5>

                      <p>
                        Your information, including Personal Data, may be transferred to — and
                        maintained on — computers located outside of your state, province, country
                        or other governmental jurisdiction where the data protection laws may differ
                        than those from your jurisdiction.
                      </p>

                      <br />

                      <p>
                        If you are located outside Pakistan and choose to provide information to us,
                        please note that we transfer the data, including Personal Data, to Pakistan
                        and process it there.
                      </p>

                      <br />

                      <p>
                        Your consent to this Privacy Policy followed by your submission of such
                        information represents your agreement to that transfer.
                      </p>

                      <br />

                      <p>
                        Lincoln Method will take all steps reasonably necessary to ensure that your
                        data is treated securely and in accordance with this Privacy Policy and no
                        transfer of your Personal Data will take place to an organization or a
                        country unless there are adequate controls in place including the security
                        of your data and other personal information.
                      </p>

                      <h4>Disclosure Of Data</h4>

                      <h5>Legal Requirements</h5>

                      <p>
                        Lincoln Method may disclose your Personal Data in the good faith belief that
                        such action is necessary to:
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i>To comply with a legal obligation
                        </li>
                        <li>
                          <i className="fa fa-check"></i> To protect and defend the rights or
                          property of Lincoln Method{" "}
                        </li>
                        <li>
                          <i className="fa fa-check"></i> To prevent or investigate possible
                          wrongdoing in connection with the Service{" "}
                        </li>
                        <li>
                          <i className="fa fa-check"></i> To protect the personal safety of users of
                          the Service or the public{" "}
                        </li>
                        <li>
                          <i className="fa fa-check"></i> To protect against legal liability{" "}
                        </li>
                      </ul>

                      <h4>Security Of Data</h4>
                      <p>
                        The security of your data is important to us, but remember that no method of
                        transmission over the Internet, or method of electronic storage is 100%
                        secure. While we strive to use commercially acceptable means to protect your
                        Personal Data, we cannot guarantee its absolute security.
                      </p>

                      <h4>Service Providers</h4>
                      <p>
                        We may employ third party companies and individuals to facilitate our
                        Service ("Service Providers"), to provide the Service on our behalf, to
                        perform Service-related services or to assist us in analyzing how our
                        Service is used.
                      </p>

                      <br />

                      <p>
                        These third parties have access to your Personal Data only to perform these
                        tasks on our behalf and are obligated not to disclose or use it for any
                        other purpose.
                      </p>

                      <h4>Analytics</h4>

                      <p>
                        We may use third-party Service Providers to monitor and analyze the use of
                        our Service.
                      </p>

                      <h5>Google Analytics</h5>
                      <p>
                        Google Analytics is a web analytics service offered by Google that tracks
                        and reports website traffic. Google uses the data collected to track and
                        monitor the use of our Service. This data is shared with other Google
                        services. Google may use the collected data to contextualize and personalize
                        the ads of its own advertising network.
                      </p>

                      <br />

                      <p>
                        You can opt-out of having made your activity on the Service available to
                        Google Analytics by installing the Google Analytics opt-out browser add-on.
                        The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js,
                        and dc.js) from sharing information with Google Analytics about visits
                        activity.
                      </p>

                      <br />

                      <p>
                        For more information on the privacy practices of Google, please visit the
                        Google Privacy &amp; Terms web page:{" "}
                        <a href="https://policies.google.com/privacy?hl=en">
                          https://policies.google.com/privacy?hl=en
                        </a>
                      </p>

                      <h4>Links To Other Sites</h4>

                      <p>
                        Our Service may contain links to other sites that are not operated by us. If
                        you click on a third party link, you will be directed to that third party's
                        site. We strongly advise you to review the Privacy Policy of every site you
                        visit.
                      </p>

                      <br />

                      <p>
                        We have no control over and assume no responsibility for the content,
                        privacy policies or practices of any third party sites or services.
                      </p>

                      <h4>Children's Privacy</h4>

                      <p>
                        We are committed to maintaining the privacy of our students and their
                        families. We will make reasonable efforts to ensure that personal
                        information, such as contact details, is not disclosed along with the
                        photographs, videos, and school work samples.
                      </p>

                      <h4>Changes To This Privacy Policy</h4>

                      <p>
                        We reserve the right to modify or update these terms and conditions at any
                        time. Changes will be effective upon posting on our website or through
                        written notice to parents/guardians. It is your responsibility to review
                        these terms periodically.
                      </p>

                      <br />

                      <p>
                        By enrolling your child and allowing them to participate in our online
                        classes, you acknowledge that you have read, understood, and agreed to the
                        terms and conditions outlined above regarding the use of your child's
                        photographs, videos, and school work samples
                      </p>

                      <br />

                      <p>
                        You are advised to review this Privacy Policy periodically for any changes.
                        Changes to this Privacy Policy are effective when they are posted on this
                        page.
                      </p>

                      <h4>Release and Waiver</h4>

                      <p>
                        By granting consent, you release Lincoln Method from any liability or claims
                        arising out of the use of the photographs, videos, and school work samples
                        in accordance with these terms and conditions
                      </p>

                      <h4>Agreement Acceptance:</h4>

                      <p>
                        By enrolling your child and allowing them to participate in our online
                        educational programs, you indicate your understanding of these terms and
                        conditions and your agreement to grant us permission for the use of your
                        child's photographs, videos, and school work samples as described.
                      </p>

                      <h4>Contact Us</h4>

                      <p>
                        For any questions, concerns, or requests related to the use of your child's
                        materials, you can contact us at:
                      </p>

                      <br />

                      <p>By email: hello@lincolnmethod.com</p>
                      <p>By phone number: +358 41 575 1803</p>
                    </div>
                  </div>
                </Col>

                {/* <Col lg="3" md="4" sm="12">
                                    <div className="event-details-sidebar">
                                        <Row>
                                            <Col md="12">
                                                <div className="event-sidebar-info">
                                                    <div className="event-sidebar-timer text-center">
                                                        <Timer initialTime={1040 * 970 * 980} direction="backward">
                                                            <p><Timer.Days /><span>Days</span></p>
                                                            <p><Timer.Hours /><span>Hours</span></p>
                                                            <p><Timer.Minutes /><span>Minutes</span></p>
                                                        </Timer>
                                                    </div>
                                                    <ul className="list-unstyled event-info-list">
                                                        <li>Start Date: <span>Aug 21, 2020</span></li>
                                                        <li>Time: <span>08:30am</span></li>
                                                        <li>Seat: <span>220</span></li>
                                                        <li>Place: <span>Ontario,CA</span></li>
                                                        <li>Organizer: <span>David Young</span></li>
                                                        <li>Phone: <span>+1 (396) 486 4709</span></li>
                                                        <li>Email: <span>email@me.com</span></li>
                                                        <li>Website: <span>www.mydomain.com</span></li>
                                                    </ul>
                                                    <button type="button" className="buy-btn">Buy Ticket</button>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <PopularCourse />
                                            </Col>
                                            <Col md="12">
                                                <CourseTag />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col> */}
              </Row>
            </Container>
          </section>
          <FooterTwo />
        </div>
      </Styles>
    );
  }
}

export default PrivacyPolicy;
