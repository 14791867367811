import React, { Component } from 'react';
import HeaderTwo from '../components/HeaderTwo';
import { BreadcrumbBox } from '../components/common/Breadcrumb';
import FooterTwo from '../components/FooterTwo';
import { Styles } from "./styles/privacyPolicy.js";
import { Container, Row, Col } from 'react-bootstrap';

class MarketingPage extends Component {

    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper privacy-policy-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Digital Marketing" />

                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <div className="event-details-content">
                                        {/* <div className="heading">
                                            <h4>Terms and Conditions</h4>
                                        </div> */}

                                        <div className="event-details-overview">

                                            <h5 className="">We have a short-term project for digital marketing agencies </h5>

                                            <p>We are running short term project, which we are calling an experiment to see what traffic aggregation strategies would work best for us in the long term. Agencies who are able to deliver the right strategies will asked to participate with us for the long term.</p>

                                            <br />

                                            <h5>Budget allocated for the short-term project</h5>

                                            <p>We are allocating a total budget of $50,000 to be distributed amongst a handful of agencies for this short-term project (our experiment).
</p>

                                            <br />

                                            <h5>Inviting digital marketing agencies</h5>

                                            <p>In our experiment we are inviting multiple agencies to participate, we will provide them marketing budgets as well as pay their retainer fee, and then see whose strategy is able to deliver paid conversions at the least cost. At the end of our experiment we will continue with the top three agencies for the long term (12 months+), where the budgets that we allocate will be much higher.</p>

                                            <br />

                                            <h5>One sub-domain for every agency</h5>

                                            <p>Each agency will be given their own subdomain to avoid any cross-contamination of data and marketing spend.
</p>

                                            <br />

                                            <h5>We will set up the FB Pixel, the Events, and also provide the Creatives</h5>


                                            <p>In order to avoid any complications we will provide the following to each agency:</p>

                                            <ol>
                                                <li> We will set up the Facebook Pixel on a unique sub-domain specific to that agency.</li>
                                                <li>We will configure the Events on the Pixel.</li>
                                                <li>We will provide all the Creatives, since we know how we want to position our brand.</li>

                                            </ol>


                                            <br />

                                            <h5>The agency can focus only on delivering its targets</h5>

                                            <p>Since we are taking away a lot of the workload from the agency without reducing the retainer, it gives the agency the opportunity to focus only on delivering the targets that they commit.</p>


                                            <br />

                                            <h5>Committing targets and deliverables</h5>

                                            <p>In order to qualify it is important for a participating agency to indicate what the budgets they need and what targets they will deliver.</p>

                                            <p>Once you have studied our user sign-up process and our user conversion process please indicate the following:</p>
                                            <ol>
                                                <li>How many users can you direct towards sign up for a free class (top of the funnel)?</li>
                                                <li>What percentage do you feel will convert to paying clients (bottom of the funnel)?</li>
                                                <li>What will be the total cost to us (media spend plus retainer plus any hidden fees)?</li>

                                            </ol>

                                            <br />

                                            <h5>What areas and tasks do we want the agency to focus</h5>

                                            <p>Since we have taken away a lot of the responsibility, we want the agency to provide all their focus on the following:</p>
                                            <ol>
                                                <li>Confirm that the Facebook Pixel and the Events have been setup as per your liking.</li>
                                                <li>Create and manage an ad campaign, which includes setting up ad sets and allocating appropriate budgets to different ads based upon performance. </li>
                                                <li>Use our creatives to optimize your audience selection and re-targeting. </li>
                                                <li>Identify look alike audience, and value-based look alike audiences.</li>
                                                <li>Consider the changes in IOS 14.5 updates and ensure that you do not have a leaky ad spend.</li>
                                                <li>Use the data being collected in our CRM to optimize custom audience creation, as well as optimize the media spend.</li>
                                                <li>Upload our existing customer lists to further refine your audience.</li>
                                                <li>Leverage the data in our CRM to identify value-based look alike audiences.</li>
                                            </ol>

                                            <br />

                                            <h5>We are doing the short term project so that we can identify which agencies we want to work with in the long term</h5>

                                            <p>In the past we have worked with multiple digital marketing agencies, and we have realized that some work better than others. We have also realize that some agencies focus on their own short-term billing and their retainers rather than delivering true value to the client.</p>
                                            <p>Since we have allocated a significantly large long-term budget for digital marketing, we want to use this short term experiment to identify which agencies are truly able to deliver value for us so that we can forge a long-term relationship with them.</p>

                                            <h5>Terms of engagement</h5>

                                            <p>The following are the terms of engagement that we are putting forward for this project.</p>

                                            <ol>
                                                <li>Agency commits to a delivery target of how much of the traffic they send to us actually converts into paying clients</li>
                                                <li>Agency shows us a clear plan (strategy) so that we know what we are investing in (we can show you the strategy that other agencies have presented to us)</li>
                                                <li>Agency receives 50% retainer in the beginning of the month, and the remainder at the end of the month provided that the delivery targets stated are met</li>
                                            </ol>

                                            <p>Please contact us if you would like to participate in this experiment with us.</p>

                                            <h5>How can you reach us?</h5>

                                            <p>Here is how you can reach us:</p>

                                            <p>hello@lincolnmethod.com</p>
                                        </div>
                                    </div>
                                </Col>

                                {/* <Col lg="3" md="4" sm="12">
                                    <div className="event-details-sidebar">
                                        <Row>
                                            <Col md="12">
                                                <div className="event-sidebar-info">
                                                    <div className="event-sidebar-timer text-center">
                                                        <Timer initialTime={1040 * 970 * 980} direction="backward">
                                                            <p><Timer.Days /><span>Days</span></p>
                                                            <p><Timer.Hours /><span>Hours</span></p>
                                                            <p><Timer.Minutes /><span>Minutes</span></p>
                                                        </Timer>
                                                    </div>
                                                    <ul className="list-unstyled event-info-list">
                                                        <li>Start Date: <span>Aug 21, 2020</span></li>
                                                        <li>Time: <span>08:30am</span></li>
                                                        <li>Seat: <span>220</span></li>
                                                        <li>Place: <span>Ontario,CA</span></li>
                                                        <li>Organizer: <span>David Young</span></li>
                                                        <li>Phone: <span>+1 (396) 486 4709</span></li>
                                                        <li>Email: <span>email@me.com</span></li>
                                                        <li>Website: <span>www.mydomain.com</span></li>
                                                    </ul>
                                                    <button type="button" className="buy-btn">Buy Ticket</button>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <PopularCourse />
                                            </Col>
                                            <Col md="12">
                                                <CourseTag />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </section>
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default MarketingPage