import styled from "styled-components";

import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    fixedDemoComponent {
        .EnrollButton {
            // background: ${colors.gr_bg};
            border: none;
            display: none;
            // padding: 15px;
            color: #fff;
            -webkit-box-shadow: -1px -3px 6px 0px rgba(50, 50, 50, 0.75);
            -moz-box-shadow:    -1px -3px 6px 0px rgba(50, 50, 50, 0.75);
            box-shadow:         -1px -3px 6px 0px rgba(50, 50, 50, 0.75);
      
            @media(max-width: 767px) {
                display: block;
                position: fixed;
                left: 0;
                bottom: 0;
                margin: 0;
                border-radius: 0;
                z-index: 999;
                width: 100%;
      
            }
        }
    }
`;