import React, { Component } from "react";
import Datas from "../../data/blog/grid.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Pagination from "./../../components/Pagination";
import BlogSidebar from "./components/BlogSidebar";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/blog.js";

class LMBlogGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewItems: 3,
    };
  }
  render() {
    const blogData = [
      {
        id: "1",
        postImg: "fontana.jpeg",
        authorImg: "logo512.png",
        authorName: "Admin",
        postLink:
          "https://edu.lincolnmethod.com/ai-awareness-project-for-schools-in-california/",
        postDate: "April 23, 2020",
        postTitle: "AI Awareness Project for schools in California",
        postDesc:
          "FAQs for School Administrators in California Q: What is the Learn AI project?A: The Learn AI project introduces AI courses to junior high and high school students in California.",
        authorLink: "/",
      },
      {
        id: "2",
        postImg: "malta.jpeg",
        authorImg: "logo512.png",
        authorName: "Admin",
        postLink: "https://edu.lincolnmethod.com/ai-courses-in-malta/",
        postDate: "April 23, 2020",
        postTitle: "AI Courses in Malta",
        postDesc:
          "FAQs for School Administrators in Malta Q: What is the Learn AI project?A: The Learn AI project introduces AI courses to primary and secondary schools in Malta.   Q: What will schools be taught in the AI courses?",
        authorLink: "/",
      },
      {
        id: "3",
        postImg: "kidsLaptop.jpeg",
        authorImg: "logo512.png",
        authorName: "Admin",
        postLink:
          "https://edu.lincolnmethod.com/scholarship-for-entrepreneurship-ai/",
        postDate: "April 23, 2020",
        postTitle: "Scholarship for Entrepreneurship & AI",
        postDesc:
          "FAQs & Answers Q: What is the “Scholarship for Entrepreneurship & AI”? A: It’s a unique course designed in collaboration with the City of Fontana, aiming",
        authorLink: "/",
      },
      {
        id: "3",
        postImg: "kidsLaptop.jpeg",
        authorImg: "logo512.png",
        authorName: "Admin",
        postLink:
          "https://edu.lincolnmethod.com/scholarship-for-entrepreneurship-ai/",
        postDate: "April 23, 2020",
        postTitle: "Scholarship for Entrepreneurship & AI",
        postDesc:
          "FAQs & Answers Q: What is the “Scholarship for Entrepreneurship & AI”? A: It’s a unique course designed in collaboration with the City of Fontana, aiming",
        authorLink: "/",
      },
      {
        id: "3",
        postImg: "kidsLaptop.jpeg",
        authorImg: "logo512.png",
        authorName: "Admin",
        postLink:
          "https://edu.lincolnmethod.com/scholarship-for-entrepreneurship-ai/",
        postDate: "April 23, 2020",
        postTitle: "Scholarship for Entrepreneurship & AI",
        postDesc:
          "FAQs & Answers Q: What is the “Scholarship for Entrepreneurship & AI”? A: It’s a unique course designed in collaboration with the City of Fontana, aiming",
        authorLink: "/",
      },
      {
        id: "3",
        postImg: "kidsLaptop.jpeg",
        authorImg: "logo512.png",
        authorName: "Admin",
        postLink:
          "https://edu.lincolnmethod.com/scholarship-for-entrepreneurship-ai/",
        postDate: "April 23, 2020",
        postTitle: "Scholarship for Entrepreneurship & AI",
        postDesc:
          "FAQs & Answers Q: What is the “Scholarship for Entrepreneurship & AI”? A: It’s a unique course designed in collaboration with the City of Fontana, aiming",
        authorLink: "/",
      },
      {
        id: "3",
        postImg: "kidsLaptop.jpeg",
        authorImg: "logo512.png",
        authorName: "Admin",
        postLink:
          "https://edu.lincolnmethod.com/scholarship-for-entrepreneurship-ai/",
        postDate: "April 23, 2020",
        postTitle: "Scholarship for Entrepreneurship & AI",
        postDesc:
          "FAQs & Answers Q: What is the “Scholarship for Entrepreneurship & AI”? A: It’s a unique course designed in collaboration with the City of Fontana, aiming",
        authorLink: "/",
      },
      {
        id: "3",
        postImg: "kidsLaptop.jpeg",
        authorImg: "logo512.png",
        authorName: "Admin",
        postLink:
          "https://edu.lincolnmethod.com/scholarship-for-entrepreneurship-ai/",
        postDate: "April 23, 2020",
        postTitle: "Scholarship for Entrepreneurship & AI",
        postDesc:
          "FAQs & Answers Q: What is the “Scholarship for Entrepreneurship & AI”? A: It’s a unique course designed in collaboration with the City of Fontana, aiming",
        authorLink: "/",
      },
      {
        id: "3",
        postImg: "kidsLaptop.jpeg",
        authorImg: "logo512.png",
        authorName: "Admin",
        postLink:
          "https://edu.lincolnmethod.com/scholarship-for-entrepreneurship-ai/",
        postDate: "April 23, 2020",
        postTitle: "Scholarship for Entrepreneurship & AI",
        postDesc:
          "FAQs & Answers Q: What is the “Scholarship for Entrepreneurship & AI”? A: It’s a unique course designed in collaboration with the City of Fontana, aiming",
        authorLink: "/",
      },
    ];
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper blog-grid-page">
          {/* Blog Classic */}
          <section className="blog-grid-area">
            <Container>
              <Row>
                <Col lg="12" md="12" sm="12">
                  <h3>Our Blogs</h3>
                  <Row>
                    {blogData.slice(0, this.state.viewItems).map((data, i) => (
                      <Col lg="4" md="12" key={i}>
                        <div className="blog-item">
                          <div className="blog-img">
                            <a href={data.postLink}>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/LM/${data.postImg}`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </a>
                          </div>
                          <div className="blog-content">
                            <div className="blog-auth_date d-flex">
                              <div className="author-img d-flex">
                                <a href={data.postLink}>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      `/assets/images/LM/${data.authorImg}`
                                    }
                                    alt=""
                                  />
                                </a>
                                <p>
                                  <a href={data.postLink}>{data.authorName}</a>
                                </p>
                              </div>
                              {/* <div className="post-date">
                                <p>
                                  <i className="las la-calendar"></i>{" "}
                                  {data.postDate}
                                </p>
                              </div> */}
                            </div>
                            <div className="blog-title">
                              <h6>
                                <a href={data.postLink}>{data.postTitle}</a>
                              </h6>
                            </div>
                            <div className="blog-desc">
                              <p>{data.postDesc}</p>
                            </div>
                            <div className="tag-box">
                              <a href={data.postLink}>Read More</a>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
                {/* <Col md="12">
                  <div className="buttonAllView mb-3">
                    <button
                      className=""
                      onClick={() =>
                        this.setState({ viewItems: blogData.length })
                      }
                    >
                      View More Blogs
                    </button>
                  </div>
                </Col> */}
                <Col md="12">
                  <div className="buttonAllView mb-3">
                    <a href="https://edu.lincolnmethod.com/">
                      <button className="">View More Blogs</button>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Styles>
    );
  }
}

export default LMBlogGrid;
