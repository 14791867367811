import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
   
.UsernamesListings{
    position: relative;
    overflow: hidden;
    background: #fff;
    color: ${colors.bg1};
    min-height: 570px;
    // border: 3px solid ${colors.bg1};

    @media(max-width: 767px){
        min-height: calc(100vh);
    }
    // .wrapper {        
    //     position: absolute;
    //     width: 200%;
    //     height: 600px;
    //     left: -50%;
    //     top: 25vh;
    //     @media screen and (max-width: '767px'){
    //         top: 32vh;
    //     }
    //     background-color: #24c4ff;
    //     border-radius: 50%;
    // }

    h3 {
        color: ${colors.bg1};
        text-align: center;
        margin: 10vh 0 0 0;
        padding: 0 35px;
        font-size: 2.5rem;
    }

    > .container {
        padding: 0;
    }

    .phoneStyle {
        margin: 0 !important;

        > .col-12 {
            padding: 0;
        }

        .registeredUsersList {
            position: relative;
            background-color: #fff;
            margin: 0;
            padding: 30px;

            // &::before {
            //     content: '';
            //     width: 200%;
            //     height: 600px;
            //     position: absolute;
            //     left: 0;
            //     top: -20px;
            //     background-color: #24c4ff;
            //     z-index: 0;
            // }
        }
    }

    .registeredUsersList {
        list-style-type: none;
        padding: 0 10px;
        .hide {
            display: none;
            cursor: pointer;
          }
              
          .myDiv{
            cursor: pointer;
              &:hover{
                .hide {
                    display: block;
                    color: ${colors.bg1};
                    font-weight: bold;
                    font-size: 13px;
                    cursor: pointer;
                  }
              }
            }
        li {
            padding: 8px 0;
            cursor: pointer;

            &:hover {
                button {
                    border-color: #2a6a82;
                }
            }

            button {
                display: inline-block;
            }
            p {
                padding: 10px 0;
                padding-left: 15px;
                display: inline-block;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                margin: 0;
                line-height: 1.3rem;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #2a6a82;
                }
            }
        }

        button {
            background: #fff;
            color: #24c4ff;
            position: relative;
            font-weight: 600;

            &:not(.btn-block) {
                border-color: #fff;
                line-height: 22px;
                float: left;
            }

            &.btn-block {
                padding: 15px 0;
                padding-left: 40px;
            }

            i {
                margin: 0;
                // background: #24c4ff;
                color: #fff;
                width: 35px;
                height: 35px;
                position: absolute;
                top: 12px;
                left: 12px;
                padding: 10px;
                border-radius: 50%;
            }

        }
    }

    button {
        &.btn-lg {
            &.btn-primary {
                background: ${colors.green};
                color: #fff;
                border: none;
                // font-size: 1.5rem;
                // font-weight: 500;
            }
        }
    }

}
`;