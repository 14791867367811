import React, { Component } from 'react';
import Datas from '../../data/walloffame/wallOfFame.json';
import DatasNew from '../../data/walloffame/wallOfFameNew.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import ModalVideo from 'react-modal-video';
import { Styles } from './styles/videoTestimonial.js';
// import VideoThmbnail1 from "/public/assets/images/LM/Parents/VideoTestimonial1.png";

class VideoTestimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: '',
            DataForImages: [
                {
                    id: 1,
                    videoId: "mOXLxDYKk88",
                    galleryImage: "VideoTestimonial3.png",
                    isOpen: false
                },
                {
                    id: 2,
                    videoId: "B9GK71C77aY",
                    galleryImage: "VideoTestimonial4.png",
                    isOpen: false
                },
                {
                    id: 3,
                    videoId: "TG0vUAvvdek",
                    galleryImage: "VideoTestimonial2.png",
                    isOpen: false
                },
                {
                    id: 4,
                    videoId: "SleYSUSOlik",
                    galleryImage: "VideoTestimonial5.png",
                    isOpen: false
                },
                {
                    id: 5,
                    videoId: "HeNKoxMDXYY",
                    galleryImage: "VideoTestimonial6.png",
                    isOpen: false
                },
                {
                    id: 6,
                    videoId: "hDZFWRC0Ymw",
                    galleryImage: "VideoTestimonial7.png",
                    isOpen: false
                },
            ]
        }
    }
    render() {

        const openVideo = (id) => {
            this.state.DataForImages.map((item, index) => {
                if (item.id === id) {
                    debugger;
                    item.isOpen = !item.isOpen
                }
            })

            this.setState({
                DataForImages: [...this.state.DataForImages]
            })
        }
        console.log("data", this.state.DataForImages);
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper video-testimonial">

                    {/* Header 2 */}
                    {/* <HeaderTwo /> */}

                    {/* Breadcroumb */}
                    {/* <BreadcrumbBox title="Success Stories" /> */}

                    {/* Gallery Area */}
                    <section className="video-testimonial-area">
                        <Container>
                            <Row>
                                <Col md="12" className="text-center" >
                                    <h3>Parents like us because...</h3>
                                </Col>

                                {this.state.DataForImages.map((item, index) =>
                                    <Col lg="4" key={index} className="mt-3">
                                        <div className="video-player" style={{ backgroundImage: `url(/assets/images/LM/Parents/${item.galleryImage})` }}>
                                            <ModalVideo channel='youtube' videoId={item.videoId} isOpen={item.isOpen === true} onClose={() => openVideo(item.id)} />
                                            <button onClick={() => openVideo(item.id)} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    {/* <FooterTwo /> */}

                </div>
            </Styles>
        )
    }
}

export default VideoTestimonial