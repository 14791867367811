import React, { Component } from "react";
import Datas from "../data/course/slider.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { Styles } from "./styles/courseSlider.js";
import BackgroundImageWithText from "../pages/careers/PageSnippetComponent.js";
import BackgroundImageWithText2 from "../pages/careers/BackgroundImageWithText2.js";

class SlidesSlider extends Component {
  render() {
    const settings = {
      slidesPerView: 1,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    //   autoplay: {
    //     delay: 2500,
    //     disableOnInteraction: false
    //   },
    //   renderPrevButton: () => <button className="swiper-button-prev btn btn-sm btn-success">Prev</button>,
    //   renderNextButton: () => <button className="swiper-button-next"><i className="fa fa-chevron-right fa-3x m-auto"/></button>,
    };

    return (
      <Styles>
        {/* Course Slider */}
        <section className="">
            {/* <Row> */}
              {/* <Col md="12" className=""> */}
                <Swiper {...settings}>
                  <div>
                    <BackgroundImageWithText />
                  </div>
                  <div>
                    <BackgroundImageWithText2 />
                  </div>
                </Swiper>
              {/* </Col> */}
            {/* </Row> */}
        </section>
      </Styles>
    );
  }
}

export default SlidesSlider;
