import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
.CareerPageSnippetComponent{
    padding: 20vh 0 15vh 0;
    position: relative;
    overflow: hidden;

    .BannerWithText_heading{
        color: #fff;
        font-size: 3.3rem;
        font-weight: bold;
        text-shadow: 2px 3px 15px rgba(0,0,0,0.7);

        @media(max-width: 767px) {
            font-size: 1.7rem;
        }
    }
    .BannerWithText_paragraph{
        margin-top: 20px;
        color: #fff;
        font-size: 1rem;

        @media(max-width: 767px) {
            width: 68%;
        }
    }

    .BannerWithText_btn{
        background: ${colors.gr_bg};
        border-radius: 5px;
        border: 1px #11B67A solid;
        color: #fff;
        margin-top: 25px;
        padding: 12px 30px;
        outline: none;
        font-size: 1.5rem;

        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active{
            background-color: #000;
            border: 1px #fff solid;
            outline: none;
        }

        @media(max-width: 767px) {
            font-size: 1rem;
        }

    }

    img{
        max-width: 85%;
    }

    &.gray{
        background: #ccc;

        * {
            color: #fff !important;
        }
    }

    &.yellow{
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        background-attachment: inherit !important;
    }

    &.blue{
        .BannerWithText_btn{
            background-color: #11B67A;
            color: #fff;
    
            &:hover,
            &:active,
            &:focus,
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active{
                background-color: #182B49;
                // border: 5px #fff solid;
                outline: none;
            }
    
        }
    }
    
    &.green{
        .BannerWithText_btn{
            background: #182B49;
            color: #fff;
    
            &:hover,
            &:active,
            &:focus,
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active{
                background-color: #182B49;
                // border: 5px #fff solid;
                outline: none;
            }
    
        }
    }

    &.white{
        .BannerWithText_heading{
            color: #182B49;
            font-size: 2rem;
            font-weight: bold;
            text-align: left !important;
            text-shadow: none;
            position: relative;

            &::after{
                content: '';
                width: 34%;
                height: 7px;
                background: #11B67A;
                position: absolute;
                bottom: -17px;
                left: 0;
                border-radius: 15px;
            }

            @media(max-width: 767px) {
                font-size: 1.7rem;
            }
        }
        .BannerWithText_paragraph{
            margin-top: 60px !important;
            text-align: left;
            margin-top: 20px;
            color: #000;
            font-size: 1.4rem;

            ul {
                margin-top : 30px;
                li {
                    font-size: 14px;
                    color: ${colors.text3};
                    margin-bottom: 10px;
                    i {
                        // float: left;
                        color: ${colors.green};
                        border: 1px solid ${colors.border3};
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        text-align: center;
                        padding-top: 9px;
                        // margin-top: 8px;
                        margin-right: 15px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }    
    }

    @media(max-width: 767px) {
        padding: 3vh 0 0vh 0;
    }

}
`;