import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, FormGroup, Accordion, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Styles } from "./styles/phoneNumberVerification.js";
import {
  sendVerificationCode,
  sendPhoneNumber,
  getClassAvailability,
  getPhoneUsersList,
} from "../../actions/webRedActions.js";
import { toast } from "react-toastify";

const PhoneNumberVerificationCode = (props) => {
  const [verificationCode, setverificationCode] = useState("");
  const [codeError, setcodeError] = useState(false);
  const [showCountDown, setshowCountDown] = useState(false);
  const [availableTimings, setavailableTimings] = useState([]);
  const [teacherId, setteacherId] = useState("");
  const [demoDay, setdemoDay] = useState("");
  const [demoTime, setdemoTime] = useState("");
  const [waitingList, setwaitingList] = useState(0);
  const [slotId, setSlotId] = useState("");
  const [loopCount, setloopCount] = useState(false);
  const { phonenumber, courseDetails } = props;

  useEffect(() => {
    getClassAvailability(courseDetails.id)
      .then((res) => {
        console.log("Available Timings", res.data.result);
        setavailableTimings(res.data.result);
      })
      .catch((err) => {
        debugger;
      });
    callTimer(60);
  }, []);

  const callTimer = (timer) => {
    setshowCountDown(true);
    var value = timer;
    var interval = setInterval(() => {
      value = value - 1;
      var second = value,
        min = "";
      console.log(value);

      // if((value / 60) > 1){
      //     min = parseInt((value / 60)) + " min ";
      // } else {
      //     min = "";
      // }

      try {
        document.getElementById("counterHere").innerHTML = min + second + " sec";
      } catch (e) {
        clearInterval(interval);
      }
    }, 1000);

    setTimeout(() => {
      console.log("setTimeout");
      clearInterval(interval);
      setshowCountDown(false);
    }, timer * 1000);
  };

  const selectDay = (day, time) => {
    console.log("Day is:", day, "Time is: ", time, "Teacher Id", teacherId);
    setdemoDay(day);
    setdemoTime(time);
  };

  const cardSelect = (teacherId, slotId) => {
    setSlotId(slotId);
    setteacherId(teacherId);
    setdemoDay("");
    setdemoTime("");
    // setwaitingList(0);
    setloopCount(false);
  };

  const NextStep = (waitingListCheck) => {
    if (demoDay && demoTime) {
      var demoObj = {
        demoDay,
        demoTime,
        teacherId,
        slotId,
        waitingList: waitingListCheck,
      };
      localStorage.setItem("demoObject", JSON.stringify(demoObj));
      props.getPhoneUsersList(phonenumber);
      console.log("Demo Object", demoObj);
    } else {
      toast.error("Please select a day for your demo");
    }
  };
  const NextStepWaitingList = (waitingListCheck) => {
    var demoObj = {
      demoDay: 0,
      demoTime: 0,
      teacherId,
      slotId,
      waitingList: waitingListCheck,
    };
    localStorage.setItem("demoObject", JSON.stringify(demoObj));
    props.getPhoneUsersList(phonenumber);
    console.log("Demo Object", demoObj);
  };

  const showTime = (data) => {
    debugger;
    const filteredDataArray = Object.keys(data)
      .filter((key) => data[key].start !== "" && data[key].end !== "")
      .map((key) => data[key]);

    return filteredDataArray;
  };

  const checkAvailability = (arr, val) => {
    debugger;
    if (val > arr.total_students && loopCount == false) {
      debugger;
      setwaitingList(0);
      setloopCount(true);
    }
  };

  const returnListTimings = (courseTimings) => {
    let daysArary = [];

    var timings = courseTimings;
    timings = JSON.parse(timings);
    console.log("TIMINGS", timings);
    Object.keys(timings).map((id, key) => {
      var day = timings[id];
      if (day.start != "" && day.end != "") {
        var start_time = day.start;
        var firstCharStart = start_time.split("");
        if (firstCharStart[0] == "0") {
          start_time = start_time.substring(1);
        }

        var end_time = day.end;
        var firstCharEnd = end_time.split("");
        if (firstCharEnd[0] == "0") {
          end_time = end_time.substring(1);
        }

        var dayName = day.day.split("");

        let dayData = (
          <React.Fragment>
            <li key={key}>
              <div className="d-flex justify-content-between">
                {day.day_date}
                <div className="text-center">
                  <span className="">
                    {start_time} - {end_time}
                  </span>
                </div>
                <div className="text-center">
                  <button
                    disabled={day.total_students >= 7 ? true : false}
                    className={
                      day.total_students >= 7
                        ? "selectBtnDisabled ml-2"
                        : demoDay === day.day_date
                        ? "selectBtnChecked ml-2"
                        : "selectBtn ml-2"
                    }
                    onClick={() => selectDay(day.day_date, start_time)}
                  >
                    {day.total_students >= 7 ? (
                      "Full"
                    ) : demoDay === day.day_date ? (
                      <span>
                        <i className="las la-check"></i>
                      </span>
                    ) : (
                      "Select"
                    )}
                    {/* {demoDay === day.day_date ? <span><i className="las la-check mr-1"></i></span> : "Select"} */}
                  </button>
                </div>
              </div>
            </li>
          </React.Fragment>
        );
        // checkAvailability(day, 7, key);
        daysArary.push(dayData);
        console.log(start_time, end_time);
      }
    });
    return daysArary;
  };
  return (
    <Styles>
      <div
        className="PhoneNumberVerificationCode"
        style={{
          background: "#fff",
          color: "75a9f9",
          padding: "1vh 0",
        }}
      >
        <div className="wrapper"></div>
        {props.timeTableView ? (
          <Container>
            <Row>
              <Col style={{ textAlign: "left" }}>
                <div className="text-center">
                  <h4>When do you want to start your class?</h4>
                </div>

                <Accordion className="mt-5">
                  {availableTimings &&
                    availableTimings.map((item, index) =>
                      item.show_on_website === 0 && item.total_students > 0 ? (
                        <Card className="tutorsCard">
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={`index${index}`}
                            onClick={() => cardSelect(item.teacher_id, item.slot_id)}
                          >
                            <div className="d-flex justify-content-between mt-3">
                              <div className="d-flex">
                                <img
                                  className=""
                                  src={JSON.parse(item.teachers_info)[0]["img_src"]}
                                  width="40"
                                  height="40"
                                />
                                <div>
                                  <label className="ml-2">
                                    <span>{JSON.parse(item.teachers_info)[0]["name"]}</span>
                                  </label>
                                  <div className="timeSection">
                                    <label className="">
                                      {showTime(JSON.parse(item.Timing))?.[0]?.["start"]} -&nbsp;
                                      {showTime(JSON.parse(item.Timing))?.[0]?.["end"]}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <button className="btn btn-light joinButton">Join</button>
                              </div>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse
                            eventKey={`index${index}`}
                            style={{ padding: "10px 0" }}
                          >
                            <Card.Body>
                              <div className="text-right">
                                <span className="mr-2"> Asia/Karachi</span>
                              </div>
                              <div className="text-left">
                                <span className="text-danger" style={{ fontSize: 12 }}>
                                  *When do you want to start
                                </span>
                              </div>
                              <ul className="list-unstyled daysList">
                                {returnListTimings(item.Timing)}
                              </ul>

                              {!item.waitinglist == 1 ? (
                                <div>
                                  <button
                                    className="btn btn-success btn-block btn-lg mt-2"
                                    onClick={() => NextStep(item.waitinglist)}
                                  >
                                    Next
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <button
                                    className="btn btn-success btn-block btn-lg mt-2"
                                    onClick={() => NextStepWaitingList(item.waitinglist)}
                                  >
                                    Join Waiting Room
                                  </button>
                                </div>
                              )}
                              {/* {waitingList && waitingList ?
                                                            <div>
                                                                <button className='btn btn-success btn-block btn-lg mt-2' onClick={() => NextStepWaitingList()}>Join Waiting Room</button>
                                                            </div>
                                                            : null
                                                        } */}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      ) : item.show_on_website === 1 ? (
                        <Card className="tutorsCard">
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={`index${index}`}
                            onClick={() => cardSelect(item.teacher_id, item.slot_id)}
                          >
                            <div className="d-flex justify-content-between mt-3">
                              <div className="d-flex">
                                <img
                                  className=""
                                  src={JSON.parse(item.teachers_info)[0]["img_src"]}
                                  width="40"
                                  height="40"
                                />
                                <div>
                                  <label className="ml-2">
                                    <span>{JSON.parse(item.teachers_info)[0]["name"]}</span>
                                  </label>
                                  <div className="timeSection">
                                    <label className="">
                                      {showTime(JSON.parse(item.Timing))[0]["start"]} -&nbsp;
                                      {showTime(JSON.parse(item.Timing))[0]["end"]}
                                      {/* {JSON.parse(item.Timing)[0]["start"]} -{" "}
                                      {JSON.parse(item.Timing)[0]["end"]} */}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <button className="btn btn-light joinButton">Join</button>
                              </div>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse
                            eventKey={`index${index}`}
                            style={{ padding: "10px 0" }}
                          >
                            <Card.Body>
                              <div className="text-right">
                                <span className="mr-2"> Asia/Karachi</span>
                              </div>
                              <div className="text-left">
                                <span className="text-danger" style={{ fontSize: 12 }}>
                                  *When do you want to start
                                </span>
                              </div>
                              <ul className="list-unstyled daysList">
                                {returnListTimings(item.Timing)}
                              </ul>
                              {!item.waitinglist == 1 ? (
                                <div>
                                  <button
                                    className="btn btn-success btn-block btn-lg mt-2"
                                    onClick={() => NextStep(item.waitinglist)}
                                  >
                                    Next
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <button
                                    className="btn btn-success btn-block btn-lg mt-2"
                                    onClick={() => NextStepWaitingList(item.waitinglist)}
                                  >
                                    Join Waiting Room
                                  </button>
                                </div>
                              )}
                              {/* {waitingList && waitingList ?
                                                                <div>
                                                                    <button className='btn btn-success btn-block btn-lg mt-2' onClick={() => NextStepWaitingList()}>Join Waiting Room</button>
                                                                </div>
                                                                : null
                                                            } */}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      ) : null
                    )}
                </Accordion>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <h4>Did you get our text?</h4>
                <h3>
                  We just texted you a verification code on your phone{" "}
                  <i style={{ color: "green", fontSize: 25 }} class="fas fa-mobile-alt"></i> at:{" "}
                  <br />+{phonenumber}
                </h3>

                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    marginBottom: "7px",
                    color: "#182B49",
                    fontSize: "1.5rem",
                    marginTop: "35px",
                  }}
                >
                  Enter <span style={{ fontWeight: "700" }}>3 digit</span> code
                </p>
              </Col>
            </Row>

            <Row className="phoneStyle">
              <Col xs={{ span: 8, offset: 2 }}>
                <FormGroup style={{ position: "relative" }}>
                  <input
                    className="form-control"
                    type="number"
                    name="verifyCode"
                    id="verifyCode"
                    value={verificationCode}
                    onChange={(e) => setverificationCode(e.target.value)}
                    maxLength="3"
                    style={{
                      textAlign: "center",
                      color: "#11B67A",
                      fontSize: "2rem",
                      letterSpacing: "15px",
                      borderRadius: "10px",
                      borderColor: "#11B67A",
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md="12">
                {showCountDown ? (
                  <div className="countDownBlockVerify">
                    <span style={{ color: "#8D8E92" }}>Click here to resend in</span> <br />
                    <span id="counterHere" style={{ marginRight: "5px" }}></span>
                  </div>
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      color: "#11B67A",
                      fontSize: "16px",
                      cursor: "pointer",
                      margin: "30px 0",
                      fontWeight: "bold",
                    }}
                    onClick={(e) => props.sendPhoneNumber(phonenumber, 1)}
                  >
                    Click here to resend
                  </p>
                )}

                {verificationCode.length == 3 ? (
                  <Button
                    className="nextButtonNew"
                    style={{ margin: "35px auto" }}
                    onClick={(e) => {
                      props.sendVerificationCode(verificationCode, phonenumber);
                    }}
                    size="lg"
                    color="secondary"
                    block
                  >
                    Next
                    <i className="fas fa-arrow-right" color="#fff" />
                  </Button>
                ) : null}

                {codeError && codeError.length ? <p className="errorClass">{codeError}</p> : null}
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </Styles>
  );
};

export default connect(null, { sendPhoneNumber, sendVerificationCode, getPhoneUsersList })(
  PhoneNumberVerificationCode
);
