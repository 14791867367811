import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
.OnlineClassRules{
    margin-bottom: 40px;

    @media(max-width: 767px) {
        margin-bottom: 20px;
    }

}
`;