import React, { useState } from "react";
import { FormGroup, Button } from "react-bootstrap";
import store from "./../../store";
import PropTypes from "prop-types";
import {
  doLogin,
  triggerForgotPassPopup,
  resetPasswordCode,
  resetPassword
} from "./../../actions/webRedActions";
import { connect } from "react-redux";
import {
  validateEmailAddress,
  getCurrentClient,
  getBaseUrl,
} from "./../../actions/utility";
import axios from "axios";
import { useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const LoginForm = (props) => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [loginStep, setloginStep] = useState(1);
  const [registeredUsers, setregisteredUsers] = useState([]);
  const [isUserName, setisUserName] = useState();
  const [selectedUsername, setselectedUsername] = useState("");
  const [credError, setcredError] = useState("");
  const [userId, setuserId] = useState("");
  const [verificationCode, setverificationCode] = useState("");
  const [newPassword, setnewPassword] = useState("");

  useEffect(() => {
    debugger;
    if (
      typeof props.loginSuccessWithCourse === "object" &&
      props.loginSuccessWithCourse.message &&
      props.loginSuccessWithCourse.message.indexOf("password is incorrect") > 0
    ) {
      // showing here the incorrect credentials msg
      setcredError(props.loginSuccessWithCourse.message);
    } else if (
      typeof props.loginSuccessWithCourse === "object" &&
      props.loginSuccessWithCourse.status == "success"
    ) {
      debugger;
      console.log("login has been succcessfull");
      try {
        localStorage.setItem(
          "loginData",
          JSON.stringify(props.loginSuccessWithCourse.result)
        );
      } catch (err) {
        console.log("loginData could not store:", err);
      }
    }
  }, [props.loginSuccessWithCourse]);

  useEffect(() => {
    debugger;
    if (
      typeof props.loginSuccessNormal === "object" &&
      props.loginSuccessNormal.message &&
      props.loginSuccessNormal.message.indexOf("password is incorrect") > 0
    ) {
      // showing here the incorrect credentials msg
      setcredError(props.loginSuccessNormal.message);
    } else if (
      typeof props.loginSuccessNormal === "object" &&
      props.loginSuccessNormal.status == "success"
    ) {
      debugger;
      console.log("login has been succcessfull");
      try {
        localStorage.setItem(
          "loginData",
          JSON.stringify(props.loginSuccessNormal.result)
        );
      } catch (err) {
        console.log("loginData could not store:", err);
      }
    }
  }, [props.loginSuccessNormal]);

  const validateLoginCredentials = (column, value) => {
    if (value != "") {
      var bodyFormData = new FormData();
      var currentClient = getCurrentClient();

      bodyFormData.set("client_id", currentClient.id);
      bodyFormData.set("column", column);
      bodyFormData.set("val", value);

      axios
        .post(getBaseUrl() + "/api/v1/validate/user/column", bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            if (res.data.result == "1") {
              setregisteredUsers(res.data.data);
              setcredError("");
              setisUserName(false);
              setloginStep(2);
            } else {
              // now validate if this exits as username.

              bodyFormData.set("column", "user_loginname");

              axios
                .post(
                  getBaseUrl() + "/api/v1/validate/user/column",
                  bodyFormData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((res) => {
                  console.log(res);
                  if (res.data.status == "success") {
                    if (res.data.result == "1") {
                      setisUserName(true);
                      setcredError("");
                      setloginStep(2);
                      setselectedUsername(value);
                    } else {
                      setcredError(
                        "Email or username does not exist in the system."
                      );
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                });

              // this.setState({
              //     credError : "Email does not exist in the system."
              // })
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setcredError("Please enter valid email or username.");
    }
  };
  const selectUser = (user) => {
    debugger;
    setselectedUsername(user.user_loginname);
    setisUserName(true);
    setuserId(user.id);
  };

  const submitLoginForm = (event) => {
    if (email != "" && password != "") {
      // if(validateEmailAddress(email)){

      var loginType = "course";
      var courseId = 0,
        isPurchase = 0,
        isPassRequired = 1;

      if (
        localStorage.getItem("loginType") &&
        localStorage.getItem("loginType") != null
      ) {
        loginType = localStorage.getItem("loginType");
        if (loginType == "course") {
          courseId = store.getState().webRed.courseDetail.course.id;
          isPurchase = 1;
        }
      }

      var loginViaThis = email;
      if (isUserName) {
        loginViaThis = selectedUsername;
      }

      debugger;
      props.doLogin(
        loginViaThis,
        password,
        courseId,
        isPurchase,
        loginType,
        isPassRequired
      ); // calling the action to login in now
    } else {
      setcredError("Username and Password is required.");
    }
  };

  const onEnterPressNext = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      validateLoginCredentials("user_email", email);
    }
  };

  const onEnterPressLogin = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      submitLoginForm();
    }
  };

  const LoginCredentials = [
    {
      email: "hamza@yopmail.com",
      password: "123123",
      color: Math.floor(Math.random() * 16777215).toString(16),
    },
    {
      email: "afzalAli@yopmail.com",
      password: "123123",
      color: Math.floor(Math.random() * 16777215).toString(16),
    },
    {
      email: "hamzaTest@yopmail.com",
      password: "123123",
      color: Math.floor(Math.random() * 16777215).toString(16),
    },
  ];
  const forgotActions = () => {
    setloginStep(3);
    resetPasswordCode(userId);
  };

  const resetPasswordFunc = () => {
    resetPassword(userId , verificationCode , newPassword)
    .then((res) => {
      if(res.data.result == "true") {
        toast.success("Password Reset Successfully");
        setloginStep(2);
      }
      else {
        toast.error("Invalid Verififcation Code");
      }

      debugger;
    })
    .catch((err) => {
      console.log(err);
    })
  }
  return (
    <div style={{ padding: "1vh 0" }} className="LoginForm">
      {/* <Form className="loginform"> */}

      <h4>
        {loginStep == 1
          ? "Login"
          : loginStep == 2
          ? "Password"
          : "Verification"}
      </h4>
      <br />
      {credError && credError.length ? (
        <FormGroup style={{ margin: 0 }}>
          <p style={{ left: "0", position: "relative" }} className="error">
            {credError}
          </p>
        </FormGroup>
      ) : null}
      {loginStep == 1 ? (
        <FormGroup style={{ position: "relative" }}>
          <label style={{ color: "#000" }}>Enter Email or Username:</label>
          <input
            className="form-control"
            style={{
              color: "#007bff",
              fontSize: "17px",
              // borderRadius: '10px'
            }}
            type="email"
            name="login_email"
            id="login_email"
            placeholder="Enter email or username *"
            onChange={(evt) => {
              setemail(evt.target.value);
            }}
            onKeyDown={onEnterPressNext}
          />
          <br />
          <Button
            onClick={(e) => validateLoginCredentials("user_email", email)}
            size="md"
            color="warning"
          >
            Next
          </Button>

          {/* <hr />

                        <h6>Previously added accounts:</h6>
                        {LoginCredentials.map((item, index) =>
                            <div className="loginRemembered" key={index}>
                                <div className="d-flex">
                                    <div className="circle" style={{ background: `#${item.color}` }}><span>{item.email.charAt(0)}</span></div>
                                    <div className="emailPassword">
                                        <label>{item.email}</label>
                                        <br />
                                        <span>******</span>
                                    </div>
                                </div>
                            </div>
                        )} */}
        </FormGroup>
      ) : null}

      {loginStep == 2 ? (
        registeredUsers.length && registeredUsers.length > 1 && !isUserName ? (
          <div className="Users" style={{ minHeight: "auto" }}>
            <label style={{ fontSize: "12px" }}>
              Select a user account to login
            </label>
            {/* <hr /> */}
            <div className="row justify-content-center">
              {registeredUsers.map((user, key) => (
                <div
                  key={key}
                  className="col-md-3 col-4 boxContent"
                  onClick={() => selectUser(user)}
                >
                  <div
                    className="boxForStudent"
                    style={{ background: `#030b1e` }}
                  >
                    <label>{user.user_name.charAt(0)}</label>
                  </div>
                  <label>{user.user_name}</label>
                </div>
              ))}
            </div>
            {/* <ul className="registeredUsersList">
              {registeredUsers.map((user, key) => (
                <li
                  key={key}
                  onClick={() => selectUser(user)}
                  style={{ marginBottom: 20 }}
                >
                  <div className="d-flex">
                    <Button>
                      use <br />
                      this
                    </Button>
                    <p style={{ marginLeft: 10 }}>
                      {user.user_loginname} <br />
                      <span style={{ color: "#000" }}>{user.user_name}</span>
                    </p>
                  </div>
                </li>
              ))}
            </ul> */}
          </div>
        ) : (
          <div>
            <FormGroup>
              <label style={{ fontSize: "12px" }}>
                Your {isUserName ? "username" : "email"} is{" "}
                {isUserName ? selectedUsername : email}{" "}
                <span
                  style={{
                    color: "#fff",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setloginStep(1);
                  }}
                >
                  change
                </span>{" "}
              </label>
              {/* <hr /> */}
              <input
                className="form-control"
                style={{
                  color: "#007bff",
                  fontSize: "17px",
                }}
                type="password"
                name="password"
                id="login_password"
                placeholder="Enter password *"
                onChange={(evt) => {
                  setpassword(evt.target.value);
                }}
                onKeyDown={onEnterPressLogin}
              />
            </FormGroup>
            <FormGroup>
              <Button onClick={submitLoginForm} size="md" color="warning">
                Login
              </Button>
              <br />
              <a
                onClick={() => forgotActions()}
                className="text-primary"
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Forgot password?
              </a>
              <br />
            </FormGroup>
          </div>
        )
      ) : null}

      {loginStep == 3 ? (
        <div>
          <div className="text-center text-success font-weight-bold">
            <h6>We have sent you 5 digits verification code on {email}</h6>
          </div>

          <label className="mt-3" style={{ color: "#000" }}>
            Verification code:
          </label>
          <input
            className="form-control"
            style={{
              color: "#007bff",
              fontSize: "17px",
              // borderRadius: '10px'
            }}
            type="number"
            name="login_email"
            id="login_email"
            placeholder="e.g: 12345"
            onChange={(evt) => {
              setverificationCode(evt.target.value)
            }}
            // onKeyDown={onEnterPressNext}
          />
          <br />
          <label style={{ color: "#000" }}>New password:</label>
          <input
            className="form-control"
            style={{
              color: "#007bff",
              fontSize: "17px",
              // borderRadius: '10px'
            }}
            type="text"
            name="login_email"
            id="login_email"
            placeholder=""
            onChange={(evt) => {
              setnewPassword(evt.target.value);
            }}
          />
          <FormGroup>
            <Button
              onClick={() => resetPasswordFunc()}
              size="md"
              color="success"
              className="mt-3"
            >
              Change Password
            </Button>
          </FormGroup>
        </div>
      ) : null}

      {/* </Form> */}

      {/* <p style={{marginBottom: '0', marginTop: '10px', fontSize: '14px'}}>Connect with us.</p>
                <SocialLoginComponent /> */}
    </div>
  );
};
const mapStatesToProps = (state) => ({
  loginSuccessWithCourse: state.webRed.loginSuccessWithCourse,
  loginSuccessNormal: state.webRed.loginSuccessNormal,
});
export default connect(mapStatesToProps, { doLogin, triggerForgotPassPopup })(
  LoginForm
);
