import React, { useState } from 'react';
import { sendUsername, updateUserPassword } from './../../actions/webRedActions';
import { connect } from 'react-redux';
import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
} from "react-bootstrap";
import { Styles } from "./styles/usernamefield.js";


const UsernameField = (props) => {
    const [username, setusername] = useState("");
    const [usernameError, setusernameError] = useState(false);
    const [password, setpassword] = useState("");
    const [passwordError, setpasswordError] = useState(false);

    const validatePassword = (e) => {
        setpassword(e.target.value);
        if (e.target.value.length <= 3) {
            setpasswordError("Password should have more than 4 digits")
        }
        else {
            setpasswordError(false)
        }
    }

    const { isChild } = props;
    return (
        <Styles>

            <div style={{ padding: '1vh 0' }} className="UsernameField" style={{
                background: 'f4eb49',
                color: '75a9f9'
            }}>
                <div className="wrapper"></div>

                <Container>
                    <Row>
                        <Col style={{ textAlign: 'center' }}>
                            <h3 style={{ margin: "75px  0" }}>
                                {/* Create a username & password for {isChild ? "your child" : "yourself"} */}
                                Create a username & password for the student
                            </h3>
                        </Col>
                    </Row>

                    <Row className="phoneStyle">
                        <Col xs="12">
                            <FormGroup style={{ position: 'relative' }}>
                            {/* <label for="password">Type in username of your choice</label> */}
                                <input className="form-control" type="text" name="username" id="username" value={username}
                                    placeholder="Create username"
                                    onChange={(e) => {
                                        setusername(e.target.value)
                                    }}
                                    style={{
                                        textAlign: 'center',
                                        color: '#11B67A',
                                        fontSize: '1.5rem',
                                        borderRadius: '10px',
                                        borderColor: "#11B67A"
                                        // marginTop: '35px'
                                    }}
                                />
                                <p style={{ color: '#182B49', fontSize: "12px", padding: '5px 10px', textAlign: 'center' }}>example: <strong>JohnSmith11</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (no spaces)</p>
                            </FormGroup>

                        </Col>

                        <Col xs="12">
                            <FormGroup style={{ position: 'relative' }}>
                                {/* <label for="password">Type in password of your choice</label> */}
                                <input className="form-control" type="password" name="password" id="password" value={password}
                                    placeholder="Create password"
                                    onChange={(e) => {
                                        validatePassword(e);
                                    }}
                                    style={{
                                        textAlign: 'center',
                                        color: '#11B67A',
                                        fontSize: '1.5rem',
                                        borderRadius: '10px',
                                        borderColor: "#11B67A"
                                        // marginTop: '35px'
                                    }}
                                />

                            </FormGroup>

                            {/* <FormGroup style={{position: 'relative'}}>
                            <label for="c_password">Re-type the password</label>
                            <Input type="password" name="c_password" id="c_password" value={c_password}
                                placeholder="re-type password"
                                onChange={(e) => {
                                    this.setState({
                                        c_password : e.target.value
                                    }, () => {
                                        this.validateError();
                                    })
                                }}
                                style={{
                                    textAlign: 'center',
                                    color: '#007bff',
                                    fontSize: '1.5rem',
                                    borderRadius: '10px',
                                    // marginTop: '35px'
                                }}
                            />
                            
                        </FormGroup> */}

                        </Col>

                        <Col md={{ size: 12 }}>
                            {
                                passwordError ?
                                    <p style={{ color: 'red', textAlign: 'center' }}>{passwordError}</p>
                                    : null
                            }
                            {
                                usernameError ?
                                    <p style={{ color: 'red', textAlign: 'center' }}>{usernameError}</p>
                                    : null
                            }

                            {
                                username.length > 0 && !passwordError && password.length > 3 && password ?

                                    <Button
                                        className="nextButtonNew"
                                        style={{ margin: '35px auto' }}
                                        onClick={(e) => {
                                            props.sendUsername(username);
                                            props.updateUserPassword(password);
                                        }}
                                        size="lg" color="secondary" block>
                                        Next
                                    {/* <FontAwesomeIcon icon={faArrowRight} size="2x" color="#fff" /> */}
                                        <i className="fas fa-arrow-right" color="#fff" />
                                    </Button>

                                    : null
                            }

                        </Col>


                    </Row>

                </Container>

            </div>
        </Styles>
    );
}

export default connect(null, { sendUsername, updateUserPassword })(UsernameField);