import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Table, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import axios from 'axios';

const instance = 'https://lm-api-nest.skillupz.com/urls';

const AddUrl = () => {
  const [url, setUrl] = useState('');
  const [originalUrls, setOriginalUrls] = useState([]);
  const [filteredUrls, setFilteredUrls] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state
  const history = useHistory();

  useEffect(() => {
    // Check if user is authenticated
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      history.push('/urllogin');
    }
  }, [history]);

  useEffect(() => {
    // Fetch URLs on component mount
    fetchUrls();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = originalUrls.filter((u) => u.url.includes(searchTerm));
      setFilteredUrls(filtered);
    } else {
      setFilteredUrls(originalUrls);
    }
  }, [searchTerm, originalUrls]);

  const fetchUrls = async () => {
    setLoading(true); // Set loading to true before starting the fetch
    try {
      const response = await axios.get(instance);
      setOriginalUrls(response.data.urls || []);
      setFilteredUrls(response.data.urls || []);
    } catch (error) {
      console.error('Error fetching URLs:', error);
    }
    setLoading(false); // Set loading to false after data is fetched
  };

  const handleAddUrl = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${instance}/update`, { urls: [`${url}`] });
      fetchUrls();
      setUrl('');
    } catch (error) {
      console.error('Error adding URL:', error);
    }
  };

  const handleRemoveUrl = async (urlToRemove) => {
    try {
      await axios.delete(`${instance}/remove`, { params: { url: urlToRemove } });
      fetchUrls();
    } catch (error) {
      console.error('Error removing URL:', error);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={12}>
          <h2 className='text-center mb-5'>LM Services Status</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <Form onSubmit={handleAddUrl}>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Enter URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
              <Button variant="primary" type="submit">
                Add URL
              </Button>
            </InputGroup>
          </Form>
        </Col>
        <Col md={6}>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Search URLs"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      
      <Row>
        <Col md={12}>
          {loading ? ( // Show spinner while loading
            <div className="text-center">
              <Spinner animation="border" role="status">
              </Spinner>
            </div>
          ) : ( // Show table once data is loaded
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sr No: #</th>
                  <th>Service URL</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredUrls.map((u, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td><a href={u.url} target='__blank' rel="noopener noreferrer">{u.url}</a></td>
                    <td className='text-info'>{u.status || 'WIP'}</td>
                    <td className='text-center'>
                      <i className="las la-trash" style={{ fontSize: '2rem', color: 'red' }} onClick={() => handleRemoveUrl(u.url)}></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AddUrl;
