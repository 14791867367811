import React, { Component } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { Styles } from "./styles/numberCounter.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class NeedKGSTutor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classesConduct: 0,
            classesSlide: [],
            startCount: true,
            gradesData: "",
            grades: [],
            filteredCourses: {},
            sortedCourses: "",
            selectedGrade: "",
            showSubjectDropdown: false,
            courseName: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.gradesData !== nextProps.demoGrades) {
            debugger;
            const { demoGrades } = nextProps;
            var sortedCoursesArray = [];
            var coursesKeys = Object.keys(demoGrades.gradescourses);
            coursesKeys.map(course => {

                var gradeArray = [];
                var courseKey = Object.keys(demoGrades.gradescourses[course]);
                var gradeName = demoGrades.gradescourses[course][courseKey[0]].grade;
                courseKey.map(_course => {
                    var thisCourse = demoGrades.gradescourses[course][_course];
                    gradeArray.push(thisCourse);
                })
                sortedCoursesArray.push({
                    grade: gradeName,
                    courses: gradeArray,
                    visible: true
                })

            })
            return {
                gradesData: demoGrades,
                grades: coursesKeys,
                sortedCourses: sortedCoursesArray
            };
        }
        return null;
    }

    gradeClicked = (e) => {
        // window.scrollTo(0, 60); //scroll to top
        // var element = document.getElementById("box");

        // element.scrollIntoView();
        debugger;
        this.setState({
            selectedGrade: e.target.value
        })
        // if (e !== "") {
        //     this.setState({
        //         showSubjectDropdown: true,
        //         selectedGrade: e.target.value
        //     })
        // }
        // else {
        //     this.setState({
        //         showSubjectDropdown: false,
        //         selectedGrade: e.target.value
        //     })
        // }
    }
    courseCLicked = (e) => {
        debugger;
        if (this.state.selectedGrade === "Grade 3 KGS admission prep") {
            this.props.history.push('course-details/4M9G0NDB5rZ5Jd3');
        }
        else if (this.state.selectedGrade === "Grade 7 KGS admission prep") {
            this.props.history.push('course-details/ba9Mz7Z6b7OERm0');
        }
        else if (this.state.selectedGrade === "Grade 10 KGS admission prep") {
            this.props.history.push('course-details/5k3vgrJZErB80Yp');
        }
        else if (this.state.selectedGrade === "Nursery KGS admission prep") {
            this.props.history.push('course-details/JlpzM7nj579LGQk');
        }
        // this.state.sortedCourses.map(_grade => {
        //     // console.log(grade);
        //     if (_grade.grade == this.state.selectedGrade) {

        //         console.log(_grade)
        //         debugger;
        //         _grade.courses.map(course => {
        //             if (course.course_name.indexOf(this.state.courseName) > -1) {
        //                 this.props.history.push('course-details/' + course.id);
        //             }
        //         })
        //     }
        // })

    }

    render() {
        const { grades, showSubjectDropdown, selectedGrade, courseName } = this.state;
        return (
            <Styles>
                {/* Counter Area */}
                <section className="counter-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Images for Homepage//)` }}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="12" className="">
                                <div className="sec-title text-center">
                                    <h3>Find an online Tutor for KGS Admission Prep</h3>
                                    {/* <label className="text-light">for homework help, or exam prep, or to get ahead</label> */}
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-4">
                            <Col md="4" xs="8">
                                <label className="text-light">I need a tutor for:</label>
                                {grades.length !== 0 ?
                                    <select
                                        className="custom-select"
                                        name="staffId"
                                        onChange={this.gradeClicked}
                                    >
                                        <option value="">
                                            Select a Grade
                                </option>
                                        <option value="Nursery KGS admission prep">Nursery KGS admission prep</option>
                                        <option value="Grade 3 KGS admission prep">Grade 3 KGS admission prep</option>
                                        <option value="Grade 7 KGS admission prep">Grade 7 KGS admission prep</option>
                                        <option value="Grade 10 KGS admission prep">Grade 10 KGS admission prep</option>
                                    </select>

                                    :
                                    <React.Fragment>
                                        <br />
                                        <Spinner className="centered-spinner" animation="border" variant="light" />
                                    </React.Fragment>
                                }
                            </Col>
                        </Row>
                        {/* <Row className="justify-content-center mt-4">
                            <Col md="4" xs="8">
                                <label className="text-light">I need help in:</label>
                                <select
                                    className="custom-select"
                                    onChange={(e) => this.setState({
                                        courseName: e.target.value
                                    })}
                                >
                                    <option>
                                        Select a Subject
                                    </option>
                                    {this.state.selectedGrade !== "" ?
                                        <React.Fragment>
                                            <option value="English">English</option>
                                            <option value="Math">Math</option>
                                        </React.Fragment>
                                        :
                                        null
                                    }
                                    {
                                        this.state.selectedGrade == "Grade 2" ||
                                            this.state.selectedGrade == "Grade 3" ||
                                            this.state.selectedGrade == "Grade 4" ||
                                            this.state.selectedGrade == "Grade 5" ||
                                            this.state.selectedGrade == "Grade 6" ||
                                            this.state.selectedGrade == "Grade 7" ||
                                            this.state.selectedGrade == "Grade 8" ||
                                            this.state.selectedGrade == "Grade 9" ?
                                            <option value="Creative Writing">Creative Writing</option>
                                            : null
                                    }

                                    {
                                        this.state.selectedGrade == "Grade 9" ||
                                            this.state.selectedGrade == "Grade 10" ?
                                            <option value="Chemistry">Chemistry</option>

                                            : null

                                    }

                                    {
                                        this.state.selectedGrade == "Kindergarten" ||
                                            this.state.selectedGrade == "Grade 1" ||
                                            this.state.selectedGrade == "Grade 2" ||
                                            this.state.selectedGrade == "Grade 3" ||
                                            this.state.selectedGrade == "Grade 4" ||
                                            this.state.selectedGrade == "Grade 5" ||
                                            this.state.selectedGrade == "Grade 6" ||
                                            this.state.selectedGrade == "Grade 7" ||
                                            this.state.selectedGrade == "Grade 8" ?
                                            <option value="Science">Science</option> : null
                                    }


                                </select>
                                {selectedGrade !== "" && courseName === "" ?
                                    <span className="text-danger">*Required</span>
                                    : null
                                }
                            </Col>
                        </Row> */}
                        <Row className="justify-content-center">
                            <Col md="4" xs="8" className="" >
                                <button className={selectedGrade !== "" ? "findButton" : "findButtonDisabled"} disabled={selectedGrade !== "" ? false : true} onClick={this.courseCLicked}>Find {selectedGrade !== "" ? <i className="ml-2 fas fa-arrow-right"></i> : null}  </button>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Marquee classesSlide={this.state.classesSlide} /> */}
            </Styles>
        )
    }
}

const mapStateToProps = state => ({
    coursesDataOnlineSchool: state.webRed.coursesDataOnlineSchool,
    demoGrades: state.webRed.demoGrades
})
export default withRouter(connect(mapStateToProps, null)(NeedKGSTutor));