import React from 'react';
import HeaderTwo from '../../components/HeaderTwo';
import { Styles } from './styles/onlineClassRules.js';
import { Container, Row, Col, Image } from 'react-bootstrap';


const OnlineClassRules = () => {
    return (
        <div>
            <HeaderTwo />
            <Styles>
                <section>

                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%', height: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/1.png"} />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/2.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/3.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/4.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/5.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/6.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/7.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/8.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/9.png"} fluid />
                    </div>

                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/10.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/11.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/12.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/13.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/14.png"} fluid />
                    </div>
                    <div className="OnlineClassRules" >
                        <Image style={{ width: '100%' }} src={process.env.PUBLIC_URL + "/assets/images/LM/OnlineClassRules/15.png"} fluid />
                    </div>

                </section>
            </Styles>
        </div>
    );
}

export default OnlineClassRules;