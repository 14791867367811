import React, { useState } from 'react';
import HeaderTwo from '../../components/HeaderTwo';
import { Container, Row, Col } from 'react-bootstrap';
import FooterTwo from '../../components/FooterTwo';
import ModalVideo from 'react-modal-video';
import { Styles } from '../../components/styles/privacyPolicy.js';


const InvestWithUs = () => {
    const [isOpen, setisOpen] = useState(false);
    return (<div>
        <HeaderTwo />
        <Styles>
            <div className="main-wrapper privacy-policy-page"
                style={{ padding: '10vh 0 0 0', background: 'linear-gradient(90deg, #009444 0%, #11B67A 100%)' }}>
                <Container style={{ textAlign: 'left' }}>
                    <Row>
                        <Col md={8}>
                            <h2 style={{ color: '#fff' }}>We are impact entrepreneurs, and we generate purpose-driven profits</h2>
                            <h3 style={{ color: '#fff', padding: '15vh 0 5vh 0' }}>Lincoln Method supports SDGs</h3>
                            <img style={{ maxWidth: '100%' }} src={"/assets/images/LM/sdg.png"} />
                        </Col>
                        <Col md={4}>
                            <img style={{ maxWidth: '100%' }} src={"/assets/images/LM/kidPicture.png"} />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="main-wrapper privacy-policy-page">
                <section className="event-details-area">
                    <div>
                        <Container style={{ textAlign: 'left' }}>
                            <Row>
                                <Col md={12}>
                                    <div className="event-details-content">
                                        <div className="video-player" style={{
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundImage: `url(/assets/images/LM/SQInvest.jpeg)`
                                        }}>
                                            <ModalVideo channel='youtube' isOpen={isOpen} videoId='1CY7m5_KUQA' onClose={() => setisOpen(false)} />
                                            <button onClick={() => setisOpen(true)} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            </div>
        </Styles>
        <FooterTwo />
    </div >);
}

export default InvestWithUs;