import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Spinner,
  ButtonGroup,
  Modal,
  Button,
} from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
// import { BreadcrumbBox } from '../../components/common/Breadcrumb';
// import ReviewForm from './components/ReviewForm';
// import PopularCourse from './components/PopularCourse';
// import CourseTag from './components/CourseTag';
import FooterTwo from "../../components/FooterTwo";
import {
  getCourseDetails,
  getTestPreview,
  triggerLoginPopup,
  triggerEnrollPopup,
  triggerCheckoutPopup,
  getSignUpFormType,
  getPreviousCards,
} from "./../../actions/webRedActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { checkTutorImg, formatPrice } from "./../../actions/utility";
import { Styles } from "./styles/courseDetailsV2.js";
import $, { type } from "jquery";
import CountUp from "react-countup";
import EnrollStepsText from "../../components/EnrollStepsText";
import ModalImage from "react-modal-image";
import ImagesSlider from "../../components/ImagesSlider";
import { isMobileBrowser, getLastLinkStorage } from "../../actions/utility";
import moment from "moment-timezone";

function CourseDetailsV2(props) {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [daysPrice, setdaysPrice] = useState([]);
  const [courseTeachers, setCourseTeacher] = useState("");
  const [courseDetails, setcourseDetails] = useState("");
  const [courseTimings, setCourseTimings] = useState();
  const [course_discount_price, setCourse_discount_price] = useState("");
  const [course_price, setCourse_price] = useState("");
  const [teacher_details, setTeacher_details] = useState("");
  const [courseDetailsTable, setcourseDetailsTable] = useState("");
  const [beforeAfterImages, setbeforeAfterImages] = useState("");
  const [csrfToken, setcsrfToken] = useState("");
  const [questionHtml, setquestionHtml] = useState("");
  const [htmlModal, sethtmlModal] = useState(false);
  const [htmlmodalerror, sethtmlmodalerror] = useState(false);
  const [htmlModalLoader, sethtmlModalLoader] = useState(false);
  const [old_price, setOldPrice] = useState("5000");
  const [enableEnrollButton, setenableEnrollButton] = useState(true);
  const [showModalCoupon, setshowModalCoupon] = useState(false);
  const [modalCouponCode, setmodalCouponCode] = useState("FREECLASS");
  const [modalCouponText, setmodalCouponText] = useState("We are giving you a FREE CLASS!");
  const [teacherDetailShow, setteacherDetailShow] = useState(false);
  const [zone, setzone] = useState("Asia/Karachi");
  const [loading, setLoading] = useState(true);
  const [onPage, setonPage] = useState(true);
  const [showPrice, setshowPrice] = useState(true);

  useEffect(() => {
    setLoading(true);
    setcourseDetailsTable("");
    props.getCourseDetails(props.match.params.courseid);
    console.log("Last Link", getLastLinkStorage());
    if (getLastLinkStorage() == "/demo-home" || getLastLinkStorage() == "/") {
      setshowModalCoupon(false);
    }
    var zone = moment.tz.guess();
    setzone(zone);
    //FB PIXEL EVENT
    window.fbq("track", "ViewContent");

    const mobileView = localStorage.getItem("mobileapp");
    if (mobileView) {
      setshowPrice(false);
    }
  }, []);

  useEffect(() => {
    const courseButton = document.querySelectorAll(".course-button");
    courseButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "course-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "course-content";
          content.style.maxHeight = "0";
        }
      });
    });

    $(".overViewBtn").click(function () {
      $("html,body").animate(
        {
          scrollTop: $(".first").offset().top - 70,
        },
        "slow"
      );
    });

    $(".curriculumBtn").click(function () {
      $("html,body").animate(
        {
          scrollTop: $(".second").offset().top - 70,
        },
        "slow"
      );
    });

    $(".classTimingsBtn").click(function () {
      $("html,body").animate(
        {
          scrollTop: $(".third").offset().top - 70,
        },
        "slow"
      );
    });

    var testDiv = document.getElementById("test");
    var element = document.getElementById("stickyButtons");
    var overview = document.getElementById("overview");
    var curriculum = document.getElementById("curriculum");
    var timeSlots = document.getElementById("timeSlots");
    // window.addEventListener("scroll", () => {
    //     if (testDiv !== null) {
    //         var divHeight = testDiv.offsetTop;
    //         if ($(".first").length) {
    //             if (window.scrollY > $(".first").offset().top - 80) {
    //                 overview.classList.add("active");
    //                 curriculum.classList.remove("active");
    //                 timeSlots.classList.remove("active");
    //             }
    //             if (window.scrollY > $(".second").offset().top - 80) {
    //                 curriculum.classList.add("active");
    //                 overview.classList.remove("active");
    //                 timeSlots.classList.remove("active");
    //             }
    //             if (window.scrollY > $(".third").offset().top - 80) {
    //                 timeSlots.classList.add("active");
    //                 overview.classList.remove("active");
    //                 curriculum.classList.remove("active");
    //             }

    //         }

    //         if (window.scrollY > divHeight) {
    //             element.classList.remove("buttonForScrollSticky");
    //             element.classList.add("buttonForScroll");
    //         }
    //         else {
    //             element.classList.remove("buttonForScroll");
    //             element.classList.add("buttonForScrollSticky");
    //         }
    //     }
    // })
  });

  if (props.courseDetail !== courseDetails) {
    const { courseDetail, getSignUpFormType } = props;
    setLoading(false);
    setcourseDetails(courseDetail);
    setTeacher_details(courseDetail.courseExtraDetails.result);
    setSelectedCourse(courseDetail.course);
    setCourseTeacher(courseDetail.course.teachers_info);
    setCourseTimings(courseDetail.course.timing);
    setCourse_price(courseDetail.course.course_price);
    setCourse_discount_price(courseDetail.course.course_discount_price);
    setOldPrice(courseDetail.course.old_price);
    setbeforeAfterImages(courseDetail.course.beforeAfterImages);
    setcsrfToken(courseDetail._token);
    getSignUpFormType(courseDetail.course.id);

    //Parsing Course Features
    if (courseDetail.course.course_features !== null) {
      var Icons = [
        "las la-calendar",
        "las la-clock",
        "las la-calendar-week",
        "las la-globe",
        "las la-sort-amount-up",
        "las la-certificate",
        "las la-laptop",
        "las la-file-excel",
        "las la-chalkboard-teacher",
        "las la-tasks",
        "las la-users",
        "las la-pager",
        "las la-book-open",
        "las la-ethernet",
        "las la-sort-numeric-down-alt",
      ];
      var courseFeature = JSON.parse(courseDetail.course.course_features);
      var featureArray = [];

      Object.keys(courseFeature).map((id, key) => {
        featureArray.push({
          feature: courseFeature[id].feature,
          value: courseFeature[id].value,
          icons: Icons[key],
        });
      });
      featureArray.push(
        {
          feature: "Video Tutorials",
          value: courseDetail.course.videos,
          icons: "las la-play",
        },
        {
          feature: "Chapters",
          value: courseDetail.course.chapters,
          icons: "las la-sort-numeric-down-alt",
        },
        {
          feature: "topics",
          value: courseDetail.course.topics,
          icons: "las la-sort-numeric-down-alt",
        },
        {
          feature: "worksheets",
          value: courseDetail.course.worksheets,
          icons: "las la-sort-numeric-down-alt",
        }
      );
      console.log("After Parsing Feature", courseFeature);
      console.log("Feature Keys", Object.keys(courseFeature));
      console.log("Feature Array", featureArray);
      var arr = [
        { key: "11", value: "1100", $$hashKey: "00X" },
        { key: "22", value: "2200", $$hashKey: "018" },
      ];

      //convert
      var result = {};
      for (var i = 0; i < featureArray.length; i++) {
        result[featureArray[i].feature.replace(/ +/g, "")] = featureArray[i].value;
      }
      setcourseDetailsTable(result);

      console.log("Signlke Object", result);
    }
  }

  if (props.questionHtml != questionHtml) {
    const { questionHtml } = props;
    console.log("Questions", questionHtml);
    setquestionHtml(questionHtml);
    sethtmlModal(true);
    sethtmlModalLoader(false);
  }

  const showTeacherDetails = (showItem) => {
    let teachersArary = [];
    if (props.match.params.courseid == "xeDaK7aYZ7M8PdL") {
      openLoginModal();
    }

    var details = JSON.parse(courseTeachers);

    if (courseTeachers !== null) {
      Object.keys(details).map((id, key) => {
        var teacher = details[id];
        if (showItem === 1) {
          let item = (
            <Row key={key}>
              <Col md="4">
                <div className="instructor-img">
                  <img
                    src={checkTutorImg(teacher.img_src)}
                    // src={
                    //   teacher.img_src &&
                    //   teacher.img_src !== "" &&
                    //   teacher.img_src !==
                    //     "https://lincolnmethod.s3.us-east-1.amazonaws.com/profileImages/"
                    //     ? teacher.img_src
                    //     : "https://pngimage.net/wp-content/uploads/2018/06/no-user-image-png.png"
                    // }
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col md="8">
                <div className="instructor-content">
                  <div className="instructor-box">
                    <div className="top-content d-flex justify-content-between">
                      <div className="instructor-name">
                        <h6> {teacher.name && teacher.name != "" ? teacher.name : ""}</h6>
                        {/* <p>Senior Lecturer</p> */}
                        {!teacherDetailShow ? (
                          <React.Fragment>
                            <div className="d-flex">
                              <label>
                                <strong>Why I got into teaching ? </strong>
                              </label>
                              <span onClick={() => setteacherDetailShow(true)}>See More...</span>
                            </div>
                            <div className="d-flex">
                              <label>
                                <strong>How I make my classes fun ? </strong>
                              </label>
                              <span onClick={() => setteacherDetailShow(true)}>See More...</span>
                            </div>
                          </React.Fragment>
                        ) : null}
                      </div>
                      {/* <div className="instructor-social">
                                    <ul className="social list-unstyled list-inline">
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                    </ul>
                                </div> */}
                    </div>
                    {teacherDetailShow ? (
                      <div className="instructor-desk">
                        {teacher_details.scholasticInfo !== null ? (
                          <React.Fragment>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: teacher_details.scholasticInfo.teacher_description,
                              }}
                            ></p>
                          </React.Fragment>
                        ) : (
                          <p>-</p>
                        )}
                        <span onClick={() => setteacherDetailShow(false)}>See Less...</span>
                        <br />
                      </div>
                    ) : null}
                    <div className="about-content">
                      {/* <h4 className="about-title">{Datas.title}</h4>
                                        <p className="about-para">{Datas.desc1}<span>{Datas.desc2}</span></p> */}

                      <Row>
                        <Col sm="11 d-flex mt-1 justify-content-between">
                          <div className="counter-box box1 text-center">
                            <h3>
                              <CountUp
                                end={teacher_details.progsTaughtCnt}
                                duration={2}
                                delay={1}
                              />
                            </h3>
                            <p>Classes taught</p>
                          </div>
                          {/* </Col>
                                        <Col sm="4 mt-1"> */}
                          <div className="counter-box box2 text-center ml-3">
                            <h3>
                              <CountUp end={teacher_details.teacherEvlCnt} duration={2} delay={1} />
                            </h3>
                            <p>Student evaluation performed</p>
                          </div>
                          {/* </Col>
                                        <Col sm="4 mt-1"> */}
                          {/* <div className="counter-box box3 text-center">
                                                <h3><CountUp end={teacher_details.videosWatched} duration={2} delay={1} /></h3>
                                                <p>Video tutorials administered</p>
                                            </div> */}
                        </Col>
                      </Row>
                      {/* : <Row> <Col md="12" className="text-center"> <Spinner className="centered-spinner" animation="border" variant="dark" /> </Col> </Row> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          );
          teachersArary.push(item);
        } else {
          let item = (
            <button className="course-button active" key={key}>
              <img
                src={checkTutorImg(teacher.img_src)}
                // src={
                //   teacher.img_src &&
                //   teacher.img_src !== "" &&
                //   teacher.img_src !==
                //     "https://lincolnmethod.s3.us-east-1.amazonaws.com/profileImages/"
                //     ? teacher.img_src
                //     : "https://pngimage.net/wp-content/uploads/2018/06/no-user-image-png.png"
                // }
                alt=""
                className="img-fluid"
              />
              <label className="ml-4">
                {" "}
                Section 1 taught by:{" "}
                <label style={{ color: "#FBE001" }}>
                  {" "}
                  {teacher.name && teacher.name != "" ? teacher.name : ""}
                </label>{" "}
              </label>{" "}
              <span>Timezone: {zone}</span>
            </button>
          );
          teachersArary.push(item);
        }
      });
    }

    return teachersArary;
  };

  //TimeZone Functions Start
  const AMPMTo24HourConversion = (times) => {
    var patt1 = /\s/g;
    var time = times;
    if (times.match(patt1)) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      // alert(sHours + ":" + sMinutes);
      return sHours + ":" + sMinutes;
    } else {
      if (time.includes("PM")) {
        time = time.replace("PM", " PM");
      } else if (time.includes("AM")) {
        time = time.replace("AM", " AM");
      }
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      // alert(sHours + ":" + sMinutes);
      return sHours + ":" + sMinutes;
    }
  };

  const minutesToHour = (n) => {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rminutes == 0) {
      rminutes = "00";
    }
    console.log(num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).");
    return {
      rhours,
      rminutes,
    };
  };
  const timeConvert24to12 = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };
  const changeTimeZoneLocation = (time) => {
    var TIME = AMPMTo24HourConversion(time); // Convert 12 hour to 24 Hour Time System
    var convertedTimeinMinutes = moment.duration(TIME).asMinutes(); // Convert Hours into Minutes
    var makingTimeDifferenceZero = convertedTimeinMinutes - 300; // Subtract Pakistan's GMT time with Initial Time.
    var usersCountryOffset = moment().tz(zone).utcOffset(); // Get Client's UTCoffset by timezone
    var timeinMinutes = makingTimeDifferenceZero + usersCountryOffset; // Add Clients time by initial time
    var timeinHours = minutesToHour(timeinMinutes); // Convert minutes into Hour
    var ActualConvertedTime = "";
    if (timeinHours.rhours >= 12) {
      ActualConvertedTime = timeConvert24to12(`${timeinHours.rhours}:${timeinHours.rminutes}`); //Convert 24 Hours Back to 12 Hours
    } else {
      ActualConvertedTime = timeinHours.rhours + ":" + timeinHours.rminutes + " AM";
    }
    // console.log("Time Converted 24 to 12:", ActualConvertedTime)
    // console.log("Time in Minutes", timeinMinutes)
    // console.log("Time in Hours", timeinHours.rhours, timeinHours.rminutes)
    // console.log("User Country Offset", usersCountryOffset)
    // console.log("Converted Time in Minutes", convertedTimeinMinutes)
    // console.log("After Making Time Difference Zero", makingTimeDifferenceZero);
    // console.log("timein Hours", timeinHours.rhours, timeinHours.rminutes);
    return ActualConvertedTime;
  };
  //TimeZone Functions End

  const returnListTimings = () => {
    let daysArary = [];

    var timings = courseTimings;
    timings = JSON.parse(timings);
    console.log("TIMINGS", timings);
    Object.keys(timings).map((id, key) => {
      var day = timings[id];
      if (day.start != "" && day.end != "") {
        var start_time = day.start;
        var firstCharStart = start_time.split("");
        if (firstCharStart[0] == "0") {
          start_time = start_time.substring(1);
        }

        var end_time = day.end;
        var firstCharEnd = end_time.split("");
        if (firstCharEnd[0] == "0") {
          end_time = end_time.substring(1);
        }

        var dayName = day.day.split("");

        let dayData = (
          <li key={key}>
            <span className="play-icon">{day.day}</span>
            <span className="lecture-title"></span>
            <span className="lecture-duration">
              {changeTimeZoneLocation(start_time)} - {changeTimeZoneLocation(end_time)}
            </span>
          </li>
        );
        daysArary.push(dayData);
        console.log(start_time, end_time);
      }
    });
    return daysArary;
  };

  const openLoginModal = () => {
    // this.setState({
    //     showModalCoupon : false
    // })
    if (enableEnrollButton) {
      localStorage.setItem("loginType", "course"); // set loginType course to load login popup with individual course forms
      var loginStatus = localStorage.getItem("loginData");
      if (loginStatus != null && loginStatus != "" && typeof JSON.parse(loginStatus) === "object") {
        props.getPreviousCards(JSON.parse(loginStatus).user.id);
        props.triggerCheckoutPopup(); // triggers the checkout modal
        localStorage.setItem("tutor-data", courseTeachers);
      } else {
        if (selectedCourse.is_school == 1) {
          localStorage.setItem("tutor-data", courseTeachers);
          props.triggerEnrollPopup();
        } else {
          props.triggerLoginPopup(); // triggers the login modal
        }
      }
    } else {
      this.setState({
        daysErrorTrigger: true,
      });
    }
  };

  const demoPopupLaunch = (e, program_id, assessment_id, testid, question_id) => {
    // console.log(program_id, assessment_id, testid, question_id);
    var data = {
      token: csrfToken,
      questionid: question_id,
      assessment_id: assessment_id,
      testid: testid,
      program_id: program_id,
    };
    props.getTestPreview(data);
    sethtmlModalLoader(true);
  };

  const toggleModal = () => {
    sethtmlModal(!htmlModal);
    sethtmlmodalerror(false);
  };

  console.log("Tutor Details", teacher_details);
  console.log("Tutor name", courseTeachers);
  console.log("Course Feature", courseDetailsTable);
  console.log("No Find", courseDetailsTable.GroupDiscussion);
  return (
    <div className="main-wrapper course-details-page">
      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      {/* < BreadcrumbBox title="Course Details" /> */}

      <Styles>
        {/* Course Details */}
        <section className="course-details-area">
          <Modal size="sm" className="" show={showModalCoupon} centered>
            <Modal.Body className="text-center">
              <p>
                <label dangerouslySetInnerHTML={{ __html: modalCouponText }}></label>
                <br />
                <br />
                <strong>{modalCouponCode != "" ? "Promo code: " + modalCouponCode : ""}</strong>
              </p>
              <Button
                className="continue"
                size="lg"
                style={{ marginTop: "15px", background: "#11b67a", border: "none", width: "100%" }}
                onClick={() => setshowModalCoupon(false)}
              >
                CONTINUE
              </Button>
            </Modal.Body>
          </Modal>
          {htmlModal ? (
            <Modal
              size="lg"
              className="loginPopupMain"
              toggle={toggleModal}
              show={htmlModal}
              centered
            >
              <Modal.Body style={{ padding: "5px" }}>
                <p className="closeModalLogin" onClick={toggleModal}>
                  X
                </p>
                <div dangerouslySetInnerHTML={{ __html: questionHtml }}></div>
                {htmlmodalerror ? (
                  <p className="htmlmodalerror">
                    You need to login to view this content. <br />
                    {enableEnrollButton ? (
                      <button
                        onClick={openLoginModal}
                        style={{
                          background: "#11B67A",
                          border: "none",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        {selectedCourse.is_online == 2 ? "Buy now" : "Join now"}
                      </button>
                    ) : null}
                  </p>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <ButtonGroup style={{ width: "100%" }}>
                  <Button onClick={toggleModal}>Go Back</Button>
                  <Button onClick={() => sethtmlmodalerror(true)}>Try Again</Button>
                  <Button onClick={() => sethtmlmodalerror(true)}>Show Answer</Button>
                  <Button onClick={() => sethtmlmodalerror(true)}>Learn</Button>
                  <Button onClick={() => sethtmlmodalerror(true)}>Move On</Button>
                </ButtonGroup>
              </Modal.Footer>
            </Modal>
          ) : htmlModalLoader ? (
            <Row>
              {" "}
              <Col lg="12" className="mt-5 text-center">
                {" "}
                <Spinner
                  style={{
                    width: "3rem",
                    height: "3rem",
                    margin: "30vh -1.5rem",
                    position: "fixed",
                    zIndex: "999",
                  }}
                  className="centered-spinner"
                  animation="border"
                  variant="dark"
                />
              </Col>
            </Row>
          ) : null}
          {!loading ? (
            <Container>
              <Row>
                {/* <Col lg="3" md="4" sm="12">
                                <div className="single-details-sidbar">
                                    <Row>
                                        <Col md="12">
                                            <div className="course-details-feature">
                                                <h5 className="title">Course Details</h5>
                                                <ul className="list-unstyled feature-list">
                                                    <li><i className="las la-calendar"></i> Start Date: <span>Aug 21, 2020</span></li>
                                                    <li><i className="las la-clock"></i> Duration: <span>Semester / Term</span></li>
                                                    <li><i className="las la-globe"></i> Language: <span>English</span></li>
                                                    <li><i className="las la-sort-amount-up"></i> Skill Level: <span>Intermediate</span></li>
                                                    <li><i className="las la-graduation-cap"></i> Chapters: <span>22</span></li>
                                                    <li><i className="las la-book"></i> Topics: <span>252</span></li>
                                                    <li><i className="las la-bookmark"></i> Videos Tutorials: <span>361</span></li>
                                                    <li><i className="las la-certificate"></i> Certification: <span>Yes</span></li>
                                                    <li><i className="las la-certificate"></i> Supporting Worksheet: <span>352</span></li>
                                                    <li><i className="las la-certificate"></i> Self Learning Dashboard: <span>Yes</span></li>
                                                    <li><i className="las la-certificate"></i> Class Performance Report: <span>Daily</span></li>
                                                    <li><i className="las la-certificate"></i> Teacher's Report: <span>Monthly</span></li>
                                                    <li><i className="las la-certificate"></i> Progress Report: <span>Monthly</span></li>
                                                    <li><i className="las la-certificate"></i> Group Discussion: <span>Yes</span></li>
                                                    <li><i className="las la-certificate"></i> Home work: <span>Weekly</span></li>
                                                    <li><i className="las la-certificate"></i> Before & After Report: <span>Yes</span></li>
                                                </ul>
                                                <button type="button" className="enroll-btn">Enroll Course</button>
                                            </div>
                                        </Col>
                                        
                                    </Row>
                                </div>

                            </Col> */}
                <Col lg="9" md="8" sm="12">
                  <div className="course-details-top">
                    {courseTeachers &&
                    courseTeachers != undefined &&
                    courseTeachers != "" &&
                    selectedCourse.is_online !== 2 ? (
                      <div className="instructor-tab" id="test">
                        <h5>Our Tutor for {selectedCourse.course_name} </h5>
                        {/* <label className="mb-5" >Hello! <br />

                                                    My name is Shoaib.
                                                    I am so excited to have you join Coding Basics. <br />
                                                    Let us learn together!</label> */}
                        <div className="instructor-item">{showTeacherDetails(1)}</div>
                      </div>
                    ) : null}

                    <div className="timeTable-element">
                      <h5>Online Class Timings (Using Zoom)</h5>
                      {courseTimings && courseTimings != undefined && courseTimings != "" ? (
                        <div className="course-item shadow-lg">
                          {showTeacherDetails(2)}
                          {/* <div className="">
                            
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                                                                <img style={{ borderRadius: "50%" , height: 50 , width: 50}} src={process.env.PUBLIC_URL + `/assets/images/LM/Teachers/Ayesha Khalid.png`} alt="" className="img-fluid" />
                                                                                <label style={{ color: "#fff" , marginLeft: 5}}>Class Taught by: <Link style={{ color: "yellow"}} to="/instructor-details"><span>Ayesha Khalid</span></Link></label>
                                                                            </li>
                                                                        </ul>
                                                                  

                                                            </div> */}
                          <div className="course-content show">
                            <ul className="list-unstyled">
                              {returnListTimings()}
                              {/* <li>
                                                                        <span className="play-icon"> Monday</span>
                                                                        <span className="lecture-title"></span>
                                                                        <span className="lecture-duration">4:50 PM - 5:30 PM</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="play-icon"> Tuesday</span>
                                                                        <span className="lecture-title"></span>
                                                                        <span className="lecture-duration">4:50 PM - 5:30 PM</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="play-icon"> Wednesday</span>
                                                                        <span className="lecture-title"></span>
                                                                        <span className="lecture-duration">4:50 PM - 5:30 PM</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="play-icon"> Thursday</span>
                                                                        <span className="lecture-title"></span>
                                                                        <span className="lecture-duration">4:50 PM - 5:30 PM</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="play-icon"> Friday</span>
                                                                        <span className="lecture-title"></span>
                                                                        <span className="lecture-duration">4:50 PM - 5:30 PM</span>
                                                                    </li> */}
                            </ul>

                            <div className="teacher-info-timetable">
                              <Row className="">
                                {/* <Col lg="4" className="img-col">
                                                                            <img src={process.env.PUBLIC_URL + `/assets/images/LM/Teachers/Ayesha Khalid.png`} alt="" className="img-fluid" />
                                                                            <label>Taught by: <br /> <Link to="/instructor-details"><span>Ayesha Khalid</span></Link></label>

                                                                        </Col> */}
                                {/* <Col lg="4" className="text-center">
                                                                            <div className="apply-btn">
                                                                                <Link to={process.env.PUBLIC_URL + "/registration"}><i className="animate__animated animate__flash animate__repeat-3 animate__slower las la-arrow-right"></i>Enroll for these timeslots</Link>
                                                                            </div>
                                                                            <div className="belowApply">
                                                                                <label>Your first class is FREE</label>
                                                                            </div>
                                                                        </Col> */}

                                <Col lg="7"></Col>

                                <Col sm="5">
                                  <div className="cardPrice">
                                    {showPrice && (
                                      <div className="priceCourse">
                                        {/* <h6>Price</h6> */}
                                        {course_discount_price &&
                                        (course_discount_price == 0 ||
                                          course_discount_price > 0) ? (
                                          <div className="">
                                            {/* <p
                                            style={{
                                              textDecoration: "line-through",
                                              marginRight: "10px",
                                            }}
                                          >
                                            {selectedCourse.symbol + formatPrice(old_price)}
                                          </p> */}
                                            <p>
                                              {selectedCourse.symbol +
                                                formatPrice(course_discount_price)}
                                            </p>
                                            <span>or use Promo code</span>
                                            <br />
                                            {/* <span>20 classes per month (5 days a week)</span> */}
                                          </div>
                                        ) : (
                                          <React.Fragment>
                                            <p className="mb-0">
                                              {selectedCourse.symbol + formatPrice(course_price)}
                                            </p>
                                            <span>or use Promo code</span>
                                            <br />
                                            {/* <span>20 classes per month (5 days a week)</span> */}
                                          </React.Fragment>
                                        )}

                                        {/* <p>$29 / month</p> */}
                                      </div>
                                    )}
                                    {/* <div className="belowApply">
                                                                                    <label>Get a FREE class</label>
                                                                                </div> */}
                                    <div
                                      className="apply-btn enroll-smartlook"
                                      onClick={openLoginModal}
                                    >
                                      <a>Let's Start</a>
                                    </div>
                                    {/* <div
                                      className="apply-btn enroll-smartlook"
                                      onClick={openLoginModal}
                                    >
                                      <a>
                                        {" "}
                                        Join 1{" "}
                                        <strong style={{ textDecoration: "underline" }}>
                                          Free
                                        </strong>{" "}
                                        Class{" "}
                                      </a>
                                    </div> */}
                                  </div>

                                  {/* <button className="button1">
                                                                                <span className="text-light">
                                                                                    <span className="outer" style={{ color: "red", textDecoration: 'line-through' }}>
                                                                                        <span style={{ color: "#fff" }}>
                                                                                            PKR 7,500
                                                </span>
                                                                                    </span>
                                                                                    <span style={{ fontSize: 16, margin: 5 }}>
                                                                                        PKR 3,700
                                                </span>
                                                                                </span>
                                                                            </button>
                                                                            <button className="button2">Enroll</button> */}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="heading">
                      {/* <h2>Grade 2</h2> */}
                      {/* <h4>{selectedCourse.course_name}</h4> */}
                    </div>
                    {selectedCourse.chapters > 0 ? (
                      <div className="course-element">
                        {/* <h5>Course covered these 22 chapters & 252 topics</h5> */}
                        <h5>
                          Tutor covers {selectedCourse.chapters} chapters{" "}
                          <span style={{ color: "#999", fontSize: "12px" }}>Click any to see</span>{" "}
                        </h5>

                        {selectedCourse.assessments &&
                          selectedCourse.assessments.map((assessment, key) => (
                            <div className="course-item" key={key}>
                              <button
                                className="course-button"
                                data-toggle="collapse"
                                data-target={`#one${key}`}
                                aria-expanded="false"
                                aria-controls={`#collapseExample${assessment.name}`}
                              >
                                <i className="fas fa-chevron-down mr-3"></i> {assessment.name}{" "}
                              </button>
                              <div className="course-content" id={`one${key}`}>
                                <ul className="list-unstyled">
                                  {assessment.tests.length &&
                                    assessment.tests.map((test, key) => (
                                      <li key={key}>
                                        {/* <span className="play-icon"><i className="fas fa-check"></i></span> */}
                                        <span className="lecture-title">{test.testname}</span>
                                        {test.question_id != 0 ? (
                                          <button
                                            className="lecture-duration font-italic"
                                            onClick={(e) =>
                                              demoPopupLaunch(
                                                e,
                                                selectedCourse.program_id,
                                                assessment.assessment_id,
                                                test.testid,
                                                test.question_id
                                              )
                                            }
                                          >
                                            preview
                                          </button>
                                        ) : null}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : null}
                    {/* <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="author">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/author.jpg`} alt="" />
                                                <div className="author-name">
                                                    <h6>Author</h6>
                                                    <p>Andy Robert</p>
                                                </div>
                                            </div>
                                            <div className="category">
                                                <h6>Category</h6>
                                                <p>Social Science</p>
                                            </div>
                                            <div className="rating">
                                                <h6>Rating</h6>
                                                <ul className="list-unstyled list-inline">
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                    </li>
                                                    <li className="list-inline-item">(4.5)</li>
                                                </ul>
                                            </div>
                                            <div className="price">
                                                <h6>Price</h6>
                                                <p>$29.00</p>
                                            </div>
                                        </div>
                                    </div> */}
                    {/* <div className="course-details-banner">
                                            {
                                                selectedCourse.course_img_cover && selectedCourse.course_img_cover != null && selectedCourse.course_img_cover != '' ?
                                                    <img src={'https://dashboard.lincolnmethod.com/uploads/' + selectedCourse.course_img_cover} alt={selectedCourse.course_name} className="img-fluid" />
                                                    :
                                                    selectedCourse.course_img && selectedCourse.course_img != null && selectedCourse.course_img != '' ?
                                                        <img src={'https://dashboard.lincolnmethod.com/uploads/' + selectedCourse.course_img} alt={selectedCourse.course_name} className="img-fluid" /> : null
                                            }
                                        </div> */}
                    {/* <div className="course-tab-list">
                                        <Tab.Container defaultActiveKey="overview">
                                            <Nav className="flex-column makeStickyonPhone">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="overview">Overview</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="curriculum">Curriculum</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="timeSlots">Class Timings</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="instructor">Faculty</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="review">Success Stories</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        
                                        
                                        </Tab.Container>
                                    </div> */}
                  </div>
                  {/* {selectedCourse.is_online !== 2 ?
                                        <div className="buttonForScrollSticky">
                                            <Nav className="flex-column" id="stickyButtons">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="overview" id="overview" className="overViewBtn">Overview</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="curriculum" id="curriculum" className="curriculumBtn">Curriculum</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="timeSlots" id="timeSlots" className="classTimingsBtn">Class Timings</Nav.Link>
                                                </Nav.Item>
                                                <span id="demo"></span>
                                            </Nav>
                                        </div>
                                        :
                                        null} */}

                  {/* Making Tabs Outside */}
                  {selectedCourse.is_online !== 2 ? (
                    <Tab.Pane eventKey="overview" className="overview-tab first">
                      {/* {
                                            selectedCourse.course_details && selectedCourse.course_details != '' ?
                                                <div className="course-desc">
                                                    <h5>About this course</h5>
                                                    <p dangerouslySetInnerHTML={{ __html: selectedCourse.course_details.replace(/\/userfiles/g, 'https://dashboard.lincolnmethod.com/userfiles') }}></p>
                                                </div>
                                                : null
                                        } */}
                      <div className="single-details-sidbar">
                        <Row>
                          <Col md="10">
                            <div className="course-details-feature">
                              <h5 className="title">More about this course</h5>
                              <ul className="list-unstyled feature-list">
                                {/* {courseDetailsTable.length > 0 ?  courseDetailsTable.map((item, index) =>
                                                                <li key={index}><i className={item.icons}></i> {item.feature}: <span>{item.value}</span></li>
                                                            )
                                                        :
                                                        <p>Course Details Not Found</p>
                                                        } */}
                                {typeof courseDetailsTable.StartDate !== "undefined" &&
                                courseDetailsTable.StartDate !== "" ? (
                                  <li>
                                    <i className="las la-calendar"></i> Start Date:{" "}
                                    <span>{courseDetailsTable.StartDate}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable.ClassDuration !== "undefined" ? (
                                  <li>
                                    <i className="las la-clock"></i>Class Duration:{" "}
                                    <span>{courseDetailsTable.ClassDuration}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable.Frequency !== "undefined" &&
                                courseDetailsTable.Frequency !== "" ? (
                                  <li>
                                    <i className="las la-calendar-week"></i>Frequency:{" "}
                                    <span>{courseDetailsTable.Frequency}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable.Language !== "undefined" &&
                                courseDetailsTable.Language !== "" ? (
                                  <li>
                                    <i className="las la-globe"></i> Language:{" "}
                                    <span>{courseDetailsTable.Language}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable.SkillLevel !== "undefined" &&
                                courseDetailsTable.SkillLevel !== "" ? (
                                  <li>
                                    <i className="las la-sort-amount-up"></i> Skill Level:{" "}
                                    <span>{courseDetailsTable.SkillLevel}</span>
                                  </li>
                                ) : null}

                                {courseDetails.course.chapters &&
                                courseDetails.course.chapters !== 0 ? (
                                  <li>
                                    <i className="las la-graduation-cap"></i> Chapters covered:{" "}
                                    <span>{courseDetails.course.chapters}</span>
                                  </li>
                                ) : null}

                                {courseDetails.course.topics &&
                                courseDetails.course.topics !== 0 ? (
                                  <li>
                                    <i className="las la-book"></i> Topics covered:{" "}
                                    <span>{courseDetails.course.topics}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetails.course.videos !== "undefined" &&
                                courseDetails.course.videos !== 0 ? (
                                  <li>
                                    <i className="las la-play"></i> Videos Tutorials:{" "}
                                    <span>{courseDetails.course.videos}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable.Certifiaction !== "undefined" &&
                                courseDetailsTable.Certifiaction !== "No" ? (
                                  <li>
                                    <i className="las la-certificate"></i> Certificate:{" "}
                                    <i className="fas fa-crown crown"></i>{" "}
                                    <span>{courseDetailsTable.Certifiaction}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetails.course.worksheets !== "undefined" &&
                                courseDetails.course.worksheets !== 0 ? (
                                  <li>
                                    <i className="las la-ethernet"></i> Supporting Worksheet:{" "}
                                    <i className="fas fa-crown crown"></i>{" "}
                                    <span>{courseDetails.course.worksheets}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable.SelfLearningDashboard !== "undefined" &&
                                courseDetailsTable.SelfLearningDashboard !== "No" ? (
                                  <li>
                                    <i className="las la-laptop"></i> Self Learning Dashboard:{" "}
                                    <span>{courseDetailsTable.SelfLearningDashboard}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable.Language !== "undefined" ? (
                                  <li>
                                    <i className="las la-file-excel"></i> Class Performance Report:{" "}
                                    <span>{courseDetailsTable.ClassPerformanceReport}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable["Teacher'sReport"] !== "undefined" ? (
                                  <li>
                                    <i className="las la-chalkboard-teacher"></i> Teacher's Report:{" "}
                                    <i className="fas fa-crown crown"></i>{" "}
                                    <span>{courseDetailsTable["Teacher'sReport"]}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable.ProgressReport !== "undefined" &&
                                courseDetailsTable.ProgressReport !== "0" ? (
                                  <li>
                                    <i className="las la-tasks"></i> Progress Report:{" "}
                                    <span>{courseDetailsTable.ProgressReport}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable.GroupDiscussion !== "undefined" &&
                                courseDetailsTable.GroupDiscussion !== "No" ? (
                                  <li>
                                    <i className="las la-users"></i> Group Discussion:{" "}
                                    <span>{courseDetailsTable.GroupDiscussion}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable.Homework !== "undefined" ? (
                                  <li>
                                    <i className="las la-book-open"></i> Homework:{" "}
                                    <span>{courseDetailsTable.Homework}</span>
                                  </li>
                                ) : null}

                                {typeof courseDetailsTable["Before&AfterReport"] !== "undefined" &&
                                courseDetailsTable["Before&AfterReport"] !== "No" ? (
                                  <li>
                                    <i className="las la-sort-numeric-down-alt"></i> Before & After
                                    Report: <span>{courseDetailsTable["Before&AfterReport"]}</span>
                                  </li>
                                ) : null}
                              </ul>
                              <button
                                type="button"
                                className="enroll-btn enroll-smartlook"
                                onClick={openLoginModal}
                              >
                                Let's Start
                              </button>
                              {/* <button
                                type="button"
                                className="enroll-btn enroll-smartlook"
                                onClick={openLoginModal}
                              >
                                {" "}
                                Join 1 <strong style={{ textDecoration: "underline" }}>
                                  Free
                                </strong>{" "}
                                Class{" "}
                              </button> */}
                            </div>
                          </Col>
                          {/* <Col md="12">
                                            <PopularCourse />
                                        </Col> */}
                          {/* <Col md="12">
                                            <CourseTag />
                                        </Col> */}
                        </Row>
                      </div>
                      {/* <div className="course-feature">
                                                        <h5>Course Feature</h5>
                                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi, quidem facere quisquam aperiam neque dolorem saepe. Laboriosam, quam aliquam. Optio earum accusantium quam eius dignissimos quaerat voluptatem excepturi aliquid dolor ducimus. Illo porro maiores fuga dignissimos temporibus odio nulla nobis nemo.</p>
                                                        <ul className="list-unstyled">
                                                            <li><i className="las la-arrow-right"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere excepturi aliquid dolor ducimus.</li>
                                                            <li><i className="las la-arrow-right"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere excepturi aliquid .</li>
                                                            <li><i className="las la-arrow-right"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere excepturi.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="course-learn">
                                                        <h5>Learning Outcome</h5>
                                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi, quidem facere quisquam aperiam neque dolorem saepe. Laboriosam, quam aliquam odit modi harum libero culpa distinctio.</p>
                                                        <ul className="list-unstyled">
                                                            <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                            <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                            <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                            <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="course-share">
                                                        <h5>Share This Course</h5>
                                                        <ul className="social list-unstyled list-inline">
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                                        </ul>
                                                    </div> */}
                    </Tab.Pane>
                  ) : null}
                  {selectedCourse.is_online !== 2 ? (
                    <Tab.Pane className="curriculum-tab second">
                      {/* <div className="course-curriculum">
                                                        <h5>Course Curriculum</h5>
                                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi, quidem facere quisquam aperiam neque dolorem saepe. Laboriosam, quam aliquam. Optio earum accusantium quam eius dignissimos quaerat voluptatem excepturi aliquid dolor ducimus. Illo porro maiores fuga dignissimos temporibus odio nulla nobis nemo.</p>
                                                    </div> */}
                    </Tab.Pane>
                  ) : null}

                  {selectedCourse.is_online !== 2 ? (
                    <Tab.Pane eventKey="timeSlots" className="curriculum-tab third">
                      {/* <div className="course-curriculum">
                                                        <h5>Course Curriculum</h5>
                                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi, quidem facere quisquam aperiam neque dolorem saepe. Laboriosam, quam aliquam. Optio earum accusantium quam eius dignissimos quaerat voluptatem excepturi aliquid dolor ducimus. Illo porro maiores fuga dignissimos temporibus odio nulla nobis nemo.</p>
                                                    </div> */}
                    </Tab.Pane>
                  ) : null}
                  {/* <div class="second">Hi</div> */}

                  <div className="course-details-images">
                    <div>
                      <h5>Minimum Technology Requirements:</h5>
                      <label>
                        This course works best using a tablet or laptop or PC (you can use a
                        smartphone but a larger screen is preferred)
                      </label>{" "}
                    </div>
                    <div>
                      <label>You also require a stable internet connection</label>
                    </div>
                    <div>
                      <label>
                        You also need Zoom Account and/or a Google Meets account to connect with a
                        teacher
                      </label>
                    </div>
                  </div>
                  {selectedCourse.is_online !== 2 ? (
                    <div className="mt-4">
                      <EnrollStepsText />
                    </div>
                  ) : null}
                  {beforeAfterImages.length !== 0 ? (
                    !isMobileBrowser() ? (
                      <div className="course-details-images">
                        <h5>Our Success Stories</h5>
                        <Row>
                          {beforeAfterImages.map((images, index) => (
                            <Col xs={4} key={index}>
                              <div className="gallery-box">
                                <ModalImage
                                  small={images.imageurl}
                                  large={images.imageurl}
                                  alt=""
                                />
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ) : (
                      <ImagesSlider imagesData={beforeAfterImages} title={"Our Success Stories"} />
                    )
                  ) : null}
                  {selectedCourse.course_details && selectedCourse.course_details != "" ? (
                    <div className="course-desc">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: selectedCourse.course_details.replace(
                            /\/userfiles/g,
                            "https://dashboard.lincolnmethod.com/userfiles"
                          ),
                        }}
                      ></p>
                    </div>
                  ) : null}
                </Col>

                {/* Button For Mobile Only */}
                {/* <Col lg="12">
                                
                            </Col> */}
              </Row>
              {selectedCourse.is_online !== 2 ? (
                <p
                  onClick={() => openLoginModal()}
                  className="demo-box enroll-smartlook animate__animated animate__pulse animate__infinite"
                >
                  Let's Start
                </p>
              ) : (
                // <p
                //   onClick={() => openLoginModal()}
                //   className="demo-box enroll-smartlook animate__animated animate__pulse animate__infinite"
                // >
                //   {" "}
                //   Enroll for <br /> a FREE <br /> class{" "}
                // </p>
                <p
                  onClick={() => openLoginModal()}
                  className="demo-box enroll-smartlook animate__animated animate__pulse animate__infinite"
                >
                  {" "}
                  Click here <br /> to BUY <br />{" "}
                  {selectedCourse.symbol + formatPrice(course_price)}{" "}
                </p>
              )}
              {showPrice && (
                <ButtonGroup className="EnrollButton enroll-smartlook" onClick={openLoginModal}>
                  <button className="btn" style={{ width: "100%", fontSize: 18, color: "#fff" }}>
                    {course_discount_price &&
                    (course_discount_price == 0 || course_discount_price > 0) ? (
                      <React.Fragment>
                        {selectedCourse.is_online !== 2 ? (
                          <div className="d-flex justify-content-around">
                            <label style={{ position: "relative", top: "10px" }}>Enroll</label>
                            <div>
                              <div className="d-flex justify-content-center">
                                {/* <label
                                style={{ textDecoration: "line-through", marginRight: "10px" }}
                              >
                                {selectedCourse.symbol + formatPrice(old_price)}
                              </label> */}
                                <label>
                                  {selectedCourse.symbol + formatPrice(course_discount_price)}
                                </label>
                              </div>
                              {/* <p style={{ fontSize: 11 }}>per month 20 classes (5 days a week)</p> */}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-around">
                            <label>Buy</label>
                            <div>
                              <div className="d-flex justify-content-center">
                                <label
                                  style={{ textDecoration: "line-through", marginRight: "10px" }}
                                >
                                  {selectedCourse.symbol + formatPrice(old_price)}
                                </label>
                                <label>
                                  {selectedCourse.symbol + formatPrice(course_discount_price)}
                                </label>
                              </div>
                              {/* <p style={{ fontSize: 11 }}>per month 20 classes (5 days a week)</p> */}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {selectedCourse.is_online !== 2 ? (
                          <div className="text-center">
                            <span className="movingArrow">
                              <i class="fas fa-chevron-down"></i>
                            </span>
                            <div>
                              {/* <label style={{ textTransform: "uppercase" }}>
                              Join 1 <strong style={{ textDecoration: "underline" }}>Free</strong>{" "}
                              class
                            </label> */}
                              <label style={{ textTransform: "uppercase" }}>Let's Start</label>
                            </div>
                            <div className="text-center">
                              {/* <p style={{ fontSize: 11 }}>
                                {selectedCourse.symbol + formatPrice(course_price)} for 20 classes
                                per month (5 days a week)
                              </p> */}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-around">
                            <div>
                              <label style={{ textTransform: "uppercase" }}>
                                <strong style={{ textDecoration: "underline" }}>BUY</strong>
                              </label>
                            </div>
                            <div className="text-center">
                              <label>{selectedCourse.symbol + formatPrice(course_price)}</label>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </button>
                </ButtonGroup>
              )}
              {/* <ButtonGroup className="EnrollButton" onClick={openLoginModal}>
                                <button className="btn" style={{ background: "#4bc959", padding: 3, width: "60%", fontSize: 18, color: "#fff" }}>
                                    {
                                        course_discount_price && (course_discount_price == 0 || course_discount_price > 0) ?
                                            <React.Fragment>
                                                <div className="d-flex justify-content-center">
                                                    <label style={{ textDecoration: 'line-through', marginRight: '10px' }}>{selectedCourse.symbol + formatPrice(old_price)}</label>
                                                    <label>{selectedCourse.symbol + formatPrice(course_discount_price)}</label>
                                                </div>
                                                <p style={{ fontSize: 11 }}>(per month)</p>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <label>{selectedCourse.symbol + formatPrice(course_price)}</label>
                                                <p style={{ fontSize: 11 }}>(per month)</p>
                                            </React.Fragment>
                                    }
                      

                                </button>
                                <button className="btn btn-warning" style={{ padding: 3, width: "40%", fontSize: 18 }}>

                                    <label className="font-weight-bold"> Enroll </label>

                                    <p style={{ fontSize: 11 }}>(1 free class)</p>
                                </button>
                            </ButtonGroup> */}
            </Container>
          ) : (
            <Row>
              {" "}
              <Col lg="12" className="mt-5 text-center">
                {" "}
                <Spinner className="centered-spinner" animation="border" variant="dark" />{" "}
                <label className="ml-3" style={{ position: "relative", bottom: 5 }}>
                  Just a moment while we find a tutor
                </label>
              </Col>
            </Row>
          )}
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
}

const mapStateToProps = (state) => ({
  courseDetail: state.webRed.courseDetail,
  questionHtml: state.webRed.questionHtml,
  loginSuccessWithCourse: state.webRed.loginSuccessWithCourse,
});

export default connect(mapStateToProps, {
  getCourseDetails,
  getTestPreview,
  triggerLoginPopup,
  triggerEnrollPopup,
  triggerCheckoutPopup,
  getSignUpFormType,
  getPreviousCards,
})(CourseDetailsV2);
