import React, { Component } from "react";
import HeaderTwo from "../../components/HeaderTwo";
import FooterTwo from "../../components/FooterTwo";
import CareerPageSnippetComponent from "./CareerPageSnippetComponent";


class CareersOnlineTeacherComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            step1 : {
                heading: "Do you want to teach online",
                para: "",
                btn_text: "Apply now",
                btn_text_url: "http://form.assessmentfund.com/ODcxMQ==",
                image_url: "/assets/images/LM/Careers/onlineteacher1s.png",
                background : '#ffc000',
                themeColor: "yellow",
                position: "full"
            },
            step2 : {
                heading: "Can you teach English or Math to kids from Grade 1 to 11",
                para: "",
                btn_text: "Apply now",
                btn_text_url: "http://form.assessmentfund.com/ODcxMQ==",
                image_url: "",
                background : '#182B49',
                themeColor: "blue",
                position: "full"
            },
            step3 : {
                heading: "Teach more classes Earn more money",
                para: "",
                btn_text: "Apply now",
                btn_text_url: "http://form.assessmentfund.com/ODcxMQ==",
                image_url: "/assets/images/LM/Careers/onlineteacher2.png",
                background : '#ffc000',
                themeColor: "yellow",
                position: "full"
            },
            step4 : {
                heading: "You will go through training & certification",
                para: "",
                btn_text: "Apply now",
                btn_text_url: "http://form.assessmentfund.com/ODcxMQ==",
                image_url: "/assets/images/LM/Careers/onlineteacher3.png",
                background : '#11B67A',
                themeColor: "green",
                position: "ltr"
            },
            step5 : {
                heading: "Here is what we look for when hiring people to teach online",
                para: "<ul class='list-unstyled'><li><i class='fa fa-check'></i>You need to know your subject matter really well</li><li><i class='fa fa-check'></i>You need to be comfortable with using technology</li><li><i class='fa fa-check'></i>You need to like kids, and know how to be patient with kids </li><li><i class='fa fa-check'></i> You need to be extremely organized and punctual</li></ul>",
                btn_text: "",
                btn_text_url: "",
                image_url: "/assets/images/LM/Careers/onlineteacher4.PNG",
                background : '#fafafa',
                themeColor: "white",
                position: "ltr"
            }
        }
    }

    openLighBox(index) {
        this.setState({
            photoIndex: index,
            isOpen: true
        })
    }

    componentDidMount() {
        document.title = "Careers - Online school teacher - Lincoln Method";
    }
    
    render() {
        
        return (
        // <DocumentMeta {...meta}>
            <div className="CareersOnlineTeacherComponent">

                <HeaderTwo/>

                    <CareerPageSnippetComponent propData={this.state.step5} />
                    <CareerPageSnippetComponent propData={this.state.step1} />
                    <CareerPageSnippetComponent propData={this.state.step2} />
                    <CareerPageSnippetComponent propData={this.state.step3} />
                    <CareerPageSnippetComponent propData={this.state.step4} />
                
                <FooterTwo />
            </div>
        // </DocumentMeta>
        );
    }
}

export default CareersOnlineTeacherComponent;