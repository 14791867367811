import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { triggerEnrollPopup, triggerCheckoutPopup } from "./../actions/webRedActions";
import { connect } from "react-redux";
import { Styles } from "./styles/footerTwo.js";
import SamplePage from "./SamplePage";
import { isMobileBrowser } from "../actions/utility";
import FixedDemoComponent from "./FixedDemoComponent";
import TranslatePhone from "./TranslatePhone.js";

function FooterTwo(props) {
  // useEffect(() => {
  //     const form = document.getElementById("form4");
  //     const email = document.getElementById("email4");

  //     form.addEventListener("submit", formSubmit);

  //     function formSubmit(e) {
  //         e.preventDefault();

  //         const emailValue = email.value.trim();

  //         if (emailValue === "") {
  //             setError(email, "Email can't be blank");
  //         } else if (!isEmail(emailValue)) {
  //             setError(email, "Not a valid email");
  //         } else {
  //             setSuccess(email);
  //         }
  //     }

  //     function setError(input, message) {
  //         const formControl = input.parentElement;
  //         const errorMsg = formControl.querySelector(".input-msg4");
  //         formControl.className = "form-control error";
  //         errorMsg.innerText = message;
  //     }

  //     function setSuccess(input) {
  //         const formControl = input.parentElement;
  //         formControl.className = "form-control success";
  //     }

  //     function isEmail(email) {
  //         return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  //     }
  // });
  const location = useLocation();
  console.log("Current Route", location.pathname);
  return (
    <Styles>
      {/* Footer Two */}
      <footer
        className="footer2"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/homePage/6.png)`,
        }}
      >
        <Container>
          <Row>
            <Col md="3">
              <div className="footer-logo-info">
                <div className="">
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/LM/logo.png"}
                      alt=""
                      className="img-fluid mt-4"
                    />
                  </div>
                </div>
                <p>© 2023 lincolnmethod.com All rights reserved.</p>
                <p>A product of Lincoln School Oy</p>
                <ul className="list-unstyled">
                  <li>
                    <a style={{ color: "#969696" }} href="tel:+358415751803">
                      <i className="las la-phone"></i>+358 41 575 1803
                    </a>
                  </li>
                  <li>
                    <a style={{ color: "#969696" }} href="mailto:hello@lincolnmethod.com">
                      <i className="las la-envelope"></i>hello@lincolnmethod.com
                    </a>
                  </li>
                  <li>
                    <i className="las la-map-marker"></i>Aurorankatu 19 A 6, 00100 Helsinki
                  </li>
                </ul>
                {/* <p className="mt-5" style={{ fontSize: 16 }}>
                  GDPR compliance
                </p> */}
              </div>
            </Col>
            <Col md="4">
              <div className="f-links">
                <h5>Quick Links</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to={"/faq"}>
                      <i className="las la-angle-right"></i>FAQs
                    </Link>
                  </li>
                  <li>
                    <Link to={"/refund-policy"}>
                      <i className="las la-angle-right"></i>Refund Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={"/license-agreement"}>
                      <i className="las la-angle-right"></i>License Agreement
                    </Link>
                  </li>
                  <li>
                    <Link to={"/privacy-policy"}>
                      <i className="las la-angle-right"></i>GDPR & Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={"/reports"}>
                      <i className="las la-angle-right"></i>Reports
                    </Link>
                  </li>
                  <li>
                    <Link to={"/course-grid"}>
                      <i className="las la-angle-right"></i>Self-learning Courses
                    </Link>
                  </li>
                  <li>
                    <Link to={"/online-class-rules"}>
                      <i className="las la-angle-right"></i>Online class rules
                    </Link>
                  </li>
                  <li>
                    <Link to={"/how-to-invest-with-us"}>
                      <i className="las la-angle-right"></i>How to invest with us?
                    </Link>
                  </li>
                  <li>
                    <Link to={"/careers/finland-teacher-certification-program"}>
                      <i className="las la-angle-right"></i>Finland Teacher Certification Program
                    </Link>
                  </li>
                  <li >
                          <a
                            href="https://www.mparsec.com/"
                            target="_blank"
                          >
                             <i className="las la-angle-right"></i>mPARSEC Technology Partner
                          </a>
                        </li>
                </ul>
              </div>
              {/* <SamplePage /> */}
            </Col>
            <Col md="3">
              <div className="f-links">
                <h5>Terms & Conditions</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to={"/terms-and-conditions"}>
                      <i className="las la-angle-right"></i>For Students & Parents
                    </Link>
                  </li>
                  <li>
                    <Link to={"/refund-policy"}>
                      <i className="las la-angle-right"></i>For Teachers on our payroll
                    </Link>
                  </li>
                  <li>
                    <Link to={"/license-agreement"}>
                      <i className="las la-angle-right"></i>For Teachers not on our payroll
                    </Link>
                  </li>
                  <li>
                    <Link to={"/license-agreement-for-schools"}>
                      <i className="las la-angle-right"></i>For Schools
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <SamplePage /> */}
            </Col>
            <Col md="2">
              <div className="f-links">
                <h5>Career</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to={"/education-mama"}>
                      <i className="las la-angle-right"></i>Education Mama
                    </Link>
                  </li>
                  <li>
                    <Link to={"/careers/online-school-teachers"}>
                      <i className="las la-angle-right"></i>Online school teachers
                    </Link>
                  </li>
                </ul>
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/LM/finland3.png"}
                  alt=""
                  width={120}
                  height={120}
                  className="mt-5 img-fluid"
                />
                <p className="mt-3">Certified from Education Alliance Finland.</p>
              </div>
              {/* {isMobileBrowser() ? <SamplePage /> : null} */}
              {isMobileBrowser() ? <TranslatePhone /> : null}
            </Col>
            {/* <Col md="3">
                            <div className="f-post">
                                <h5>Twitter Post</h5>
                                <div className="post-box d-flex">
                                    <div className="po-icon">
                                        <i className="fab fa-twitter"></i>
                                    </div>
                                    <div className="po-content">
                                        <Link to={process.env.PUBLIC_URL + "/blog-details"}>Lorem ipsum dolor sit ...</Link>
                                        <span>Mar 30, 2019</span>
                                    </div>
                                </div>
                                <div className="post-box d-flex">
                                    <div className="po-icon">
                                        <i className="fab fa-twitter"></i>
                                    </div>
                                    <div className="po-content">
                                        <Link to={process.env.PUBLIC_URL + "/blog-details"}>Lorem ipsum dolor sit ...</Link>
                                        <span>Mar 30, 2019</span>
                                    </div>
                                </div>
                                <div className="post-box d-flex">
                                    <div className="po-icon">
                                        <i className="fab fa-twitter"></i>
                                    </div>
                                    <div className="po-content">
                                        <Link to={process.env.PUBLIC_URL + "/blog-details"}>Lorem ipsum dolor sit ...</Link>
                                        <span>Mar 30, 2019</span>
                                    </div>
                                </div>
                            </div>
                        </Col> */}
            {/* <Col md="4">
                            <div className="f-newsletter">
                                <h5>Newsletter</h5>
                                <p>Lorem ipsum dolor sit amet, consectet adipisicing elit.</p>

                                <form id="form4" className="form">
                                    <p className="form-control">
                                        <input type="email" placeholder="Enter email here" id="email4" />
                                        <span className="input-msg4"></span>
                                    </p>
                                    <button>Submit</button>
                                </form>
                            </div>
                            <SamplePage />
                        </Col> */}
            <Col md="12">
              <div className="copytext-area text-center">
                {/* <p>Copyright &copy; 2017 | Designed With <i className="las la-heart"></i> by <a href={process.env.PUBLIC_URL + "/"} target="_blank" rel="noopener noreferrer">SnazzyTheme</a></p> */}
                <ul className="social list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/LincolnMethod"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://twitter.com/LincolnMethod"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.linkedin.com/company/lincoln-method/"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.youtube.com/channel/UCPFRa4VViOC_6TWMaIqD_Dg"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/lincolnmethod/"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
              {/* {location.pathname.includes("course-details") ? null : (
                <Link to="/demo-home">
                  <p className="demo-box animate__animated animate__pulse animate__infinite">
                    Take <br /> a DEMO <br /> class
                  </p>
                </Link>
              )}

              {location.pathname.includes("course-details") ||
              location.pathname === "/" ||
              location.pathname.includes("finland") ? null : (
                <FixedDemoComponent />
              )} */}
            </Col>
          </Row>
        </Container>

        {/* Back To Top  */}
        {/* <BackToTop /> */}
      </footer>
    </Styles>
  );
}

export default connect(null, { triggerEnrollPopup, triggerCheckoutPopup })(FooterTwo);
