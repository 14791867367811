import React, { Component } from 'react';
import Datas from '../../data/partnership/unitedNation.json';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/unitedNations.js';
import ModalVideo from 'react-modal-video';

class EducationComptonComponent extends Component {
    constructor() {
        super()
        this.state = {
            data: [{
                iconColor: '#fff',
                iconImage: "/assets/images/LM/partnerships/compton/Math.png",
                iconLabel: 'Rise in Math'
            }, {
                iconColor: '#fff',
                iconImage: "/assets/images/LM/partnerships/compton/Positivity.png",
                iconLabel: 'Rise in Positivity'
            }, {
                iconColor: '#fff',
                iconImage: "/assets/images/LM/partnerships/compton/Teamwork.png",
                iconLabel: 'Rise in Teamwork'
            }, {
                iconColor: '#fff',
                iconImage: "/assets/images/LM/partnerships/compton/Blockchain.png",
                iconLabel: 'Rise in Blockchain'
            }, {
                iconColor: '#fff',
                iconImage: "/assets/images/LM/partnerships/compton/Passion.png",
                iconLabel: 'Rise in Passion'
            }, {
                iconColor: '#fff',
                iconImage: "/assets/images/LM/partnerships/compton/Creativity.png",
                iconLabel: 'Rise in Creativity'
            }]
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }
    render() {

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper unitednations-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Education Compton" backgroundImage="/assets/images/LM/partnerships/UN_Pic_Banner.png" />

                    {/* Gallery Area */}
                    <section className="unitednations-page-area">
                        <Container>
                            <Row>
                                <Col lg="12">
                                    <div className="heading">
                                        <h4>The "Compton Rising" project</h4>
                                    </div>

                                    <br />

                                    <div className="heading-text">
                                        <p>The year 2019 marks 400 years of Black history, and we would like to draw the community's attention to the state of education in Compton (Los Angeles).</p>

                                        <p>This is why we are starting the Compton Rising project and we are inviting our close friends and their organizations to express their support as we grow this project.</p>

                                        <p>We want to reach out to you and invite you to show your support for the Compton Rising Project.</p>

                                        <p>By signing your support, your only obligation would be to speak positively of Compton, and endorse the education and blockchain for kids, within your social networks.</p>

                                        <p>If you are an organization, we would like to post your logo, as a supporter, on this page as well.</p>

                                        <p>We hope you will join us on this exciting journey.</p>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                        <div className="KidsRiseContent">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <h3>We will help kids RISE in 6 program</h3>
                                    </Col>
                                    {this.state.data.map((item, index) => (
                                        <Col lg="4" key={index}>
                                            <img src={item.iconImage} alt="image for program" />
                                            <h5>{item.iconLabel}</h5>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        </div>
                        <Container>
                            <div className="SomeOneRiseContent">
                                <Row>
                                    <Col lg="12">
                                        <h4>Help Someone RISE</h4>
                                    </Col>
                                </Row>
                            </div>
                        </Container>

                        <div style={{ padding: "10px 0"}}>
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <div className="heading">
                                            <h4>Value Proposition</h4>
                                        </div>

                                        <div className="heading-text">
                                            <ol>
                                                <li>Assess and then teach the kids on their weakness in:
                                    <ul>
                                                        <li>Math Concepts</li>
                                                        <li>Blockchain Basics</li>
                                                        <li>Positive Outlook</li>
                                                        <li>Teamwork</li>
                                                        <li>Commitment to a Cause</li>
                                                        <li>Creativity</li>
                                                    </ul>
                                                </li>
                                                <li>Kids will be able to increase their grades by at least 20 percentage points (2 letter grades)</li>
                                                <li>Each child will be assigned to a career coach for the duration of the program</li>
                                                <li>Promote program participants to business leaders within California</li>
                                            </ol>
                                        </div>

                                        <div className="heading">
                                            <h4>Incentives for learning & completing the program</h4>
                                        </div>

                                        <div className="heading-text">
                                            <ol>
                                                <li>Kids will be given the tablets from the start of the program</li>
                                                <li>Kids get to enjoy recognition and acknowledgement at sporting event or a music event</li>
                                                <li>Kids will learn lessons to instill the right skills to become future leaders</li>
                                                <li>Kids will learn to practice confident communication as a result of the activity based practical learning sessions</li>
                                                <li>Documentaries for the top few kids will be created</li>
                                            </ol>
                                        </div>

                                        <div className="heading">
                                            <h4>Key Activities</h4>
                                        </div>

                                        <div className="heading-text">
                                            <ol>
                                                <li>Kids can learn at home throughout the month using the tablets that have been given to them since the beginning of the program</li>
                                                <li>Over the weekends, the kids will be invited for group discussion and activity session to reinforce their concepts</li>
                                                <li>Short documentaries will be created on selected number of participating students</li>
                                            </ol>
                                        </div>

                                        <div className="heading">
                                            <h4>Intensity of learning</h4>
                                        </div>

                                        <div className="heading-text">
                                            <ol>
                                                <li>Kids will be learning through:
                                    <ul>
                                                        <li>16 learning sessions</li>
                                                        <li>8 activity &amp; discussion</li>
                                                    </ul>
                                                </li>
                                                <li>Learning sessions will be conducted individually at home</li>
                                                <li>Activity sessions &amp; discussion will take place in-class, and will be held on Friday afternoons, and Saturday mornings</li>
                                            </ol>
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="heading">
                                            <h4>Our Trustees</h4>
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <img src="/assets/images/LM/partnerships/compton/billBrown.jpg" alt="bill" width="100%" />
                                        <h4 className="mt-2">Bill Brown <i className="fab fa-linkedin text-primary"></i></h4>
                                    </Col>
                                    <Col lg="3">
                                        <img src="/assets/images/LM/partnerships/compton/saleemQureshi.jpg" alt="bill" width="100%" />
                                        <h4 className="mt-2">Saleem Qureshi <i className="fab fa-linkedin text-primary"></i></h4>
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <div className="xFactorImg">
                            <Container fluid>
                                <Row>
                                    <Col lg="12">
                                        <img src="/assets/images/LM/partnerships/compton/xFactor.jpeg" alt="xfactor" width="100%" />
                                        <img src="/assets/images/LM/partnerships/compton/yesYouCan.jpeg" alt="xfactor" width="100%" />

                                    </Col>
                                </Row>
                            </Container>
                        </div>

                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles >
        )
    }
}

export default EducationComptonComponent