import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .unitednations-page {
        .unitednations-page-area {
            padding : 70px 0;

            .xFactorImg {
                            
            }

            .SomeOneRiseContent{
                background: ${colors.gr_bg};
                position: relative;
                top: -50px;
                padding: 5vh 0;
                text-align: center;
                border-radius: 5px;
                h4 {
                    color: #fff;
                }
            }
            .KidsRiseContent {
                background: ${colors.bg1};
                padding: 15vh 0;
                text-align: center;
                img {
                    margin-top: 30px;
                }
                h3 {
                    color: #fff;
                }

                h5 {
                    font-weight: 300;
                    color: #fff;
                    margin-top: 20px;   
                }
            }

            .fasionContent {
                background: ${colors.footer1};
                padding: 60px 0;

                p {
                    color: #fff;
                    font-size: 15px;
                    line-height: 2;

                    a {
                        color: ${colors.brightYellow};
                    }
                }
            }

            .smallImage {
                margin-top: -20px;
                height: 150px;
                width: 100%;
            }
            .row-divider {
                // border-top: solid;
                // border-top-color: ${colors.text5};
                padding: 20px 0;
                background: ${colors.bg2};

                img{
                    border-radius: 5px;
                    margin-bottom: 30px;
                    transition : all 0.3s ease;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
            .heading {
                h4 {
                    color : ${colors.black1};
                    font-weight: 600;
                    line-height: 25px;
                    margin-bottom: 10px;

                    @media(max-width: 767px) {
                        font-size : 20px;
                    }
                }
            }
            .heading-text {
                p {
                    margin-bottom: 25px;
                    font-size: 15px;
                    color: ${colors.text2};
                    line-height: 25px;

                    @media(max-width: 767px) {
                        font-size : 15px;
                    }
                }

                li {
                    font-size: 15px;
                    line-height: 30px;
            
                ul {
                    margin-left: 30px;
                }
            }
            }

            .gallery-box {
                border-radius : 5px;
                overflow: hidden;
                margin-bottom: 30px;
                position: relative;
                img {
                    transition : all 0.3s ease;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            @media(max-width: 767px) {
                padding: 30px 0;
            }


            .video-player {
                // position           : absolute;
                bottom             : -83px;
                right              : 0;
                width              : 100%;
                height             : 270px;
                background-size    : cover;
                background-position: center;
                background-repeat  : no-repeat;
                border-radius      : 5px;
                margin-top         : 23px;

                // &::before {
                //     position        : absolute;
                //     content         : '';
                //     background-color: rgba(0, 0, 0, 0.2);
                //     width           : 100%;
                //     height          : 100%;
                //     top             : 0;
                //     left            : 0;
                //     border-radius : 5px;
                // }

                button.play-button {
                    position  : absolute;
                    z-index   : 10;
                    top       : 50%;
                    left      : 50%;
                    transform : translateX(-50%) translateY(-50%);
                    box-sizing: content-box;
                    display   : block;
                    width     : 32px;
                    height    : 44px;
                    border-radius : 50%;

                    i {
                        position   : relative;
                        font-size  : 40px;
                        color      : ${colors.bg1};
                        z-index    : 11;
                        padding-top: 2px;
                        margin-left: -2px;
                    }

                    &::before {
                        content   : "";
                        position  : absolute;
                        z-index   : 0;
                        left      : 50%;
                        top       : 50%;
                        transform : translateX(-50%) translateY(-50%);
                        display   : block;
                        width     : 70px;
                        height    : 70px;
                        background: #ffffff;
                        border-radius : 50%;
                        animation: pulse-border 1500ms ease-out infinite;
                    }

                    &:after {
                        content   : "";
                        position  : absolute;
                        z-index   : 1;
                        left      : 50%;
                        top       : 50%;
                        transform : translateX(-50%) translateY(-50%);
                        display   : block;
                        width     : 70px;
                        height    : 70px;
                        background: #ffffff;
                        border-radius : 50%;
                        transition : all 200ms;
                    }

                    &:hover {
                        i {
                            color: ${colors.green};
                        }
                    }

                    @keyframes pulse-border {
                        0% {
                            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                            opacity  : 1;
                        }

                        100% {
                            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                            opacity  : 0;
                        }
                    }
                }

                @media(max-width: 991px) {
                    bottom: -70%;
                }

                @media(max-width: 767px) {
                    position: unset;
                    width: 100%;
                    height: 250px;
                    margin-bottom: 30px;
                }
            }
        }
    }
`;