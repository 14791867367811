import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .marquee-area {
        background-size    : cover;
        background-position: center;
        background-repeat  : no-repeat;
        padding            : 0px;
        position           : relative;

        &:before {
            position  : absolute;
            content   : '';
            background: ${colors.bg1};
            // opacity   : 0.98;
            width     : 100%;
            height    : 100%;
            // top       : -1px;
            left      : 0;
        }

        .marquee {
            line-height: 50px;
            background-color: red;
            color: white;
            white-space: nowrap;
            overflow: hidden;
            box-sizing: border-box;
        }
        .marquee label {
            display: inline-block;
            animation: slide 5s linear infinite;
        }
        @keyframes slide {
            0%   { transform: translatex(0%); }
            100% { transform: translatex(100%); }
        }

        .ticker {
            span {
                color: ${colors.green};
                font-size: 16px;
            }
            label{
                color: #b9d3f7;
                font-size: 16px;
            }

            @media(max-width: 767px) {
                span {
                    font-size: 16px;
                }
                label {
                    font-size: 16px;
                }
            }
        }

        @media(max-width: 767px) {
            // padding: 30px 0 25px;
        }
    }
`;