import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`

    /* Course Grid */
    .course-grid-area {
        padding: 70px 0;
        // background: #fcef9f;
        .course-items {
            h5.heading {
                color : ${colors.black1};
                font-weight : 600;
                padding-bottom: 10px;
                margin-bottom: 20px;
                position : relative;
                &:before {
                    position : absolute;
                    content : "";
                    background : ${colors.green};
                    width : 50px;
                    height: 2px;
                    bottom : 0;
                    left : 0;
                }
    
                @media(max-width: 575px) {
                    font-size  : 17px;
                }
            }
            .course-item {
                border: 1px solid ${colors.border1};
                border-radius : 5px;
                transition : all 0.2s ease;
                overflow : hidden;
                margin-bottom: 30px;

                button.enroll-btn {
                    font-size: 16px;
                    color: #fff;
                    background: ${colors.gr_bg};
                    display: inline-block;
                    width: 100%;
                    height: 40px;
                    font-weight : 500;
                    border : none;
                    padding: 9px;
                    border-radius: 5px;
                    &:hover {
                        background: ${colors.gr_bg2};
                    }

                    @media(max-width: 575px) {
                        font-size : 14px;
                    }
                }

                @media(max-width: 1199px) {
                    padding: 12px 15px 15px;
                }

                .course-image {
                    width              : 100%;
                    height             : 253px;
                    background-size    : cover;
                    background-position: center;
                    background-repeat  : no-repeat;
                    border-radius : 5px 5px 0 0;
                    position: relative;

                    .author-img {
                        position: absolute;
                        left    : 20px;
                        bottom  : 20px;

                        img {
                            max-width: 40px;
                            border-radius : 50%;
                            margin-right: 5px;
                        }

                        .title {
                            background: #ffffff;
                            padding   : 3px 8px;
                            border-radius : 5px;

                            p {
                                font-size    : 12px;
                                color        : ${colors.black1};
                                font-weight  : 500;
                                margin-bottom: -4px;
                            }

                            span {
                                font-size  : 11px;
                                color      : ${colors.text3};
                                font-weight: 500;
                            }
                        }

                    }

                    .course-price {
                        p {
                            font-size  : 16px;
                            color      : #ffffff;
                            background : #007DFF;
                            position   : absolute;
                            left      : 30px;
                            bottom     : 20px;
                            padding    : 6px 8px;
                            font-weight: 500;
                            border-radius : 5px;

                            @media(max-width: 767px) {
                                font-size  : 14px;
                                bottom     : 5px;
                            }
                        }
                    }

                    @media(max-width: 767px) {
                        height: 185px;
                    }
                }

                .course-content {
                    background: #fff;
                    padding   : 20px 20px;

                    h6.heading {
                        a {
                            color        : ${colors.black1};
                            font-weight  : 600;
                            display      : inline-block;
                            margin-bottom: 12px;

                            &:hover {
                                color: ${colors.green};
                            }
                        }
                    }

                    p.desc {
                        font-size     : 14px;
                        color         : ${colors.text3};
                        line-height   : 25px;
                        border-bottom : 1px solid ${colors.border1};
                        padding-bottom: 10px;
                        margin-bottom : 12px;
                    }

                    .course-instructor-img {
                        img {
                            width: 70px;
                            height: 70px;
                            border-radius: 5%;
                        }
                        @media(max-width: 575px) {
                            img {
                                margin-top: 2px;
                                width: 50px;
                                height: 50px;
                                border-radius: 5%;
                            }
                            label {
                                position: absolute;
                                top: 18px;
                                left: 70px;
                            }
                        }
                    }

                    .course-info{
                        ul {
                            text-align: left;
                            margin-top: 20px;
                            li {
                                margin-right: 0;

                                i {
                                    font-size: 20px;
                                }

                                i.fa-book {
                                    font-size: 17px;
                                    color: #8C95A4;
                                }
                                i.fa-video {
                                    font-size: 14px;
                                    color: #8C95A4;
                                }
                                i.fa-wifi {
                                    font-size: 14px;
                                    color: #8C95A4;
                                }
                                i.fa-calendar-alt {
                                    color: #8C95A4;
                                }

                                label {
                                    margin-left: 10px;
                                    color    : ${colors.text2};
                                }
                                span {
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .course-face {
                        .duration,
                        .student {
                            p {
                                font-size: 13px;
                                color    : ${colors.text3};

                                i {
                                    font-size     : 16px;
                                    color         : ${colors.green};
                                    vertical-align: text-bottom;
                                    margin-right  : 3px;
                                }
                            }
                        }

                        .rating {
                            ul {
                                li {
                                    margin-right: 0;

                                    i {
                                        font-size: 14px;
                                        color    : ${colors.yellow};
                                    }

                                    &:last-child {
                                        font-size: 13px;
                                        color    : ${colors.text3};
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
                }
            }
        }

        @media(max-width: 767px) {
            padding: 40px 0 30px;
        }
    }

    /* Course List */
    .course-list-area {
        padding: 70px 0;
        .course-items2 {
            .course-item {
                border-radius : 5px;
                transition : all 0.2s ease;
                overflow : hidden;
                box-shadow: 0 8px 20px 5px rgba(0,0,0,0.07);
                margin-bottom: 30px;

                .course-image-box {
                    .course-image {
                        width              : 290px;
                        height             : 230px;
                        background-size    : cover;
                        background-position: center;
                        background-repeat  : no-repeat;
                        border-radius : 5px 5px 0 0;
                        position: relative;

                        .author-img {
                            position: absolute;
                            left    : 20px;
                            top  : 20px;

                            img {
                                max-width: 40px;
                                border-radius : 50%;
                                margin-right: 5px;
                            }

                            .title {
                                background: #ffffff;
                                padding   : 3px 8px;
                                border-radius : 5px;

                                p {
                                    font-size    : 12px;
                                    color        : ${colors.black1};
                                    font-weight  : 500;
                                    margin-bottom: -4px;
                                }

                                span {
                                    font-size  : 11px;
                                    // color      : ${colors.text3};
                                    color      : #fff;
                                    font-weight: 500;
                                }
                            }

                            @media(max-width: 991px) {
                                top  : unset;
                                bottom : 20px;
                            }
                        }

                        .course-price {
                            p {
                                font-size  : 16px;
                                color      : #ffffff;
                                background : ${colors.bg1};
                                position   : absolute;
                                right      : 20px;
                                top     : 20px;
                                padding    : 8px 10px;
                                font-weight: 500;
                                border-radius : 5px;

                                @media(max-width: 991px) {
                                    top  : unset;
                                    bottom : 20px;
                                }
                            }
                        }

                        @media(max-width: 991px) {
                            width: 100%;
                        }
                    }
                }

                .course-content {
                    background: #fff;
                    padding   : 20px 25px;

                    h6.heading {
                        padding-bottom: 12px;
                        margin-bottom: 20px;
                        position: relative;
                        &:before {
                            position: absolute;
                            content: "";
                            background: ${colors.green};
                            width: 50px;
                            height: 2px;
                            bottom: 0;
                            left: 0;
                        }

                        a {
                            color        : ${colors.black1};
                            font-weight  : 600;
                            display      : inline-block;

                            &:hover {
                                color: ${colors.green};
                            }
                        }
                    }

                    .rating {
                        margin-bottom: 6px;
                        ul {
                            li {
                                margin-right: 0;

                                i {
                                    font-size: 14px;
                                    color    : ${colors.yellow};
                                }

                                &:last-child {
                                    font-size: 13px;
                                    color    : ${colors.text3};
                                }
                            }
                        }
                    }

                    p.desc {
                        font-size     : 14px;
                        color         : ${colors.text3};
                        line-height   : 25px;
                        margin-bottom : 15px;
                    }

                    a.details-btn {
                        font-size : 13px;
                        color : ${colors.green};
                        border : 1px solid ${colors.border3};
                        padding: 7px 15px;
                        border-radius: 5px;
                        font-weight: 500;
                        &:hover {
                            color : #ffffff;
                            background : ${colors.gr_bg};
                            border-color : ${colors.green};
                        }
                    }
                }

                &:hover {
                    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.1);
                }

                @media(max-width: 991px) {
                    display : block !important;
                }
            }
        }

        @media(max-width: 767px) {
            padding: 40px 0 30px;
        }
    }

    /* Course Details */
    .course-details-area {
        padding : 10px 0px;

        .about-content {
            h4.about-title {
                color        : ${colors.black1};
                line-height  : 35px;
                font-weight  : 600;
                margin-bottom: 25px;

                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }

            p.about-para {
                font-size    : 15px;
                color        : ${colors.text3};
                line-height  : 25px;
                margin-bottom: 40px;

                span {
                    display   : block;
                    margin-top: 20px;
                }

                @media(max-width: 575px) {
                    margin-bottom : 20px;
                }
            }

            .counter-box {
                h3 {
                    margin-bottom: 10px;

                    span {
                        font-weight: 600;
                    }

                    i {
                        font-size     : 20px;
                        vertical-align: middle;
                    }
                }

                p {
                    font-size  : 14px;
                    color      : ${colors.text3};
                    font-weight: 500;
                }

                // @media(max-width: 575px) {
                //     display : none;
                // }
            }

            .counter-box.box1 {
                h3 {
                    color: ${colors.red};
                }
            }

            .counter-box.box2 {
                h3 {
                    color: ${colors.purple};
                }
            }

            .counter-box.box3 {
                h3 {
                    color: ${colors.blue};
                }
            }

            a.readmore-btn {
                font-size : 14px;
                color     : #fff;
                background: ${colors.gr_bg};
                display   : inline-block;
                width     : 145px;
                height    : 40px;
                text-align: center;
                padding   : 11px;
                border-radius : 5px;
                margin-top: 40px;

                &:hover {
                    background: ${colors.gr_bg2};
                }

                @media(max-width: 575px) {
                    margin-top : 0;
                }
            }
        }

        @media(max-width: 767px) {
            padding: 30px 0 40px;
        }

        .buttonForScrollSticky {
                position: initial;
                top: 0;
                z-index: 11;

                @media(max-width: 767px) {
                    position: sticky;
                    top: 0;
                    z-index: 11;
                }
            
                    .nav {
                        display: inline-block;
                        border-radius: 5px;
                        box-shadow: 0 8px 25px rgba(0,0,0,0.1);
                        margin-bottom: 35px;
                        .nav-item {
                            display: inline-block;
                            a.nav-link {
                                font-size: 14px;
                                color: ${colors.black2};
                                font-weight: 500;
                                text-transform : uppercase;
                                padding: 12px 30px 10px;
                                border-radius: 5px;
    
                                @media(max-width: 991px) {
                                    padding: 12px 16px 9px;
                                    font-size: 12px;
                                }
                            }
                            a.nav-link.active {
                                background : ${colors.gr_bg};
                                color : #ffffff;
                            }
                        }
                        @media(max-width: 767px) {
                            background: #edeaea;
                            width: 100%;
                            text-align: center;
                            box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                            -webkit-box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                            -moz-box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                        }
                    }
        }
        .buttonForScroll {
            position: initial;
            top: 0;
            z-index: 11;

            @media(max-width: 767px) {
                position: initial;
                display: none !important;
                top: 0;
                z-index: 11;
            }
        
                .nav {
                    display: inline-block;
                    border-radius: 5px;
                    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
                    margin-bottom: 35px;
                    .nav-item {
                        display: inline-block;
                        a.nav-link {
                            font-size: 14px;
                            color: ${colors.black2};
                            font-weight: 500;
                            text-transform : uppercase;
                            padding: 12px 30px 10px;
                            border-radius: 5px;

                            @media(max-width: 991px) {
                                padding: 12px 16px 9px;
                                font-size: 12px;
                            }
                        }
                        a.nav-link.active {
                            background : ${colors.gr_bg};
                            color : #ffffff;
                        }
                    }
                    @media(max-width: 767px) {
                        background: #edeaea;
                        width: 100%;
                        text-align: center;
                        box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                        -webkit-box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                        -moz-box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                    }
                }
        }
        .overview-tab {
            h5 {
                color: ${colors.black2};
                font-weight: 600;
                padding-bottom: 10px;
                margin-bottom: 20px;
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    background: ${colors.green};
                    width: 50px;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                }

                @media(max-width: 575px) {
                    font-size : 17px;
                }                            
            }
            p {
                font-size: 15px;
                color: ${colors.text2};
                line-height: 25px;

                @media(max-width: 575px) {
                    font-size : 14px;
                }
            }

            .course-desc {
                margin-bottom: 40px;
            }

            .course-feature {
                margin-bottom: 40px;
                ul {
                    margin-top: 20px;
                    li {
                        font-size : 14px;
                        color: ${colors.text3};
                        line-height : 25px;
                        margin-bottom : 10px;
                        i {
                            font-size : 20px;
                            color: ${colors.green};
                            float: left;
                            height: 40px;
                            line-height: 27px;
                            margin-right: 10px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .course-learn {
                margin-bottom: 40px;
                ul {
                    margin-top: 20px;
                    li {
                        font-size: 14px;
                        color: ${colors.text3};
                        line-height: 25px;
                        margin-bottom: 15px;
                        i {
                            float: left;
                            color: ${colors.green};
                            border: 1px solid ${colors.border3};
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            text-align: center;
                            padding-top: 9px;
                            margin-top: 8px;
                            margin-right: 15px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .course-share {
                ul.social {
                    margin-top: 30px;
                    li {
                        a {
                            text-align: center;
                            position  : relative;
                            height    : 18px;
                            display   : inline-block;

                            &:before {
                                content           : "";
                                position          : absolute;
                                border-width      : 9px 17px;
                                border-style      : solid;
                                border-top-color  : transparent;
                                border-right-color: transparent;
                                border-left-color : transparent;
                                top               : -18px;
                                left              : 0;
                                z-index           : 1;
                                transition : all 0.2s ease;
                            }

                            &:after {
                                content            : "";
                                position           : absolute;
                                border-width       : 9px 17px;
                                border-style       : solid;
                                border-right-color : transparent;
                                border-bottom-color: transparent;
                                border-left-color  : transparent;
                                bottom             : -18px;
                                left               : 0;
                                z-index            : 1;
                                transition : all 0.2s ease;
                            }

                            i {
                                font-size: 14px;
                                color    : #ffffff;
                                width    : 34px;
                            }

                            &:hover {
                                background-color: ${colors.green} !important;

                                &:before {
                                    border-bottom-color: ${colors.green} !important;
                                }

                                &:after {
                                    border-top-color: ${colors.green} !important;
                                }
                            }
                        }

                        &:nth-child(1) {
                            a {
                                background-color: #4267B2;

                                &:before {
                                    border-bottom-color: #4267B2;
                                }

                                &:after {
                                    border-top-color: #4267B2;
                                }
                            }
                        }

                        &:nth-child(2) {
                            a {
                                background-color: #1DA1F2;

                                &:before {
                                    border-bottom-color: #1DA1F2;
                                }

                                &:after {
                                    border-top-color: #1DA1F2;
                                }
                            }
                        }

                        &:nth-child(3) {
                            a {
                                background-color: #2867B2;

                                &:before {
                                    border-bottom-color: #2867B2;
                                }

                                &:after {
                                    border-top-color: #2867B2;
                                }
                            }
                        }

                        &:nth-child(4) {
                            a {
                                background-color: #DD1343;

                                &:before {
                                    border-bottom-color: #DD1343;
                                }

                                &:after {
                                    border-top-color: #DD1343;
                                }
                            }
                        }

                        &:nth-child(5) {
                            a {
                                background-color: #ea4c89;

                                &:before {
                                    border-bottom-color: #ea4c89;
                                }

                                &:after {
                                    border-top-color: #ea4c89;
                                }
                            }
                        }
                    }
                }
            }
        }

        .curriculum-tab {
            .course-curriculum {
                margin-bottom: 40px;
                h5 {
                    color: ${colors.black2};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }

                    @media(max-width: 575px) {
                        font-size : 17px;
                    }  
                }
                p {
                    font-size: 15px;
                    color: ${colors.text2};
                    line-height: 25px;

                    @media(max-width: 575px) {
                        font-size : 14px;
                    }
                }
            }
            .course-element {
                h5 {
                    color: ${colors.black2};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }

                    @media(max-width: 575px) {
                        font-size : 17px;
                    }
                }
                .course-item {
                    margin-bottom: 4px;
                    // border-style: solid;
                    // border-width: 1;
                    button.course-button {
                        border: none;
                        background: #8c95a4;
                        // margin-bottom: 15px;
                        display: block;
                        width: 100%;
                        text-align : left;
                        padding: 10px 10px;
                        font-size : 17px;
                        // color: ${colors.black2};
                        color: #fff;
                        font-weight: 500;
                        span {
                            float: right;
                            font-size: 14px;
                            color: ${colors.text3};
                            font-weight: 400;
                        }
                    }

                    .course-content {
                        max-height: 0;
                        overflow  : hidden;
                        transition: max-height 0.2s ease-in-out;
                        ul {
                            li {
                                border-bottom : 1px solid ${colors.border3};
                                margin-left: 25px;
                                padding: 12px 0;
                                span.play-icon {
                                    font-size : 14px;
                                    color: ${colors.text3};
                                    margin-right: 20px;
                                    i {
                                        // color: ${colors.green};
                                        color: #fff;
                                        background: ${colors.green};
                                        // border: 1px solid ${colors.green};
                                        font-size: 22px;
                                        width: 30px;
                                        height: 30px;
                                        border-radius: 50%;
                                        padding-left: 2px;
                                        text-align: center;
                                        line-height: 29px;
                                        vertical-align: middle;
                                        margin-right: 5px;
                                    }
                                }
                                span.lecture-title {
                                    font-size : 15px;
                                    color: ${colors.black2};

                                    @media(max-width: 575px) {
                                        font-size : 14px;
                                    }
                                }
                                button.lecture-duration {
                                    background: transparent;
                                    float: right;
                                    font-size: 14px;
                                    color: ${colors.text3};
                                    line-height: 28px;
                                    font-weight: 400;

                                    &:hover {
                                        background: ${colors.text3};
                                        color: #fff;
                                        border-radius: 2px;
                                    }
                                }
                            }
                        }
                    }

                    .course-content.show {
                        max-height: 100%;
                        margin-bottom: 40px;
                    }

                    &:last-child {
                        margin-bottom: 40px;
                    }
                }
            }
                    }

                    .course-content.show {
                        max-height: 100%;
                        margin-bottom: 40px;
                    }

                    &:last-child {
                        margin-bottom: 40px;
                    }
                }
            }
            .timeTable-element {
                h5 {
                    color: ${colors.black2};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }

                    @media(max-width: 575px) {
                        font-size : 17px;
                    }
                }
                    
                button.button1 {
                    background-color: ${colors.bg1};
                    border: 1px solid ${colors.green};
                    color: #fff;
                    padding: 8px;
                }
                button.button2 {
                    background    : ${colors.gr_bg};
                    border: 1px solid #fff;
                    border : none;
                    color: #000;
                    padding: 8px;
                    font-size: 16px;
                }
        
                .course-item {
                    margin-bottom: 10px;
                    background-color: ${colors.bg1};
                    border-radius: 1%;
                    padding: 10px 10px;
                    padding-bottom: 1px;
                    button.course-button {
                        border: none;
                        background: transparent;
                        margin-bottom: 15px;
                        margin-left: 5px;
                        display: block;
                        width: 100%;
                        text-align : left;
                        padding: 0;
                        font-size : 18px;
                        // color: ${colors.black2};
                        color: #fff;
                        font-weight: 500;
                        span {
                            margin-top: 15px;
                            float: right;
                            font-size: 14px;
                            // margin-right: 15px;
                            // color: ${colors.text3};
                            color: #fff;
                            font-weight: 400;
                        }
                        span.tName {
                            margin-top: 0px;
                            float: right;
                            font-size: 14px;
                            margin-left: 5px;
                            // color: ${colors.text3};
                            color: #fff;
                            font-weight: 400;
                        }
                        label {
                            font-size: 15px;
                        }
                    }
                    img {
                        border-radius: 50%;
                        width: 50px;
                        height: 50px;

                        @media(max-width: 575px) {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    .course-content {
                        max-height: 0;
                        overflow  : hidden;
                        transition: max-height 0.2s ease-in-out;
                        ul {    
                            li {
                                border-bottom : 1px solid ${colors.green};
                                // margin-left: 25px;
                                padding: 12px 10px;
                                span.play-icon {
                                    font-size : 14px;
                                    // color: ${colors.text3};
                                    color: #fff;
                                    margin-right: 20px;
                                    i {
                                        // color: ${colors.green};
                                        color: #fff;
                                        // border: 1px solid ${colors.green};
                                        background: ${colors.green};
                                        font-size: 22px;
                                        width: 30px;
                                        height: 30px;
                                        border-radius: 50%;
                                        padding-left: 3px;
                                        text-align: center;
                                        line-height: 29px;
                                        vertical-align: middle;
                                        margin-right: 10px;
                                    }
                                }
                                span.lecture-title {
                                    font-size : 15px;
                                    color: #fff;
                                    // color: ${colors.black2};

                                    @media(max-width: 575px) {
                                        font-size : 14px;
                                    }
                                }
                                span.lecture-duration {
                                    float: right;
                                    font-size: 14px;
                                    // color: ${colors.text3};
                                    color: #fff;
                                    line-height: 28px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    .course-content.show {
                        max-height: 100%;
                        margin-bottom: 40px;
                    }

                    &:last-child {
                        margin-bottom: 40px;
                    }

                    .teacher-info-timetable {
                        // text-align: right;
                        padding-top: 20px;

                        .cardPrice {
                            text-align: right;
                            padding: 5px;
                            border-radius: 2%;
                            background: #485d7c;

                            @media(max-width: 575px) {
                                text-align: center;
                            }
                        }
                        button {
                            min-width: 100px;
                            // padding: 15px 5px;
                        }
                        .priceCourse {
                            h6 {
                                color: #fff;
                                font-size: 18px;
                            }
                            p {
                                color: #fff;
                                font-size: 20px;
                            }
                        }
                        img {
                            border-radius: 50%;
                            width: 80px;
                            height: 80px;

                            @media(max-width: 575px) {
                                width: 60px;
                                height: 60px;
                            }
                        }
                        label{
                            margin: 10px;
                            color: #fff;
                        }
                        span {
                            color: ${colors.brightYellow};
                        }
                        .img-col {
                            // text-align: left;
                            padding-left: 30px; 
                        }
                        .belowApply{
                            label {
                                color: ${colors.brightYellow};
                                margin-top: 10px;
                                margin-left: 0px !important;
                                
                            }
                        }
                        .apply-btn {
                            // margin-left: 70px;
                            // margin-top : -1px;
            
                            a {
                                font-size     : 12px;
                                color         : #ffffff;
                                background    : ${colors.gr_bg};
                                display       : inline-block;
                                width         : 100%;
                                height        : 40px;
                                text-align    : center;
                                text-transform: uppercase;
                                font-weight   : 500;
                                padding       : 11px;
                                border-radius : 5px 5px 5px 5px;
                                position: relative;
                                cursor: pointer;
            
                                i {
                                    position : absolute;
                                    font-size: 20px;
                                    left     : -40px;
                                    top      : 0;
                                    padding  : 10px;
                                    width    : 40px;
                                    height   : 100%;
                                    border-radius : 5px 0 0 5px;
                                    background-color: ${colors.green2};
                                }
            
                                &:hover {
                                    background: ${colors.gr_bg2};
                                }
                            }
            
                            // @media(max-width: 1199px) {
                            //     margin-left: 45px;
                            // }
            
                            // @media(max-width: 991px) {
                            //     margin-top: 10px;
                            // }
                        }
                    }
                }
            }
        }

        .EnrollButton {
            // background: ${colors.gr_bg};
            border: none;
            display: none;
            text-align: center;
            // padding: 15px;
            color: #fff;
            -webkit-box-shadow: -1px -3px 6px 0px rgba(50, 50, 50, 0.75);
            -moz-box-shadow:    -1px -3px 6px 0px rgba(50, 50, 50, 0.75);
            box-shadow:         -1px -3px 6px 0px rgba(50, 50, 50, 0.75);

            @media(max-width: 767px) {
                display: block;
                position: fixed;
                left: 0;
                bottom: 0;
                margin: 0;
                border-radius: 0;
                z-index: 999;
                width: 100%;

            }
        }

        .course-details-top {
            .heading {
                h4 {
                    color : ${colors.black1};
                    font-weight: 600;
                    line-height: 35px;
                    margin-bottom: 25px;

                    @media(max-width: 575px) {
                        font-size : 20px;
                    }
                }
            }
            .course-top-overview {
                margin-bottom : 25px;
                .overviews {
                    .author {
                        margin-right : 20px;
                        padding-right : 20px;
                        margin-top: -3px;
                        border-right : 1px solid ${colors.border3};
                        img {
                            float : left;
                            max-width: 50px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                        .author-name {
                            float : left;
                            margin-top: 3px;
                            h6 {
                                color : ${colors.black2};
                                font-weight: 600;
                                text-transform: uppercase;
                                margin-bottom: 5px;

                                @media(max-width: 767px) {
                                    font-size : 14px;
                                }
                            }
                            p {
                                font-size : 15px;
                                color : ${colors.text3};
                                font-weight: 500;

                                @media(max-width: 767px) {
                                    font-size : 14px;
                                }
                            }
                        }
                    }

                    .category {
                        margin-right : 20px;
                        padding-right : 20px;
                        border-right : 1px solid ${colors.border3};
                        h6 {
                            color : ${colors.black2};
                            font-weight: 600;
                            text-transform: uppercase;
                            margin-bottom: 5px;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                        p {
                            font-size : 15px;
                            color : ${colors.text3};
                            font-weight: 500;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                    }

                    .rating {
                        margin-right : 20px;
                        padding-right : 20px;
                        border-right : 1px solid ${colors.border3};
                        h6 {
                            color : ${colors.black2};
                            font-weight: 600;
                            text-transform: uppercase;
                            margin-bottom: 5px;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                        ul {
                            li {
                                margin-right: 1px;
                                i {
                                    font-size: 16px;
                                    color: ${colors.yellow};
                                }
                                &:last-child {
                                    font-size : 15px;
                                    color : ${colors.text3};
                                    font-weight: 500;
                                    margin-left: 5px;

                                    @media(max-width: 767px) {
                                        font-size : 14px;
                                    }
                                }
                            }
                        }
                    }

                    .price {
                        h6 {
                            color : ${colors.black2};
                            font-weight: 600;
                            text-transform: uppercase;
                            margin-bottom: 5px;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                        p {
                            font-size : 15px;
                            color : ${colors.text3};
                            font-weight: 500;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                    }
                }

                @media(max-width: 480px) {
                    display : none;
                }
            }
            .course-details-banner {
                margin-bottom: 40px;
                img {
                    border-radius : 5px;
                }
            }
            .course-tab-list {
                .makeStickyonPhone {
                    position: initial;
                    top: 0;
                    z-index: 11;

                    @media(max-width: 767px) {
                        position: sticky;
                        top: 0;
                        z-index: 11;
                        background: #edeaea;
                    }
                }
                .nav {
                    display: inline-block;
                    border-radius: 5px;
                    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
                    margin-bottom: 35px;
                    .nav-item {
                        display: inline-block;
                        a.nav-link {
                            font-size: 14px;
                            color: ${colors.black2};
                            font-weight: 500;
                            text-transform : uppercase;
                            padding: 12px 30px 10px;
                            border-radius: 5px;

                            @media(max-width: 991px) {
                                padding: 12px 16px 9px;
                            }
                        }
                        a.nav-link.active {
                            background : ${colors.gr_bg};
                            color : #ffffff;
                        }
                    }
                }
                .tab-content {
                    .overview-tab {
                        h5 {
                            color: ${colors.black2};
                            font-weight: 600;
                            padding-bottom: 10px;
                            margin-bottom: 20px;
                            position: relative;
                            &:before {
                                position: absolute;
                                content: "";
                                background: ${colors.green};
                                width: 50px;
                                height: 2px;
                                bottom: 0;
                                left: 0;
                            }

                            @media(max-width: 575px) {
                                font-size : 17px;
                            }                            
                        }
                        p {
                            font-size: 15px;
                            color: ${colors.text2};
                            line-height: 25px;

                            @media(max-width: 575px) {
                                font-size : 14px;
                            }
                        }

                        .course-desc {
                            margin-bottom: 40px;
                        }

                        .course-feature {
                            margin-bottom: 40px;
                            ul {
                                margin-top: 20px;
                                li {
                                    font-size : 14px;
                                    color: ${colors.text3};
                                    line-height : 25px;
                                    margin-bottom : 10px;
                                    i {
                                        font-size : 20px;
                                        color: ${colors.green};
                                        float: left;
                                        height: 40px;
                                        line-height: 27px;
                                        margin-right: 10px;
                                    }
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                        .course-learn {
                            margin-bottom: 40px;
                            ul {
                                margin-top: 20px;
                                li {
                                    font-size: 14px;
                                    color: ${colors.text3};
                                    line-height: 25px;
                                    margin-bottom: 15px;
                                    i {
                                        float: left;
                                        color: ${colors.green};
                                        border: 1px solid ${colors.border3};
                                        width: 35px;
                                        height: 35px;
                                        border-radius: 50%;
                                        text-align: center;
                                        padding-top: 9px;
                                        margin-top: 8px;
                                        margin-right: 15px;
                                    }
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                        .course-share {
                            ul.social {
                                margin-top: 30px;
                                li {
                                    a {
                                        text-align: center;
                                        position  : relative;
                                        height    : 18px;
                                        display   : inline-block;

                                        &:before {
                                            content           : "";
                                            position          : absolute;
                                            border-width      : 9px 17px;
                                            border-style      : solid;
                                            border-top-color  : transparent;
                                            border-right-color: transparent;
                                            border-left-color : transparent;
                                            top               : -18px;
                                            left              : 0;
                                            z-index           : 1;
                                            transition : all 0.2s ease;
                                        }

                                        &:after {
                                            content            : "";
                                            position           : absolute;
                                            border-width       : 9px 17px;
                                            border-style       : solid;
                                            border-right-color : transparent;
                                            border-bottom-color: transparent;
                                            border-left-color  : transparent;
                                            bottom             : -18px;
                                            left               : 0;
                                            z-index            : 1;
                                            transition : all 0.2s ease;
                                        }

                                        i {
                                            font-size: 14px;
                                            color    : #ffffff;
                                            width    : 34px;
                                        }

                                        &:hover {
                                            background-color: ${colors.green} !important;

                                            &:before {
                                                border-bottom-color: ${colors.green} !important;
                                            }

                                            &:after {
                                                border-top-color: ${colors.green} !important;
                                            }
                                        }
                                    }

                                    &:nth-child(1) {
                                        a {
                                            background-color: #4267B2;

                                            &:before {
                                                border-bottom-color: #4267B2;
                                            }

                                            &:after {
                                                border-top-color: #4267B2;
                                            }
                                        }
                                    }

                                    &:nth-child(2) {
                                        a {
                                            background-color: #1DA1F2;

                                            &:before {
                                                border-bottom-color: #1DA1F2;
                                            }

                                            &:after {
                                                border-top-color: #1DA1F2;
                                            }
                                        }
                                    }

                                    &:nth-child(3) {
                                        a {
                                            background-color: #2867B2;

                                            &:before {
                                                border-bottom-color: #2867B2;
                                            }

                                            &:after {
                                                border-top-color: #2867B2;
                                            }
                                        }
                                    }

                                    &:nth-child(4) {
                                        a {
                                            background-color: #DD1343;

                                            &:before {
                                                border-bottom-color: #DD1343;
                                            }

                                            &:after {
                                                border-top-color: #DD1343;
                                            }
                                        }
                                    }

                                    &:nth-child(5) {
                                        a {
                                            background-color: #ea4c89;

                                            &:before {
                                                border-bottom-color: #ea4c89;
                                            }

                                            &:after {
                                                border-top-color: #ea4c89;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .curriculum-tab {
                        .course-curriculum {
                            margin-bottom: 40px;
                            h5 {
                                color: ${colors.black2};
                                font-weight: 600;
                                padding-bottom: 10px;
                                margin-bottom: 20px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    content: "";
                                    background: ${colors.green};
                                    width: 50px;
                                    height: 2px;
                                    bottom: 0;
                                    left: 0;
                                }

                                @media(max-width: 575px) {
                                    font-size : 17px;
                                }  
                            }
                            p {
                                font-size: 15px;
                                color: ${colors.text2};
                                line-height: 25px;

                                @media(max-width: 575px) {
                                    font-size : 14px;
                                }
                            }
                        }
                        .course-element {
                            h5 {
                                color: ${colors.black2};
                                font-weight: 600;
                                padding-bottom: 10px;
                                margin-bottom: 20px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    content: "";
                                    background: ${colors.green};
                                    width: 50px;
                                    height: 2px;
                                    bottom: 0;
                                    left: 0;
                                }

                                @media(max-width: 575px) {
                                    font-size : 17px;
                                }
                            }
                            .course-item {
                                margin-bottom: 4px;
                                // border-style: solid;
                                // border-width: 1;
                                button.course-button {
                                    border: none;
                                    background: #8c95a4;
                                    // margin-bottom: 15px;
                                    display: block;
                                    width: 100%;
                                    text-align : left;
                                    padding: 10px 10px;
                                    font-size : 18px;
                                    // color: ${colors.black2};
                                    color: #fff;
                                    font-weight: 500;
                                    span {
                                        float: right;
                                        font-size: 14px;
                                        color: ${colors.text3};
                                        font-weight: 400;
                                    }
                                }

                                .course-content {
                                    max-height: 0;
                                    overflow  : hidden;
                                    transition: max-height 0.2s ease-in-out;
                                    ul {
                                        li {
                                            border-bottom : 1px solid ${colors.border3};
                                            margin-left: 25px;
                                            padding: 12px 0;
                                            span.play-icon {
                                                font-size : 14px;
                                                color: ${colors.text3};
                                                margin-right: 20px;
                                                i {
                                                    // color: ${colors.green};
                                                    color: #fff;
                                                    background: ${colors.green};
                                                    // border: 1px solid ${colors.green};
                                                    font-size: 22px;
                                                    width: 30px;
                                                    height: 30px;
                                                    border-radius: 50%;
                                                    padding-left: 2px;
                                                    text-align: center;
                                                    line-height: 29px;
                                                    vertical-align: middle;
                                                    margin-right: 5px;
                                                }
                                            }
                                            span.lecture-title {
                                                font-size : 15px;
                                                color: ${colors.black2};

                                                @media(max-width: 575px) {
                                                    font-size : 14px;
                                                }
                                            }
                                            span.lecture-duration {
                                                float: right;
                                                font-size: 14px;
                                                color: ${colors.text3};
                                                line-height: 28px;
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }

                                .course-content.show {
                                    max-height: 100%;
                                    margin-bottom: 40px;
                                }

                                &:last-child {
                                    margin-bottom: 40px;
                                }
                            }
                        }
                                }

                                .course-content.show {
                                    max-height: 100%;
                                    margin-bottom: 40px;
                                }

                                &:last-child {
                                    margin-bottom: 40px;
                                }
                            }
                        }
                        .timeTable-element {
                            h5 {
                                color: ${colors.black2};
                                font-weight: 600;
                                padding-bottom: 10px;
                                margin-bottom: 20px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    content: "";
                                    background: ${colors.green};
                                    width: 50px;
                                    height: 2px;
                                    bottom: 0;
                                    left: 0;
                                }

                                @media(max-width: 575px) {
                                    font-size : 17px;
                                }
                            }
                                
                            button.button1 {
                                background-color: ${colors.bg1};
                                border: 1px solid ${colors.green};
                                color: #fff;
                                padding: 8px;
                            }
                            button.button2 {
                                background    : ${colors.gr_bg};
                                border: 1px solid #fff;
                                border : none;
                                color: #000;
                                padding: 8px;
                                font-size: 16px;
                            }
                    
                            .course-item {
                                margin-bottom: 10px;
                                background-color: ${colors.bg1};
                                border-radius: 1%;
                                padding: 10px 10px;
                                padding-bottom: 1px;
                                button.course-button {
                                    border: none;
                                    background: transparent;
                                    margin-bottom: 15px;
                                    margin-left: 5px;
                                    display: block;
                                    width: 100%;
                                    text-align : left;
                                    padding: 0;
                                    font-size : 18px;
                                    // color: ${colors.black2};
                                    color: #fff;
                                    font-weight: 500;
                                    span {
                                        margin-top: 15px;
                                        float: right;
                                        font-size: 14px;
                                        // margin-right: 15px;
                                        // color: ${colors.text3};
                                        color: #fff;
                                        font-weight: 400;
                                    }
                                    span.tName {
                                        margin-top: 0px;
                                        float: right;
                                        font-size: 14px;
                                        margin-left: 5px;
                                        // color: ${colors.text3};
                                        color: #fff;
                                        font-weight: 400;
                                    }
                                    label {
                                        font-size: 15px;
                                    }
                                }
                                img {
                                    border-radius: 50%;
                                    width: 50px;
                                    height: 50px;

                                    @media(max-width: 575px) {
                                        width: 40px;
                                        height: 40px;
                                    }
                                }

                                .course-content {
                                    max-height: 0;
                                    overflow  : hidden;
                                    transition: max-height 0.2s ease-in-out;
                                    ul {    
                                        li {
                                            border-bottom : 1px solid ${colors.green};
                                            // margin-left: 25px;
                                            padding: 12px 10px;
                                            span.play-icon {
                                                font-size : 14px;
                                                // color: ${colors.text3};
                                                color: #fff;
                                                margin-right: 20px;
                                                i {
                                                    // color: ${colors.green};
                                                    color: #fff;
                                                    // border: 1px solid ${colors.green};
                                                    background: ${colors.green};
                                                    font-size: 22px;
                                                    width: 30px;
                                                    height: 30px;
                                                    border-radius: 50%;
                                                    padding-left: 3px;
                                                    text-align: center;
                                                    line-height: 29px;
                                                    vertical-align: middle;
                                                    margin-right: 10px;
                                                }
                                            }
                                            span.lecture-title {
                                                font-size : 15px;
                                                color: #fff;
                                                // color: ${colors.black2};

                                                @media(max-width: 575px) {
                                                    font-size : 14px;
                                                }
                                            }
                                            span.lecture-duration {
                                                float: right;
                                                font-size: 14px;
                                                // color: ${colors.text3};
                                                color: #fff;
                                                line-height: 28px;
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }

                                .course-content.show {
                                    max-height: 100%;
                                    margin-bottom: 40px;
                                }

                                &:last-child {
                                    margin-bottom: 40px;
                                }

                                .teacher-info-timetable {
                                    // text-align: right;
                                    padding-top: 20px;

                                    .cardPrice {
                                        text-align: right;
                                        padding: 5px;
                                        border-radius: 2%;
                                        background: #485d7c;

                                        @media(max-width: 575px) {
                                            text-align: center;
                                        }
                                    }
                                    button {
                                        min-width: 100px;
                                        // padding: 15px 5px;
                                    }
                                    .priceCourse {
                                        h6 {
                                            color: #fff;
                                            font-size: 18px;
                                        }
                                        p {
                                            color: #fff;
                                            font-size: 20px;
                                        }
                                    }
                                    img {
                                        border-radius: 50%;
                                        width: 80px;
                                        height: 80px;

                                        @media(max-width: 575px) {
                                            width: 60px;
                                            height: 60px;
                                        }
                                    }
                                    label{
                                        margin: 10px;
                                        color: #fff;
                                    }
                                    span {
                                        color: ${colors.brightYellow};
                                    }
                                    .img-col {
                                        // text-align: left;
                                        padding-left: 30px; 
                                    }
                                    .belowApply{
                                        label {
                                            color: ${colors.brightYellow};
                                            margin-top: 10px;
                                            margin-left: 0px !important;
                                            
                                        }
                                    }
                                    .apply-btn {
                                        // margin-left: 70px;
                                        // margin-top : -1px;
                        
                                        a {
                                            font-size     : 12px;
                                            color         : #ffffff;
                                            background    : ${colors.gr_bg};
                                            display       : inline-block;
                                            width         : 100%;
                                            height        : 40px;
                                            text-align    : center;
                                            text-transform: uppercase;
                                            font-weight   : 500;
                                            padding       : 11px;
                                            border-radius : 5px 5px 5px 5px;
                                            position: relative;
                        
                                            i {
                                                position : absolute;
                                                font-size: 20px;
                                                left     : -40px;
                                                top      : 0;
                                                padding  : 10px;
                                                width    : 40px;
                                                height   : 100%;
                                                border-radius : 5px 0 0 5px;
                                                background-color: ${colors.green2};
                                            }
                        
                                            &:hover {
                                                background: ${colors.gr_bg2};
                                            }
                                        }
                        
                                        // @media(max-width: 1199px) {
                                        //     margin-left: 45px;
                                        // }
                        
                                        // @media(max-width: 991px) {
                                        //     margin-top: 10px;
                                        // }
                                    }
                                }
                            }
                        }
                    }

                    .instructor-tab {
                        h5 {
                            color: ${colors.black2};
                            font-weight: 600;
                            padding-bottom: 10px;
                            margin-bottom: 35px;
                            position: relative;
                            &:before {
                                position: absolute;
                                content: "";
                                background: ${colors.green};
                                width: 50px;
                                height: 2px;
                                bottom: 0;
                                left: 0;
                            }

                            @media(max-width: 575px) {
                                font-size : 17px;
                            }
                        }

                        .instructor-item {
                            margin-bottom: 30px;

                            .instructor-img {
                                img {
                                    border-radius : 5px;
                                }
                            }

                            .instructor-content {
                                position: relative;

                                .instructor-box {
                                    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                                    padding   : 25px;
                                    background: #ffffff;
                                    border-radius : 5px;
                                    // position: absolute;
                                    top     : 32px;
                                    left    : -11%;
                                    z-index : 1;

                                    .top-content {
                                        margin-bottom: 20px;

                                        .instructor-name {
                                            h6 {
                                                color      : ${colors.black2};
                                                font-weight: 600;
                                                text-transform: uppercase;
                                                margin-bottom: 12px;

                                                @media(max-width: 575px) {
                                                    font-size : 14px;
                                                }
                                            }
                                            p {
                                                font-size  : 14px;
                                                color      : ${colors.text3};
                                                font-weight: 500;
                                            }
                                            label {
                                                font-weight: 500;
                                                font-size  : 14px;
                                            }
                                            span {
                                                cursor: pointer;
                                                &:hover {
                                                    color: ${colors.green}
                                                }
                                            }
                                        }
                                        .instructor-social {
                                            margin-top: 25px;
                                            ul.social {
                                                li {
                                                    a {
                                                        text-align: center;
                                                        position  : relative;

                                                        &:before {
                                                            content           : "";
                                                            position          : absolute;
                                                            border-width      : 8px 14px;
                                                            border-style      : solid;
                                                            border-top-color  : transparent;
                                                            border-right-color: transparent;
                                                            border-left-color : transparent;
                                                            top               : -16px;
                                                            left              : 0;
                                                            z-index           : 1;
                                                            transition : all 0.2s ease;
                                                        }

                                                        &:after {
                                                            content            : "";
                                                            position           : absolute;
                                                            border-width       : 8px 14px;
                                                            border-style       : solid;
                                                            border-right-color : transparent;
                                                            border-bottom-color: transparent;
                                                            border-left-color  : transparent;
                                                            bottom             : -16px;
                                                            left               : 0;
                                                            z-index            : 1;
                                                            transition : all 0.2s ease;
                                                        }

                                                        i {
                                                            font-size: 13px;
                                                            color    : #ffffff;
                                                            width    : 28px;
                                                        }

                                                        &:hover {
                                                            background-color: ${colors.green} !important;

                                                            &:before {
                                                                border-bottom-color: ${colors.green} !important;
                                                            }

                                                            &:after {
                                                                border-top-color: ${colors.green} !important;
                                                            }
                                                        }
                                                    }

                                                    &:nth-child(1) {
                                                        a {
                                                            background-color: #4267B2;

                                                            &:before {
                                                                border-bottom-color: #4267B2;
                                                            }

                                                            &:after {
                                                                border-top-color: #4267B2;
                                                            }
                                                        }
                                                    }

                                                    &:nth-child(2) {
                                                        a {
                                                            background-color: #1DA1F2;

                                                            &:before {
                                                                border-bottom-color: #1DA1F2;
                                                            }

                                                            &:after {
                                                                border-top-color: #1DA1F2;
                                                            }
                                                        }
                                                    }

                                                    &:nth-child(3) {
                                                        a {
                                                            background-color: #2867B2;

                                                            &:before {
                                                                border-bottom-color: #2867B2;
                                                            }

                                                            &:after {
                                                                border-top-color: #2867B2;
                                                            }
                                                        }
                                                    }

                                                    &:nth-child(4) {
                                                        a {
                                                            background-color: #DD1343;

                                                            &:before {
                                                                border-bottom-color: #DD1343;
                                                            }

                                                            &:after {
                                                                border-top-color: #DD1343;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .instructor-desk {
                                        p {
                                            font-size : 15px;
                                            color      : ${colors.text2};
                                            line-height: 25px;

                                            @media(max-width: 575px) {
                                                font-size : 14px;
                                            }
                                        }
                                        span {
                                            cursor: pointer;
                                            &:hover {
                                                color: ${colors.green}
                                            }
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .course-details-images {
                        // background: ${colors.bg1};
                        padding-top: 10px;
                        h5 {
                            // color: ${colors.black2};
                            font-weight: 600;
                            padding-bottom: 10px;
                            margin-bottom: 35px;
                            position: relative;
                            &:before {
                                position: absolute;
                                content: "";
                                background: ${colors.green};
                                width: 50px;
                                height: 2px;
                                bottom: 0;
                                left: 0;
                            }

                            @media(max-width: 575px) {
                                font-size : 17px;
                            }
                        }
                        .gallery-box {
                            border-radius : 5px;
                            overflow: hidden;
                            margin-bottom: 30px;
                            position: relative;
                            img {
                                transition : all 0.3s ease;
                                &:hover {
                                    transform: scale(1.1);
                                }
                                @media(max-width: 767px) {
                                    // width: 40px;
                                }
                            }
                        }
                    }

                    .review-tab {
                        .review-comments {
                            margin-bottom: 40px;
                            h5 {
                                color: ${colors.black2};
                                font-weight: 600;
                                padding-bottom: 10px;
                                margin-bottom: 35px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    content: "";
                                    background: ${colors.green};
                                    width: 50px;
                                    height: 2px;
                                    bottom: 0;
                                    left: 0;
                                }

                                @media(max-width: 575px) {
                                    font-size : 17px;
                                }
                            }
                            .comment-box {
                                border-bottom: 1px solid ${colors.border1};
                                padding-bottom: 20px;
                                margin-bottom: 25px;
                                .comment-image {
                                    img {
                                        max-width : 100px;
                                        border-radius : 5px;
                                        margin-right : 20px;
                                    }
                                }
                                .comment-content {
                                    .content-title {
                                        .comment-writer {
                                            h6 {
                                                color: ${colors.black2};
                                                font-weight: 600;
                                                margin-bottom : 10px;

                                                @media(max-width: 575px) {
                                                    font-size : 14px;
                                                }
                                            }
                                            p {
                                                font-size : 12px;
                                                color: ${colors.text3};
                                                margin-bottom: 5px;
                                            }
                                            ul {
                                                margin-bottom: 8px;
                                                li {
                                                    margin-right: 1px;
                                                    i {
                                                        font-size: 16px;
                                                        color: ${colors.yellow};
                                                    }
                                                    &:last-child {
                                                        font-size: 13px;
                                                        color: ${colors.text2};
                                                        margin-left: 5px;
                                                    }
                                                }
                                            }
                                        }
                                        .reply-btn {
                                            button {
                                                font-size : 14px;
                                                color: ${colors.green};
                                                background : transparent;
                                                border : 1px solid ${colors.border3};
                                                font-weight: 500;
                                                border-radius: 25px;
                                                padding: 4px 12px 3px;
                                                margin-top : 10px;
                                                i {
                                                    font-size: 17px;
                                                    vertical-align: text-top;
                                                }
                                                &:hover {
                                                    color : #ffffff;
                                                    background : ${colors.gr_bg};
                                                    border-color : ${colors.green};
                                                }
                                            }
                                        }
                                    }
                                    .comment-desc {
                                        p {
                                            font-size: 14px;
                                            color: ${colors.text3};
                                            line-height: 25px;
                                        }
                                    }
                                }
                                &:last-child {
                                    border-bottom : none;
                                    padding-bottom : 0;
                                    margin-bottom : 0;
                                }
                            }
                        }

                        .review-form {
                            h5 {
                                color: ${colors.black2};
                                font-weight: 600;
                                padding-bottom: 10px;
                                margin-bottom: 25px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    content: "";
                                    background: ${colors.green};
                                    width: 50px;
                                    height: 2px;
                                    bottom: 0;
                                    left: 0;
                                }

                                @media(max-width: 575px) {
                                    font-size : 17px;
                                }
                            }
                        }
                    }
                }
            }

            @media(max-width: 767px) {
                margin-bottom: 40px;
            }
        }

        .single-details-sidbar {
            .course-details-feature {
                box-shadow: 0 8px 25px rgba(0,0,0,0.1);
                border-radius: 5px;
                padding: 15px 20px 20px;
                margin-bottom: 30px;
                h5.title {
                    color: ${colors.black1};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }

                    @media(max-width: 575px) {
                        font-size : 17px;
                    }
                }

                ul.feature-list {
                    margin-bottom: 20px;
                    li {
                        border-top: 1px dashed ${colors.border3};
                        padding: 12px 0;
                        font-size : 14px;
                        color : ${colors.black2};
                        font-weight: 500;
                        i {
                            font-size : 20px;
                            color: ${colors.green};
                            vertical-align: top;
                            margin-right : 2px;
                        }
                        span {
                            float : right;
                            font-size: 13px;
                            color: ${colors.text3};
                            font-weight: 400;
                            line-height: 20px;
                        }
                        &:first-child {
                            border-top : none;
                            padding-top : 0;
                        }
                        &:last-child {
                            padding-bottom : 0;
                        }
                    }
                }

                button.enroll-btn {
                    font-size: 16px;
                    color: #fff;
                    background: ${colors.gr_bg};
                    display: inline-block;
                    width: 100%;
                    height: 40px;
                    font-weight : 500;
                    border : none;
                    padding: 9px;
                    border-radius: 5px;
                    &:hover {
                        background: ${colors.gr_bg2};
                    }

                    @media(max-width: 575px) {
                        font-size : 14px;
                    }
                }

                @media(max-width: 1199px) {
                    padding: 12px 15px 15px;
                }
            }
        }

        @media(max-width: 767px) {
            // padding: 20px 0 5px;
        }
    }
`;