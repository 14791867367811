import React, { Component } from 'react';
import HeaderTwo from '../components/HeaderTwo';
import { BreadcrumbBox } from '../components/common/Breadcrumb';
import FooterTwo from '../components/FooterTwo';
import { Styles } from "./styles/privacyPolicy.js";
import { Container, Row, Col } from 'react-bootstrap';

class RefundPolicyComponent extends Component {
    componentDidMount() {
        document.title = "Refund Policy - Lincoln Method";
    }
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper privacy-policy-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Privacy Policy" />

                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <div className="event-details-content">
                                        <div className="event-details-overview">
                                            <h4>Refund Policy Lincoln Method</h4>
                                            <p>If you paid for an individual course, you can request a refund within 2 weeks of your payment. You can’t get a refund if you’ve completed the course. Or if 2 weeks have been passed.</p>

                                            <br />

                                            <p>If you subscribed to a Course or a Specialization, your subscription payments are not refundable, but you can cancel the subscription to end future payments.</p>

                                            <br />

                                            <p>If you subscribed to a Course or a Specialization, your subscription payments are not refundable, but you can cancel the subscription to end future payments.</p>

                                            <br />

                                            <p>To request a refund, please contact us.</p>

                                            <br />

                                            <p>You’ll get a confirmation email when we get your refund request.</p>

                                            <br />

                                            <p>You’ll be un-enrolled from the course.</p>

                                            <br />

                                            <p>It can take up to 7 days for funds to return to your account after a requested refund. If it has been longer than 7 days, contact us about the problem.</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default RefundPolicyComponent