import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .testimonial-area {
        background-size    : cover;
        background-position: center;
        background-repeat  : no-repeat;
        padding            : 63px 0;
        position           : relative;

        &:before {
            position  : absolute;
            content   : '';
            // background: ${colors.bg2};
            background: #0d1828;
            opacity   : 0.1;
            width     : 100%;
            height    : 100%;
            top       : 0;
            left      : 0;
        }

        .buttonAllView{
            text-align: center;

            button {
                font-size  : 14px;
                color      : #fff;
                background : ${colors.green};
                width      : 30%;
                height     : 48px;
                font-weight: 500;
                border     : none;
                border-radius : 5px;
                text-transform: uppercase;
                margin-top: 40px;

                &:hover {
                    background: ${colors.black1};

                    i {
                        color: #ffffff;
                    }
                }
                @media(max-width: 575px) {
                    width      : 60%;
                    height     : 50px;
                    font-size  : 12px;
                }
            }
        }
        .sec-title {
            h4 {
                color        : #ffffff;
                line-height  : 35px;
                font-weight  : 600;
                max-width    : 550px;
                margin       : auto;
                margin-bottom: 43px;

                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }
        }

        .testimonial-desc {
            margin-bottom: 45px;
            p {
                font-size: 15px;
                color: ${colors.text2};
                line-height: 25px;
                span {
                    // background : ${colors.bg1};
                    background : #273854;
                    // color: ${colors.black2};
                    color: #fff;
                    display : block;
                    margin: 35px 0;
                    padding: 22px 28px;
                    border-radius: 5px;
                    position: relative;
                    img {
                        position: absolute;
                        top: -25px;
                        left: 50%;
                        margin-left: -20px;
                        font-size : 30px;
                        color : #ffffff;
                        // background : ${colors.gr_bg};
                        width: 50px;
                        height: 50px;
                        text-align: center;
                        border-radius: 50%;
                        // padding-top: 7px;
                    }
                }
            }
            ul {
                margin-top : 30px;
                li {
                    font-size: 14px;
                    color: ${colors.text3};
                    line-height: 25px;
                    margin-bottom: 15px;
                    i {
                        float: left;
                        color: ${colors.green};
                        border: 1px solid ${colors.border3};
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        text-align: center;
                        padding-top: 9px;
                        margin-top: 8px;
                        margin-right: 15px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .testimonial-slider {
            .slider-item {
                .watchBtn {
                    background: ${colors.green};
                    color: #fff;
                    padding: 17px 13px;
                    border-radius : 5px;
                    text-transform: uppercase;
                }
                .desc {
                    background-color: rgba(255, 255, 255, 0.08);
                    // background-color: #182B49;
                    padding         : 30px 38px;
                    border-radius : 5px;
                    text-align: left;
                    position: relative;
                    min-height: 135px;

                    &::before {
                        content         : "";
                        position        : absolute;
                        border-width    : 15px 15px;
                        border-style    : solid;
                        border-top-color: rgba(255, 255, 255, 0.08);
                        ;
                        border-right-color : transparent;
                        border-bottom-color: transparent;
                        border-left-color  : transparent;
                        top                : 100%;
                        left               : 47px;
                        z-index            : 1;
                    }

                    h5 {
                        font-size    : 18px;
                        color        : ${colors.border1};
                        margin-bottom: 15px;
                    }

                    p {
                        font-size  : 13px;
                        color      : ${colors.border3};
                        line-height: 25px;
                    }

                    a {
                        color: ${colors.brightYellow};
                    }
                }

                .writer {
                    margin-top : 35px;
                    margin-left: 30px;

                    img {
                        max-width: 65px;
                        border-radius : 50%;
                        float       : left;
                        margin-right: 15px;
                    }

                    h6 {
                        color        : ${colors.border1};
                        padding-top  : 10px;
                        margin-bottom: 3px;
                    }

                    p {
                        color: ${colors.border3};
                    }

                    img.authorCountry {
                        border-radius: 0;
                        max-width: 40px;
                    }
                }
            }

            .slider-dot {
                margin-top: 48px !important;

                .swiper-pagination-bullet {
                    width     : 22px;
                    height    : 9px;
                    background: ${colors.text4};
                    display   : inline-block;
                    margin    : 3px;
                    opacity   : 1 !important;
                    border-radius : 5px;
                }

                .swiper-pagination-bullet.swiper-pagination-bullet-active {
                    background: ${colors.green};
                }
            }
        }

        @media(max-width: 767px) {
            padding: 30px 0;
        }
    }
`;