import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .CareersThanksForApplyingComponent {
    text-align: center;

    > img {
      display: block;
      margin: -1px auto;
      max-width: 100%;
    }

    .countDownBlock {
      h2 {
        color: #fff;
        font-weight: 400;
        font-family: inherit;
        font-size: 2.4rem !important;
        margin: 0;
      }
      span {
        color: #fff;
        font-size: 16px;
      }
    }
  }

  .tutotContractForm {
    text-align: left;
    h5 {
      color: #17a2b8;
    }
  }
  .glow-on-hover {
    max-width: 500px;
    // height: 100px;
    border: none;
    outline: none;
    color: rgb(0, 123, 255) !important;
    background: #fff !important;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    padding: 20px 50px !important;
    font-size: 26px !important;
    font-weight: bold !important;


    @media screen and (max-width: "700px"){
        font-size: 16px !important;
        width: 70vw;
        padding: 10px !important;
    }
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -15px;
    left:-15px;
    background-size: 400%;
    z-index: -1;
    filter: blur(15px);
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
    border-radius: 10px;
}
`;
