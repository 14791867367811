import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import "../login/styles/enroll.css";
import { Styles } from "../login/styles/login";

const DownloadApp = (props) => {
  const [showPopup, setshowPopup] = useState(false);

  useEffect(() => {
    // Delay showing the popup by 3 seconds
    const timeoutId = setTimeout(() => {
        if (props.showPopup !== false && props.showPopup !== showPopup) {
          setshowPopup(true);
        } else {
          setshowPopup(false);
        }
      }, 3000);
  
      // Clear the timeout if the component is unmounted or the dependency changes
      return () => clearTimeout(timeoutId);
  }, [props.showPopup]);

  const toggleLoginModal = () => {
    setshowPopup(!showPopup);
  };
  // Check if the viewport width is less than or equal to 768px (typical width for phones)
  const isPhoneView = window.matchMedia("(max-width: 768px)").matches;
  const isMobileApp = localStorage.getItem("mobileapp");

  if (isPhoneView) {
    console.log("User is on a phone view");
  } else {
    console.log("User is on a web view");
  }
  return (
    <div className="LoginComponent">
      {isPhoneView && !isMobileApp && (
        <Modal
          size={"md"}
          className=""
          show={showPopup}
          dialogClassName=""
          contentClassName="downloadAppPopupMain"
          centered
        >
          <Modal.Body>
            <div>
              <Container>
                <p
                  className="closeModalDownload text-right"
                  onClick={toggleLoginModal}
                  style={{ cursor: "pointer" }}
                >
                  X
                </p>
                <Styles>
                  <Row className="">
                    <Col md="12" className="text-center mb-5">
                      <h4 className="mb-0">Download the Lincoln Method App</h4>
                      <br />
                      <a
                        target="__blank"
                        href="https://play.google.com/store/search?q=Lincoln%20method&c=apps&hl=en&gl=US"
                      >
                        <button className="playStoreBtn">
                          <div className="d-flex ">
                            <img
                              className="playBtn my-auto"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/playBtn.png"
                              }
                            />
                            <div>
                              <label className="my-auto">
                                GET IT ON <br /> <span> Google Play</span>
                              </label>
                            </div>
                          </div>
                        </button>
                      </a>
                      <a
                        target="__blank"
                        href="https://apps.apple.com/pk/app/lincoln-method/id6447110097?platform=iphone"
                      >
                        <button className="playStoreBtn">
                          <div className="d-flex">
                            <i className="fab fa-apple my-auto"></i>
                            <div>
                              <label className="my-auto">
                                Download on the <br /> <span> App Store</span>
                              </label>
                            </div>
                          </div>
                        </button>
                      </a>
                    </Col>
                  </Row>
                </Styles>
              </Container>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default DownloadApp;
