import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import PhoneNumber from "./PhoneNumber";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// import { Styles } from "./styles/enroll.js";
import "./styles/enroll.css";
import PhoneNumberVerificationCode from "./PhoneNumberVerificationCode";
import {
  getPhoneUsersList,
  triggerNewUserType,
  doRegisterWithPhone,
  doLogin,
  sendPreRegistrationData,
} from "../../actions/webRedActions";
import UsernamesListings from "./UsernamesListings";
import UsernameField from "./UsernameField";
import UserDetails from "./UserDetails";
import { onTrainTutorLink } from "../../actions/utility";
const EnrollComponent = (props) => {
  const [showPopup, setshowPopup] = useState(false);
  const [enrollStep, setenrollStep] = useState(1);
  const [show, setShow] = useState(false);
  const [givenPhoneNumber, setgivenPhoneNumber] = useState("");
  const [isNewUser, setisNewUser] = useState(false);
  const [registeredUsers, setregisteredUsers] = useState([]);
  const [sessionId, setsessionId] = useState("");
  const [selectedCourse, setselectedCourse] = useState("");
  const [isChild, setisChild] = useState(false);
  const [newUserName, setnewUserName] = useState("");
  const [userPassword, setuserPassword] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [userDetails, setuserDetails] = useState("");
  const [timeTableView, settimeTableView] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevPhoneNumberSentRes = usePrevious(props.phoneNumberSentRes);
  const prevLoginSuccessWithCourse = usePrevious(props.loginSuccessWithCourse);

  useEffect(() => {
    setshowPopup(props.showPopup != false ? true : props.showPopup);
    setenrollStep(1);
    if (props.selectedCourse) {
      setselectedCourse(props.selectedCourse.course);
      console.log("Selected Course", props.selectedCourse.course);
    }
  }, [props.showPopup]);

  useEffect(() => {
    debugger;
    if (
      props.phoneNumberSentRes !== "" &&
      props.phoneNumberSentRes !== prevPhoneNumberSentRes &&
      typeof prevPhoneNumberSentRes !== "undefined"
    ) {
      debugger;
      if (props.phoneNumberSentRes.data.data.status == "success") {
        if (
          props.phoneNumberSentRes.data.data.result &&
          props.phoneNumberSentRes.data.data.result !== undefined
        ) {
          setgivenPhoneNumber(props.phoneNumberSentRes.phone);
          // toast.success(
          //   "We have texted you a verification code to your phone number: " + givenPhoneNumber
          // );
          changeFormStep(2); // ask for verification code
        }
      } else if (props.phoneNumberSentRes.data.data.status == "error") {
        var message = props.phoneNumberSentRes.data.data.message;
        console.log("Error Message", message);
        toast.error(message.replace("[HTTP 400]", ""));
      }
    }
  }, [props.phoneNumberSentRes]);

  useEffect(() => {
    debugger;
    if (props.phoneNumberVerificationRes !== "" && showPopup) {
      if (props.phoneNumberVerificationRes.data.data.status == "success") {
        if (
          props.phoneNumberVerificationRes.data.data.result &&
          props.phoneNumberVerificationRes.data.data.result == 1
        ) {
          if (onTrainTutorLink()) {
            props.getPhoneUsersList(givenPhoneNumber); //get the list of users registered against this phone number
          } else {
            settimeTableView(true);
          }
        } else {
          toast.error("Verification code is not valid");
        }
      }
    }
  }, [props.phoneNumberVerificationRes]);

  useEffect(() => {
    if (props.registeredUsersPhoneList !== "" && showPopup) {
      if (props.registeredUsersPhoneList.data.data.status == "success") {
        if (
          props.registeredUsersPhoneList.data.data.child_accounts &&
          props.registeredUsersPhoneList.data.data.child_accounts.length > 0
        ) {
          debugger;
          setisNewUser(false);
          setregisteredUsers(props.registeredUsersPhoneList.data.data.child_accounts);
          setsessionId(props.registeredUsersPhoneList.data.data.session_id);
          changeFormStep(3);
        } else {
          debugger;
          setisNewUser(true);
          setregisteredUsers([]);
          setsessionId(props.registeredUsersPhoneList.data.data.session_id);
          // changeFormStep(3);
          props.triggerNewUserType("child");
        }
      }
    }
  }, [props.registeredUsersPhoneList]);

  useEffect(() => {
    if (props.newUserType !== "" && showPopup) {
      if (props.newUserType.isChild && props.newUserType.isChild == 1) {
        setisChild(true);
        changeFormStep(4);
      } else {
        setisChild(false);
        changeFormStep(4);
      }
    }
  }, [props.newUserType]);

  useEffect(() => {
    debugger;
    if (props.isUserNameExists !== "" && showPopup) {
      if (props.isUserNameExists.data.data.status == "success") {
        if (
          props.isUserNameExists.data.data.result &&
          props.isUserNameExists.data.data.result == "0"
        ) {
          setnewUserName(props.isUserNameExists.username);
          props
            .sendPreRegistrationData(
              props.isUserNameExists.username,
              givenPhoneNumber,
              selectedCourse.id,
              selectedCourse.program_id,
              selectedCourse.course_name
            )
            .then((res) => {
              debugger;
            })
            .catch((err) => {
              debugger;
            });
          if (!isNewUser && registeredUsers.length > 0) {
            var email_already = registeredUsers[0].user_email;
            setuserEmail(email_already ? email_already : "");
          }
          const mobileView = localStorage.getItem("mobileapp");
          const mobileEmail = localStorage.getItem("mobileemail");
          if (mobileView) {
            setuserEmail(mobileEmail ? mobileEmail : "");
          }

          if (props.newUserPassword !== "") {
            if (props.newUserPassword.password) {
              setuserPassword(props.newUserPassword.password);
              localStorage.setItem('userPassword' , props.newUserPassword.password)
              changeFormStep(5);
            }
          }
          // this.setState({
          //     newUserName : nextProps.isUserNameExists.username
          // }, () => {
          //     this.changeFormStep(5);     Trigger Password Modal
          // })
        } else if (
          props.isUserNameExists.data.data.result &&
          props.isUserNameExists.data.data.result == "1"
        ) {
          toast.warning("Username already in use, Please enter another one.");
        }
      }
    }
  }, [props.isUserNameExists]);

  // useEffect(() => {
  //     if (props.newUserPassword !== "") {
  //         if (props.newUserPassword.password) {
  //             setuserPassword(props.newUserPassword.password)
  //             changeFormStep(5);
  //         }
  //     }
  // }, [props.newUserPassword])

  useEffect(() => {
    if (props.newUserDetails !== "" && showPopup) {
      if (props.newUserDetails.userDetails) {
        setuserDetails(props.newUserDetails.userDetails);
      }
    }
  }, [props.newUserDetails]);

  useEffect(() => {
    if (userEmail !== "" && userDetails !== "" && showPopup === true) {
      debugger;
      doRegistrationNow();
    }
  }, [userEmail, userDetails]);

  useEffect(() => {
    if (props.newUserEmail !== "" && showPopup) {
      if (props.newUserEmail.email) {
        setuserEmail(props.newUserEmail.email);
        // doRegistrationNow();
      }
    }
  }, [props.newUserEmail]);

  useEffect(() => {
    if (props.signupSuccessWithPhone && props.signupSuccessWithPhone != "" && showPopup) {
      debugger;
      if (
        props.signupSuccessWithPhone.data.data.result &&
        props.signupSuccessWithPhone.data.data.result == "1"
      ) {
        var loginType = "course";
        var courseId = selectedCourse.id;
        var isPurchase = 1;
        var isPassRequired = 1;
        props.doLogin(newUserName, userPassword, courseId, isPurchase, loginType, isPassRequired); // calling the action to login in now

        // this.changeFormStep(8); // proceed to checkout now
      } else if (props.signupSuccessWithPhone == "error") {
        toast.warning("There's some issue in registration, please try again.");
      }
    }
  }, [props.signupSuccessWithPhone]);

  useEffect(() => {
    debugger;
    if (props.loginSuccessWithCourse !== "" && typeof prevLoginSuccessWithCourse !== "undefined") {
      if (
        typeof props.loginSuccessWithCourse === "object" &&
        props.loginSuccessWithCourse.message &&
        props.loginSuccessWithCourse.message.indexOf("password is incorrect") > 0
      ) {
        // showing here the incorrect credentials msg
        toast.warning(props.loginSuccessWithCourse.message);
      } else if (
        typeof props.loginSuccessWithCourse === "object" &&
        props.loginSuccessWithCourse.status == "success"
      ) {
        console.log("login has been succcessfull");
        try {
          localStorage.setItem("loginData", JSON.stringify(props.loginSuccessWithCourse.result));
        } catch (err) {
          console.log("loginData could not store:", err);
        }
      }
    }
  }, [props.loginSuccessWithCourse]);

  const changeFormStep = (index) => {
    setenrollStep(index);
    // this.setState({
    //     enrollStep: index
    // }, () => {
    //     if(this.state.enrollStep == 2) {

    //         this.setState({
    //             verifyScreenRandomProp : Math.random()
    //         })
    //     }
    // });
  };

  const doRegistrationNow = () => {
    // const { enrollStep, givenPhoneNumber, isNewUser, registeredUsers, sessionId, selectedCourse, isChild, newUserName, userPassword, userDetails, userEmail } = this.state;

    var data = {
      session_id: sessionId,
      user_name: newUserName,
      user_password: userPassword,
      user_fname: userDetails.firstname,
      user_lname: userDetails.lastname,
      user_gender: userDetails.gender,
      user_dob: userDetails.birthDate,
      user_email: userEmail,
      prog_id: selectedCourse.program_id,
      user_course: selectedCourse.course_name,
      courseid: selectedCourse.id,
      purchase: 1,
      clientUrl: window.location.host,
      clientUrlProtocol: window.location.protocol,
      tutorData: onTrainTutorLink() ? localStorage.getItem("tutorDetails") : null,
    };
    debugger;
    console.log("Send Login Data", data);
    props.doRegisterWithPhone(data);
  };

  return (
    // <Styles>
    <div>
      <Modal size="sm" className="loginPopupMain" show={showPopup} onHide={handleClose} centered>
        <Modal.Body style={{ padding: 0 }}>
          <div>
            <Container style={{ padding: 0 }}>
              {
                <p className="closeModalLogin" onClick={() => setshowPopup(false)}>
                  X
                </p>
              }
              {enrollStep > 1 && enrollStep != 2 ? (
                <div className="backButtonModal" onClick={(e) => changeFormStep(enrollStep - 1)}>
                  <i className="fas fa-arrow-left"></i>
                </div>
              ) : null}
              <Row>
                <Col md="12">
                  {enrollStep == 1 ? <PhoneNumber /> : null}

                  {enrollStep == 2 ? (
                    <PhoneNumberVerificationCode
                      timeTableView={timeTableView}
                      courseDetails={selectedCourse}
                      phonenumber={givenPhoneNumber}
                    />
                  ) : null}
                  {enrollStep == 3 ? (
                    <UsernamesListings
                      phonenumber={givenPhoneNumber}
                      newuser={isNewUser}
                      registeredUsers={registeredUsers}
                      selectedCourse={selectedCourse}
                    />
                  ) : null}
                  {enrollStep == 4 ? <UsernameField isChild={isChild} /> : null}
                  {enrollStep == 5 ? (
                    <UserDetails newUserName={newUserName} userEmail={userEmail} />
                  ) : null}
                </Col>
              </Row>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </div>
    // </Styles>
  );
};

const mapStatesToProps = (state) => ({
  phoneNumberSentRes: state.webRed.phoneNumberSentRes,
  phoneNumberVerificationRes: state.webRed.phoneNumberVerificationRes,
  registeredUsersPhoneList: state.webRed.registeredUsersPhoneList,
  newUserType: state.webRed.newUserType,
  isUserNameExists: state.webRed.isUserNameExists,
  newUserPassword: state.webRed.newUserPassword,
  newUserDetails: state.webRed.newUserDetails,
  newUserEmail: state.webRed.newUserEmail,
  signupSuccessWithPhone: state.webRed.signupSuccessWithPhone,
  loginSuccessWithCourse: state.webRed.loginSuccessWithCourse,
  selectedCourse: state.webRed.courseDetail,
});

export default connect(mapStatesToProps, {
  getPhoneUsersList,
  triggerNewUserType,
  doRegisterWithPhone,
  doLogin,
  sendPreRegistrationData,
})(EnrollComponent);
