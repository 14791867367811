import React, { Component } from 'react';
import HeaderTwo from './components/HeaderTwo';
import NumberCounter from './components/NumberCounter';
import FooterTwo from './components/FooterTwo';
import GradeSubjectSelection from './components/GradeSubjectSelection';

export default class DemoHome extends Component {
  render() {
    return (
      <div className="main-wrapper">

        {/* Header 2 */}
        <HeaderTwo />


        {/* Counter Area */}
        <NumberCounter />

        {/* Grade Subject */}
        <GradeSubjectSelection />

        {/* Footer 2 */}
        <FooterTwo />

      </div>
    )
  }
}