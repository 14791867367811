import React, { Component } from 'react';
import Datas from '../data/course/slider.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Modal, Table } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { getImgURL } from "../actions/utility";
import { Styles } from "./styles/teacherCourseSlider.js";

class TeacherCourseSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTimeTable: false,
            gradeId: 0,
            courseId: 0,
            timings: []
        }
    }

    returnTimings = (timings) => {
        let daysArary = [];

        Object.keys(timings).map((id, key) => {
            var day = timings[id];
            if (day.start != '' && day.end != '') {
                var start_time = day.start;
                var firstCharStart = start_time.split("");
                if (firstCharStart[0] == '0') {
                    start_time = start_time.substring(1);
                }

                var end_time = day.end;
                var firstCharEnd = end_time.split("");
                if (firstCharEnd[0] == '0') {
                    end_time = end_time.substring(1);
                }

                var dayName = day.day.split("");

                let dayData = <tr key={key}>
                    <th style={{ padding: '5px 0' }}>{dayName[0] + dayName[1] + dayName[2]}</th>
                    <td style={{ padding: '5px 0' }}>
                        {/* <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faClock} size="1x" color="#ff0863" />
                        {this.changeTimeZoneLocation(start_time)} - {this.changeTimeZoneLocation(end_time)} */}
                    </td>
                </tr>;
                daysArary.push(dayData);
                debugger;
                return daysArary;
            }
        })
    }

    openTimeTableModal = (Id) => {

        this.setState(prevState => ({
            showTimeTable: !prevState.showTimeTable
        }), () => {
            if (this.state.showTimeTable) {
                let filterCourse = this.props.relatedCourses.filter(item => item.id === Id);
                let timings = JSON.parse(filterCourse[0].timing);
                let dayArray = [];
                Object.keys(timings).map((item, index) => {
                    dayArray.push(timings[item])
                })
                console.log("ADd", dayArray);

                this.setState({
                    timings: dayArray
                })
                console.log("Timings", this.state.timings)
                debugger;


            }
        })

    }

    render() {
        const { relatedCourses, tutorImgName } = this.props;
        console.log("Hello", relatedCourses);
        const settings = {
            slidesPerView: 3,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                }
            }
        };

        return (
            <Styles>
                {/* Course Slider */}
                <section className="course-slider-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                {typeof relatedCourses == 'object' ?
                                    <Modal className="htmlModalMain"
                                        show={this.state.showTimeTable} onHide={this.openTimeTableModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Time Table</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Table>
                                                {/* <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Username</th>
                                                </tr>
                                            </thead> */}
                                                <tbody>
                                                    {this.state.timings && this.state.timings.map((item, key) => (
                                                        item.is_on ?
                                                            // <React.Fragment>
                                                            <tr key={key}>

                                                                <th style={{ padding: '5px 0' }}>{item.day}</th>
                                                                <td style={{ padding: '5px 0' }}>
                                                                    <i className="fas fa-clock mr-5 fa-2x"></i>
                                                                    {item.start} - {item.end}
                                                                </td>


                                                            </tr> : null
                                                        // </React.Fragment>
                                                    )
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Modal.Body>
                                        {/* <Modal.Footer>
                                            <Button variant="secondary" onClick={this.openTimeTableModal}>
                                            Close
                                         </Button>
                                        <Button variant="primary" onClick={this.openTimeTableModal}>
                                            Save Changes
                                        </Button>
                                        </Modal.Footer> */}
                                    </Modal> : null}
                                <div className="sec-title text-center">
                                    <h5>Look at the courses taught by {tutorImgName.user_name}</h5>
                                </div>
                            </Col>
                            <Col md="12" className="course-slider">
                                <Swiper {...settings}>
                                    {
                                        relatedCourses && relatedCourses.map((data, i) => (
                                            <div className="course-item" key={i} >
                                                <Link to={process.env.PUBLIC_URL + data.courseLink}>
                                                    <div className="course-image" style={{ backgroundImage: `url(https://dashboard.lincolnmethod.com/uploads/${data.course_img})` }}>
                                                        {/* <div className="author-img d-flex">
                                                            <div className="img">
                                                                <img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" />
                                                            </div>
                                                            <div className="title">
                                                                <p>{data.authorName}</p>
                                                                <span>{data.authorCourses}</span>
                                                            </div>
                                                        </div> */}
                                                        <div className="course-price">
                                                            <p>{data.grade}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="course-content">
                                                    <h6 className="heading"><Link to={process.env.PUBLIC_URL + data.courseLink}>{data.course_name}</Link></h6>
                                                    <p className="desc">{i + 1}</p>
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="course-info">
                                                                <ul className="list-unstyled">
                                                                    <li>
                                                                        <i className="fas fa-book"></i>
                                                                        <label>15 Chapters</label>
                                                                    </li>
                                                                    <li>
                                                                        <i className="fas fa-video"></i>
                                                                        <label>154 Curated online videos</label>
                                                                    </li>
                                                                    <li>
                                                                        <i className="fas fa-wifi"></i>
                                                                        <label>Online</label>
                                                                    </li>
                                                                    <li onClick={() => this.openTimeTableModal(data.id)}>
                                                                        <i className="fas fa-calendar-alt"></i>
                                                                        <label className="timeTableList">View Course timetable</label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="course-instructor-img">
                                                                <ul className="list-unstyled">
                                                                    <li>
                                                                        <img src={`${getImgURL()}${tutorImgName.image}`} alt="teacher-img" className="img-fluid" />
                                                                        <label>Taught by: {tutorImgName.user_name}</label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {/* <div className="course-face d-flex justify-content-between">
                                                        <div className="duration">
                                                            <p><i className="las la-clock"></i>120</p>
                                                        </div>
                                                        <div className="rating">
                                                            <ul className="list-unstyled list-inline">
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                                </li>
                                                                <li className="list-inline-item">(4.5)</li>
                                                            </ul>
                                                        </div>
                                                        <div className="student">
                                                            <p><i className="las la-chair"></i>60</p>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div style={{ backgroundColor: "#8C95A4" }} className="">


                                                    <button className="btn text-light" style={{ width: "40%", height: 50 }}>
                                                        View Class Details
                                                            </button>
                                                    <button className="btn" style={{ backgroundColor: "#676c75", width: "60%", height: 50 }}>
                                                        {data.course_discount_price && (data.course_discount_price != 0 || data.course_discount_price == 0) ?
                                                            <span className="text-light">
                                                                <span className="outer" style={{ color: "red", textDecoration: 'line-through' }}>
                                                                    <span style={{ color: "#fff" }}>
                                                                        PKR {data.course_discount_price}
                                                                    </span>
                                                                </span>
                                                                <span style={{ fontSize: 16, margin: 5 }}>
                                                                    PKR {data.course_price}
                                                                </span>
                                                            </span>
                                                            : <span className="text-light" style={{ fontSize: 16, margin: 5 }}>
                                                                PKR {data.course_price}
                                                            </span>}
                                                        {/* <span class="outer" style={{ color: "red", textDecoration: 'line-through' }}>
                                                                <span class="inner" style={{ color: "green" }}>foo bar</span>
                                                            </span> */}
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Swiper>
                            </Col>
                            <Col md="12" className="text-center">
                                <button className="allCourses">View All Courses</button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default TeacherCourseSlider
