import React, { Component } from 'react';
import Datas from '../../data/instructor/details.json';
// import { Link } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/instructor.js';
import CountUp from 'react-countup';
import TestimonialSlider from '../../components/TestimonialSlider';
import TeacherCourseSlider from '../../components/TeacherCourseSlider';
import { getSingleTutorInfo } from "../../actions/webRedActions";
import { getImgURL } from "../../actions/utility";
import { connect } from 'react-redux';

class InstructorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tutorImgName: '',
            tutorInfo: '',
            teachersData: '',
            dummyText: "No Description Added Yet"

        }
    }

    componentDidMount() {
        var teacherData = JSON.parse(localStorage.getItem("teacherInfo"));
        this.setState({
            tutorImgName: teacherData,
        })
        this.props.getSingleTutorInfo(teacherData.id);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // if (prevState.tutorImgName !== nextProps.setTutorImgID) {
        //     debugger;
        //     // nextProps.getSingleTutorInfo(nextProps.setTutorImgID.Id);
        //     return {
        //         tutorImgName: nextProps.setTutorImgID,
        //     };
        // }
        if (prevState.tutorInfo !== nextProps.singleTutorInfo) {
            debugger;
            return {
                tutorInfo: nextProps.singleTutorInfo
            }
        }
        return null;
    }
    render() {
        const { tutorImgName, tutorInfo, teachersData } = this.state;
        console.log("Tutor Filter", this.state.teachersData);
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper instructor-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title={tutorImgName.user_name} />

                    {/* Instructor Details Area */}
                    <section className="instructor-details-area">
                        <Container>
                            <Row>
                                <Col md="4">
                                    {tutorImgName !== "" ?
                                        <div className="instructor-img">
                                            <img src={`${getImgURL()}${tutorImgName.image}`} alt="" className="img-fluid" />
                                            {/* <ul className="list-unstyled getintouch">
                                            <li><i className="las la-phone"></i> +1 (396) 486 4709</li>
                                            <li><i className="lar la-envelope"></i> mail@contactme.com</li>
                                            <li><i className="lab la-skype"></i> contact.me</li>
                                        </ul> */}
                                            {/* <ul className="social list-unstyled list-inline">
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                        </ul> */}
                                        </div>
                                        : ''}
                                    <div className="about-content">
                                        {/* <h4 className="about-title">{Datas.title}</h4>
                                        <p className="about-para">{Datas.desc1}<span>{Datas.desc2}</span></p> */}
                                        {tutorInfo !== "" ?
                                            <Row>
                                                <Col sm="4">
                                                    <div className="counter-box box1 text-center">
                                                        <h3><CountUp end={tutorInfo.progsTaughtCnt} duration={2} delay={1} /><i className="las la-plus"></i></h3>
                                                        <p>Classes taught</p>
                                                    </div>
                                                </Col>
                                                <Col sm="4">
                                                    <div className="counter-box box2 text-center">
                                                        <h3><CountUp end={tutorInfo.teacherEvlCnt} duration={2} delay={1} /><i className="las la-plus"></i></h3>
                                                        <p>Student evaluation performed</p>
                                                    </div>
                                                </Col>
                                                <Col sm="4">
                                                    <div className="counter-box box3 text-center">
                                                        <h3><CountUp end={tutorInfo.videosWatched} duration={2} delay={1} /><i className="las la-plus"></i></h3>
                                                        <p>Video tutorials administered</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            : <Row> <Col md="12" className="text-center"> <Spinner className="centered-spinner" animation="border" variant="dark" /> </Col> </Row>}
                                    </div>
                                </Col>
                                <Col md="8">
                                    {tutorImgName !== "" ?
                                        <div className="instructor-content">
                                            <h4>{tutorImgName.user_name}</h4>
                                            <span>Senior Teacher</span>
                                    {tutorInfo && tutorInfo.scholasticInfo !== null ? <p dangerouslySetInnerHTML={{ __html: tutorInfo.scholasticInfo.teacher_description }}></p> : <p>{this.state.dummyText}</p>}

                                        </div>
                                        : ''}
                                        <br />
                                    <div className="qual-expe d-flex">
                                        <div className="qualification">
                                            <h5>Qualifications</h5>
                                            <div className="qual-expe-box">
                                                <h6>Classroom Management Level 1</h6>
                                                <p>Efficient planning for Online Clases</p>
                                                <p>Conducting dynamic classes Online </p>
                                                <p>Ending and evaluating an Online Class</p>
                                                <p>Learning Platforms complimenting Online Classes</p>
                                            </div>
                                            {/* <div className="qual-expe-box">
                                                <h6>SZABIST</h6>
                                                <p>Masters of Computer Science & Engeniering</p>
                                            </div> */}
                                        </div>
                                        {/* <div className="experiance">
                                            <h5>Experiance</h5>
                                            <div className="qual-expe-box">
                                                <h6>SnazzyTheme.com</h6>
                                                <p>2016 - 2019</p>
                                            </div>
                                            <div className="qual-expe-box">
                                                <h6>Envato Market</h6>
                                                <p>2019 - Present</p>
                                            </div>
                                        </div> */}
                                    </div>
                                </Col>
                                <Col md="12">
                                    {/* <div className="instructor-course-title">
                                        <h5>Courses being taught by Ayesha Khalid</h5>
                                    </div> */}
                                    {/* <TeacherCourseSlider tutorImgName={tutorImgName} relatedCourses={tutorInfo.relatedCourses} /> */}
                                </Col>
                            </Row>
                        </Container>
                        <TestimonialSlider />
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

function mapStateToProps(state) {
    return {
        setTutorImgID: state.webRed.setTutorImgID,
        singleTutorInfo: state.webRed.singleTutorInfo,
        allTutorsInfo: state.webRed.allTutorsInfo
    };
}
export default connect(mapStateToProps, { getSingleTutorInfo })(InstructorDetails);