import React, { Component } from "react";
import { Container, Row, Col, Spinner, Modal, Button, Form } from "react-bootstrap";
import { Styles } from "./styles/numberCounter.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class NeedTutor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classesConduct: 0,
      classesSlide: [],
      startCount: true,
      gradesData: "",
      grades: [],
      filteredCourses: {},
      sortedCourses: "",
      selectedGrade: "",
      showSubjectDropdown: false,
      courseName: "",
      curriculumName: "",
      openMultipleSubjects: false,
      selectedSubjects: [],
      showCurriculum: false,
      curriculumList: [
        {
          id: 1,
          curriculumName: "UK: Cambridge (O & A Level)",
        },
        {
          id: 2,
          curriculumName: "UK: GSCE",
        },
        {
          id: 3,
          curriculumName: "UK: IGSCE",
        },
        {
          id: 4,
          curriculumName: "US: Common Core",
        },
        {
          id: 5,
          curriculumName: "Canada: Cambridge",
        },
        {
          id: 6,
          curriculumName: "China: Chinese National Curriculum",
        },
        {
          id: 7,
          curriculumName: "France: The Common Foundation of Knowledge, Skills and Culture",
        },
        {
          id: 8,
          curriculumName: "India: CBSE",
        },
        {
          id: 9,
          curriculumName: "India: CISCE",
        },
        {
          id: 10,
          curriculumName: "Pakistan: Matric",
        },
        {
          id: 11,
          curriculumName: "Phillipines",
        },
        {
          id: 12,
          curriculumName: "Poland: Matura",
        },
        {
          id: 13,
          curriculumName: "UAE: KHDA",
        },
      ],

      subjectList: [
        {
          id: 1,
          subjectName: "Mathematics",
        },
        {
          id: 2,
          subjectName: "Urdu",
        },
        {
          id: 3,
          subjectName: "Islamiat",
        },
        {
          id: 4,
          subjectName: "History",
        },
        {
          id: 5,
          subjectName: "Geography",
        },
        {
          id: 6,
          subjectName: "Urdu",
        },
        {
          id: 7,
          subjectName: "English",
        },
        {
          id: 8,
          subjectName: "Computer Studies",
        },
        {
          id: 9,
          subjectName: "Science",
        },
        {
          id: 10,
          subjectName: "Biology",
        },
        {
          id: 11,
          subjectName: "Chemistry",
        },
        {
          id: 12,
          subjectName: "Physics",
        },
        {
          id: 13,
          subjectName: "Accounts",
        },
        {
          id: 14,
          subjectName: "Economics",
        },
        {
          id: 15,
          subjectName: "Business Studies",
        },
        {
          id: 16,
          subjectName: "Creative Writing",
        },
      ],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.gradesData !== nextProps.demoGrades) {
      debugger;
      const { demoGrades } = nextProps;
      var sortedCoursesArray = [];
      var coursesKeys = Object.keys(demoGrades.gradescourses);
      coursesKeys.map((course) => {
        var gradeArray = [];
        var courseKey = Object.keys(demoGrades.gradescourses[course]);
        var gradeName = demoGrades.gradescourses[course][courseKey[0]].grade;
        courseKey.map((_course) => {
          var thisCourse = demoGrades.gradescourses[course][_course];
          gradeArray.push(thisCourse);
        });
        sortedCoursesArray.push({
          grade: gradeName,
          courses: gradeArray,
          visible: true,
        });
      });
      return {
        gradesData: demoGrades,
        grades: coursesKeys,
        sortedCourses: sortedCoursesArray,
      };
    }
    return null;
  }

  gradeClicked = (e) => {
    // window.scrollTo(0, 60); //scroll to top
    // var element = document.getElementById("box");

    // element.scrollIntoView();
    debugger;
    if (e !== "") {
      this.setState({
        showSubjectDropdown: true,
        selectedGrade: e.target.value,
      });
    } else {
      this.setState({
        showSubjectDropdown: false,
        selectedGrade: e.target.value,
      });
    }
  };
  courseCLicked = (e) => {
    debugger;
    if (this.state.courseName !== "" && this.state.courseName !== "Multiple Subjects") {
      this.state.sortedCourses.map((_grade) => {
        // console.log(grade);
        if (_grade.grade == this.state.selectedGrade) {
          console.log(_grade);
          debugger;
          _grade.courses.map((course) => {
            if (
              course.course_name.indexOf(this.state.courseName) > -1 &&
              !course.course_name.includes("AI") && !course.course_name.includes("Homework")
            ) {
              this.props.history.push("/course-details/" + course.id);
              const { selectedSubjects, curriculumName } = this.state;
              const data = { selectedSubjects, curriculumName };
              localStorage.setItem("curriculumInfo", JSON.stringify(data));
            }
          });
        }
      });
    } else {
      this.setState({ openMultipleSubjects: true });
    }
  };

  selectedSubjectsFunction = (checkValue, data) => {
    if (checkValue) {
      this.setState({
        selectedSubjects: [...this.state.selectedSubjects, data],
      });
    } else {
      var filterData = this.state.selectedSubjects.filter((item) => item.id !== data.id);
      this.setState({
        selectedSubjects: filterData,
      });
    }
  };

  redirectToCourse = () => {
    const { selectedSubjects, curriculumName } = this.state;
    const data = { selectedSubjects, curriculumName };
    localStorage.setItem("curriculumInfo", JSON.stringify(data));
    console.log("Data", data);
    this.props.history.push("course-details/xeDaK7aYZ7M8PdL");
  };

  render() {
    const {
      curriculumName,
      grades,
      openMultipleSubjects,
      selectedGrade,
      courseName,
      curriculumList,
      subjectList,
      selectedSubjects,
      showCurriculum
    } = this.state;
    console.log("Subjects", this.state.selectedSubjects);
    return (
      <Styles>
        {/* Counter Area */}
        <Modal show={openMultipleSubjects} centered fullscreen="true">
          {/* <Modal.Header closeButton>
            <Modal.Title>Available Subjects</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <label className="text-success mt-2" style={{ fontSize: 16 }}>
              Select as many subjects from the list:
            </label>
            <div className="p-4">
              <Row className="justify-content-center">
                {subjectList.map((item, index) => (
                  <Col key={index} md="12" className="">
                    <input
                      type="checkbox"
                      className="check-box"
                      style={{ appearance: "auto" }}
                      onClick={(e) => this.selectedSubjectsFunction(e.target.checked, item)}
                    />
                    <label className="ml-2">{item.subjectName}</label>
                  </Col>
                ))}
              </Row>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <a
                className="mt-2"
                onClick={() => this.setState({ openMultipleSubjects: false })}
                style={{ color: "#000", cursor: "pointer" }}
              >
                <i className="ml-2 fas fa-arrow-left"> Back </i>
              </a>
              <button
                className=""
                disabled={selectedSubjects.length > 0 ? false : true}
                onClick={this.redirectToCourse}
                style={
                  selectedSubjects.length > 0
                    ? {
                        fontSize: 12,
                        color: "rgb(255, 255, 255)",
                        background:
                          "linear-gradient(90deg, rgb(17, 182, 122) 0%, rgb(0, 148, 68) 100%)",
                        display: "inline-block",
                        height: "40px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontWeight: "500",
                        padding: "11px",
                        borderRadius: "5px",
                        position: "relative",
                        cursor: "pointer",
                      }
                    : {
                        fontSize: 12,
                        color: "rgb(255, 255, 255)",
                        background: "#C7C2BE",
                        display: "inline-block",
                        height: "40px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontWeight: "500",
                        padding: "11px",
                        borderRadius: "5px",
                        position: "relative",
                        cursor: "pointer",
                      }
                }
              >
                {" "}
                Join 1 <strong style={{ textDecoration: "underline" }}>Free</strong> Class{" "}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <section
          className="counter-area"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Images for Homepage//)`,
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col md="12" className="">
                <div className="sec-title text-center">
                  <h3>Find an online Tutor</h3>
                  <label className="text-light">
                    for homework help, or exam prep, or to get ahead
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center mt-4">
              <Col md="4" xs="8">
                <label className="text-light">I need a tutor for:</label>
                {grades.length !== 0 ? (
                  <select className="custom-select" name="staffId" onChange={this.gradeClicked}>
                    <option value="">Select a Grade</option>
                    {grades.length &&
                      grades.map((grade, index) =>
                        grade == "General" || grade == "Pre-K" ? null : (
                          <option key={index} value={grade}>
                            {grade}
                          </option>
                        )
                      )}
                  </select>
                ) : (
                  <React.Fragment>
                    <br />
                    <Spinner className="centered-spinner" animation="border" variant="light" />
                  </React.Fragment>
                )}
              </Col>
            </Row>
            {/* <Row className="justify-content-center mt-4">
              <Col md="4" xs="8">
                <label className="text-light">Available curriculums:</label>
                {curriculumList.length !== 0 ? (
                  <select
                    className="custom-select"
                    name="staffId"
                    onChange={(e) => this.setState({ curriculumName: e.target.value })}
                  >
                    <option value="">Select a Curriculum</option>
                    {curriculumList.length &&
                      curriculumList.map((grade, index) => (
                        <option key={index} value={grade.curriculumName}>
                          {grade.curriculumName}
                        </option>
                      ))}
                  </select>
                ) : (
                  <React.Fragment>
                    <br />
                    <Spinner className="centered-spinner" animation="border" variant="light" />
                  </React.Fragment>
                )}
                {selectedGrade !== "" && curriculumName === "" ? (
                  <span className="text-danger">*Required</span>
                ) : null}
              </Col>
            </Row> */}
            <Row className="justify-content-center mt-4">
              <Col md="4" xs="8">
                <label className="text-light">I need help in:</label>
                <select
                  className="custom-select"
                  onChange={(e) =>
                    this.setState({
                      courseName: e.target.value,
                    })
                  }
                >
                  <option>Select a Subject</option>
                  {this.state.selectedGrade !== "" ? (
                    <React.Fragment>
                      <option value="English">English</option>
                      <option value="Math">Math</option>
                    </React.Fragment>
                  ) : null}
                  {this.state.selectedGrade == "Grade 2" ||
                  this.state.selectedGrade == "Grade 3" ||
                  this.state.selectedGrade == "Grade 4" ||
                  this.state.selectedGrade == "Grade 5" ||
                  this.state.selectedGrade == "Grade 6" ||
                  this.state.selectedGrade == "Grade 7" ||
                  this.state.selectedGrade == "Grade 8" ||
                  this.state.selectedGrade == "Grade 9" ? (
                    <option value="Creative Writing">Creative Writing</option>
                  ) : null}

                  {/* {
                                        this.state.selectedGrade == "Grade 9" ||
                                            this.state.selectedGrade == "Grade 10" ?
                                            <option value="Chemistry">Chemistry</option>

                                            : null

                                    } */}

                  {this.state.selectedGrade == "Kindergarten" ||
                  this.state.selectedGrade == "Grade 1" ||
                  this.state.selectedGrade == "Grade 2" ||
                  this.state.selectedGrade == "Grade 3" ||
                  this.state.selectedGrade == "Grade 4" ||
                  this.state.selectedGrade == "Grade 5" ||
                  this.state.selectedGrade == "Grade 6" ||
                  this.state.selectedGrade == "Grade 7" ||
                  this.state.selectedGrade == "Grade 8" ? (
                    <option value="Science">Science</option>
                  ) : null}
                  <option value="Multiple Subjects">Multiple Subjects</option>
                </select>
                {selectedGrade !== "" && courseName === "" ? (
                  <span className="text-danger">*Required</span>
                ) : null}
              </Col>
            </Row>
            <Row className="justify-content-center mt-4">
              <Col md="4" xs="8">
                <label
                  onClick={() =>
                    this.setState({ showCurriculum: !showCurriculum })
                  }
                  className="text-light"
                >
                  Select your own curriculum{" "}
                 {showCurriculum ? <i className="fa fa-chevron-up ml-2"/> : <i className="fa fa-chevron-down ml-2"></i> }
                </label>
                {showCurriculum && curriculumList.length !== 0 && (
                  <select
                    className="custom-select"
                    name="staffId"
                    onChange={(e) =>
                      this.setState({ curriculumName: e.target.value })
                    }
                  >
                    <option value="">Select a Curriculum</option>
                    {curriculumList.length &&
                      curriculumList.map((grade, index) => (
                        <option key={index} value={grade.curriculumName}>
                          {grade.curriculumName}
                        </option>
                      ))}
                  </select>
                )}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="4" xs="8" className="">
                <button
                  className={
                    courseName !== "" && selectedGrade !== ""
                      ? "findButton"
                      : "findButtonDisabled"
                  }
                  disabled={
                    courseName !== "" && selectedGrade !== ""
                      ? false
                      : true
                  }
                  onClick={this.courseCLicked}
                >
                  Find{" "}
                  {courseName !== "" && selectedGrade !== "" ? (
                    <i className="ml-2 fas fa-arrow-right"></i>
                  ) : null}{" "}
                </button>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <Marquee classesSlide={this.state.classesSlide} /> */}
      </Styles>
    );
  }
}

const mapStateToProps = (state) => ({
  coursesDataOnlineSchool: state.webRed.coursesDataOnlineSchool,
  demoGrades: state.webRed.demoGrades,
});
export default withRouter(connect(mapStateToProps, null)(NeedTutor));
