import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, FormGroup, Image } from "react-bootstrap";
import SelectSearch from "react-select-search";
import countryData from "./../../actions/countryData";
import { validatePhone } from "./../../actions/utility";
import { sendPhoneNumber } from "../../actions/webRedActions";
import { connect } from "react-redux";
import { Styles } from "./styles/login.js";

const PhoneNumber = (props) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectValue, setselectValue] = useState("PK");
  const [phonecode, setphonecode] = useState("92");
  const [phonenumber, setphonenumber] = useState("");
  const [phoneError, setphoneError] = useState(false);
  const [tutorData, settutorData] = useState("");

  useEffect(() => {
    var teacherData = JSON.parse(localStorage.getItem("tutor-data"));
    settutorData(teacherData);
    var defaultLocation = localStorage.getItem("Country-Code");
    var defaultCallingCode = countryData.filter((item) => item.alpha2Code === defaultLocation);
    setselectValue(defaultLocation);
    setphonecode(defaultCallingCode[0].callingCodes[0]);
    setSelectOptions(
      countryData.map((country, index) => {
        return {
          id: index,
          name: country.name,
          value: country.alpha2Code,
          callingCode: country.callingCodes[0],
          flag: country.flag,
          alpha2Code: country.alpha2Code,
        };
      })
    );
  }, []);

  useEffect(() => {}, [phoneError]);
  const updateValue = (val) => {
    setselectValue(val);
    selectOptions.map((country) => {
      if (country.value == val) {
        setphonecode(country.callingCode);
      }
    });
  };
  const nextButtonClick = () => {
    var thisPhone = phonecode + phonenumber;
    if (!validatePhone(thisPhone)) {
      setphoneError(
        "Enter a valid number. Dash, dot or any other special characters are not applicable. ex: +923456123568"
      );
    } else {
      setphoneError(false);
      debugger;
      props.sendPhoneNumber(thisPhone, 0);
    }
  };
  console.log("PhoneCode", phonecode);
  console.log("selectValue", selectValue);
  return (
    <Styles>
      <div className="PhoneNumber">
        <Container>
          <Row>
            <Col className="text-center">
              <div className="teacherSection text-center">
                {tutorData && (
                  <>
                    <label>Your class is being setup with:</label>
                    <div className="text-center">
                      <img
                        src={
                          tutorData !== "" && Object.keys(tutorData).length !== 0
                            ? tutorData[0].img_src
                            : "https://pngimage.net/wp-content/uploads/2018/06/no-user-image-png.png"
                        }
                        alt=""
                        className="tutorImg img-fluid"
                      />
                      <br />
                      <span>
                        {tutorData !== "" && Object.keys(tutorData).length !== 0
                          ? tutorData[0].name
                          : null}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <h4>What number should we call you at?</h4>

              {/* <p style={{ textAlign: 'center', fontWeight: '600', marginBottom: '7px', color: "#182B49" }}>
                                Select your Country & Enter your phone number
                            </p> */}

              <SelectSearch
                search={true}
                options={selectOptions}
                value={selectValue}
                name="countryCode"
                placeholder="Choose your Country"
                onChange={(e) => {
                  if (e && e != null && e != undefined) {
                    updateValue(e);
                  }
                }}
              />
              {selectValue && selectValue != "" ? (
                <Row className="phoneStyle">
                  <Col xs="4">
                    <FormGroup style={{ position: "relative" }}>
                      {selectOptions.map((country, key) => {
                        if (country.value == selectValue) {
                          return (
                            <div key={key}>
                              <Image
                                className="flagImg"
                                object
                                src={`https://flagcdn.com/h20/${country.alpha2Code.toLowerCase()}.png`}
                                alt="no-img"
                              />
                              <input
                                className="form-control"
                                disabled
                                type="text"
                                name="callingCode"
                                id="callingCode"
                                value={"+" + country.callingCode}
                              ></input>
                            </div>
                          );
                        }
                      })}
                    </FormGroup>
                  </Col>
                  <Col xs="8">
                    <FormGroup style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        type="tel"
                        name="callingNum"
                        id="callingNum"
                        onChange={(e) => setphonenumber(e.target.value)}
                      />
                    </FormGroup>
                    <hr />
                  </Col>
                </Row>
              ) : null}

              {phoneError ? <p style={{ color: "red", fontSize: "12px" }}>{phoneError}</p> : null}

              {phonecode !== "" && phonenumber !== "" ? (
                <Button
                  className="nextButtonNew"
                  onClick={nextButtonClick}
                  size="lg"
                  color="secondary"
                  block
                >
                  Next
                  <i className="fas fa-arrow-right" color="#fff" />
                </Button>
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    </Styles>
  );
};

export default connect(null, { sendPhoneNumber })(PhoneNumber);
