import React, { useState, useEffect } from 'react';
import { Modal, Container, Row, Col } from "react-bootstrap";
import LoginForm from "./LoginForm";
import store from './../../store';
import "./styles/enroll.css"
import SignUpForm from './SignUpForm';


const LoginComponent = (props) => {
    const [showPopup, setshowPopup] = useState(false);
    const [isLoginForm, setisLoginForm] = useState(false);
    const [signupFormType, setsignupFormType] = useState("normal");
    const [isCredentialActive, setisCredentialActive] = useState(0);
    const [extraFields, setextraFields] = useState([]);

    useEffect(() => {
        if (props.showPopup !== false && props.showPopup !== showPopup) {
            setshowPopup(true);
            if (localStorage.getItem('loginType') === 'normal') {
                setisLoginForm(true);
            }
            else {
                setisLoginForm(false);
                var typehere = store.getState().webRed.SignUpFormType;
                if (typehere) {
                    setsignupFormType(TypeError.formFor);
                }
            }
        }
        else {
            setshowPopup(false);
        }
    }, [props.showPopup])

    const toggleLoginModal = () => {
        setshowPopup(!showPopup);
    }
    return (
        <div className="LoginComponent">
            <Modal size={signupFormType !== 'normal' ? "lg" : "md"} className="loginPopupMain" show={showPopup} centered>

                <Modal.Body>
                    <div>
                        <Container>
                            <p className="closeModalLogin" onClick={toggleLoginModal}>X</p>
                            <Row>
                                <Col md="12">
                                    {
                                        isLoginForm ?
                                            <LoginForm />
                                            :
                                            <React.Fragment>
                                                <p className="anchorLink" onClick={() => setisLoginForm(!isLoginForm)}>(Login if you already have an account)</p>
                                                <hr />
                                                <SignUpForm formType={signupFormType} isCredentialActive={isCredentialActive} extraFields={extraFields} />
                                            </React.Fragment>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal.Body>
            </Modal>
        </div>);
}

export default LoginComponent;