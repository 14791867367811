import store from "../store";

// all these utility methods are for whole app. generalization purpose

export const getAppUrl = () => {
  // var appUrl = "http://lincolnmethod.com/" // depends on white label
  // var appUrl = "https://neuro-lincoln.local/";
  var appUrl = "https://stag-dashboard.lincolnmethod.com/";
  return appUrl;
};

export const getBaseUrl = () => {
  var baseUrl = "https://dashboard.lincolnmethod.com"; // depends on white label
  // var baseUrl = "https://stag-dashboard.lincolnmethod.com";
  // var baseUrl = "https://neuro-lincoln.local";
  return baseUrl;
};

export const getNestUrl = () => {
  var baseUrl = "https://lm-api-nest.skillupz.com"; // depends on white label
  return baseUrl;
};

export const getImgURL = () => {
  var imgUrl = "https://lincolnmethod.s3.us-east-1.amazonaws.com/profileImages/"; // Amazon folder URL
  return imgUrl;
};

export const validateEmailAddress = (email) => {
  // validates email address (return boolean)
  var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(email);
};

export const validatePhone = (phone) => {
  // validates email address (return boolean)
  var regex =
    /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/;
  return regex.test(phone);
};

export const getGradeId = (courseId) => {
  var allCoursesGrades = store.getState().webRed.coursesData.coursesData.filteredCourses;
  var selected_grade = "";
  var gradeId = 0;

  try {
    Object.keys(allCoursesGrades).map((grade) => {
      var gradeKeys = Object.keys(allCoursesGrades[grade]);
      var ifExistsCourse = gradeKeys.includes(courseId);
      // console.log(ifExistsCourse)
      if (ifExistsCourse) {
        selected_grade = grade;
        // console.log(selected_grade)
        var allGrades = store.getState().webRed.coursesData.grades;
        // console.log(allGrades)
        allGrades.map((thisGrade) => {
          if (thisGrade.grade == selected_grade) {
            // console.log(thisGrade)
            gradeId = thisGrade.id;
          }
        });
      }
    });
  } catch (e) {
    console.log(e);
  }

  return gradeId;
};

//extract the client id from clients array
export const getCurrentClient = () => {
  // console.log(store.getState().webRed.allclientslist)
  // var clientsList = store.getState().webRed.allclientslist;
  // if(clientsList && clientsList.length > -1){
  //     var path = window.location.pathname;
  //     if(path.indexOf('/c/') > -1){
  //         var pathArray = path.split('/c/');
  //         if(pathArray[1].indexOf('/') > -1){
  //             var pathArray2 = pathArray[1].split('/');
  //             clientsList.map((client) => {
  //                 if(client.client_url == pathArray2[0]){
  //                     return client;
  //                 }
  //             })
  //         }
  //         else{
  //             clientsList.map((client) => {
  //                 if(client.client_url == pathArray[1]){
  //                     return client;
  //                 }
  //             })
  //         }
  //     }
  //     else{
  //         return clientsList[1];
  //     }
  // }

  return {
    id: 2,
    store_id: 1,
    currency: 1,
    client_name: "Lincoln Method",
    client_url: "store",
  };
};

export const formatPrice = (x) => {
  try {
    if (x && x != undefined && x != null) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else if (x == 0) {
      return x;
    }
  } catch (e) {
    console.log("formatPrice:", e);
    return 0;
  }
};

export const logOut = () => {
  localStorage.setItem("loginData", ""); // clear loginData localstorage
  return true;
};

export const isMobileBrowser = () => {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const reserveBackOnMobile = (check) => {
  if (check) {
    window.location.hash = "reserve";
    window.onhashchange = function (event) {
      window.location.hash = "reserve";
    };
  } else {
    window.onhashchange = function (event) {
      window.location.hash = "";
    };
  }
};

export const updateLastLinkStorage = () => {
  // update the url in localstorage
  localStorage.setItem(
    "lastLinkHistory",
    localStorage.getItem("currentLink") &&
      localStorage.getItem("currentLink") != null &&
      localStorage.getItem("currentLink") != "" &&
      localStorage.getItem("currentLink") != ""
      ? localStorage.getItem("currentLink")
      : ""
  );

  localStorage.setItem("currentLink", window.location.pathname);
};

export const getLastLinkStorage = () => {
  // update the url in localstorage
  if (
    localStorage.getItem("lastLinkHistory") &&
    localStorage.getItem("lastLinkHistory") != null &&
    localStorage.getItem("lastLinkHistory") != "" &&
    localStorage.getItem("lastLinkHistory") != ""
  ) {
    return localStorage.getItem("lastLinkHistory");
  }
};

// utilities for graph statistics

export const ObjectToArray = (objectData, callback) => {
  let objectKeys = Object.keys(objectData);

  let data = [];
  var counter = 1;
  objectKeys.map((key, index) => {
    data.push(objectData[key]);
    if (objectKeys.length == counter) {
      callback(data);
    }
    counter++;
  });
};

export const years = ["2020", "2019", "2018"];
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const onTrainTutorLink = () => {
  if (window.location.href.includes("/WZ4pK7KlyNkVvd6")) {
    return true;
  } else {
    return false;
  }
};

export const checkTutorImg = (imgLink) => {
  if (imgLink.includes("lincolnmethod.s3")) {
    return imgLink;
  } else {
    return `https://lincolnmethod.s3.us-east-1.amazonaws.com/profileImages/${imgLink}`;
  }
};
