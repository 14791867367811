import React, { useEffect } from "react";
import { Styles } from "./styles/language.js";

const TranslatePhone = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      { pageLanguage: "en" },
      "google_translate_element"
    );
  };
  const changeLanguageByButtonClick = (language) => {
    // var language = document.getElementById("language").value;
    var selectField = document.querySelector(
      "#google_translate_element select"
    );
    for (var i = 0; i < selectField.children.length; i++) {
      var option = selectField.children[i];
      // find desired langauge and change the former language of the hidden selection-field
      if (option.value == language) {
        selectField.selectedIndex = i;
        // trigger change event afterwards to make google-lib translate this side
        selectField.dispatchEvent(new Event("change"));
        break;
      }
    }
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <Styles>
      <div>
        <label className="text-white">Change language to: </label>
      </div>
      <div className="languageBtn2 d-flex">
        <div id="google_translate_element" style={{ display: "none" }}></div>

        <div>
          <button
            className=""
            onClick={() => changeLanguageByButtonClick("sv")}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/images/sw.png"}
              alt=""
            />{" "}
            Sv
          </button>
        </div>
        <div>
          <button
            className=""
            onClick={() => changeLanguageByButtonClick("fi")}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/images/fi.png"}
              alt=""
            />{" "}
            Fi
          </button>
        </div>
        <div>
          <button
            className=""
            onClick={() => changeLanguageByButtonClick("en")}
          >
            {" "}
            <img
              src={process.env.PUBLIC_URL + "/assets/images/us.png"}
              alt=""
            />{" "}
            En
          </button>
        </div>
      </div>
    </Styles>
  );
};

export default TranslatePhone;
