import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .education-mama {
        .instructor-area {
            // padding: 70px 0;
            // padding: 0;


            .seperator {
                background: ${colors.gr_bg2};
                width: 100%;
                height: 5px;
            }
            .SomeOneRiseContent{
                background: ${colors.gr_bg};
                position: relative;
                top: -50px;
                padding: 5vh 0;
                text-align: center;
                border-radius: 5px;
                h4 {
                    color: #fff;
                }
            }
            .KidsRiseContent {
                background: ${colors.bg1};
                padding: 15vh 0;
                text-align: center;
                i {
                    color: #fff;
                    font-size: 60px;
                    margin-top: 30px;
                }
                img {
                    margin-top: 30px;
                }
                h3 {
                    color: #fff;
                }

                h5 {
                    font-weight: 300;
                    color: #fff;
                    margin-top: 20px;   
                }

                @media(max-width: 767px) {
                    padding: 10vh 0;
                }
            }
            .gallery-box {
                border-radius : 5px;
                overflow: hidden;
                margin-bottom: 30px;
                position: relative;
                img {
                    transition : all 0.3s ease;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
            .headingBanner {
                padding: 40px 0;
                background: ${colors.gr_bg2};
                line-height: 2;

                label {
                    color: #fff;
                    font-size: 30px;
                    text-shadow: 2px 3px 15px rgba(0,0,0,0.7);
                }

                h1 {
                    color: #fff;
                    font-weight: bold;
                }
                h2 {
                    color: #fff;
                    font-weight: bold;
                }

                @media(max-width: 767px) {
                    padding: 20px 0;

                   label {
                     font-size: 20px;
                   }
                   h2 {
                    font-size: 26px;
                   }
                       
                }
            }
            .headingBannerBgImage {
                padding: 60px 0px;
                background-size    : 888px;
                background-position: right;
                background-repeat  : no-repeat;
                height             : 500px;
                // margin-bottom: 20px;
                
                label {
                    color: ${colors.text2};
                    font-size: 23px;
                    // text-shadow: 2px 3px 15px rgba(0,0,0,0.7);
                    }
                .subImageBanner{
                    display: none;
                }
                .gallery-box {
                    border-radius : 5px;
                    overflow: hidden;
                    margin-bottom: 30px;
                    position: relative;
                    img {
                        transition : all 0.3s ease;
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
                label.greenStrap {
                    font-size: 40px;
                    color: #fff;
                    padding: 5px;
                    background-color: ${colors.green};
                    vertical-align: middle;

                    @media(max-width: 767px) {
                        font-size: 26px;
                    }
                }
                h1 {
                    @media(max-width: 767px) {
                        font-size: 26px;
                    }
                }
                ul {
                    margin-top : 30px;
                    li {
                        font-size: 16px;
                        color: ${colors.text3};
                        margin-bottom: 10px;
                        i {
                            // float: left;
                            color: ${colors.green};
                            border: 1px solid ${colors.border3};
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            text-align: center;
                            padding-top: 9px;
                            // margin-top: 8px;
                            margin-right: 15px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .yellowBoxText {
                    background: ${colors.bg1};
                    padding: 20px;
                
                    label {
                    color: #fff;
                    font-size: 20px;
                    // text-shadow: 2px 3px 15px rgba(0,0,0,0.7);
                    }

                    h1 {
                    color: #fff;
                    font-weight: bold;
                    }
                    h2 {
                    color: #fff;
                    font-weight: bold;
                    }
                }
                @media(max-width: 767px) {
                    padding: 40px 0;
                    height             : auto;
                    background-image: none !important;

                    .subImageBanner{
                        height: 281px;
                        background-position: right;
                        background-size: 500px;
                        background-repeat: no-repeat;
                        display: block;
                    }

                }
            }
            .centered-spinner {
                width: 40px;
                height: 40px;
            }
            .instructor-item {
                position     : relative;
                margin-bottom: 80px;

                a {
                    img {
                        border-radius : 5px;
                    }
                }

                .img-content {
                    background: #ffffff;
                    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.07);
                    position  : absolute;
                    bottom    : -60px;
                    left      : 10%;
                    width     : 80%;
                    z-index   : 1;
                    border-radius : 5px;
                    padding: 15px 0;

                    h5 {
                        margin-bottom: 5px;
                        a {
                            color        : ${colors.black2};
                            font-weight  : 600;
                            &:hover {
                                color : ${colors.green};
                            }
                        }
                    }

                    p {
                        font-size    : 14px;
                        color        : ${colors.text3};
                        font-weight  : 500;
                        margin-bottom: 5px;
                    }

                    ul {
                        li {
                            a {
                                i {
                                    font-size: 14px;
                                    color    : #ffffff;
                                    width    : 33px;
                                    height   : 33px;
                                    border-radius : 50%;
                                    padding-top: 10px;
                                    transition : all 0.2s ease;

                                    &:hover {
                                        background-color: ${colors.green} !important;
                                    }
                                }

                                i.fa-facebook-f {
                                    background-color: #4267B2;
                                }

                                i.fa-twitter {
                                    background-color: #1DA1F2;
                                }

                                i.fa-youtube {
                                    background-color: #DD1343;
                                }
                            }
                        }
                    }
                }
            }

            @media(max-width: 767px) {
                padding : 40px 0 30px;
            }
        }

        .hero-image-area {
            background-size    : cover;
            background-position: center;
            background-repeat  : no-repeat;
            height             : 700px;
            position           : relative;
    
            &::before {
                position  : absolute;
                content   : "";
                background: ${colors.black1};
                opacity   : 0.6;
                width     : 100%;
                height    : 100%;
                top       : 0;
                left      : 0;
            }
    
            .round-shape {
                background-position: bottom;
                background-repeat  : no-repeat;
                background-size    : contain;
                position           : absolute;
                bottom             : 0;
                left               : 0;
                width              : 100%;
                height             : 100%;
            }
    
            .hero-table {
                display : table;
                width   : 100%;
                height  : 100%;
                position: relative;
                z-index : 2;
    
                .hero-tablecell {
                    display       : table-cell;
                    vertical-align: middle;
    
                    .hero-box {
    
                        h1 {
                            font-size    : 46px;
                            color        : #ffffff;
                            max-width    : 700px;
                            margin       : auto;
                            margin-bottom: 20px;
                            font-weight  : 600;
    
                            @media(max-width: 767px) {
                                font-size: 26px;
                                font-weight: 500;
                                max-width: 100%;
                            }
                        }
    
                        p {
                            font-size    : 16px;
                            color        : ${colors.border3};
                            max-width    : 600px;
                            line-height  : 30px;
                            margin       : auto;
                            margin-bottom: 60px;
    
                            @media(max-width: 575px) {
                                font-size : 15px;
                                line-height : 25px;
                            }
                        }
    
                        .video-player {
    
                            button.play-button {
                                transform : translateX(0) translateY(-50%);
                                box-sizing: content-box;
                                display   : block;
                                width     : 32px;
                                height    : 44px;
                                margin    : auto;
                                border-radius : 50%;
    
                                i {
                                    position   : relative;
                                    font-size  : 40px;
                                    color      : #ffffff;
                                    z-index    : 11;
                                    padding-top: 2px;
                                    margin-left: -2px;
                                }
    
                                &::before {
                                    content   : "";
                                    position  : absolute;
                                    z-index   : 0;
                                    left      : 50%;
                                    top       : 50%;
                                    transform : translateX(-50%) translateY(-50%);
                                    display   : block;
                                    width     : 70px;
                                    height    : 70px;
                                    background: ${colors.green};
                                    border-radius : 50%;
                                    animation: pulse-border 1500ms ease-out infinite;
                                }
    
                                &:after {
                                    content   : "";
                                    position  : absolute;
                                    z-index   : 1;
                                    left      : 50%;
                                    top       : 50%;
                                    transform : translateX(-50%) translateY(-50%);
                                    display   : block;
                                    width     : 70px;
                                    height    : 70px;
                                    background: ${colors.green};
                                    border-radius : 50%;
                                    transition : all 200ms;
                                }
    
                                &:hover {
                                    i {
                                        color: #ffffff;
                                    }
                                }
    
                                @keyframes pulse-border {
                                    0% {
                                        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                                        opacity  : 1;
                                    }
    
                                    100% {
                                        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                                        opacity  : 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
    
            @media(max-width: 767px) {
                height : 450px;
            }
    
            @media(max-width: 575px) {
                height : 360px;
            }
        }

    }

    .trainTutor {
        .subImageBanner{
            display: none;
        }
        .bg-image-slides {
            padding: 100px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            .logo {
                width: 200px;
            }

            p {
                font-size: 20px;
            }

            .headingTutor {
                color: ${colors.finlandColor};
            }

            .mainHeading {
                font-size: 4rem;
            }

            .signUpButtonTutor {
                background: transparent;
                border: 2px solid ${colors.finlandColor};
                color: ${colors.finlandColor};
                padding: 10px;
                font-size: 15px;

                &:hover {
                    color : #ffffff;
                    background : ${colors.finlandColor};
                }
            }
            .signUpButtonTutorWhite {
                background: #fff;
                border: 2px solid ${colors.finlandColor};
                color: ${colors.finlandColor};
                padding: 10px;
                font-size: 15px;

                &:hover {
                    color : #ffffff;
                    background : #000;
                }
            }

            .purpleBox {
                padding: 20px 20px 20px 20px;
                border: 2px solid #EFEDEC;
            }
            .whiteBox {
                margin-bottom: 50px;
                width: 80%;
                text-align: center;
                background-color: #fff;
                padding: 10px;
                cursor: pointer;

                &:hover {
                    background-color: #000;
                    color: #fff;
                }
            }
            .subImageBanner{
                display: none;
            }

            ul {
                margin-top : 30px;
                li {
                    font-size: 20px;
                    // color: ${colors.text3};
                    margin-bottom: 10px;
                    i {
                        // float: left;
                        color: ${colors.finlandColor};
                        border: 1px solid ${colors.border3};
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        text-align: center;
                        padding-top: 9px;
                        // margin-top: 8px;
                        margin-right: 15px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            @media(max-width: 767px) {
                padding: 40px 0;
                height             : auto;
                background-image: none !important;

                .mainHeading {
                    font-size: 3rem;
                }
                .logo {
                    width: 100px;
                }
                .purpleBox {
                    p {
                        font-size: 16px;
                    }
                }
                .signUpButtonTutor {
                    font-size: 12px;
                }

                .signUpButtonTutorWhite {
                    font-size: 12px;
                }

                .centerOnMobile {
                    text-align: center;
                }

                h1 {
                    font-size: 2rem;
                }

                h3 {
                    font-weight: normal;
                    font-size: 16px;
                }

                p {
                    font-size: 16px;
                }

                .makingDivs {
                    margin-top: 10px;
                }

                .whiteBox {
                    font-size: 2rem;
                    width: 100%;
                    margin-bottom: 20px;
                }

                .subImageBanner{
                    margin-top:30px;
                    height: 280px;
                    background-position: right;
                    background-size: 170%;
                    background-repeat: no-repeat;
                    display: block;
                }
                ul {
                    margin-top : 30px;
                    li { 
                        font-size: 16px;
                    }
                }

            }
        }
                @media(max-width: 767px) {
                    .subImageBanner{
                        height: 280px;
                        background-position: right;
                        background-size: 170%;
                        background-repeat: no-repeat;
                        display: block;
                    }
                }
        .bg-image-slides-2 {
            height: 700px;
            padding: 50px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            .priceSection {
                text-align: right;
                align-items: end;
                 h1 {
                    // text-decoration: line-through;
                    text-decoration-color: #000;
                 }
            }

            .signUpButtonTutor {
                background: transparent;
                border: 2px solid ${colors.finlandColor};
                color: ${colors.finlandColor};
                padding: 10px;
                font-size: 15px;

                &:hover {
                    color : #ffffff;
                    background : ${colors.finlandColor};
                }
            }

            .signUpButtonTutorWhite {
                background: #fff;
                border: 2px solid ${colors.finlandColor};
                color: ${colors.finlandColor};
                padding: 10px;
                font-size: 15px;

                &:hover {
                    color : #ffffff;
                    background : #000;
                }
            }

            .purpleBox {
                padding: 20px 20px 20px 20px;
                border: 2px solid ${colors.green};
            }
            .whiteBox {
                margin-bottom: 100px;
                width: 80%;
                text-align: center;
                background-color: #fff;
                padding: 10px;
            }
            .subImageBanner{
                display: none;
            }

            ul {
                margin-top : 30px;
                li {
                    font-size: 16px;
                    color: ${colors.text3};
                    margin-bottom: 10px;
                    i {
                        // float: left;
                        color: ${colors.green};
                        border: 1px solid ${colors.border3};
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        text-align: center;
                        padding-top: 9px;
                        // margin-top: 8px;
                        margin-right: 15px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            @media(max-width: 767px) {
                padding: 40px 0;
                height             : auto;
                background-image: none !important;

                h1 {
                    font-size: 2rem;
                }

                h3 {
                    font-size: 18px;
                }

                .makingDivs {
                    margin-top: 10px;
                }

                .whiteBox {
                    font-size: 2rem;
                }
                .priceSection {
                    text-align: right;
                    align-items: center;
                }

                .subImageBanner{
                    margin-top:30px;
                    height: 280px;
                    background-position: right;
                    background-size: 170%;
                    background-repeat: no-repeat;
                    display: block;
                }

            }
        }
    }

    .instructor-details-page {
        .instructor-details-area {
            padding : 70px 0 63px;

            .about-content {
                h4.about-title {
                    color        : ${colors.black1};
                    line-height  : 35px;
                    font-weight  : 600;
                    margin-bottom: 25px;
    
                    @media(max-width: 575px) {
                        margin-bottom: 15px;
                        font-size: 20px;
                    }
                }
    
                p.about-para {
                    font-size    : 15px;
                    color        : ${colors.text3};
                    line-height  : 25px;
                    margin-bottom: 40px;
    
                    span {
                        display   : block;
                        margin-top: 20px;
                    }
    
                    @media(max-width: 575px) {
                        margin-bottom : 20px;
                    }
                }
    
                .counter-box {
                    h3 {
                        margin-bottom: 10px;
    
                        span {
                            font-weight: 600;
                        }
    
                        i {
                            font-size     : 20px;
                            vertical-align: middle;
                        }
                    }
    
                    p {
                        font-size  : 14px;
                        color      : ${colors.text3};
                        font-weight: 500;
                    }
    
                    // @media(max-width: 575px) {
                    //     display : none;
                    // }
                }
    
                .counter-box.box1 {
                    h3 {
                        color: ${colors.red};
                    }
                }
    
                .counter-box.box2 {
                    h3 {
                        color: ${colors.purple};
                    }
                }
    
                .counter-box.box3 {
                    h3 {
                        color: ${colors.blue};
                    }
                }
    
                a.readmore-btn {
                    font-size : 14px;
                    color     : #fff;
                    background: ${colors.gr_bg};
                    display   : inline-block;
                    width     : 145px;
                    height    : 40px;
                    text-align: center;
                    padding   : 11px;
                    border-radius : 5px;
                    margin-top: 40px;
    
                    &:hover {
                        background: ${colors.gr_bg2};
                    }
    
                    @media(max-width: 575px) {
                        margin-top : 0;
                    }
                }
            }
    
            @media(max-width: 767px) {
                padding: 30px 0 40px;
            }

            .instructor-img {
                margin-bottom : 55px;
                img {
                    border-radius : 5px;
                    margin-bottom : 20px;
                }
                ul.getintouch {
                    margin-bottom: 30px;
                    li {
                        font-size: 20px;
                        color: ${colors.black2};
                        font-weight : 500;
                        margin-bottom: 5px;
                        i {
                            font-size: 26px;
                            color: ${colors.green};
                            vertical-align: text-bottom;
                            margin-right: 5px;

                            @media(max-width: 991px) {
                                font-size: 20px;
                            }
                        }

                        @media(max-width: 991px) {
                            font-size: 16px;
                        }
                    }
                }

                ul.social {
                    li {
                        margin-right: 10px;
                        a {
                            text-align: center;
                            position  : relative;
                            height    : 18px;
                            display   : inline-block;

                            &:before {
                                content           : "";
                                position          : absolute;
                                border-width      : 9px 17px;
                                border-style      : solid;
                                border-top-color  : transparent;
                                border-right-color: transparent;
                                border-left-color : transparent;
                                top               : -18px;
                                left              : 0;
                                z-index           : 1;
                                transition : all 0.2s ease;
                            }

                            &:after {
                                content            : "";
                                position           : absolute;
                                border-width       : 9px 17px;
                                border-style       : solid;
                                border-right-color : transparent;
                                border-bottom-color: transparent;
                                border-left-color  : transparent;
                                bottom             : -18px;
                                left               : 0;
                                z-index            : 1;
                                transition : all 0.2s ease;
                            }

                            i {
                                font-size: 14px;
                                color    : #ffffff;
                                width    : 34px;
                            }

                            &:hover {
                                background-color: ${colors.green} !important;

                                &:before {
                                    border-bottom-color: ${colors.green} !important;
                                }

                                &:after {
                                    border-top-color: ${colors.green} !important;
                                }
                            }
                        }

                        &:nth-child(1) {
                            a {
                                background-color: #4267B2;

                                &:before {
                                    border-bottom-color: #4267B2;
                                }

                                &:after {
                                    border-top-color: #4267B2;
                                }
                            }
                        }

                        &:nth-child(2) {
                            a {
                                background-color: #1DA1F2;

                                &:before {
                                    border-bottom-color: #1DA1F2;
                                }

                                &:after {
                                    border-top-color: #1DA1F2;
                                }
                            }
                        }

                        &:nth-child(3) {
                            a {
                                background-color: #2867B2;

                                &:before {
                                    border-bottom-color: #2867B2;
                                }

                                &:after {
                                    border-top-color: #2867B2;
                                }
                            }
                        }

                        &:nth-child(4) {
                            a {
                                background-color: #DD1343;

                                &:before {
                                    border-bottom-color: #DD1343;
                                }

                                &:after {
                                    border-top-color: #DD1343;
                                }
                            }
                        }

                        &:nth-child(5) {
                            a {
                                background-color: #ea4c89;

                                &:before {
                                    border-bottom-color: #ea4c89;
                                }

                                &:after {
                                    border-top-color: #ea4c89;
                                }
                            }
                        }

                        &:last-child {
                            margin-right : 0;
                        }
                    }
                }

                @media(max-width: 575px) {
                    margin-bottom: 30px;
                }
            }

            .instructor-content {
                h4 {
                    color : ${colors.black1};
                    font-weight: 600;
                    margin-bottom: 10px;

                    @media(max-width: 575px) {
                        font-size : 20px;
                    }
                }
                span {
                    font-size : 16px;
                    color : ${colors.green};
                    font-weight: 500;
                    display : inline-block;
                    margin-bottom: 15px;

                    @media(max-width: 575px) {
                        font-size : 15px;
                    }
                }
                p {
                    font-size: 15px;
                    color: ${colors.text2};
                    line-height: 25px;
                    // margin-bottom: 40px;

                    @media(max-width: 575px) {
                        font-size : 14px;
                    }
                }
            }
            .qual-expe {
                h5 {
                    color: ${colors.black2};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }


                }
                .qual-expe-box {
                    margin-bottom: 20px;
                    h6 {
                        color: ${colors.black2};
                        margin-bottom: 8px;
                        font-weight: 600;

                        @media(max-width: 575px) {
                            font-size : 15px;
                        }
                    }
                    p {
                        font-size : 15px; 
                        color: ${colors.text3};

                        @media(max-width: 575px) {
                            font-size : 14px;
                        }
                    }
                }
                .qualification {
                    margin-right : 100px;

                    @media(max-width: 575px) {
                        margin-right : 20px;
                    }
                }

                @media(max-width: 360px) {
                    display: block !important;
                }
            }

            .instructor-course-title {
                h5 {
                    color: ${colors.black2};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 35px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
            .instructor-course-slider {
                position: relative;
                .course-item {
                    border: 1px solid ${colors.border1};
                    border-radius : 5px;
                    transition : all 0.2s ease;
                    margin-bottom: 30px;

                    .course-image {
                        width              : 100%;
                        height             : 220px;
                        background-size    : cover;
                        background-position: center;
                        background-repeat  : no-repeat;
                        border-radius : 5px 5px 0 0;
                        position: relative;

                        .author-img {
                            position: absolute;
                            left    : 20px;
                            bottom  : 20px;

                            img {
                                max-width: 40px;
                                border-radius : 50%;
                                margin-right: 5px;
                            }

                            .title {
                                background: #ffffff;
                                padding   : 3px 8px;
                                border-radius : 5px;

                                p {
                                    font-size    : 12px;
                                    color        : ${colors.black1};
                                    font-weight  : 500;
                                    margin-bottom: -4px;
                                }

                                span {
                                    font-size  : 11px;
                                    color      : ${colors.text3};
                                    font-weight: 500;
                                }
                            }

                        }

                        .course-price {
                            p {
                                font-size  : 16px;
                                color      : #ffffff;
                                background : ${colors.bg1};
                                position   : absolute;
                                right      : 20px;
                                bottom     : 20px;
                                padding    : 8px 10px;
                                font-weight: 500;
                                border-radius : 5px;
                            }
                        }
                    }

                    .course-content {
                        background: #fff;
                        padding   : 20px 25px;
                        border-radius : 0 0 5px 5px;

                        h6.heading {
                            a {
                                color        : ${colors.black1};
                                font-weight  : 600;
                                display      : inline-block;
                                margin-bottom: 12px;

                                &:hover {
                                    color: ${colors.green};
                                }
                            }
                        }

                        p.desc {
                            font-size     : 14px;
                            color         : ${colors.text3};
                            line-height   : 25px;
                            border-bottom : 1px solid ${colors.border1};
                            padding-bottom: 10px;
                            margin-bottom : 12px;
                        }

                        .course-face {

                            .duration,
                            .student {
                                p {
                                    font-size: 13px;
                                    color    : ${colors.text3};

                                    i {
                                        font-size     : 16px;
                                        color         : ${colors.green};
                                        vertical-align: text-bottom;
                                        margin-right  : 3px;
                                    }
                                }
                            }

                            .rating {
                                ul {
                                    li {
                                        margin-right: 0;

                                        i {
                                            font-size: 14px;
                                            color    : ${colors.yellow};
                                        }

                                        &:last-child {
                                            font-size: 13px;
                                            color    : ${colors.text3};
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
                    }
                }

                .slider-dot {
                    margin-top: 17px !important;

                    .swiper-pagination-bullet {
                        width     : 22px;
                        height    : 9px;
                        background: ${colors.text4};
                        display   : inline-block;
                        margin    : 3px;
                        opacity   : 1 !important;
                        border-radius : 5px;
                    }

                    .swiper-pagination-bullet.swiper-pagination-bullet-active {
                        background: ${colors.green};
                    }
                }
            }

            @media(max-width: 767px) {
                padding: 40px 0 30px;
            }
        }

    }
`;