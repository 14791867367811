import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";

//importing component for Websites
import ScrollToTop from "./helper/ScrollToTop";
import { GlobalStyle } from "./components/common/styles/global.js";
import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import DemoHome from "./DemoHome";
import About from "./pages/about/About";
import CourseGrid from "./pages/courses/CourseGrid";
import CourseList from "./pages/courses/CourseList";
import CourseDetails from "./pages/courses/CourseDetails";
import Instructor from "./pages/instructor/Instructors";
import InstructorDetails from "./pages/instructor/InstructorDetails";
import Gallery from "./pages/gallery/Gallery";
import Events from "./pages/events/Events";
import EventDetails from "./pages/events/EventsDetails";
import Login from "./pages/account/Login";
import Register from "./pages/account/Register";
import Contact from "./pages/contact/Contact";
import Faq from "./pages/faq/Faq";
import PageNotFound from "./pages/404/PageNotFound";
import ComingSoon from "./pages/comingsoon/ComingSoon";
import BlogClassic from "./pages/blog/BlogClassic";
import BlogGrid from "./pages/blog/BlogGrid";
import BlogDetails from "./pages/blog/BlogDetails";
import Product from "./pages/shop/Products";
import ProductDetails from "./pages/shop/ProductDetails";
import Cart from "./pages/shop/Cart";
import UnitedNationsComponent from "./pages/partnership/UnitedNationsComponent";
import PrivacyPolicy from "./components/PrivacyPolicy";
import LicenseAgreementComponent from "./components/LicenseAgreementComponent";
import RefundPolicyComponent from "./components/RefundPolicyComponent";
import ResearchComponent from "./components/ResearchComponent";
import WalloffameComponent from "./pages/walloffame/WalloffameComponent";
import Reports from "./pages/reports/Reports";
import SlumBlockComponent from "./pages/partnership/SlumBlockComponent";
import FasionDesignComponent from "./pages/partnership/FashionDesignComponent";
import EducationComptonComponent from "./pages/partnership/EducationComptonComponent";
import AllTestimonials from "./components/AllTestimonials";
import EducationMamas from "./pages/instructor/EducationMamas";
import Welcome from "./pages/welcome/Welcome";
import OnlineSchool from "./pages/onlineSchool/OnlineSchool";
import CareersOnlineTeacherComponent from "./pages/careers/CareersOnlineTeacherComponent";
import OnlineClassRules from "./pages/careers/OnlineClassRules";
import InvestWithUs from "./pages/careers/InvestWithUs";
import CourseDetailsV2 from "./pages/courses/CourseDetailsV2";
import TermsAndCondition from "./components/TermsAndCondition";
import SchoolsTerms from "./components/TermsSchools.js";
import AllVideosTestimonials from "./components/AllVideoTestimonials";
import KGSHome from "./KGSHome";
import MarketingPage from "./components/MarketingPage";
import KnockontheDoor from "./components/KnockontheDoor";
import ConfirmDemo from "./components/ConfirmDemo";
import OurTeam from "./pages/instructor/OurTeam";
import CareersThanksForApplying from "./components/CareerThanksForApplying";
import TraintheTutors from "./pages/instructor/TraintheTutors";
import zambianTeachers from "./pages/instructor/zambianTeachers";
import LoginPage from "./pages/urls/Login.js";
import AddUrl from "./pages/urls/AddUrl.js";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div>
          <GlobalStyle />
          <ScrollToTop />
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={HomeTwo} />
            <Route path={`${process.env.PUBLIC_URL + "/true/:mobileemail/:pushToken?"}`} component={HomeTwo} />
            <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} component={HomeOne} />
            <Route path={`${process.env.PUBLIC_URL + "/KGS"}`} component={KGSHome} />
            <Route path={`${process.env.PUBLIC_URL + "/demo-home"}`} component={DemoHome} />
            <Route path={`${process.env.PUBLIC_URL + "/about"}`} component={About} />
            <Route path={`${process.env.PUBLIC_URL + "/course-grid"}`} component={CourseGrid} />
            <Route path={`${process.env.PUBLIC_URL + "/course-list"}`} component={CourseList} />
            {/* <Route path={`${process.env.PUBLIC_URL + "/course-details/:courseid"}`} component={CourseDetails} /> */}
            <Route
              path={`${process.env.PUBLIC_URL + "/course-details/:courseid"}`}
              component={CourseDetailsV2}
            />
            <Route path={`${process.env.PUBLIC_URL + "/instructor"}`} component={Instructor} />
            <Route
              path={`${process.env.PUBLIC_URL + "/instructor-details"}`}
              component={InstructorDetails}
            />
            <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} component={Gallery} />
            <Route path={`${process.env.PUBLIC_URL + "/events"}`} component={Events} />
            <Route path={`${process.env.PUBLIC_URL + "/event-details"}`} component={EventDetails} />
            <Route path={`${process.env.PUBLIC_URL + "/login"}`} component={Login} />
            <Route path={`${process.env.PUBLIC_URL + "/registration"}`} component={Register} />
            <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact} />
            <Route path={`${process.env.PUBLIC_URL + "/faq"}`} component={Faq} />
            <Route path={`${process.env.PUBLIC_URL + "/404"}`} component={PageNotFound} />
            <Route path={`${process.env.PUBLIC_URL + "/coming-soon"}`} component={ComingSoon} />
            <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} component={BlogClassic} />
            <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} component={BlogGrid} />
            <Route path={`${process.env.PUBLIC_URL + "/blog-details"}`} component={BlogDetails} />
            <Route path={`${process.env.PUBLIC_URL + "/products"}`} component={Product} />
            <Route
              path={`${process.env.PUBLIC_URL + "/product-details"}`}
              component={ProductDetails}
            />
            <Route path={`${process.env.PUBLIC_URL + "/cart"}`} component={Cart} />
            <Route path="/all-testimonials" component={AllTestimonials} />

            {/* Lincoln Method Routes */}
            <Route path="/un-nations-sdg" component={UnitedNationsComponent} />
            <Route path="/slum-block-millionaire" component={SlumBlockComponent} />
            <Route path="/fashion-design-shout-outs" component={FasionDesignComponent} />
            <Route path="/educate-compton" component={EducationComptonComponent} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/license-agreement" component={LicenseAgreementComponent} />
            <Route path="/refund-policy" component={RefundPolicyComponent} />
            <Route path="/research" component={ResearchComponent} />
            <Route path="/wall-of-fame" component={WalloffameComponent} />
            <Route path="/reports" component={Reports} />
            <Route path="/education-mama" component={EducationMamas} />
            <Route path="/courses" component={OnlineSchool} />
            <Route path="/welcome-to-online-school" component={Welcome} />
            <Route
              path="/careers/online-school-teachers"
              component={CareersOnlineTeacherComponent}
            />
            <Route path="/online-class-rules" component={OnlineClassRules} />
            <Route path="/how-to-invest-with-us" component={InvestWithUs} />
            <Route path="/terms-and-conditions" component={TermsAndCondition} />
            <Route path="/license-agreement-for-schools" component={SchoolsTerms} />
            <Route path="/AllVideosTestimonials" component={AllVideosTestimonials} />
            <Route path="/DM" component={MarketingPage} />
            <Route path="/CSR" component={KnockontheDoor} />
            <Route path="/join-the-class/:id" component={ConfirmDemo} />
            <Route path="/OurTeam" component={OurTeam} />
            <Route path="/careers/thanks-for-applying" component={CareersThanksForApplying} />
            <Route
              path="/careers/finland-teacher-certification-program"
              component={TraintheTutors}
            />
            <Route path="/careers/teacher-training-zambian" component={zambianTeachers} />
            <Route path="/urllogin" component={LoginPage} />
            <Route path="/url/add" component={AddUrl} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
