import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
// import Search from './common/Search';
// import Sidebar from './common/Sidebar';
// import StickyMenu from './common/StickyMenu';
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/headerTwo.js";
import {
  getTutorsInfo,
  getAllCourses,
  getAllOnlineSchoolCourses,
  triggerCheckoutPopup,
  triggerLoginPopup,
  getDemoGrades,
  paymentCashOnDelivery,
  enrollInFreeCourse
} from "../actions/webRedActions";
import { connect } from "react-redux";
import store from "../store";
import EnrollComponent from "../pages/login/EnrollComponent";
import { ToastContainer } from "react-toastify";
import { getBaseUrl, isMobileBrowser, logOut, updateLastLinkStorage } from "../actions/utility";
import "react-toastify/dist/ReactToastify.css";
import LoginComponent from "../pages/login/LoginComponent";
import CheckoutPaymentComponent from "../pages/checkout/CheckoutPaymentComponent";
import SamplePage2 from "./SamplePage2";
// import SamplePage from './SamplePage';

class HeaderTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teachersData: [],
      showLoginPopup: false,
      isLoggedIn: false,
      showEnrollmentPopup: false,
      showCheckoutPopup: false,
      loggedInUserName: "",
    };
  }

  setLoggedInUser() {
    var loginStatus = localStorage.getItem("loginData");
    if (loginStatus && loginStatus != null && typeof JSON.parse(loginStatus) === "object") {
      this.setState({
        isLoggedIn: true,
        loggedInUserName: JSON.parse(loginStatus).user.user_name,
      });
    }
  }

  logout = () => {
    if (logOut()) {
      // calls logout from utility
      this.setState({
        isLoggedIn: false,
      });
      // window.location.reload();
    }
  };

  openLoginModal2 = () => {
    // this.toggle(); // closes the menu
    localStorage.setItem("loginType", "normal"); // set loginType normal to load login popup with normal forms
    try {
      var loginStatus = localStorage.getItem("loginData");
      if (loginStatus != null && typeof JSON.parse(loginStatus) === "object") {
        this.setState({
          isLoggedIn: true,
        });
      } else {
        this.props.triggerLoginPopup(); // triggers the login modal
      }
    } catch (err) {
      this.props.triggerLoginPopup(); // triggers the login modal
    }
  };

  componentDidMount() {
    document.title = "Lincoln Method";

    if (!store.getState().webRed.coursesData.grades) {
      // check if data exists it'll not call action
      this.props.getAllCourses(); //getting data for all courses
      localStorage.setItem("t-s", Date.now());
    }

    if (!store.getState().webRed.allTutorsInfo) {
      // check if data exists it'll not call action
      this.props.getTutorsInfo(); //getting data for all courses
    }

    if (!store.getState().webRed.coursesDataOnlineSchool.grades) {
      // check if data exists it'll not call action
      this.props.getAllOnlineSchoolCourses(); //getting data for all online school courses
    }

    if (!store.getState().webRed.demoGrades) {
      // check if data exists it'll not call action
      this.props.getDemoGrades(); //getting data for all online school courses
    }

    const mobileView = localStorage.getItem("mobileapp");
    if (!mobileView) {
      this.setLoggedInUser();
    }

    //update history links in storage
    updateLastLinkStorage();

    fetch("https://extreme-ip-lookup.com/json/?key=jSGX525k1kRnzcChmLTI")
      .then((res) => res.json())
      .then((response) => {
        if (response.countryCode !== "") {
          debugger;
          console.log("Country: ", response.countryCode);
          localStorage.setItem("Country-Code", response.countryCode);
        } else {
          localStorage.setItem("Country-Code", "PK");
        }
      })
      .catch((data, status) => {
        console.log("Request failed");
        localStorage.setItem("Country-Code", "PK");
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.showLoginPopupRed != prevProps.showLoginPopupRed) {
      // gets and opens popup for login
      this.setState({
        showLoginPopup: this.props.showLoginPopupRed,
      });
    }

    if (prevProps.showEnrollmentPopupRed != this.props.showEnrollmentPopupRed) {
      this.setState({
        showEnrollmentPopup: this.props.showEnrollmentPopupRed,
      });
    }

    if (this.props.showCheckoutPopupRed != prevProps.showCheckoutPopupRed) {
      // gets and opens popup for checkout
      debugger;
      this.setState({
        showCheckoutPopup: this.props.showCheckoutPopupRed,
      });
    }

    if (this.props.loginSuccessNormal != prevProps.loginSuccessNormal) {
      debugger;
      if (
        typeof this.props.loginSuccessNormal === "object" &&
        this.props.loginSuccessNormal.status == "success"
      ) {
        this.setState(
          {
            showLoginPopup: false,
            showEnrollmentPopup: false,
          },
          () => {
            console.log(this.props.loginSuccessNormal);
            var loginData = localStorage.getItem("loginData");
            if (loginData && loginData != null && typeof JSON.parse(loginData) === "object") {
              if (JSON.parse(loginData).user.user_type == 1) {
                // if it's admin user
                // window.location = getBaseUrl() + "/programs/" + JSON.parse(loginData).user.id;
                window.location = `https://feeds.lincolnmethod.com/#/${
                  JSON.parse(loginData).user.id
                }`;
              } else {
                debugger;
                this.setLoggedInUser();
                try {
                  var userId = JSON.parse(localStorage.getItem("loginData")).user.id;
                  // window.location = getBaseUrl() + '/home/' + userId;
                  window.location = `https://feeds.lincolnmethod.com/#/${
                    JSON.parse(loginData).user.id
                  }`;
                } catch (error) {
                  console.error("there error occured while redirected to user profile:", error);
                }
                // window.location = getBaseUrl() + "/welcome/" + JSON.parse(loginData).user.id;
                // window.location = window.location.protocol + "//" + window.location.host + '/course-grid';
              }
            }
          }
        );
      }
    }

    if (this.props.loginSuccessWithCourse != prevProps.loginSuccessWithCourse) {
      if (
        typeof this.props.loginSuccessWithCourse === "object" &&
        this.props.loginSuccessWithCourse.status == "success"
      ) {
        debugger;
        var selected = store.getState().webRed.courseDetail;
        this.setState(
          {
            selectedCourse: selected.course,
            showLoginPopup: false,
            showEnrollmentPopup: false,
          },
          () => {
            var loginData = localStorage.getItem("loginData");
            if (loginData && loginData != null && typeof JSON.parse(loginData) === "object") {
              if (JSON.parse(loginData).user.user_type == 1) {
                // if it's admin user
                // window.location = getBaseUrl() + "/programs/" + JSON.parse(loginData).user.id;
                window.location = `https://feeds.lincolnmethod.com/#/${
                  JSON.parse(loginData).user.id
                }`;
              } else {
                const mobileView = localStorage.getItem("mobileapp");
                if (!mobileView) {
                  this.setLoggedInUser();
                }
                debugger;
                if (
                  localStorage.getItem("demoAfterLogin") &&
                  localStorage.getItem("demoAfterLogin") != null &&
                  localStorage.getItem("demoAfterLogin") != "false"
                ) {
                  var courseId = localStorage.getItem("demoAfterLogin");
                  localStorage.setItem("demoAfterLogin", "false");
                  // window.location = getBaseUrl() + "/takedemo/" + courseId + "/" + JSON.parse(loginData).user.id;
                  window.location = `https://feeds.lincolnmethod.com/#/${
                    JSON.parse(loginData).user.id
                  }`;
                } else {
                  const mobileView = localStorage.getItem("mobileapp");
                  debugger;
                  if (mobileView) {
                    var userid = JSON.parse(loginData).user.id;
                    var courseid = this.state.selectedCourse.id;
                    var contact_number = JSON.parse(loginData).phone_number;
                    var userEmail = JSON.parse(loginData)?.user?.user_email;
                    var userLoginName = JSON.parse(loginData)?.user?.user_loginname;
                    const userPassword = localStorage.getItem("userPassword");
                    this.props.enrollInFreeCourse(
                      userid,
                      courseid,
                      null,
                      0,
                      null,
                      0,
                      0,
                      contact_number,
                      1,
                      1,
                      1,
                      1,
                      1,
                      1,
                      1,
                      0,
                      null,
                      null
                    )
                    debugger;
                    window.postMessage(
                      JSON.stringify({
                        email: userEmail,
                        loginName: userLoginName,
                        password: userPassword,
                      })
                    );
                    localStorage.setItem("loginData", ""); // clear loginData localstorage
                    // window.location = `https://feeds.lincolnmethod.com/#/${
                    //   JSON.parse(loginData).user.id
                    // }`;
                  } else {
                    debugger;
                    var userid = JSON.parse(loginData).user.id;
                    var courseid = this.state.selectedCourse.id;
                    var contact_number = JSON.parse(loginData).phone_number;
                    var userEmail = JSON.parse(loginData)?.user?.user_email;
                    var userLoginName = JSON.parse(loginData)?.user?.user_loginname;
                    const userPassword = localStorage.getItem("userPassword");
                    this.props.enrollInFreeCourse(
                      userid,
                      courseid,
                      null,
                      0,
                      null,
                      0,
                      0,
                      contact_number,
                      1,
                      1,
                      1,
                      1,
                      1,
                      1,
                      1,
                      0,
                      null,
                      null
                    )
                    // window.postMessage(
                    //   JSON.stringify({
                    //     email: userEmail,
                    //     loginName: userLoginName,
                    //     password: userPassword,
                    //   })
                    // );
                    localStorage.setItem("loginData", ""); // clear loginData localstorage
                    window.location = `https://feeds.lincolnmethod.com/#/${
                      JSON.parse(loginData).user.id
                    }`;
                    //When User Signup from Course, This functions Run right after complete register (Sign Up)
                    // this.props.triggerCheckoutPopup(); // triggers the CheckOut modal
                  }
                }
                // this.props.triggerCheckoutPopup(); // triggers the login modal

                // this.setState({
                //   isLoggedIn : true
                // }, () => {
                //   this.props.triggerCheckoutPopup(); // triggers the login modal
                // })
              }
            }
          }
        );
      }
    }
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //     debugger;
  //     if (nextProps.showEnrollmentPopupRed != nextProps.showEnrollmentPopupRed) { // gets and opens popup for login
  //         debugger;
  //        return ({
  //            showEnrollmentPopup: nextProps.showEnrollmentPopupRed
  //        })
  //       }
  //     return null;
  //   }

  // componentWillReceiveProps(nextProps, thisProps) {
  //     debugger;
  //     console.log(nextProps)

  //     if (nextProps.coursesData != 'error' && this.props.coursesData != nextProps.coursesData) { // check if there's change in courses data
  //         debugger;
  //         // this.courseStateManagement(nextProps.coursesData);

  //     }

  // }
  render() {
    return (
      <Styles>
        {/* Topbar 2 */}
        <ToastContainer />

        <section className="top-bar2">
          <EnrollComponent showPopup={this.state.showEnrollmentPopup} />
          <LoginComponent showPopup={this.state.showLoginPopup} />
          <CheckoutPaymentComponent showPopup={this.state.showCheckoutPopup} />
          <Container>
            <Row>
              <Col lg="5" md="7">
                <div className="bar-left">
                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                      <i className="las la-phone"></i>+358 41 575 1803
                    </li>
                    <li className="list-inline-item">
                      <i className="las la-envelope"></i>hello@lincolnmethod.com
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="7" md="5">
                <div className="bar-right d-flex justify-content-end">
                  <ul className="list-unstyled list-inline bar-lang">
                    {/* <li className="list-inline-item"> */}
                    {/* <Dropdown>
                                                <Dropdown.Toggle><img src={process.env.PUBLIC_URL + "/assets/images/us.png"} alt="" />English<i className="las la-angle-down"></i></Dropdown.Toggle>
                                                <Dropdown.Menu as="ul">
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/us.png"} alt="" /> English</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/fra.png"} alt="" /> French</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/ger.png"} alt="" /> German</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/spa.png"} alt="" /> Spanish</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/bra.png"} alt="" /> Brazilian</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                    {!isMobileBrowser() ? (
                      <li className="list-inline-item">
                        <SamplePage2 />
                      </li>
                    ) : null}
                    {/* </li> */}
                  </ul>
                  <ul className="list-unstyled list-inline bar-social">
                    <li className="list-inline-item">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.facebook.com/LincolnMethod"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://twitter.com/LincolnMethod"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/company/lincoln-method/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/lincolnmethod/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.youtube.com/channel/UCPFRa4VViOC_6TWMaIqD_Dg"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>

                  {/* <ul className="list-unstyled list-inline sidebar-button">
                                        <li className="list-inline-item nav-item side-box">
                                            <Sidebar />
                                        </li>
                                    </ul> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Logo Area 2 */}
        <section className="logo-area2">
          <Container>
            <Row>
              <Col md="2">
                <div className="logo">
                  <Link to="/">
                    <img src="/assets/images/LM/logo.png" alt="" />
                  </Link>
                </div>
              </Col>
              <Col md="10">
                <div className="menu-box d-flex justify-content-end">
                  <ul className="nav menu-nav">
                    <li className="nav-item dropdown active">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        Home
                      </Link>
                      {/* <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Home Style 1</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/home-two"}>Home Style 2</Link></li>
                                            </ul> */}
                    </li>
                    {/* <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Pages <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/about"}>About Us</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/gallery"}>Gallery</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/login"}>Log In</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/registration"}>Registration</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/faq"}>Faq</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/404"}>404</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/coming-soon"}>Coming Soon</Link></li>
                                            </ul>
                                        </li> */}
                    <li className="nav-item dropdown">
                      {/* <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Method</Link> */}
                      {/* <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                                            </ul> */}
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/courses"}
                        data-toggle="dropdown"
                      >
                        Courses
                      </Link>
                      {/* <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                                            </ul> */}
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/instructor"}
                        data-toggle="dropdown"
                      >
                        Tutors
                      </Link>
                      {/* <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/instructor"}>Faculty</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/instructor-details"}>Faculty Details</Link></li>
                                            </ul> */}
                    </li>
                    {/* <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to="/events" data-toggle="dropdown">Event</Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/events"}>Events</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/event-details"}>Event Details</Link></li>
                                            </ul>
                                        </li> */}

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        PARTNERSHIPS<i className="las la-angle-down"></i>
                      </Link>
                      <ul className="dropdown list-unstyled">
                        <li className="nav-item">
                          <Link className="nav-link" to="/research">
                            Research
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/un-nations-sdg">
                            United Nations SDG
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to={"/slum-block-millionaire"}>
                            Slum-Block Millionaire
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to={"/fashion-design-shout-outs"}>
                            Fashion & Design shout-outs
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to={"/educate-compton"}>
                            Educate Compton
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="https://info.lincolnmethod.com/NTU-survey/"
                            target="_blank"
                          >
                            Textile Industry - Survey
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="https://info.lincolnmethod.com/NTU-results/"
                            target="_blank"
                          >
                            Textile Industry - Results
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="https://www.mparsec.com/"
                            target="_blank"
                          >
                            mPARSEC
                          </a>
                        </li>
                      </ul>
                    </li>
                    {/* <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Blog <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-classic"}>Blog Classic</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-grid"}>Blog Grid</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-details"}>Blog Details</Link></li>
                                            </ul>
                                        </li> */}
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/wall-of-fame"}
                        data-toggle="dropdown"
                      >
                        Success Stories
                      </Link>
                      {/* <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/products"}>Products</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/product-details"}>Product Details</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/cart"}>Cart</Link></li>
                                            </ul> */}
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/OurTeam"}
                        data-toggle="dropdown"
                      >
                        Our Team
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        target="__blank"
                        href="https://edu.lincolnmethod.com/"
                      >
                        Blogs
                      </a>
                    </li>
                    {/* <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Shop <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/products"}>Products</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/product-details"}>Product Details</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/cart"}>Cart</Link></li>
                                            </ul>
                                        </li> */}
                  </ul>
                  {/* <div className="search-box">
                                        <Search />
                                    </div> */}
                  {!this.state.isLoggedIn ? (
                    <div className="apply-btn">
                      {/* <a onClick={this.openLoginModal2}>
                        <i className="las la-clipboard-list"></i>Login
                      </a> */}
                      <a href="https://feeds.lincolnmethod.com/" target="_blank">
                        <i className="las la-clipboard-list"></i>Login
                      </a>
                    </div>
                  ) : (
                    // <p> {this.state.loggedInUserName} </p>
                    <div className="userDropdown">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {this.state.loggedInUserName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sticky Menu */}
        {/* <StickyMenu /> */}

        {/* Mobile Menu */}
        <MobileMenu />
      </Styles>
    );
  }
}

const mapStateToProps = (state) => ({
  showLoginPopupRed: state.webRed.showLoginPopupRed,
  showEnrollmentPopupRed: state.webRed.showEnrollmentPopupRed,
  showForgotPasswordRed: state.webRed.showForgotPasswordRed,
  showCheckoutPopupRed: state.webRed.showCheckoutPopupRed,
  loginSuccessNormal: state.webRed.loginSuccessNormal,
  loginSuccessWithCourse: state.webRed.loginSuccessWithCourse,
  successData: state.webRed.successData,
  showCongratulationsRed: state.webRed.showCongratulationsRed,
});
export default connect(mapStateToProps, {
  getTutorsInfo,
  getAllCourses,
  getAllOnlineSchoolCourses,
  triggerCheckoutPopup,
  triggerLoginPopup,
  getDemoGrades,
  paymentCashOnDelivery,
  enrollInFreeCourse
})(HeaderTwo);
