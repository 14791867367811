import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Modal, Table } from "react-bootstrap";
import { checkTutorImg, formatPrice } from "./../../../actions/utility";
import moment from "moment-timezone";

class OnlineSchoolCourseGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zone: "Asia/Karachi",
    };
  }
  componentDidMount() {
    var zone = moment.tz.guess();
    this.setState({
      zone,
    });
  }

  //TimeZone Functions Start
  AMPMTo24HourConversion = (times) => {
    var patt1 = /\s/g;
    var time = times;
    if (times.match(patt1)) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      // alert(sHours + ":" + sMinutes);
      return sHours + ":" + sMinutes;
    } else {
      if (time.includes("PM")) {
        time = time.replace("PM", " PM");
      } else if (time.includes("AM")) {
        time = time.replace("AM", " AM");
      }
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      // alert(sHours + ":" + sMinutes);
      return sHours + ":" + sMinutes;
    }
  };

  minutesToHour = (n) => {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rminutes == 0) {
      rminutes = "00";
    }
    console.log(num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).");
    return {
      rhours,
      rminutes,
    };
  };
  timeConvert24to12 = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };
  changeTimeZoneLocation = (time) => {
    debugger;
    var TIME = this.AMPMTo24HourConversion(time); // Convert 12 hour to 24 Hour Time System
    var convertedTimeinMinutes = moment.duration(TIME).asMinutes(); // Convert Hours into Minutes
    var makingTimeDifferenceZero = convertedTimeinMinutes - 300; // Subtract Pakistan's GMT time with Initial Time.
    var usersCountryOffset = moment().tz(this.state.zone).utcOffset(); // Get Client's UTCoffset by timezone
    var timeinMinutes = makingTimeDifferenceZero + usersCountryOffset; // Add Clients time by initial time
    var timeinHours = this.minutesToHour(timeinMinutes); // Convert minutes into Hour
    var ActualConvertedTime = "";
    if (timeinHours.rhours >= 12) {
      ActualConvertedTime = this.timeConvert24to12(`${timeinHours.rhours}:${timeinHours.rminutes}`); //Convert 24 Hours Back to 12 Hours
    } else {
      ActualConvertedTime = timeinHours.rhours + ":" + timeinHours.rminutes + " AM";
    }
    // console.log("Time Converted 24 to 12:", ActualConvertedTime)
    // console.log("Time in Minutes", timeinMinutes)
    // console.log("Time in Hours", timeinHours.rhours, timeinHours.rminutes)
    // console.log("User Country Offset", usersCountryOffset)
    // console.log("Converted Time in Minutes", convertedTimeinMinutes)
    // console.log("After Making Time Difference Zero", makingTimeDifferenceZero);
    // console.log("timein Hours", timeinHours.rhours, timeinHours.rminutes);
    return ActualConvertedTime;
  };
  //TimeZone Functions End

  showTeacherDetails = (details, name) => {
    let teachersArary = [];

    var details = JSON.parse(details);

    Object.keys(details).map((id, key) => {
      var teacher = details[id];
      let item = (
        <Fragment>
          {" "}
          <img
            src={checkTutorImg(teacher?.img_src)}
            // src={
            //   teacher.img_src &&
            //   teacher.img_src !== "" &&
            //   teacher.img_src !== "https://lincolnmethod.s3.us-east-1.amazonaws.com/profileImages/"
            //     ? teacher.img_src
            //     : "https://pngimage.net/wp-content/uploads/2018/06/no-user-image-png.png"
            // }
            alt="teacher-img"
            className="img-fluid shadow-lg"
          />
          <label>
            Taught by: <br /> {teacher?.name && teacher?.name != "" ? teacher?.name : ""}
          </label>
        </Fragment>
      );

      teachersArary.push(item);
    });

    return teachersArary;
  };
  returnListTimings = (gradeId, courseId) => {
    debugger;
    let daysArary = [];

    var timings = this.props.sortedCourses[gradeId].courses[courseId]?.onlineSchoolCourseDetail?.[0]?.Timing;
    timings = JSON.parse(timings);

    Object.keys(timings).map((id, key) => {
      var day = timings[id];
      if (day.start != "" && day.end != "") {
        var start_time = day.start;
        var firstCharStart = start_time.split("");
        if (firstCharStart[0] == "0") {
          start_time = start_time.substring(1);
        }

        var end_time = day.end;
        var firstCharEnd = end_time.split("");
        if (firstCharEnd[0] == "0") {
          end_time = end_time.substring(1);
        }

        var dayName = day.day.split("");

        let dayData = (
          <tr key={key}>
            <th style={{ padding: "10px 0" }}>{dayName[0] + dayName[1] + dayName[2]}</th>
            <td style={{ padding: "10px 0" }}>
              <i className="fas fa-clock mr-4 text-primary"></i>
              {this.changeTimeZoneLocation(start_time)} - {this.changeTimeZoneLocation(end_time)}
            </td>
          </tr>
        );
        daysArary.push(dayData);
      }
    });
    console.log(daysArary);
    return daysArary;
  };
  render() {
    const {
      sortedCourses,
      visibleGradeList,
      showPopupTime,
      popOverCourseId,
      popOverGradeId,
      toggleTimeTableModal,
    } = this.props;
    console.log("visibleGradeList", visibleGradeList);
    console.log("popvercourseId", popOverCourseId);
    console.log("popOverGradeId", popOverGradeId);
    return (
      <Fragment>
        {sortedCourses && sortedCourses.length ? (
          <Modal
            className="htmlModalMain"
            show={showPopupTime}
            onHide={(e) => toggleTimeTableModal(0, 0)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {sortedCourses[popOverGradeId].courses[popOverCourseId].course_name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label> Timezone: {this.state.zone} </label>
              <Table>
                <tbody>
                  {sortedCourses[popOverGradeId].courses[popOverCourseId]?.onlineSchoolCourseDetail?.[0]?.Timing &&
                  sortedCourses[popOverGradeId].courses[popOverCourseId]?.onlineSchoolCourseDetail?.[0]?.Timing != ""
                    ? this.returnListTimings(popOverGradeId, popOverCourseId)
                    : null}
                </tbody>
              </Table>
            </Modal.Body>
            {/* <Modal.Footer>
                                            <Button variant="secondary" onClick={this.openTimeTableModal}>
                                            Close
                                         </Button>
                                        <Button variant="primary" onClick={this.openTimeTableModal}>
                                            Save Changes
                                        </Button>
                                        </Modal.Footer> */}
          </Modal>
        ) : null}
        {/* Course Item */}
        {sortedCourses &&
          sortedCourses.map((course, key) => (
            <Fragment>
              {!visibleGradeList.length || visibleGradeList.includes(course.grade) ? (
                <Col md="12">
                  <h5 className="heading">{course.grade}</h5>
                  <hr />
                </Col>
              ) : null}
              {!visibleGradeList.length || visibleGradeList.includes(course.grade)
                ? course.courses &&
                  course.courses.map((_course, _key) => (
                    <Col lg="4" md="12" key={_key}>
                      <div className="course-item">
                        <Link to={`/course-details/${_course.id}`}>
                          <div
                            className="course-image"
                            style={{
                              backgroundImage:
                                _course.course_img && _course.course_img != null
                                  ? "url(" +
                                    "https://lm-neuro-src.s3.amazonaws.com/dashboard/public/uploads/" +
                                    _course.course_img +
                                    ")"
                                  : null,
                            }}
                          >
                            {/* <div className="author-img d-flex">
                                            <div className="img">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" />
                                            </div>
                                            <div className="title">
                                                <p>{data.authorName}</p>
                                                <span>{data.authorCourses}</span>
                                            </div>
                                        </div> */}
                            <div className="course-price">
                            <p>{_course.gradeAeTagValue}</p>
                            </div>
                          </div>
                        </Link>
                        <div className="course-content" style={{ minHeight: 200 }}>
                          <h6 className="heading">
                            <Link to={`/course-details/${_course.id}`}>{_course.course_name}</Link>
                          </h6>
                          {/* <p className="desc">{data.courseDesc}</p> */}
                          <Row>
                            <Col md="8">
                              <div className="course-info">
                                <ul className="list-unstyled">
                                  {_course.chapters && _course.chapters != 0 ? (
                                    <li>
                                      <i className="fas fa-book"></i>
                                      <label>{_course.chapters} Chapters</label>
                                    </li>
                                  ) : null}
                                  {_course.videos && _course.videos != 0 ? (
                                    <li>
                                      <i className="fas fa-video"></i>
                                      <label>{_course.videos} Video tutorials</label>
                                    </li>
                                  ) : null}
                                  {_course.is_online && _course.is_online != 0 ? (
                                    <li>
                                      <i className="fas fa-wifi"></i>
                                      <label>Online</label>
                                    </li>
                                  ) : null}
                                  {sortedCourses[key].courses[_key]?.onlineSchoolCourseDetail?.[0]?.Timing &&
                                  sortedCourses[key].courses[_key]?.onlineSchoolCourseDetail?.[0]?.Timing != "" ? (
                                    <span className="btn btn-light p-0">
                                      <li onClick={(e) => toggleTimeTableModal(key, _key)}>
                                        <i className="fas fa-calendar-alt"></i>
                                        <label> View Class timings</label>
                                      </li>
                                    </span>
                                  ) : null}
                                </ul>
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="course-instructor-img">
                                {_course?.onlineSchoolCourseDetail?.[0]?.teachers_info &&
                                _course?.onlineSchoolCourseDetail?.[0]?.teachers_info != undefined &&
                                _course?.onlineSchoolCourseDetail?.[0]?.teachers_info != ""
                                  ? this.showTeacherDetails(
                                      _course?.onlineSchoolCourseDetail?.[0]?.teachers_info,
                                      _course.course_name
                                    )
                                  : null}
                              </div>
                            </Col>
                          </Row>
                          {/* <div className="course-face d-flex justify-content-between">
                                        <div className="duration">
                                            <p><i className="las la-clock"></i>120</p>
                                        </div>
                                        <div className="rating">
                                            <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                </li>
                                                <li className="list-inline-item">(4.5)</li>
                                            </ul>
                                        </div>
                                        <div className="student">
                                            <p><i className="las la-chair"></i>60</p>
                                        </div>
                                    </div> */}
                        </div>
                        <Link to={`/course-details/${_course.id}`}>
                          <div style={{ backgroundColor: "#8C95A4" }} className="">
                            <button className="btn text-light" style={{ width: "40%", height: 50 }}>
                              View Class Details
                            </button>
                            <button
                              className="btn"
                              style={{ backgroundColor: "#676c75", width: "60%", height: 50 }}
                            >
                              <span style={{ fontSize: 16, margin: 5, color: "#FFF" }}>
                                {_course.currency ? _course?.currency : "PKR"}{" "}
                                {formatPrice(_course.course_price)}
                              </span>
                            </button>
                            {/* <button
                              className="btn"
                              style={{ backgroundColor: "#676c75", width: "60%", height: 50 }}
                            >
                              {_course.course_discount_price &&
                              (_course.course_discount_price != 0 ||
                                _course.course_discount_price == 0) ? (
                                <span className="text-light">
                                  <span
                                    className="outer"
                                    style={{ color: "red", textDecoration: "line-through" }}
                                  >
                                    <span style={{ color: "#fff" }}>
                                      {_course.currency ? _course?.currency : "PKR"}{" "}
                                      {formatPrice(_course.course_price)}
                                    </span>
                                  </span>
                                  <span style={{ fontSize: 16, margin: 5 }}>
                                    {_course.currency ? _course?.currency : "PKR"}{" "}
                                    {formatPrice(_course.course_discount_price)}
                                  </span>
                                </span>
                              ) : (
                                <span style={{ fontSize: 16, margin: 5, color: "#FFF" }}>
                                  {_course.currency ? _course?.currency : "PKR"}{" "}
                                  {formatPrice(_course.course_price)}
                                </span>
                              )}
                             
                            </button> */}
                          </div>
                        </Link>
                      </div>
                    </Col>
                  ))
                : null}
            </Fragment>
          ))}
      </Fragment>
    );
  }
}

export default OnlineSchoolCourseGrid;
