import React from "react";
import Datas from "../../data/walloffame/wallOfFame.json";
import DatasNew from "../../data/walloffame/wallOfFameNew.json";
import { Container, Row, Col } from "react-bootstrap";
import ModalImage from "react-modal-image";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import FooterTwo from "../../components/FooterTwo";
import { Link } from "react-router-dom";
import { Styles } from "../walloffame/styles/gallery.js";

const Certificate = (props) => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper gallery-page">
        <section className="gallery-page-area">
          <Container>
            <Row>
              <Col md="12" className="text-center">
                <h3 style={{ color: props.textColor }}>
                  Lincoln Method is a certified training body
                </h3>

                <img
                  src="/assets/images/LM/EduCertificate.jpeg"
                  className="img-fluid"
                />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default Certificate;
