import React, { Component } from 'react';
import Datas from '../../data/partnership/unitedNation.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/unitedNations.js';
import ModalVideo from 'react-modal-video';

class SlumBlockComponent extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }
    render() {

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper unitednations-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Slum-Block Millionaire" backgroundImage="" />

                    {/* Gallery Area */}
                    <section className="unitednations-page-area">
                        <Container>
                            <Row>
                                <Col lg="12" className='mb-5'>
                                    <img className='img-fluid' src="/assets/images/LM/partnerships/slum/slumblockbanner.jpeg" />
                                </Col>
                                <Col lg="12">
                                    <div className="heading">
                                        <h4>Statement of Interest for the Slum-Block Millionaire project</h4>
                                    </div>

                                    <br />

                                    <div className="heading-text">
                                        <p>We are starting our Slum Block Millionaire Project and we are inviting a few of our close friends and their organizations to express their support in the Initial phase.</p>

                                        <p>In the Initial phase, we want to reach out to all our close friends and have them sign this Statement of Interest for the Slum Block Millionaire Project.</p>

                                        <p>By signing the Statement of Interest, your only obligation would be to speak positively of blockchain and endorse the education of blockchain within your social networks.</p>

                                        <p>We would also post your logo, as a supporter, on the web page that we are creating for the Slum Block Millionaire Project.</p>

                                        <p>We hope you will join us on this exciting journey.</p>
                                    </div>
                                </Col>
                                {/* {
                                    Datas.map((data, i) => (
                                        <Col lg="4" sm="6" key={i}>
                                            <div className="gallery-box">
                                                <ModalImage small={`/assets/images/LM/partnerships/twitter/${data.galleryImage}`} large={`/assets/images/LM/partnerships/twitter/${data.galleryImage}`} alt="" />
                                            </div>
                                        </Col>
                                    ))
                                } */}

                            </Row>

                            <Row style={{ padding: "5vh 0" }} className="justify-content-center">
                                    <Col lg="6">
                                        <div className="video-player" style={{ backgroundImage: `url(/assets/images/LM/partnerships/slum/karachi.png)` }}>
                                            <ModalVideo channel='custom' isOpen={this.state.isOpen} url="/assets/images/LM/partnerships/slum/blockchain-in-karachi.mp4" onClose={() => this.setState({ isOpen: false })} />
                                            <button onClick={() => this.setState({ isOpen: true })} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                    <div className="video-player" style={{ backgroundImage: `url(/assets/images/LM/partnerships/slum/Liyaari.png)` }}>
                                            <ModalVideo channel='custom' isOpen={this.state.isOpen3} url="/assets/images/LM/partnerships/slum/Liyaari.mp4" onClose={() => this.setState({ isOpen3: false })} />
                                            <button onClick={() => this.setState({ isOpen3: true })} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                    <div className="video-player" style={{ backgroundImage: `url(/assets/images/LM/partnerships/slum/leni-vedio.png)` }}>
                                            <ModalVideo channel='custom' isOpen={this.state.isOpen4} url="/assets/images/LM/partnerships/slum/leni-vedio.mp4" onClose={() => this.setState({ isOpen4: false })} />
                                            <button onClick={() => this.setState({ isOpen4: true })} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                    <div className="video-player" style={{ backgroundImage: `url(/assets/images/LM/partnerships/slum/samuel.png)` }}>
                                            <ModalVideo channel='custom' isOpen={this.state.isOpen5} url="/assets/images/LM/partnerships/slum/samuel-video.mp4" onClose={() => this.setState({ isOpen5: false })} />
                                            <button onClick={() => this.setState({ isOpen5: true })} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="video-player" style={{ backgroundImage: `url(/assets/images/LM/partnerships/slum/un-blockpng.png)` }}>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen6} videoId='3IB4GREDpKc' onClose={() => this.setState({ isOpen6: false })} />
                                            <button onClick={() => this.setState({ isOpen6: true })} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                        <Col lg="12">
                                            <div className="heading text-center mb-5">
                                                <h3>Success Stories</h3>
                                            </div>
                                        </Col>
                                        <Col lg="6" sm="6">
                                            <div className="gallery-box">
                                                <ModalImage small={`/assets/images/LM/partnerships/slum/SBL-01.jpg`} large={`/assets/images/LM/partnerships/slum/SBL-01.jpg`} alt="" />
                                            </div>
                                        </Col>
                                        <Col lg="6" sm="6">
                                            <div className="gallery-box">
                                                <ModalImage small={`/assets/images/LM/partnerships/slum/SBL-02.jpg`} large={`/assets/images/LM/partnerships/slum/SBL-02.jpg`} alt="" />
                                            </div>
                                        </Col>
                                </Row>
                                <hr />
                            <Row>
                            <Col lg="12">
                                        <div className="heading text-center mb-5">
                                            <h3>Supporters</h3>
                                        </div>
                                    </Col>
                                <Col lg="4">
                                <div className="gallery-box">
                                        <ModalImage small={`/assets/images/LM/partnerships/slum/SBL-1.jpeg`} large={`/assets/images/LM/partnerships/slum/SBL-1.jpeg`} alt="" />
                                </div>
                                </Col>
                                <Col lg="4">
                                <div className="gallery-box">
                                        <ModalImage small={`/assets/images/LM/partnerships/slum/SBL-2.jpeg`} large={`/assets/images/LM/partnerships/slum/SBL-2.jpeg`} alt="" />
                                        </div>
                                </Col>
                                <Col lg="4">
                                <div className="gallery-box">
                                        <ModalImage small={`/assets/images/LM/partnerships/slum/SBL-3.jpeg`} large={`/assets/images/LM/partnerships/slum/SBL-3.jpeg`} alt="" />
                                        </div>
                                </Col>
                                <Col lg="4">
                                <div className="gallery-box">
                                        <ModalImage small={`/assets/images/LM/partnerships/slum/SBL-4.jpeg`} large={`/assets/images/LM/partnerships/slum/SBL-4.jpeg`} alt="" />
                                        </div>
                                </Col>
                                <Col lg="4">
                                <div className="gallery-box">
                                        <ModalImage small={`/assets/images/LM/partnerships/slum/SBL-5.jpeg`} large={`/assets/images/LM/partnerships/slum/SBL-5.jpeg`} alt="" />
                                </div>
                                </Col>
                            </Row>

                            {/* <Row className="row-divider">
                                <Col lg="3">
                                    <ModalImage small={`/assets/images/LM/partnerships/united/un2.jpg`} large={`/assets/images/LM/partnerships/united/un2.jpg`} alt="" />
                                </Col>
                                <Col lg="3" md="6">
                                    <div>
                                        <ModalImage className="" small={`/assets/images/LM/partnerships/united/un1.jpg`} large={`/assets/images/LM/partnerships/united/un1.jpg`} alt="" />
                                    </div>
                                    <div>
                                        <ModalImage className="smallImage" small={`/assets/images/LM/partnerships/united/un5.jpg`} large={`/assets/images/LM/partnerships/united/un5.jpg`} alt="" />
                                    </div>
                                </Col>
                                <Col lg="3">
                                    <ModalImage small={`/assets/images/LM/partnerships/united/un3.jpg`} large={`/assets/images/LM/partnerships/united/un3.jpg`} alt="" />
                                </Col>
                                <Col lg="3">
                                    <ModalImage small={`/assets/images/LM/partnerships/united/un7.jpg`} large={`/assets/images/LM/partnerships/united/un7.jpg`} alt="" />
                                </Col>
                           
                                <Col lg="4">
                                    <ModalImage small={`/assets/images/LM/partnerships/united/un4.jpg`} large={`/assets/images/LM/partnerships/united/un4.jpg`} alt="" />
                                </Col>
                                <Col lg="4">
                                    <ModalImage small={`/assets/images/LM/partnerships/united/un6.jpg`} large={`/assets/images/LM/partnerships/united/un6.jpg`} alt="" />
                                </Col>
                                <Col lg="4">
                                    <ModalImage small={`/assets/images/LM/partnerships/united/un8.jpg`} large={`/assets/images/LM/partnerships/united/un8.jpg`} alt="" />
                                </Col>
                            </Row> */}

                            {/* <Row style={{ padding: "5vh 0" }}>
                                <Col lg="4">
                                    <div className="video-player" style={{backgroundImage: `url(https://images.unsplash.com/photo-1537287363118-157ee6915cff?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80)`}}>
                                        <ModalVideo channel='custom' isOpen={this.state.isOpen} url="/assets/images/LM/video1.mp4" onClose={() => this.setState({ isOpen: false })} />
                                        <button onClick={this.openModal} className="play-button"><i className="las la-play"></i></button>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="video-player" style={{backgroundImage: `url(https://images.unsplash.com/photo-1551817958-c5b51e7b4a33?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80)`}}>
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='uXFUl0KcIkA' onClose={() => this.setState({ isOpen: false })} />
                                        <button onClick={this.openModal} className="play-button"><i className="las la-play"></i></button>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="video-player" style={{backgroundImage: `url(https://images.unsplash.com/photo-1551817958-c5b51e7b4a33?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80)`}}>
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='uXFUl0KcIkA' onClose={() => this.setState({ isOpen: false })} />
                                        <button onClick={this.openModal} className="play-button"><i className="las la-play"></i></button>
                                    </div>
                                </Col>
                            </Row> */}
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default SlumBlockComponent