import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
        .SignUpForm {
            position: relative;
            overflow: hidden;

            p.error {
                font-size: 12px;
                line-height: 17px;
                color: red;
                position: absolute;
                right: 20px;
                top: 0;
                padding: 0 8px;
                background: #f3f3f3;
                border-radius: 10px;
            }

            .genderBox {
                label {
                    margin-left: 10px;
                    font-size: 15px;
                }
            }

            .termsBox {
                label {
                    margin-left: 10px;
                    font-size: 15px;
                }
            }

            input{
                border-radius: 5px;
                box-shadow: none;
                height: 40px;
                color: ${colors.bg1};
                font-size: 15px;
                padding-left: 15px;
                // font-weight: bold;
                border-color: ${colors.green};
                
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                
            }

            input[type=radio] {
                border : 2px solid #9eb3d1;
                appearance: none;
                width: 18px;
                height: 18px;
                cursor: pointer;
                margin-right: 6px;
                position: relative;
                top: 4px;
                border-radius: 50%;
            }

            input[type=checkbox] {
                border : 2px solid #9eb3d1;
                appearance: none;
                width: 18px;
                height: 18px;
                cursor: pointer;
                margin-right: 6px;
                position: relative;
                top: 4px;
                // border-radius: 50%;
            }
            
            input[type=radio]:checked {
                background-color: #11b67a;
                background: #11b67a url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
                border-color : #11b67a;
            }

            input[type=checkbox]:checked {
                background-color: #11b67a;
                background: #11b67a url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
                border-color : #11b67a;
            }
        }
`;