import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/bgImg";

const BackgroundImageWithText = () => {
  return (
    <Styles>
      <div
        className="bgImg"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/LM/usbg.jpg')`,
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <div className="d-flex">
                <i className="starColor fa fa-star fa-3x" />
                <i className="starColor fa fa-star fa-3x" />
                <i className="starColor fa fa-star fa-3x" />
                <i className="starColor fa fa-star fa-3x" />
                <i className="starColor fa fa-star fa-3x" />
              </div>
              <h2 className="bgText text-white">
                Award Winning <br /> Teaching Methodology <br /> from Finland
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    </Styles>
  );
};

export default BackgroundImageWithText;
