import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`

    /* Course Grid */
    .course-grid-area {
        padding: 70px 0;

        .course-items {
            h5.heading {
                color : ${colors.black1};
                font-weight : 600;
                padding-bottom: 10px;
                margin-bottom: 20px;
                position : relative;
                &:before {
                    position : absolute;
                    content : "";
                    background : ${colors.green};
                    width : 50px;
                    height: 2px;
                    bottom : 0;
                    left : 0;
                }
    
                @media(max-width: 575px) {
                    font-size  : 17px;
                }
            }
            .course-item {
                border: 1px solid ${colors.border1};
                border-radius : 5px;
                transition : all 0.2s ease;
                overflow : hidden;
                margin-bottom: 30px;

                button.enroll-btn {
                    font-size: 16px;
                    color: #fff;
                    background: ${colors.gr_bg};
                    display: inline-block;
                    width: 100%;
                    height: 40px;
                    font-weight : 500;
                    border : none;
                    padding: 9px;
                    border-radius: 5px;
                    &:hover {
                        background: ${colors.gr_bg2};
                    }

                    @media(max-width: 575px) {
                        font-size : 14px;
                    }
                }

                @media(max-width: 1199px) {
                    padding: 12px 15px 15px;
                }

                .course-image {
                    width              : 100%;
                    height             : 253px;
                    background-size    : cover;
                    background-position: center;
                    background-repeat  : no-repeat;
                    border-radius : 5px 5px 0 0;
                    position: relative;

                    .author-img {
                        position: absolute;
                        left    : 20px;
                        bottom  : 20px;

                        img {
                            max-width: 40px;
                            border-radius : 50%;
                            margin-right: 5px;
                        }

                        .title {
                            background: #ffffff;
                            padding   : 3px 8px;
                            border-radius : 5px;

                            p {
                                font-size    : 12px;
                                color        : ${colors.black1};
                                font-weight  : 500;
                                margin-bottom: -4px;
                            }

                            span {
                                font-size  : 11px;
                                color      : ${colors.text3};
                                font-weight: 500;
                            }
                        }

                    }

                    .course-price {
                        p {
                            font-size  : 16px;
                            color      : #ffffff;
                            background : #007DFF;
                            position   : absolute;
                            left      : 30px;
                            bottom     : 20px;
                            padding    : 6px 8px;
                            font-weight: 500;
                            border-radius : 5px;

                            @media(max-width: 767px) {
                                font-size  : 14px;
                                bottom     : 5px;
                            }
                        }
                    }

                    @media(max-width: 767px) {
                        height: 185px;
                    }
                }

                .course-content {
                    background: #fff;
                    padding   : 20px 20px;

                    h6.heading {
                        a {
                            color        : ${colors.black1};
                            font-weight  : 600;
                            display      : inline-block;
                            margin-bottom: 12px;

                            &:hover {
                                color: ${colors.green};
                            }
                        }
                    }

                    p.desc {
                        font-size     : 14px;
                        color         : ${colors.text3};
                        line-height   : 25px;
                        border-bottom : 1px solid ${colors.border1};
                        padding-bottom: 10px;
                        margin-bottom : 12px;
                    }

                    .course-instructor-img {
                        img {
                            width: 70px;
                            height: 70px;
                            border-radius: 5%;
                        }
                        @media(max-width: 575px) {
                            img {
                                margin-top: 2px;
                                width: 50px;
                                height: 50px;
                                border-radius: 5%;
                            }
                            label {
                                position: absolute;
                                top: 18px;
                                left: 70px;
                            }
                        }
                    }

                    .course-info{
                        ul {
                            text-align: left;
                            margin-top: 20px;
                            li {
                                margin-right: 0;

                                i {
                                    font-size: 20px;
                                }

                                i.fa-book {
                                    font-size: 17px;
                                    color: #8C95A4;
                                }
                                i.fa-video {
                                    font-size: 14px;
                                    color: #8C95A4;
                                }
                                i.fa-wifi {
                                    font-size: 14px;
                                    color: #8C95A4;
                                }
                                i.fa-calendar-alt {
                                    color: #8C95A4;
                                }

                                label {
                                    margin-left: 10px;
                                    color    : ${colors.text2};
                                }
                                span {
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .course-face {
                        .duration,
                        .student {
                            p {
                                font-size: 13px;
                                color    : ${colors.text3};

                                i {
                                    font-size     : 16px;
                                    color         : ${colors.green};
                                    vertical-align: text-bottom;
                                    margin-right  : 3px;
                                }
                            }
                        }

                        .rating {
                            ul {
                                li {
                                    margin-right: 0;

                                    i {
                                        font-size: 14px;
                                        color    : ${colors.yellow};
                                    }

                                    &:last-child {
                                        font-size: 13px;
                                        color    : ${colors.text3};
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
                }
            }
        }

        @media(max-width: 767px) {
            padding: 40px 0 30px;
        }
    }
`;