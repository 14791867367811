import React from 'react';
import { Styles } from "./styles/marquee.js";

const Marquee = (props) => {
    return (
        <Styles>
            <div className="marquee-area">
                <marquee direction="left" scrollamount="10">
                    <div className="ticker" style={{ padding: "0", fontSize: 20 }}>
                        {props.classesSlide.map((item, index) =>
                            <label className="mr-5" key={index}><span>{item.class}</span>, {item.time}</label>
                        )}
                    </div>
                </marquee>
            </div>
        </Styles>
        );
}

export default Marquee;