import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
.PhoneNumberVerificationCode {
    position: relative;
    overflow: hidden;
    background: #fff;
    color: ${colors.bg1};
    min-height: 570px;
    // border: 3px solid ${colors.bg1};

    @media(max-width: 767px) {
        min-height: calc(100vh);    
    }

    .tutorsCard {
        background: ${colors.bg1};
        color: #fff;
        margin-top: 10px;

        .timeSection {
            position: relative;
            top: -10px;
            margin-left: 8px;
          }

          .daysList {
            padding: 10px 0px;
            color: #fff;
    
            li {
              padding: 10px;
              border-bottom: 1px solid #b5e5ba;

              input[type=radio] {
                border : 2px solid #11b67a;
                appearance: none;
                width: 18px;
                height: 18px;
                cursor: pointer;
                margin-right: 6px;
                position: relative;
                top: 4px;
                border-radius: 50%;
              }
              input[type=radio]:checked {
                background-color: #11b67a;
                background: #11b67a url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
                border-color : #11b67a;
              }

              .selectBtn {
                  padding: 5px 5px;
                  width: 55px;
                  border-radius: 0.3rem;
                  color: #000;
              }
              .selectBtnChecked {
                padding: 5px 5px;
                width: 55px;
                border-radius: 0.3rem;
                background: #28a745;
                color: #fff;
              }
              .selectBtnDisabled {
                padding: 5px 5px;
                width: 55px;
                border-radius: 0.3rem;
                background: #556d91;
                color: #fff;
              }
            }
    
            span {
            //   float: right;
              color: #fff;
              // font-weight: 600;
            }
          }

        img {
            border-radius: 50%
        }
    }

    h3 {
        color: ${colors.bg1};
        font-size: 1.2rem;
        margin-top: 10px;
        // padding: 10vh 0 0 0;

        @media(max-width: 767px) {
            font-size: 28px;
        }
    }
    h4 {
        color: ${colors.green};
        font-size: 1.2rem;
        padding: 10vh 0 0 0;

        @media(max-width: 767px) {
            font-size: 28px;
        }
    }

    // .wrapper {
    //     position: absolute;
    //     width: 200%;
    //     height: 600px;
    //     left: -50%;
    //     top: 32vh;
    //     background-color: #fec63d;
    //     border-radius: 50%;
    // }

    
    input{
        
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
    }

    .countDownBlockVerify {
        display: inline-block;
        width: 100%;
        // height: 50px;
        margin: 25px 0px 25px 0;
        border-radius: 50%;
        // background: #fff;
        font-family: inherit;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: center;
        line-height: 30px;
    }
    
    .nextButtonNew {

        &.btn {
            width: 90%;
            margin: 30px auto 30px auto;
            background: transparent !important;
            border: none;
            box-shadow: none !important;
            text-align: center;
            font-size: 2rem;
            font-family: 'Open Sans', sans-serif;
            color: ${colors.bg1};
    
            i {
                padding: 10px;
                width: 46px;
                height: 46px;
                background: ${colors.bg1} !important;
                margin-left: 15px;
                position: relative;
                top: 1px;
                border-radius: 50%;
                color: #fff;
            }
        }
        
    }

}
`;