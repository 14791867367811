import React, { Component } from 'react';
// import Datas from '../../data/instructor/instructor.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/instructor.js';
import { getImgURL } from "../../actions/utility";
import { setTeacherImgAndId } from "../../actions/webRedActions";
import { connect } from 'react-redux';

class Instructor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teachersData: '',
            taughtClasses: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        debugger;
        if (prevState.teachersData !== nextProps.allTutorsInfo) {
            debugger;
            return {
                teachersData: nextProps.allTutorsInfo.teachers,
                taughtClasses: nextProps.allTutorsInfo.taughtClasses
            };
        }
        return null;
    }

    setTeacherInfoLocalStorage = (data) => {
        localStorage.setItem("teacherInfo", JSON.stringify(data));
    }
    render() {
        // onClick={() => this.props.setTeacherImgAndId(data.image, data.id , data.user_name)}
        const { teachersData, taughtClasses } = this.state;
        debugger;
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper instructor-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Tutors" backgroundImage="" />

                    {/* Instructor Area */}
                    <section className="instructor-area">
                        <Container>
                            <Row>
                                {
                                    teachersData.length > 0 ?

                                        teachersData.map((data, i) => (
                                            <Col lg="3" md="4" sm="6" key={i}>
                                                <div className="instructor-item" onClick={() => this.setTeacherInfoLocalStorage(data)}>
                                                    <Link to={`/instructor-details`}>
                                                        {data.image !== null ?
                                                            <img src={`${getImgURL()}${data.image}`} alt="" className="img-fluid" />
                                                            :
                                                            <img src={'/assets/images/LM/Yellow1.jpg'} alt="" className="img-fluid" />
                                                        }
                                                    </Link>
                                                    <div className="img-content text-center">
                                                        <h5><Link to={`/instructor-details`}>{data.user_name}</Link></h5>
                                                        <p>Tutor</p>
                                                        {/* <p>{taughtClasses[data.id]}</p> */}
                                                    </div>
                                                </div>
                                            </Col>
                                        ))

                                        : <Col md="12" className="text-center"> <Spinner className="centered-spinner" animation="border" variant="dark" /> </Col>
                                }

                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}
function mapStateToProps(state) {
    return {
        allTutorsInfo: state.webRed.allTutorsInfo
    };
}
export default connect(mapStateToProps, { setTeacherImgAndId })(Instructor);