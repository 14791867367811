import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
.UserDetails {
    position: relative;
    overflow: hidden;
    background: #fff;
    color: ${colors.bg1};
    min-height: 570px;
    // border: 3px solid ${colors.bg1};

    @media(max-width: 767px){
        min-height: calc(100vh);
    }
    
    label {
        color: ${colors.bg1};
        font-size: 12px;
        margin: 0;
        padding-left: 20px;
        text-align: left;
        width: 100%;
    }

    input {
        text-align: left;
        margin-top: 0 !important;
        // border: none;
        padding: 20px 15px;
        width: 90%;
        margin: auto;
        
        &::-webkit-input-placeholder { /* Edge */
            color: #ccc;
            font-size: 1rem;
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #ccc;
        }
        
        &::placeholder {
            color: #ccc;
        }
    }

    .btn.btn-secondary.btn-md {
        padding: 7px 7px;
        @media(max-width: 767px){
            padding: 10px 16px;
        }
    }

    .nextButtonNew {
        &.btn {
            width: 90%;
            margin: 30px auto 30px auto;
            background: transparent !important;
            border: none;
            box-shadow: none !important;
            text-align: center;
            font-size: 2rem;
            font-family: 'Open Sans', sans-serif;
            color: ${colors.bg1};
    
            i {
                padding: 10px;
                width: 46px;
                height: 46px;
                background: ${colors.bg1} !important;
                margin-left: 15px;
                position: relative;
                top: 1px;
                border-radius: 50%;
                color: #fff;
            }
        }
    }

}
`;