import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .video-testimonial {
        .video-testimonial-area {
            padding : 60px 0 70px;

            .video-player {
                // position           : absolute;
                bottom             : -83px;
                right              : 0;
                width              : 100%;
                height             : 200px;
                background-size    : cover;
                background-position: center;
                background-repeat  : no-repeat;
                border-radius      : 5px;

                // &::before {
                //     position        : absolute;
                //     content         : '';
                //     background-color: rgba(0, 0, 0, 0.2);
                //     width           : 100%;
                //     height          : 100%;
                //     top             : 0;
                //     left            : 0;
                //     border-radius : 5px;
                // }

                button.play-button {
                    position  : absolute;
                    z-index   : 10;
                    top       : 50%;
                    left      : 50%;
                    transform : translateX(-50%) translateY(-50%);
                    box-sizing: content-box;
                    display   : block;
                    width     : 32px;
                    height    : 44px;
                    border-radius : 50%;

                    i {
                        position   : relative;
                        font-size  : 40px;
                        color      : ${colors.bg1};
                        z-index    : 11;
                        padding-top: 2px;
                        margin-left: -2px;
                    }

                    &::before {
                        content   : "";
                        position  : absolute;
                        z-index   : 0;
                        left      : 50%;
                        top       : 50%;
                        transform : translateX(-50%) translateY(-50%);
                        display   : block;
                        width     : 70px;
                        height    : 70px;
                        background: #ffffff;
                        border-radius : 50%;
                        animation: pulse-border 1500ms ease-out infinite;
                    }

                    &:after {
                        content   : "";
                        position  : absolute;
                        z-index   : 1;
                        left      : 50%;
                        top       : 50%;
                        transform : translateX(-50%) translateY(-50%);
                        display   : block;
                        width     : 70px;
                        height    : 70px;
                        background: #ffffff;
                        border-radius : 50%;
                        transition : all 200ms;
                    }

                    &:hover {
                        i {
                            color: ${colors.green};
                        }
                    }

                    @keyframes pulse-border {
                        0% {
                            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                            opacity  : 1;
                        }

                        100% {
                            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                            opacity  : 0;
                        }
                    }
                }

                @media(max-width: 991px) {
                    bottom: -70%;
                }

                @media(max-width: 767px) {
                    position: unset;
                    width: 100%;
                    height: 250px;
                    margin-bottom: 30px;
                }
            }


            .buttonAllView{
                text-align: center;
    
                button {
                    font-size  : 14px;
                    color      : #fff;
                    background : ${colors.green};
                    width      : 30%;
                    height     : 48px;
                    font-weight: 500;
                    border     : none;
                    border-radius : 5px;
                    text-transform: uppercase;
                    margin-top: 40px;
    
                    &:hover {
                        background: ${colors.black1};
    
                        i {
                            color: #ffffff;
                        }
                    }
                    @media(max-width: 575px) {
                        width      : 50%;
                        height     : 40px;
                        font-size  : 12px;
                    }
                }
            }
             h4 {
                color        : #182B49;
                line-height  : 35px;
                font-weight  : 600;
                // max-width    : 550px;
                margin       : auto;
                margin-bottom: 20px;

                @media(max-width: 575px) {
                    // margin-bottom: 15px;
                    font-size: 18px;
                }
            }
            h3 {
                color        : ${colors.bg1};
                line-height  : 35px;
                font-weight  : 600;
                // max-width    : 550px;
                margin       : auto;
                margin-bottom: 30px;
                font-size: 45px;

                @media(max-width: 575px) {
                    // margin-bottom: 15px;
                    font-size: 25px;
                }
            }
            .gallery-box {
                border-radius : 5px;
                overflow: hidden;
                margin-bottom: 30px;
                position: relative;
                img {
                    max-width: 100%;
                    max=height: 100%;
                    transition : all 0.3s ease;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            @media(max-width: 767px) {
                padding: 30px 0;
            }
        }
    }
`;