import React, { Component } from 'react';
import HeaderTwo from '../components/HeaderTwo';
import { BreadcrumbBox } from '../components/common/Breadcrumb';
import FooterTwo from '../components/FooterTwo';
import { Styles } from "./styles/privacyPolicy.js";
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import axios from 'axios';

class ConfirmDemo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopUp: false
        }
    }
    componentDidMount() {
        document.title = "Confirm Demo - Lincoln Method";
    }

     submitInterest = () => {
         debugger;
            this.setState({ showPopUp: true })
            var bodyFormData = new FormData();
            bodyFormData.set("studentId", 0);
            bodyFormData.set("emId", 0);
            bodyFormData.set("course_id", 0);
            bodyFormData.set("lead_id", this.props.match.params.id);
            bodyFormData.set("lead_status_label", "user_confirm");
            bodyFormData.set("demo_status_id", 2);
            bodyFormData.set("demo_status_history", 1);
            bodyFormData.set("video_url", "");
            bodyFormData.set("video_name", "");
            bodyFormData.set("client_id", 2);
          
            return axios
              .post("https://dashboard.lincolnmethod.com/api/v1/lead/statushistoryupdate", bodyFormData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                  debugger;
                console.log(res);
                if (res.data.status == "success") {
                    debugger;
                  // if success only
                  // dispatch({
                  //   type: _ownLead,
                  //   payload: lead_id,
                  // });
                  return res;
                } else {
                  if (res.data.status == "error") {
                      debugger;
                    // dispatch({
                    // 	type: _allProgramsDataSuccess,
                    // 	payload: res.data
                    // })
                  } else {
                    // dispatch({
                    // 	type: _allProgramsDataSuccess,
                    // 	payload: res.data
                    // })
                  }
                }
              })
              .catch((error) => {
                // console.log(error);
                // dispatch({
                // 	type: _allProgramsDataSuccess,
                // 	payload: "error"
                // })
              });
          }
    
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper privacy-policy-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Join the class" />

                    <section className="event-details-area">
                        <Modal className="htmlModalMain"
                            show={this.state.showPopUp} onHide={() => this.setState({ showPopUp: false })} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Zoom Link for the Class</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <label>It seems like you don't have the Zoom Link for this class.</label>
                                <br />
                                <label>We will contact you soon, and provide you the link and the course material</label>
                            </Modal.Body>
                            <Modal.Footer>
                                            {/* <Button variant="secondary">
                                            Close
                                         </Button> */}
                                        <Button variant="success" onClick={() => this.setState({ showPopUp: false })}>
                                            Ok
                                        </Button>
                                        </Modal.Footer>
                        </Modal>
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <div className="event-details-content">
                                        <div className="heading text-center">
                                            <h4>Join with the Zoom Link</h4>
                                        </div>

                                        <div className="">
                                            <div className="apply-btn text-center" onClick={this.submitInterest}>
                                                <a>Join</a>
                                            </div>
                                            <div className="apply-btn-No text-center" onClick={this.submitInterest}>
                                                <a>I don't have the zoom link</a>
                                            </div>
                                            <div className="heading text-center mt-5">
                                                <h6 className="font-weight-bold">If you need more help call us at <br /> <a href="tel:+923008296228"> +92 300 829 6228 </a></h6>
                                            </div>
                                        </div>
                                        <div className="event-details-overview">

                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                    </section>
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default ConfirmDemo