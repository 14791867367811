import React, { Component } from 'react';
import HeaderTwo from '../components/HeaderTwo';
import { BreadcrumbBox } from '../components/common/Breadcrumb';
import FooterTwo from '../components/FooterTwo';
import { Styles } from "./styles/privacyPolicy.js";
import { Container, Row, Col } from 'react-bootstrap';

class LicenseAgreementComponent extends Component {

    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper privacy-policy-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="License Agreement" />

                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <div className="event-details-content">
                                        <div className="heading">
                                            <h4>License Agreement Lincoln Method</h4>
                                        </div>
                                        {/* <div className="heading-text">
                                            <p>Effective date: July 06, 2018</p>


                                            <p>Lincoln Method ("us", "we", or "our") operates the LincolnMethod.com website (the "Service").</p>

                                            <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. This Privacy Policy  for Lincoln Method.</p>

                                            <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from LincolnMethod.com</p>
                                        </div> */}

                                        <div className="event-details-overview">
                                            <p>The terms and conditions that follow set forth a legal agreement between you (an educational institution or a private/career school), the end user, and <strong>Lincoln Method</strong>, relating to the <strong>LM Learning App</strong>® Licenses, software and certain other related software modules (including the assessments, analysis and reporting tools), as applicable (collectively, the “Software”) The term “Software” also applies to (i) any upgrades to the Software that you may receive from time to time under a subscription service or other support arrangement, (ii) any add-in modules to the Software you may order and install from time to time. You may not copy it without a license from <strong>Lincoln Method</strong>. This agreement sets forward the terms for non-exclusive use of the licenses.  This is a license agreement and not an agreement for sale.</p>

                                            <br />

                                            <p>Eligibility Requirements for LM Learning App License</p>

                                            <br />

                                            <p>You acknowledge that the LM Learning App Licenses are provided to qualifying educational institutions and to private/career schools at prices that are significantly less than the prices to commercial users. As a consequence, the LM Learning App Licenses contain use restrictions that are not found in commercial licenses of the Software. Only educational institutions and private/career schools that meet the following criteria are qualified to receive LM Learning App Licenses:</p>

                                            <ul className="list-unstyled">
                                                <li><i className="fa fa-check"></i> You are a non-profit public or private educational institution or a for-profit private/career school, or operate a government certified apprenticeship program for degree-, diploma- or certificate-seeking students.</li>
                                                <li><i className="fa fa-check"></i> You plan to use the Software for instructional purposes in classes for full-time, degree-, diploma- or certificate-seeking students, or you plan to use the Software for instructional purposes in an apprenticeship program for degree-, diploma- or certificate-seeking students.</li>
                                                <li><i className="fa fa-check"></i> You will advertise the LM Learning App courses in your school catalogue</li>
                                                <li><i className="fa fa-check"></i> You have the choice to advertise the LM Learning App in any print or online media, including on-line web sites.</li>
                                            </ul>

                                            <br/>

                                            <h5 className="">GRANT OF LICENSE:</h5>

                                            <ul className="list-unstyled">
                                                <li><i className="fa fa-check"></i> The LM Learning App grants to you a nonexclusive, nontransferable (except as expressly set forth herein) license to use the Software and the printed and/or electronic user documentation (the “Documentation”) accompanying the Software in accordance with this Agreement.</li>
                                                <li><i className="fa fa-check"></i> You may use the purchase as many licenses as you desire, however each license will be granted in the name of one specific student. You may not transfer the license from one student to another.</li>
                                                <li><i className="fa fa-check"></i> The License is effective on a month-to-month basis.</li>
                                            </ul>

                                            <br/>

                                            <h5>INTERNET TOOLS AND SERVICES</h5>

                                            <p>The Software works in collaboration with free third party online learning tutorials including YouTube, which are accessed directly through the Software using a steady internet connection that has the relevant bandwidth to support all users who are connected.</p>

                                            <br/>

                                            <h5>OWNERSHIP OF THE SOFTWARE AND RESTRICTIONS ON COPYING</h5>

                                            <p>LM Learning App or its licensors own and will retain all copyright, trade secret and other proprietary rights in and to the Software and the Documentation.  THE SOFTWARE AND THE DOCUMENTATION ARE PROTECTED BY COPYRIGHT LAWS AND OTHER INTELLECTUAL PROPERTY LAWS.</p>

                                            <br/>

                                             <h5>OTHER RESTRICTIONS ON USE</h5>

                                         <p>This Agreement is your proof of license to exercise the rights granted herein and must be retained by you. You may not modify or make works derivative of the Software or Documentation and you may not analyze for purposes of creating a competitor to the LM Learning App, reverse-engineer, decompile, disassemble or otherwise attempt to discover the source code of the Software, as it contains trade secrets (such as the Software’s structure, organization and code) of LM Learning App.</p>

                                         <br/>

                                            <h5>SUBSCRIPTION SERVICE</h5>

                                           <p>If you purchase Subscription Service for the Software you have licensed hereunder by paying the fee therefor, you will be entitled to receive telephone support. The term of this service runs for one (1) year from the first day of the first month following the date you ordered subscription service, e.g., if you order subscription service on June 10 of 2018, the one year term starts June 1 of 2018. It will automatically renew from year to year. Software that is delivered as an upgrade to a previous version of the licensed Software must replace the previous version.</p>

                                           <br/>

                                            <h5>TERM</h5>


                                            <p>The license granted herein will continue until it is terminated in writing. LM Learning App may terminate the license granted herein within 30 days upon written notice to you (i) for justified cause, including without limitation, breach of any provision of this Agreement, (ii) if you breach any provision of this Agreement and fail to cure such breach within fifteen (15) days of notice thereof, or (iii) for not meeting payment obligations. Upon the termination of the license for any such reason, you will promptly return to LM Learning App or destroy all copies of the Software and Documentation covered by the license as instructed by LM Learning App.</p>

                                            <br/>

                                            <h5>RESPONSIBILITY FOR SELECTION AND USE OF SOFTWARE</h5>

                                            <p>You are responsible for the supervision, management and control of the use of the Software, and output of the Software, including, but not limited to (i) selection of the Software to achieve your intended results, (ii) determining the appropriate uses of the Software and the output of the Software in your instructional courseware, (iii) establishing adequate independent procedures for testing the accuracy of the Software and any output, (iv) preventing files created from the Software from being used for commercial purposes and/or from being distributed to commercial entities. The Software is a tool that is intended to be used to assess, educate and train students only. It is not to be a substitute for professional judgment or independent testing of physical prototypes for product stress, safety, and utility; you are solely responsible for the students’ learning outcomes and academic grades achieved from using the Software.</p>


                                            <br/>

                                            <h5>LIMITED WARRANTY, EXCEPTIONS & DISCLAIMERS</h5>

                                            <p>The Software may contain bugs which occur due to a normal software development lifecycle and new version roll-outs. The Software’s engineering team is continually releasing patches that are automatically updated to address any bugs. If all efforts of the Software’s engineering team fails, and if you choose to e terminate the agreement, LM Learning App shall refund the subscription of the current month.</p>

                                            <br/>

                                            <h5>JURISDICTION</h5>

                                            <p>This Agreement shall be governed by and construed and enforced in accordance with the substantive laws of the state of Delaware, USA. The English-language version of this Agreement shall be the authorized text for all purposes, despite translations or interpretations of this Agreement into other languages. If, for any reason, a court of competent jurisdiction finds any provision of this Agreement, or a portion thereof, to be unenforceable, that provision or portion shall be enforced to the maximum extent permissible and the remainder of this Agreement shall remain in full force and effect.</p>

                                            <br/>

                                            <h5>ENTIRE AGREEMENT</h5>

                                            <p>You further agree that this Agreement is the complete and exclusive statement of your agreement with LM Learning App relating to the Software and subscription service and supersedes any other agreement, oral or written, or any other communications between you and LM Learning App relating to the Software and subscription service; provided, however, that this Agreement shall not supersede the terms of any signed agreement between you and LM Learning App relating to the Software and Subscription Service.</p>

                                        </div>
                                    </div>
                                </Col>

                                {/* <Col lg="3" md="4" sm="12">
                                    <div className="event-details-sidebar">
                                        <Row>
                                            <Col md="12">
                                                <div className="event-sidebar-info">
                                                    <div className="event-sidebar-timer text-center">
                                                        <Timer initialTime={1040 * 970 * 980} direction="backward">
                                                            <p><Timer.Days /><span>Days</span></p>
                                                            <p><Timer.Hours /><span>Hours</span></p>
                                                            <p><Timer.Minutes /><span>Minutes</span></p>
                                                        </Timer>
                                                    </div>
                                                    <ul className="list-unstyled event-info-list">
                                                        <li>Start Date: <span>Aug 21, 2020</span></li>
                                                        <li>Time: <span>08:30am</span></li>
                                                        <li>Seat: <span>220</span></li>
                                                        <li>Place: <span>Ontario,CA</span></li>
                                                        <li>Organizer: <span>David Young</span></li>
                                                        <li>Phone: <span>+1 (396) 486 4709</span></li>
                                                        <li>Email: <span>email@me.com</span></li>
                                                        <li>Website: <span>www.mydomain.com</span></li>
                                                    </ul>
                                                    <button type="button" className="buy-btn">Buy Ticket</button>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <PopularCourse />
                                            </Col>
                                            <Col md="12">
                                                <CourseTag />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </section>
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default LicenseAgreementComponent