import React, { Component } from 'react';
import { Container, Row, Col , Spinner } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { connect } from 'react-redux';
import { Styles } from '../courses/styles/courseCustomise.js';
import OnlineSchoolSidebar from './components/OnlineSchoolSidebar';
import OnlineSchoolCourseGrid from './components/OnlineSchoolCourseGrid';

class OnlineSchool extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            grades: [],
            filteredCourses: {},
            sortedCourses: [],
            visibleGradeList: [],
            applyGrid: false,
            showFiltersOnMobile: false,
            popoverOpen: [],
            popOverGradeId: 0,
            popOverCourseId: 0,
            showPopupTime: false,
            coursesData: "",
            zone: ""
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.coursesData !== nextProps.coursesData) {
            var sortedCoursesArray = [];
            var sortedPopoverArray = [];
            var coursesKeys = Object.keys(nextProps.coursesData.coursesData.filteredCourses);
            coursesKeys.map(course => {

                // console.log(this.state.filteredCourses[course]);
                var gradeArray = [];
                var popOverArray = [];
                var courseKey = Object.keys(nextProps.coursesData.coursesData.filteredCourses[course]);
                var gradeName = nextProps.coursesData.coursesData.filteredCourses[course][courseKey[0]].gradeAeTagValue;
                courseKey.map(_course => {
                    var thisCourse = nextProps.coursesData.coursesData.filteredCourses[course][_course];
                    gradeArray.push(thisCourse);
                    popOverArray.push(false);
                })
                sortedCoursesArray.push({
                    grade: gradeName,
                    courses: gradeArray,
                    visible: true
                })
                sortedPopoverArray.push({
                    courses: popOverArray
                })

            })
            return {
                coursesData: nextProps.coursesData,
                sortedCourses: sortedCoursesArray,
                popoverOpen: sortedPopoverArray,
                grades: nextProps.coursesData.grade,
                filteredCourses: nextProps.coursesData.coursesData.filteredCourses,
                loading: false
            };
        }
        return null;
    }

    filterCourses = (e, grade) => {
        var visibleList = this.state.visibleGradeList;
        debugger;
        if (!visibleList.includes(grade)) {
            visibleList.push(grade);
            this.setState({
                visibleGradeList: visibleList
            },
                () => {
                    // console.log(this.state.visibleGradeList)
                })
        }
        else {
            var index = visibleList.indexOf(grade);
            if (index > -1) {
                visibleList.splice(index, 1);
                this.setState({
                    visibleGradeList: visibleList
                },
                    () => {
                        // console.log(this.state.visibleGradeList)
                    })
            }
        }
    }

    toggleTimeTableModal = (key, key2) => {
        debugger;
        this.setState(prevState => ({
            showPopupTime: !prevState.showPopupTime
        }), () => {
            if (this.state.showPopupTime) { // if it's true
                this.setState({
                    popOverGradeId: key,
                    popOverCourseId: key2
                })
            }
        });
    }
   
    render() {
        console.log("Component State", this.state);
        const { grades , sortedCourses , visibleGradeList , showPopupTime , popOverCourseId , popOverGradeId } = this.state;
        return (
            <div className="main-wrapper course-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Courses" />

                <Styles>
                    {/* Course Grid */}
                    <section className="course-grid-area">
                        <Container>
                            {sortedCourses.length != 0 ?
                                <Row>
                                    <Col lg="12" md="4" sm="5">
                                        <OnlineSchoolSidebar visibleGradeList={visibleGradeList} filterCourses={this.filterCourses} grades={grades} />
                                    </Col>
                                    {/* <Col lg="3" md="4" sm="5">
                                        <CourseSidebar visibleGradeList={visibleGradeList} filterCourses={this.filterCourses} grades={grades} />
                                    </Col> */}
                                    <Col lg="12" md="12" sm="7">
                                        <div className="course-items">
                                            <Row>
                                                <OnlineSchoolCourseGrid toggleTimeTableModal={this.toggleTimeTableModal} showPopupTime={showPopupTime} popOverCourseId={popOverCourseId} popOverGradeId={popOverGradeId} visibleGradeList={visibleGradeList} sortedCourses={sortedCourses} />
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                                :

                                <Row> <Col lg="12" className="text-center"> <Spinner className="centered-spinner" animation="border" variant="dark" /></Col></Row>
                            }
                        </Container>
                    </section>
                </Styles>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        )
    }
}

const mapStateToProps = state => ({
    coursesData: state.webRed.coursesDataOnlineSchool
})

export default connect(mapStateToProps, null)(OnlineSchool);