import React, { Component } from 'react';
// import Datas from '../../data/instructor/instructor.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Spinner, Image } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/educationMama.js';
import { getImgURL } from "../../actions/utility";
import { setTeacherImgAndId } from "../../actions/webRedActions";
import ModalImage from "react-modal-image";
import { connect } from 'react-redux';

class EducationMamas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teachersData: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        debugger;
        if (prevState.teachersData !== nextProps.allTutorsInfo) {
            debugger;
            return {
                teachersData: nextProps.allTutorsInfo.EM
            };
        }
        return null;
    }

    setTeacherInfoLocalStorage = (data) => {
        localStorage.setItem("teacherInfo", JSON.stringify(data));
    }
    render() {
        // onClick={() => this.props.setTeacherImgAndId(data.image, data.id , data.user_name)}
        const { teachersData } = this.state;
        debugger;
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper education-mama">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    {/* <BreadcrumbBox title="Education Mama" /> */}

                    {/* Instructor Area */}
                    <section className="hero-image-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/homePage/4.png)` }}>
                <div className="round-shape" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/r-shape.png)` }}></div>
                    <div className="hero-table">
                        <div className="hero-tablecell">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <div className="hero-box text-center">
                                            <h1>Who is an EDUCATION MAMA?</h1>
                                            <p>These are mothers who are concerned about the quality of education that their children are receiving at school. An Education Mama works within her community by helping other mothers get access to quality education at affordable prices.</p>
                                            {/* <div className="video-player">
                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='vIOTc841KtI' onClose={() => this.setState({ isOpen: false })} />
                                                <button onClick={this.openModal} className="play-button"><i className="las la-play"></i></button>
                                            </div> */}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
                    <section className="instructor-area">
                        {/* <Image width="100%" src="/assets/images/LM/EducationMama/1.png" /> */}
                        <div className="headingBannerBgImage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/EducationMama/6.png)` }}>
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>What does an <br />
                                            <label className="greenStrap font-weight-bold">EDUCATION MAMA</label> do?
                                        </h1>
                                        <ul className="list-unstyled">
                                            <li><i className="fas fa-check"></i>
                                            Talk to other mothers in your community about education
                                            </li>
                                            <li><i className="fas fa-check"></i>
                                            Listen to mothers'problems regarding kids education.
                                            </li>
                                            <li><i className="fas fa-check"></i>
                                            Provide mothers an affordable solution for quality education.
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        {/* <div className="headingBannerBgImage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/EducationMama/4.png)` }}>
                            <Container>
                                <Row>
                                    <Col md="6">

                                    </Col>
                                    <Col md="6">
                                        <div className="yellowBoxText">
                                            <h2>Who is an <br /> EDUCATION MAMA?</h2>
                                            <br />
                                            <br />
                                            <label>These are mothers who are
                                            concerned about the quality of
                                            education that their children are
                                            receiving at school.
                                        </label>

                                            <br />
                                            <br />

                                            <label>An Education Mama works within
                                            her community by helping other
                                            mothers get access to quality
                                            education at affordable prices.</label>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div> */}
                        <div className="headingBanner">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h2>EDUCATION MAMA</h2>
                                        <label>An opportunity for house wives to earn and become community leaders</label>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="seperator" />
                        
                        <div className="KidsRiseContent">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <h3>Education Mama helps so many other mothers in her community</h3>
                                    </Col>

                                    <Col lg="4">
                                        <i className="fas fa-users"></i>
                                        <h5>Friends</h5>
                                    </Col>
                                    <Col lg="4">
                                        <i className="fas fa-building"></i>
                                        <h5>Neighborhood</h5>
                                    </Col>
                                    <Col lg="4">
                                        <i className="fas fa-users"></i>
                                        <h5>Family</h5>
                                    </Col>
                                    <Col lg="4">
                                        <i className="fas fa-school"></i>
                                        <h5>School moms</h5>
                                    </Col>
                                    <Col lg="4">
                                        <i className="fab fa-whatsapp"></i>
                                        <h5>WhatsApp groups</h5>
                                    </Col>
                                    <Col lg="4">
                                        <i className="fab fa-facebook"></i>
                                        <h5>Social media network</h5>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <Container>
                            <div className="SomeOneRiseContent">
                                <Row>
                                    <Col lg="12">
                                        <h4>EDUCATION MAMA improves grades of kids in her community</h4>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* <div className="headingBanner">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>EDUCATION MAMA</h1>
                                        <label>improves grades of kids in her community</label>
                                    </Col>
                                </Row>
                            </Container>
                        </div> */}
                        <div className="p-4">
                            <Container>
                                <Row>
                                    <Col lg="4" sm="6" xs="6">
                                        <div className="gallery-box">
                                            <ModalImage small={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/25.jpeg`} large={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/25.jpeg`} alt="" />
                                        </div>
                                    </Col>
                                    <Col lg="4" sm="6" xs="6">
                                        <div className="gallery-box">
                                            <ModalImage small={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/26.jpeg`} large={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/26.jpeg`} alt="" />
                                        </div>
                                    </Col>
                                    <Col lg="4" sm="6" xs="6">
                                        <div className="gallery-box">
                                            <ModalImage small={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/27.jpeg`} large={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/27.jpeg`} alt="" />
                                        </div>
                                    </Col>
                                    <Col lg="4" sm="6" xs="6">
                                        <div className="gallery-box">
                                            <ModalImage small={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/28.jpeg`} large={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/28.jpeg`} alt="" />
                                        </div>
                                    </Col>
                                    <Col lg="4" sm="6" xs="6">
                                        <div className="gallery-box">
                                            <ModalImage small={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/29.jpeg`} large={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/28.jpeg`} alt="" />
                                        </div>
                                    </Col>
                                    <Col lg="4" sm="6" xs="6">
                                        <div className="gallery-box">
                                            <ModalImage small={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/30.jpeg`} large={process.env.PUBLIC_URL + `/assets/images/LM/walloffame/28.jpeg`} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>
                        <div className="seperator" />
                        <div className="headingBannerBgImage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/EducationMama/11.png)` }}>
                            <Container>
                                <Row>
                                    <Col md="6">
                                        <h1>
                                            <label className="greenStrap font-weight-bold">EDUCATION MAMA</label>
                                            <br />
                                        earns by helping other moms
                                        </h1>
                                        <br />
                                        <br />
                                        <label>Education Mama is a community leader who helps others by suggesting online education programs.</label>
                                        <br />
                                        <br />
                                        <label>She earns from every sale.</label>
                                        <br />
                                        <label>She can earn <span className="font-weight-bold"> Rs. 40,000/month. </span></label>
                                    </Col>
                                    <Col md="12" className='subImageBanner' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/EducationMama/11.png)` }}>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="seperator" />
                        <div className="headingBannerBgImage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/EducationMama/13.png)` }}>
                            <Container>
                                <Row>
                                    <Col md="6">
                                        <h1>
                                        Become an
                                            <label className="greenStrap font-weight-bold">EDUCATION MAMA</label>
                                        </h1>
                                        <br />
                                        <label>We will provide you with</label>
                                        <br />
                                        <h1>Training & <br /> Certification </h1>
                                        <label>in community outreach and education planning</label>
                                    </Col>
                                    <Col md="12" className='subImageBanner' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/EducationMama/13.png)` }}>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="seperator" />
                        <Container>
                            <div className="SomeOneRiseContent">
                                <Row>
                                    <Col lg="12">
                                        <h4>Education Mama</h4>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        <Container>
                            <Row>
                                {
                                    teachersData.length > 0 ?

                                        teachersData.map((data, i) => (
                                            <Col lg="3" md="4" sm="6" key={i}>
                                                <div className="instructor-item">
                                                    <Link to={``}><img src={`${getImgURL()}${data.image}`} alt="" className="img-fluid" /></Link>
                                                    <div className="img-content text-center">
                                                        <h5><Link to={``}>{data.user_name}</Link></h5>
                                                        <p>Education Mama</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))

                                        : <Col md="12" className="text-center"> <Spinner className="centered-spinner" animation="border" variant="dark" /> </Col>
                                }

                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}
function mapStateToProps(state) {
    return {
        allTutorsInfo: state.webRed.allTutorsInfo
    };
}
export default connect(mapStateToProps, { setTeacherImgAndId })(EducationMamas);