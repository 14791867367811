import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
// import CourseSearch from './CourseSearch';
// import CoursePrice from './CoursePrice';
// import PopularCourse from './PopularCourse';
// import CourseTag from './CourseTag';
import CourseCategory from './CourseCategory';
import { Styles } from '../styles/courseCategory.js';


class CourseSidebar extends Component {
    render() {
        const { grades , filterCourses , visibleGradeList} = this.props
        return (
            <Styles>
                <div className="course-sidebar">
                    <Row>
                        {/* <Col md="12">
                        <CourseSearch />
                    </Col> */}
                        <Col md="12">
                            <div className="course-category">
                                <h5>Filter Courses</h5>
                                <ul className="category-item list-unstyled">
                                    {grades.length && grades.map((grade, key) =>
                                        <li className="check-btn" key={key} onClick={(e) => filterCourses(e, grade.grade)}>
                                            <label><input checked={visibleGradeList.includes(grade.grade)} type="checkbox" className="check-box" /> {grade.grade}</label>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            {/* <CourseCategory /> */}
                        </Col>
                        {/* <Col md="12">
                        <CoursePrice />
                    </Col> */}
                        {/* <Col md="12">
                        <PopularCourse />
                    </Col> */}
                        {/* <Col md="12">
                        <CourseTag />
                    </Col> */}
                    </Row>
                </div>
            </Styles>
        )
    }
}

export default CourseSidebar
