import React, { Component } from 'react';
import { Container, Row, Col, ButtonGroup, Spinner, Accordion, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Styles } from "../components/styles/gradeSubjectSelection.js";
import $ from 'jquery';

class GradeSubjectSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gradesData: "",
            grades: [],
            filteredCourses: {},
            sortedCourses: "",
            selectedGrade: "",
            showSubjectDropdown: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.gradesData !== nextProps.demoGrades) {
            debugger;
            const { demoGrades } = nextProps;
            var sortedCoursesArray = [];
            var coursesKeys = Object.keys(demoGrades.gradescourses);
            coursesKeys.map(course => {

                var gradeArray = [];
                var courseKey = Object.keys(demoGrades.gradescourses[course]);
                var gradeName = demoGrades.gradescourses[course][courseKey[0]].grade;
                courseKey.map(_course => {
                    var thisCourse = demoGrades.gradescourses[course][_course];
                    gradeArray.push(thisCourse);
                })
                sortedCoursesArray.push({
                    grade: gradeName,
                    courses: gradeArray,
                    visible: true
                })

            })
            return {
                gradesData: demoGrades,
                grades: coursesKeys,
                sortedCourses: sortedCoursesArray
            };
        }
        return null;
    }
    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if (prevState.gradesData !== nextProps.coursesDataOnlineSchool) {
    //         const { coursesDataOnlineSchool } = nextProps;
    //         var sortedCoursesArray = [];
    //         var coursesKeys = Object.keys(coursesDataOnlineSchool.coursesData.filteredCourses);
    //         coursesKeys.map(course => {

    //             var gradeArray = [];
    //             var courseKey = Object.keys(coursesDataOnlineSchool.coursesData.filteredCourses[course]);
    //             var gradeName = coursesDataOnlineSchool.coursesData.filteredCourses[course][courseKey[0]].grade;
    //             courseKey.map(_course => {
    //                 var thisCourse = coursesDataOnlineSchool.coursesData.filteredCourses[course][_course];
    //                 gradeArray.push(thisCourse);
    //             })
    //             sortedCoursesArray.push({
    //                 grade: gradeName,
    //                 courses: gradeArray,
    //                 visible: true
    //             })

    //         })
    //         return {
    //             gradesData: coursesDataOnlineSchool,
    //             grades: coursesDataOnlineSchool.grades,
    //             filteredCourses: coursesDataOnlineSchool.coursesData.filteredCourses,
    //             sortedCourses: sortedCoursesArray
    //         };
    //     }
    //     return null;
    // }
    gradeClicked = (grade) => {
        // window.scrollTo(0, 60); //scroll to top
        var element = document.getElementById("box");

        element.scrollIntoView();
        if (grade !== "") {
            this.setState({
                showSubjectDropdown: true,
                selectedGrade: grade
            })
        }
        else {
            this.setState({
                showSubjectDropdown: false,
                selectedGrade: grade
            })
        }
    }
    courseCLicked = (courseName) => {
        debugger;
        this.state.sortedCourses.map(_grade => {
            // console.log(grade);
            if (_grade.grade == this.state.selectedGrade) {

                console.log(_grade)
                debugger;
                _grade.courses.map(course => {
                    if (course.course_name.indexOf(courseName) > -1) {
                        this.props.history.push('course-details/' + course.id);
                    }
                })
            }
        })

    }
    componentDidUpdate() {
        // const courseButton = document.querySelectorAll(".allCourses");
        // courseButton.forEach(button => {
        //     button.addEventListener("click", () => {
        //         button.classList.toggle("active");
        //         const content = button.nextElementSibling;

        //         if (button.classList.contains("active")) {
        //             content.className = "course-content show";
        //             content.style.maxHeight = content.scrollHeight + "px";
        //         } else {
        //             content.className = "course-content";
        //             content.style.maxHeight = "0";
        //         }
        //     });
        // });

        // $('.collapse')
        //     .on('shown.bs.collapse', function () {
        //         $(this)
        //             .parent()
        //             .find(".fa-sort-down")
        //             .removeClass("fa-sort-down")
        //             .addClass("fa-sort-up");
        //     })
        //     .on('hidden.bs.collapse', function () {
        //         $(this)
        //             .parent()
        //             .find(".fa-sort-up")
        //             .removeClass("fa-sort-up")
        //             .addClass("fa-sort-down");
        //     });
    }
    render() {
        var design = 3;
        const { grades, showSubjectDropdown } = this.state;
        console.log("Sorted Courses Array" , this.state.sortedCourses);
        return (
            <Styles>
                {design === 1 ?
                    <div className="grade-subject-selection">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="12 text-center">
                                    <h5 className="mt-2">Select a class you want to demo</h5>
                                    <p></p>
                                </Col>
                                <Col md="4" className="">
                                    <div>
                                        <label className="">
                                            Select a grade:
                          </label>
                                        {grades.length !== 0 ?
                                            <select
                                                className="custom-select"
                                                name="staffId"
                                                onChange={this.gradeClicked}
                                            >
                                                <option value="">
                                                    Select a Grade
                                </option>
                                                {grades.length && grades.map((grade, index) =>
                                                    <option key={index} value={grade}>{grade}</option>
                                                )}

                                            </select>

                                            :
                                            <React.Fragment>
                                                <br />
                                                <Spinner className="centered-spinner" animation="border" variant="light" />
                                            </React.Fragment>
                                        }
                                    </div>
                                    <div className="mt-4">
                                        {showSubjectDropdown ?
                                            <select
                                                style={{ marginTop: 10 }}
                                                className="custom-select"
                                                onChange={this.courseCLicked}
                                            >
                                                <option>
                                                    Select a Subject
                            </option>
                                                <option value="English">English</option>
                                                <option value="Math">Math</option>
                                                {
                                                    this.state.selectedGrade == "Grade 2" ||
                                                        this.state.selectedGrade == "Grade 3" ||
                                                        this.state.selectedGrade == "Grade 4" ||
                                                        this.state.selectedGrade == "Grade 5" ||
                                                        this.state.selectedGrade == "Grade 6" ||
                                                        this.state.selectedGrade == "Grade 7" ||
                                                        this.state.selectedGrade == "Grade 8" ||
                                                        this.state.selectedGrade == "Grade 9" ?
                                                        <option value="Creative Writing">Creative Writing</option>
                                                        : null
                                                }

                                                {
                                                    this.state.selectedGrade == "Grade 9" ||
                                                        this.state.selectedGrade == "Grade 10" ?
                                                        <option value="Chemistry">Chemistry</option>

                                                        : null

                                                }

                                                {
                                                    this.state.selectedGrade == "Kindergarten" ||
                                                        this.state.selectedGrade == "Grade 1" ||
                                                        this.state.selectedGrade == "Grade 2" ||
                                                        this.state.selectedGrade == "Grade 3" ||
                                                        this.state.selectedGrade == "Grade 4" ||
                                                        this.state.selectedGrade == "Grade 5" ||
                                                        this.state.selectedGrade == "Grade 6" ||
                                                        this.state.selectedGrade == "Grade 7" ||
                                                        this.state.selectedGrade == "Grade 8" ?
                                                        <option value="Science">Science</option> : null
                                                }


                                            </select>

                                            : null}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    : design === 2 ?
                        !showSubjectDropdown ?
                            <div className="grade-subject-selection-2" id="box">
                                <Container>
                                    <Row className="justify-content-center">
                                        <Col md="12 text-center">
                                            <div className="sec-title text-center">
                                                <h4><span>Step 1:</span> Select a class you want to demo</h4>
                                            </div>
                                        </Col>
                                    </Row>
                                    {grades.length !== 0 ?
                                        grades.length && grades.map((grade, index) =>
                                            grade == "General" || grade == "Pre-K" ? null :
                                                <Row className="justify-content-center" key={index}>

                                                    <Col md="6" onClick={() => this.gradeClicked(grade)}>
                                                        <button className="allCourses">{grade}</button>
                                                    </Col>
                                                </Row>

                                        )
                                        :
                                        <Row className="justify-content-center">

                                            <Col md="12 text-center">
                                                <br />
                                                <Spinner className="centered-spinner" animation="border" variant="dark" />
                                            </Col>
                                        </Row>
                                    }

                                </Container>
                            </div>
                            :
                            <div className="grade-subject-selection-2">

                                <Container>
                                    <Row className="justify-content-center">

                                        {/* <Col md="6 text-left">
                                            <label className="btn btn-sm" style={{ background: "#182B49", color: "#fff", border: "none", fontSize: 15, marginBottom: 15, cursor: "pointer" }} onClick={() => this.setState({ showSubjectDropdown: false })}><i className="fas fa-long-arrow-alt-left"></i> Back</label>
                                        </Col> */}
                                        <Col md="12 text-center">
                                            <div className="sec-title text-center">
                                                <h4 className="mt-2"><span>Step 2:</span> Select a subject for "{this.state.selectedGrade}"</h4>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-center">
                                        <Col md="6" onClick={() => this.courseCLicked("English")}>
                                            <button className="allCourses">English</button>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-center">
                                        <Col md="6" onClick={() => this.courseCLicked("Math")}>
                                            <button className="allCourses">Math</button>
                                        </Col>
                                    </Row>

                                    {
                                        this.state.selectedGrade == "Grade 2" ||
                                            this.state.selectedGrade == "Grade 3" ||
                                            this.state.selectedGrade == "Grade 4" ||
                                            this.state.selectedGrade == "Grade 5" ||
                                            this.state.selectedGrade == "Grade 6" ||
                                            this.state.selectedGrade == "Grade 7" ||
                                            this.state.selectedGrade == "Grade 8" ||
                                            this.state.selectedGrade == "Grade 9" ?
                                            <React.Fragment>
                                                <Row className="justify-content-center">
                                                    <Col md="6" onClick={() => this.courseCLicked("Creative Writing")}>
                                                        <button className="allCourses">Creative Writing</button>
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-center">
                                                    <Col md="6" onClick={() => this.courseCLicked("Science")}>
                                                        <button className="allCourses">Science</button>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>

                                            : null
                                    }

                                    {

                                        this.state.selectedGrade == "Grade 10" ?
                                            <Row className="justify-content-center">
                                                <Col md="6" onClick={() => this.courseCLicked("Chemistry")}>
                                                    <button className="allCourses">Chemistry</button>
                                                </Col>
                                            </Row>

                                            : null

                                    }

                                    <Row className="justify-content-center">
                                        <Col md="6 text-center">
                                            <button className="btn btn-lg" style={{ background: "#182B49", color: "#fff", border: "none", cursor: "pointer", width: "50%", marginTop: 60, fontSize: 20 }} onClick={() => this.setState({ showSubjectDropdown: false })}><i className="fas fa-long-arrow-alt-left"></i> Go Back</button>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        :
                        <div className="grade-subject-selection-3" id="box">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="12 text-center">
                                        <div className="sec-title text-center">
                                            <h4>Get 1 free class with a Tutor</h4>
                                            <label>select a grade</label>
                                            <br />
                                            <i class="fas fa-arrow-down"></i>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center mt-3">
                                    <Col md="6">
                                        <Accordion>
                                            {grades.length !== 0 ?
                                                grades.length && grades.map((grade, index) =>
                                                    grade == "General" || grade == "Pre-K" ? null :
                                                        <Card>
                                                            <Accordion.Toggle as={Card.Header} style={grade === this.state.selectedGrade ? { background: "#182B49" } : null} eventKey={index} onClick={(e) => {
                                                                if (grade === this.state.selectedGrade) {
                                                                    this.setState({
                                                                        selectedGrade: null
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        selectedGrade: grade
                                                                    })
                                                                }
                                                            }}>
                                                                <label> {grade} </label>
                                                            </Accordion.Toggle>

                                                            <Accordion.Collapse eventKey={index} className="text-center">
                                                                <Card.Body>
                                                                    <div className="sec-title text-center">
                                                                        <label className="mt-2 text-lg" style={{ color: "#b9d3f7" }}>Select a subject</label>
                                                                    </div>
                                                                    <div className="mb-5">

                                                                        <button onClick={() => this.courseCLicked("English")} className="subject">English</button>
                                                                        <button onClick={() => this.courseCLicked("Math")} className="subject">Math</button>

                                                                        {
                                                                                this.state.selectedGrade == "Grade 1" ||
                                                                                this.state.selectedGrade == "Grade 2" ||
                                                                                this.state.selectedGrade == "Grade 3" ||
                                                                                this.state.selectedGrade == "Grade 4" ||
                                                                                this.state.selectedGrade == "Grade 5" ||
                                                                                this.state.selectedGrade == "Grade 6" ||
                                                                                this.state.selectedGrade == "Grade 7" ||
                                                                                this.state.selectedGrade == "Grade 8" ||
                                                                                this.state.selectedGrade == "Grade 9" ?
                                                                                <React.Fragment>
                                                                                    <button onClick={() => this.courseCLicked("Creative Writing")} className="subject">Creative Writing</button>
                                                                                    <button onClick={() => this.courseCLicked("Science")} className="subject">Science</button>
                                                                                </React.Fragment>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                ) :
                                                <React.Fragment>
                                                    <div className="text-center">
                                                        <Spinner className="centered-spinner" animation="border" variant="dark" />
                                                    </div>
                                                </React.Fragment>}
                                        </Accordion>
                                    </Col>
                                </Row>

                            </Container>
                        </div>
                }
            </Styles>
        );
    }
}

const mapStateToProps = state => ({
    coursesDataOnlineSchool: state.webRed.coursesDataOnlineSchool,
    demoGrades: state.webRed.demoGrades
})
export default withRouter(connect(mapStateToProps, null)(GradeSubjectSelection));