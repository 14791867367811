import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
.hrCustom {
    border: solid;
    border-color: #000;
    border-width: 1px;
  }
  .CongratulationComponent {
    text-align: left;
    margin-top: 40px;
  
    .hrCustom {
      border: solid;
      border-color: #000;
      border-width: 8px;
    }
  
    .paragraphText {
      font-size: 20px;
    }
    .Section1 {
      .congratulationHeading {
        color: red;
      }
      .welcomeHeading {
        color: #1f78b4;
        font-weight: 600;
      }
    }
    .Section2 {
      margin-top: 80px;
      .imageSection2 {
        height: 650px;
      }
      .ReadyHeading {
        margin-right: 10px;
        padding: 5px;
      }
      .YellowBox {
        color: #fff;
        padding: 5px;
        background-color: ${colors.green};
        vertical-align: middle;
      }
      .paragraphText p {
        font-size: 15px;
        color: ${colors.text2};
        line-height: 25px;
      }
    }
    .Section3 {
      margin-top: 80px;
      .imageSection3Cover {
        width: 100%;
        height: 50%;
      }
      .backImage {
        background-image: url(/assets/images/LM/Picture5.png);
        height: 50vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
      .imageSection3Zoom {
        position: absolute;
        top: 40px;
        right: 15px;
        width: 400px;
      }
      .marginForMobile {
        margin-top: -200px;
      }
      .YellowBox {
        color: #fff;
        padding: 5px;
        background-color: ${colors.green};
        vertical-align: middle;
      }
      .paragraphText p {
        font-size: 15px;
        color: ${colors.text2};
        line-height: 25px;
      }
    }
    .Section4 {
      margin-top: 80px;
      .imageSection3Cover {
        width: 100%;
      }
      .backImage {
        background-image: url(/assets/images/LM/Picture6.png);
        height: 80vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
      .imageSection3Zoom {
        position: absolute;
        top: 40px;
        right: 15px;
        width: 400px;
      }
      .YellowBox {
        padding: 5px;
        background-color: #ffb400;
        vertical-align: middle;
      }
  
      .paragraphText p {
        font-size: 15px;
        color: ${colors.text2};
        line-height: 25px;
      }
    }
    .Section5 {
      margin-top: 80px;
      .imageSection3Cover {
        width: 100%;
      }
      .backImage {
        background-image: url(/assets/images/LM/Picture7.jpeg);
        height: 80vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
      .imageSection3Zoom {
        position: absolute;
        top: 40px;
        right: 15px;
        width: 400px;
      }
      .YellowBox {
        padding: 5px;
        background-color: #ffb400;
        vertical-align: middle;
      }
      .paragraphText p {
        font-size: 15px;
        color: ${colors.text2};
        line-height: 25px;
      }
    }
    .Section6 {
      margin-top: 80px;
      background-image: url(/assets/images/LM/Picture8.jpg);
      height: 80vh;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      .imageSection3Cover {
        width: 100%;
      }
      .blackBackgroundDesign {
        opacity: 0.8;
        color: #fff;
        background-color: #000;
      }
      .imageSection3Zoom {
        position: absolute;
        top: 40px;
        right: 15px;
        width: 400px;
      }
      .YellowBox {
        padding: 5px;
        background-color: #ffb400;
        vertical-align: middle;
      }
      .paragraphText p {
        font-size: 15px;
        color: #fff;
        line-height: 25px;
      }
      .ReadyHeading {
        color: #ffb400;
      }
    }
    .Section7 {
      margin-top: 80px;
      .imageSection3Cover {
        width: 100%;
      }
      .backImage {
        background-image: url(/assets/images/LM/Picture9.png);
        height: 80vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
      .blackBackgroundDesign {
        opacity: 0.8;
        color: #fff;
        background-color: #000;
      }
      .imageSection3Zoom {
        position: absolute;
        top: 40px;
        right: 15px;
        width: 400px;
      }
      .YellowBox {
        padding: 5px;
        background-color: #ffb400;
        vertical-align: middle;
      }
      .paragraphText p {
        font-size: 15px;
        color: ${colors.text2};
        line-height: 25px;
      }
      .ReadyHeading {
        color: #ffb400;
      }
      .imageList {
        line-height: 1.5em;
        list-style-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4621/treehouse-marker.png);
      }
    }
    .Section8 {
      margin-top: 80px;
      // h1 {
      //   color: #ffb400;
      // }
      h4 {
        color: ${colors.black2};
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        position: relative;
        &:before {
            position: absolute;
            content: "";
            background: ${colors.green};
            width: 50px;
            height: 2px;
            bottom: 0;
            left: 0;
        }

        @media(max-width: 767px) {
            font-size : 17px;
        }
    }
      h5 {
        color: ${colors.black2};
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        position: relative;
        &:before {
            position: absolute;
            content: "";
            background: ${colors.green};
            width: 50px;
            height: 2px;
            bottom: 0;
            left: 0;
        }

        @media(max-width: 767px) {
            font-size : 17px;
        }
    }
      label {
        // font-weight: bold;
        // font-size: 23px;
        color: #8da0cb;
      }
      p {
        font-size: 15px;
        color: ${colors.text2};
        line-height: 25px;
      }
    }
  }
  
  @media(max-width: 767px) {
    //mobile fixes
    .CongratulationComponent {
      text-align: left;
      margin-top: 40px;
      .hrCustom {
        border: solid;
        border-color: #1f78b4;
        border-width: 1px;
      }
      .Section1 {
        .congratulationHeading {
          font-size: 30px;
          color: red;
        }
        .welcomeHeading {
          color: #1f78b4;
          font-weight: 600;
        }
      }
      .Section2 {
        .imageSection2 {
          height: 500px;
        }
        .ReadyHeading {
          margin-right: 10px;
          padding: 5px;
        }
        .YellowBox {
          padding: 5px;
          background-color: #ffb400;
          vertical-align: middle;
        }
        .paragraphText {
          font-size: 20px;
        }
      }
      .Section3 {
        .imageSection3Cover {
          width: 100%;
          height: 50%;
        }
        .backImage {
          height: 50vh;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }
        .imageSection3Zoom {
          position: absolute;
          top: 10px;
          right: 5px;
          width: 150px;
        }
        .marginForMobile {
          margin-top: 0px;
        }
      }
      .Section4 {
        .backImage {
          height: 60vh;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }
      }
      .Section5 {
        margin-top: 80px;
        .imageSection3Cover {
          width: 100%;
        }
        .backImage {
          height: 60vh;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }
      }
      .Section7 {
        margin-top: 80px;
        .imageSection3Cover {
          width: 100%;
        }
        .backImage {
          height: 60vh;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }
      }
    }
  }  
`;