import React, { Component } from 'react';
import Datas from '../data/service/service-box.json';
import { Container, Row, Col } from 'react-bootstrap';
import { isMobileBrowser } from "../actions/utility";
import { Styles } from "./styles/serviceBox.js";

class ServiceBox extends Component {
    render() {
        return (
            <Styles>
                {/* Service Box */}
                <section className="service-area">
                {isMobileBrowser() ?
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>{Datas.secTitle}</h4>
                                </div>
                                <img src={process.env.PUBLIC_URL + `/assets/images/LM/University/uniCover.jpeg`} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                        :
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>{Datas.secTitle}</h4>
                                </div>
                            </Col>
                            {
                                Datas.dataList.map((data, i) => (
                                    <Col md="4" key={i}>
                                        <div className="service-box text-center" style={{height: 320}}>
                                            <div className="">
                                                <img src={`/assets/images/LM/University/${data.img}`} alt="University Logos" />
                                            </div>
                                            {/* <div className="box-icon">
                                                <i className={data.boxIcon}></i>
                                            </div> */}
                                            <div className="box-title" style={{marginTop: 10}}>
                                                <h6>{data.title}</h6>
                                                <p>{data.subTitle}</p>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
    }
                </section>
            </Styles>
        )
    }
}

export default ServiceBox
