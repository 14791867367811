import React, { Component } from 'react';
import HeaderTwo from '../components/HeaderTwo';
import { BreadcrumbBox } from '../components/common/Breadcrumb';
import FooterTwo from '../components/FooterTwo';
import { Styles } from "./styles/privacyPolicy.js";
import { Container, Row, Col } from 'react-bootstrap';

class TermsAndCondition extends Component {

    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper privacy-policy-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Terms & Conditions" />

                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <div className="event-details-content">
                                        <div className="heading">
                                            <h4>Terms and Conditions</h4>
                                        </div>

                                        <div className="event-details-overview">

                                            <h5 className="">Parents’ involvement in class matters:</h5>

                                            <p>We encourage parents to help their child excel, and participate with the child after the class has been completed. However, we discourage parents’ interference in how the teacher is running the class. We discourage the interference because the parent would unknowingly, and unintentionally be bringing in a biased point of view, which might not be a part of the learning journey that has been planned for the class</p>

                                            <br />

                                            <h5>Our teachers cover all chapter in the curriculum in a particular sequence</h5>

                                            <p>Since we are catering a collective group of students, the teachers will follow a set learning journey of the Chapters within the curriculum. This is to ensure that all the aspects of each topic are covered. If your child already knows the work, he is encouraged to take the test for that topic and move on to the next topic.</p>

                                            <br />

                                            <h5>Teachers use the mute option </h5>

                                            <p>Our teachers are encouraged to use the mute option when teaching a class. This helps control the ambient noise coming from the other student, and maintains a particular decorum of the class. This strategy also avoids any embarrassing situation for a student if their mic picks up comment or a noise from the ecosystem. Students are encouraged to use the "raise hand" option is they do not understand something, or if they have a question or comment and would like to contribute.</p>

                                            <br />

                                            <h5>Teachers may spot check of homework </h5>

                                            <p>Since our technology uses autocorrect for its tests (which are a part of the homework), the teacher may decide to conduct regular spot checks to see how the students are progressing.
                                            Students are expected to do the assigned homework. The majority of the homework and corresponding videos that explain the concepts. The teacher reserves the right to conduct unannounced spot check to see if the child has completed their homework. </p>

                                            <br />

                                            <h5>We discourage late arrivals</h5>


                                            <p>We discourage students coming late to class for several reasons, which include the possibility that a student might miss a key point discussed by the teacher; it would be unfair to other students if the late arrival is catered to during class.
                                            In case a student arrives late to class, we have several learning resources in the form of curated videos that are available at our system to cover up anything the student might have missed.
</p>

                                            <br />

                                            <h5>Additional time to facilitate the student requires special permission from the Administration team</h5>

                                            <p>In very rare cases, the teacher may agree to give additional personal time to facilitate a student who is falling behind and needs extra attention. However, in such rare cases, Lincoln Method’s Administration needs to approve special permission for added time given by the teacher. Please note that there might be an additional cost (depending upon the nature of the request).</p>


                                            <br />

                                            <h5>How absentees can catch up on missed work</h5>

                                            <p>It is understandable that on a certain day, a student will be absent. Parents will be notified of what work is missed. It is the students’ responsibility to review the work and watch any supporting videos, and complete any work that was assigned for that day.
                                            If the student is falling behind and wants an additional one-on-one session with the teacher, then the school's administration needs to be contacted, so that the teacher's schedule can be managed and an additional class can be put together for the student.
                                            We discourage the practice of a special one-on-one class with the teacher because it is difficult to schedule a time with the teacher since they have classes continuously throughout the day. To discourage any requests for one-on-one classes, we will be charging an additional fee for that extra class.
</p>

                                            <br />

                                            <h5>Bullying is not appreciated</h5>

                                            <p>Since the younger generation tends to have elevated levels of excitement, there can be cases when a student says something to another student during the Zoom session, which could come across as being hurtful. In such cases, our Administration will try to bring both students and their parents together and let them sort it out.
                                            We would like to highlight that we consider all students who come to our school are from respectable families and would be able to follow a proper code of conduct.
</p>

                                            <br />

                                            <h5>Teachers will be able to provide a limited role as tech support during a class</h5>

                                            <p>It is understandable that students who are new to Zoom are not aware of the feature and functionality of Zoom. It will be unfair to other students is teachers to help a new student get their bearings on how to use Zoom during a live class session.
                                            This is why we highly recommend that students practice how to login into their account before their first class.
</p>
                                            <br />

                                            <h5>How will public holidays be counted in the billing cycle</h5>

                                            <p>Our billing cycle operates just like any other school that charges its fees on monthly basis. Parents will receive their fee bill at the beginning of their monthly billing cycle. As a sign of respect, the all stakeholders, our Online School will not offer classes on public holidays. Our teachers will continue the learning journey with the students after they return from the public holiday(s). Students will have access to our entire online system at all times, and they are welcome to carry out their learning journey independently if they choose to do so. </p>
                                            <br />

                                            <h5>No refunds</h5>

                                            <p>Since the school is run as a community project (as is reflected by the lowered fees that we charge), it is not feasible for us to issue any refunds or replacement classes.</p>

                                        </div>
                                    </div>
                                </Col>

                                {/* <Col lg="3" md="4" sm="12">
                                    <div className="event-details-sidebar">
                                        <Row>
                                            <Col md="12">
                                                <div className="event-sidebar-info">
                                                    <div className="event-sidebar-timer text-center">
                                                        <Timer initialTime={1040 * 970 * 980} direction="backward">
                                                            <p><Timer.Days /><span>Days</span></p>
                                                            <p><Timer.Hours /><span>Hours</span></p>
                                                            <p><Timer.Minutes /><span>Minutes</span></p>
                                                        </Timer>
                                                    </div>
                                                    <ul className="list-unstyled event-info-list">
                                                        <li>Start Date: <span>Aug 21, 2020</span></li>
                                                        <li>Time: <span>08:30am</span></li>
                                                        <li>Seat: <span>220</span></li>
                                                        <li>Place: <span>Ontario,CA</span></li>
                                                        <li>Organizer: <span>David Young</span></li>
                                                        <li>Phone: <span>+1 (396) 486 4709</span></li>
                                                        <li>Email: <span>email@me.com</span></li>
                                                        <li>Website: <span>www.mydomain.com</span></li>
                                                    </ul>
                                                    <button type="button" className="buy-btn">Buy Ticket</button>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <PopularCourse />
                                            </Col>
                                            <Col md="12">
                                                <CourseTag />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </section>
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default TermsAndCondition