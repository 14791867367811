import React, { Component } from 'react';
import HeaderTwo from '../components/HeaderTwo';
import { BreadcrumbBox } from '../components/common/Breadcrumb';
import FooterTwo from '../components/FooterTwo';
import { Styles } from "./styles/privacyPolicy.js";
import { Container, Row, Col } from 'react-bootstrap';
import HeroSlider from './HeroSlider';
import IconBox from './IconBox';
import AllVideosTestimonials from './AllVideoTestimonials';
import SuccessStories from '../pages/SuccessStories/SuccessStories';
import AboutForKnock from './AboutForKnock';
import Register from '../pages/account/Register';

class KnockontheDoor extends Component {

    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper privacy-policy-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    {/* <BreadcrumbBox title="Corporate Employee Benefits" /> */}

                    <HeroSlider />

                    <IconBox />

                    <AboutForKnock />
                    
                    <SuccessStories />

                    <AllVideosTestimonials />


                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <div className="event-details-content">
                                        {/* <div className="heading">
                                            <h4>Knock on the door</h4>
                                        </div> */}

                                        <div className="event-details-overview">

                                            {/* <h5 className="">Who we are?</h5>
                                            
                                            <p>We have conducted 17,000 classes</p>
                                            <p>We provide on-demand learning courses</p>
                                            <p>We provide classes for students from Kindergarten all the way up to Grade 12</p>
                                            <p>We have hundreds of success stories <a href="https://pk.lincolnmethod.com/wall-of-fame">https://pk.lincolnmethod.com/wall-of-fame</a> </p>
                                            <p>What parents say about us <a href="https://pk.lincolnmethod.com/all-testimonials">https://pk.lincolnmethod.com/all-testimonials</a></p> */}
                                            {/* <ol>
                                                <li>We have conducted 17,000 classes</li>
                                                <li>We provide on-demand learning courses for</li>
                                                <li>We provide classes for students from Kindergarten all the way up to Grade 12</li>
                                                <li>We have hundreds of success stories <a href="https://pk.lincolnmethod.com/wall-of-fame">https://pk.lincolnmethod.com/wall-of-fame</a> </li>
                                                <li>What parents say about us <a href="https://pk.lincolnmethod.com/all-testimonials">https://pk.lincolnmethod.com/all-testimonials</a></li>

                                            </ol> */}

                                            {/* <br />

                                            <h5>What we can do for your employees?</h5>
                                            <p>90% of all parents send their kids for tuitions</p>
                                            <ol>
                                                <li>We can help employed parents who are very concerned about their kids’ education</li>
                                                <li>We can remove the hassle of finding a good tutor</li>
                                                <li>We can make education more accessible and convenient</li>
                                                <li>We can help parents to improve their children’s grades</li>
                                            </ol> */}

                                            <br />

                                            <h5>There is no cost for the company</h5>

                                            <p>The company will not bear any cost. Employed parents can sign up their children directly for one free demo class, conducted on Zoom. Parents can choose to enroll their children for the relevant grade and subject for Online Tutoring classes.</p>
                                            {/* <div className="event-details-banner">
                                                <img width="600" height="600" src={process.env.PUBLIC_URL + `/assets/images/LM/research/1.jpeg`} alt="" className="img-fluid" />
                                            </div> */}
                                            <br />

                                            <h5>When can we come and present to a handful of employees, who are also parents?</h5>

                                            <p></p>

                                            <br />

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <Register />
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default KnockontheDoor