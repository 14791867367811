import React, { Component } from "react";
// import Datas from '../../data/instructor/instructor.json';
import { Link } from "react-router-dom";
import { Container, Row, Col, Spinner, Image } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/educationMama.js";
import { getImgURL } from "../../actions/utility";
import { setTeacherImgAndId, triggerEnrollPopup } from "../../actions/webRedActions";
import ModalImage from "react-modal-image";
import { connect } from "react-redux";
import { colors } from "../../components/common/element/elements";
import Certificate from "../Certificate/Certificate";

class ZambianTeachers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teachersData: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    debugger;
    if (prevState.teachersData !== nextProps.allTutorsInfo) {
      debugger;
      return {
        teachersData: nextProps.allTutorsInfo.EM,
      };
    }
    return null;
  }

  setTeacherInfoLocalStorage = (data) => {
    localStorage.setItem("teacherInfo", JSON.stringify(data));
  };
  render() {
    // onClick={() => this.props.setTeacherImgAndId(data.image, data.id , data.user_name)}
    const { teachersData } = this.state;
    debugger;
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper trainTutor">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcroumb */}
          {/* <BreadcrumbBox title="Education Mama" /> */}

          <div>
            <div
              className="text-left bg-image-slides"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/1.png)`,
                backgroundColor: colors.finlandColor,
              }}
            >
              <Container>
                <Row>
                  <Col md="6">
                    <img
                      className="img-fluid logo"
                      src={`${process.env.PUBLIC_URL}/assets/images/LM/LogoWhite.png`}
                    />
                    <h1 className="mainHeading display-1 text-white font-weight-bold">Zambian</h1>
                    <h3 className="subHeading display-4 text-white">
                      Teacher <br /> certification <br /> program
                    </h3>
                  </Col>
                </Row>
              </Container>
            </div>
            <Col
              md="12"
              className="subImageBanner"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/1.png)`,
              }}
            ></Col>
          </div>

          <div className="text-left bg-image-slides">
            <Container>
              <Row className="justify-content-center">
                <Col md="12">
                  <h1 className="headingTutor display-4 font-weight-bold">
                    Do you know why Finnish teachers are highly valued?
                  </h1>
                  <br />
                  <p className="">
                    Finnish teachers go through extensive multi-disciplinary training, which makes
                    them a role model for education sector globally. The extensive training that
                    they go through makes Finnish teachers make them a role model for the education
                    sector globally. This means that they are well equipped to address the
                    classroom, academic needs, as well as social, and emotional learning needs
                    (SEL).
                  </p>
                </Col>
                <Col md="6" className="text-center">
                  <Link to="/course-details/WZ4pK7KlyNkVvd6">
                    <button className="signUpButtonTutor mt-5">APPLY FOR OUR NEXT COHORT</button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>

          <div>
            <div
              className="text-left bg-image-slides"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/2.png)`,
                backgroundColor: colors.finlandColor,
              }}
            >
              <Container>
                <Row>
                  <Col md="6">
                    <h1 className="display-2 text-white font-weight-bold">
                      Now you can also apply to receive Teacher Certification Training from Finland
                    </h1>
                    <div className="centerOnMobile">
                      <Link to="/course-details/WZ4pK7KlyNkVvd6">
                        <button className="signUpButtonTutor mt-5">
                          APPLY FOR OUR NEXT COHORT
                        </button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Col
              md="12"
              className="subImageBanner"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/2.png)`,
              }}
            ></Col>
          </div>

          <div className="text-left bg-image-slides">
            <Container>
              <Row className="justify-content-center">
                <Col md="12">
                  <h1 className="headingTutor display-4 font-weight-bold">
                    Why should you get train from Finland?
                  </h1>
                  <br />
                  <p className=" ">
                    The
                    <span className="font-weight-bold">Teacher Certification Program </span> ensures
                    that you are providing high standards of competence and pedagogy training in
                    class management, and you are excelling in maintaining quality education based
                    on international standards. You will learn how to become as effective as teacher
                    from Finland.
                  </p>
                </Col>
                <Col md="6" className="text-center">
                  <Link to="/course-details/WZ4pK7KlyNkVvd6">
                    <button className="signUpButtonTutor mt-5">APPLY FOR OUR NEXT COHORT</button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>
          <hr />
          <div>
            <div
              className="text-left bg-image-slides"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/5.png)`,
                backgroundSize: "contain",
                backgroundPosition: "right",
                height: "750px",
              }}
            >
              <Container>
                <Row>
                  <Col md="6">
                    <h1
                      style={{ color: colors.finlandColor }}
                      className="display-4 font-weight-bold"
                    >
                      Teachers who understand brain-science, get paid more
                    </h1>
                    <p className="mt-3">
                      High-paid teachers know how the brain functions, that is why they are better
                      equipped to help students succeed.
                    </p>
                    <p>
                      You can become a high-paid teacher too by learning how to stimulate the
                      student's brain.
                    </p>
                    <p>In our Teacher Certification Program, we will discuss how:</p>
                    <ul className="list-unstyled">
                      <li>
                        <i className="fas fa-arrow-right"></i>the brain consumes and processes
                        information
                      </li>
                      <li>
                        <i className="fas fa-arrow-right"></i>The brain stores information in long
                        term and short term memory
                      </li>
                      <li>
                        <i className="fas fa-arrow-right"></i>The brain retrieves information
                      </li>
                    </ul>
                    <div className="centerOnMobile">
                      <Link to="/course-details/WZ4pK7KlyNkVvd6">
                        <button className="signUpButtonTutor mt-5">
                          APPLY FOR OUR NEXT COHORT
                        </button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Col
              md="12"
              className="subImageBanner"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/5.png)`,
              }}
            ></Col>
          </div>
          <hr />
          <div className="text-left bg-image-slides">
            <Container>
              <Row>
                <Col md="12">
                  <h1 style={{ color: colors.finlandColor }} className="display-4 font-weight-bold">
                    You will learn how to...
                  </h1>
                </Col>

                <Col md="12" className="">
                  <div className="">
                    {/* <h3 style={{ color: colors.finlandColor }}>
                      <span className="font-weight-bold">STUDENT</span>{" "}
                      <span>DASHBOARD</span>
                    </h3>
                    <p className=" " style={{ fontSize: 20 }}>
                      It comprise of all relevant material required for planning
                      and conducting a class such as:{" "}
                    </p>{" "} */}
                    <ul className="list-unstyled mt-2">
                      <li>
                        <i className="fas fa-arrow-right"></i>Help students set smart goals
                      </li>
                      <li>
                        <i className="fas fa-arrow-right"></i>Create efffective learning timelines
                      </li>
                      <li>
                        <i className="fas fa-arrow-right"></i>Stimulate a bored student
                      </li>
                      <li>
                        <i className="fas fa-arrow-right"></i>Motivates student who are giving up
                      </li>
                      <li>
                        <i className="fas fa-arrow-right"></i>End a class session on a positive note
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <hr />
          <div className="text-left bg-image-slides">
            <Container>
              <Row className="justify-content-center">
                <Col md="12">
                  <h1
                    style={{ color: colors.finlandColor }}
                    className="text-center display-4 font-weight-bold"
                  >
                    Learn how teachers can become heroes
                  </h1>
                </Col>

                <Col md="6" className="mt-4">
                  <div className="">
                    <video width="100%" controls>
                      <source
                        src="https://lincolnmethod.s3.amazonaws.com/influencer/Jackub.mp4"
                        type="video/mp4"
                      />
                      <source
                        src="https://lincolnmethod.s3.amazonaws.com/influencer/Jackub.mp4"
                        type="video/ogg"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <hr />
          <div className="text-left bg-image-slides">
            <Container>
              <Row className="justify-content-center">
                <Col md="12">
                  <h1
                    style={{ color: colors.finlandColor }}
                    className="text-center display-4 font-weight-bold"
                  >
                    Learn how to improve a student's concentration
                  </h1>
                </Col>

                <Col md="6" className="mt-4">
                  <div className="">
                    <video width="100%" controls>
                      <source
                        src="https://lincolnmethod.s3.amazonaws.com/influencer/childsBrain.mp4"
                        type="video/mp4"
                      />
                      <source
                        src="https://lincolnmethod.s3.amazonaws.com/influencer/childsBrain.mp4"
                        type="video/ogg"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <hr />
          <div className="text-left bg-image-slides">
            <Container>
              <Row className="justify-content-center">
                <Col md="12">
                  <h1
                    style={{ color: colors.finlandColor }}
                    className="text-center display-4 font-weight-bold"
                  >
                    Learn how to motivate students
                  </h1>
                </Col>

                <Col md="6" className="mt-4">
                  <div className="">
                    <video width="100%" controls>
                      <source
                        src="https://lincolnmethod.s3.amazonaws.com/influencer/learn+how+to+motivate+students.mp4"
                        type="video/mp4"
                      />
                      <source
                        src="https://lincolnmethod.s3.amazonaws.com/influencer/learn+how+to+motivate+students.mp4"
                        type="video/ogg"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <hr />
          <div>
            <div
              className="text-left bg-image-slides"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/7.png)`,
                backgroundSize: "contain",
                backgroundPosition: "right",
              }}
            >
              <Container>
                <Row>
                  <Col md="6">
                    <h1
                      style={{ color: colors.finlandColor }}
                      className="display-4 font-weight-bold"
                    >
                      Get certified to teach online, so you can spend more time travelling
                    </h1>
                    <p className="mt-3">
                      Online tutoring and homeschooling is on the rise, and parents are looking for
                      qualified teachers who can teach remotely.
                    </p>
                    <p>
                      Get certified from Finland so that you can attract more online students. This
                      way you can spend time doing more important things, like travel while you
                      teach
                    </p>

                    <div className="centerOnMobile">
                      <Link to="/course-details/WZ4pK7KlyNkVvd6">
                        <button className="signUpButtonTutor mt-5">
                          APPLY FOR OUR NEXT COHORT
                        </button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Col
              md="12"
              className="subImageBanner"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/7.png)`,
              }}
            ></Col>
          </div>
          <hr />
          <div className="text-left bg-image-slides">
            <Container>
              <Row>
                <Col md="6" className="m-auto">
                  <h1 style={{ color: colors.finlandColor }} className="display-4 font-weight-bold">
                    What will you learn when you go through Finnish Certification?
                  </h1>

                  <div className="centerOnMobile">
                    <Link to="/course-details/WZ4pK7KlyNkVvd6">
                      <button className="signUpButtonTutor mt-5">APPLY FOR OUR NEXT COHORT</button>
                    </Link>
                  </div>
                </Col>
                <Col md="6" className="mt-3">
                  <div className="makingDivs purpleBox">
                    <h5 style={{ color: colors.finlandColor }} className="font-weight-bold">
                      How to Liberate the student's mind
                    </h5>
                    <p className=" ">interdisciplinary, student-centered teaching</p>
                  </div>
                  <div className="makingDivs mt-5 purpleBox">
                    <h5 style={{ color: colors.finlandColor }} className="font-weight-bold">
                      How to promote a student's mindset
                    </h5>
                    <p className=" ">social, emotional, and intellectual learning</p>
                  </div>
                  <div className="makingDivs mt-5 purpleBox">
                    <h5 style={{ color: colors.finlandColor }} className="font-weight-bold">
                      Research-backed strategies
                    </h5>
                    <p className=" ">to increase effectiveness of your lesson</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <hr />
          <div>
            <div
              className="text-left bg-image-slides"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/9.png)`,
                // backgroundSize: "contain",
                // backgroundPosition: "right",
              }}
            >
              <Container>
                <Row>
                  <Col md="6">
                    <h1
                      style={{ color: colors.finlandColor }}
                      className="display-4 font-weight-bold"
                    >
                      Training dates & times
                    </h1>
                    <p className="mt-3">The certification program is 6 weeks long.</p>
                    <p>The class meets online on Tuesdays and Thursdays.</p>
                    <p>Class times are 3pm to 5pm Finland time</p>
                    <p>All coursework will be provided to all participants to qualify</p>

                    <div className="centerOnMobile">
                      <Link to="/course-details/WZ4pK7KlyNkVvd6">
                        <button className="signUpButtonTutor mt-5">
                          APPLY FOR OUR NEXT COHORT
                        </button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Col
              md="12"
              className="subImageBanner"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/9.png)`,
              }}
            ></Col>
          </div>

          <hr />
          <Certificate textColor={colors.finlandColor} />
          <hr />
          <div className="text-left bg-image-slides">
            <Container>
              <Row>
                <Col md="12">
                  <h1
                    style={{ color: colors.finlandColor }}
                    className="display-4 font-weight-bold mb-5"
                  >
                    The Training will include the following Tools:
                  </h1>
                </Col>
                <Col md="6">
                  <img
                    className="img-fluid"
                    src={`${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/dashboard.png`}
                  />{" "}
                </Col>
                <Col md="6" className="m-auto">
                  <div className="">
                    <h3 style={{ color: colors.finlandColor }}>
                      <span className="font-weight-bold">STUDENT</span> <span>DASHBOARD</span>
                    </h3>
                    <p className=" ">
                      It comprise of all relevant material required for planning and conducting a
                      class such as:{" "}
                    </p>{" "}
                    <ul className="list-unstyled">
                      <li>
                        <i className="fas fa-arrow-right"></i> courses, chapters, and topics,
                      </li>
                      <li>
                        <i className="fas fa-arrow-right"></i> videos, worksheets, and tests for
                        each topic.
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="6">
                  <img
                    className="img-fluid"
                    src={`${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/dashboardStudent.png`}
                  />{" "}
                </Col>
                <Col md="6" className="m-auto">
                  <div className="">
                    <h3 style={{ color: colors.finlandColor }}>
                      <span className="font-weight-bold">TEACHER</span> <span>DASHBOARD</span>
                    </h3>
                    {/* <p className=" " style={{ fontSize: 20 }}>
                      It comprise of all relevant material required for planning
                      and conducting a class such as:{" "}
                    </p>{" "} */}
                    <ul className="list-unstyled">
                      <li>
                        <i className="fas fa-arrow-right"></i> will be used by the teachers to
                        upload content i.e. worksheets and videos, for the students.
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <hr />
          <div className="text-left bg-image-slides">
            <Container>
              <Row>
                <Col md="6" className="m-auto">
                  <h1 className="display-4 font-weight-bold" style={{ color: colors.finlandColor }}>
                    What will you learn when you're accepted in the next cohort?{" "}
                  </h1>

                  <div className="centerOnMobile">
                    <Link to="/course-details/WZ4pK7KlyNkVvd6">
                      <button className="signUpButtonTutor mt-5">APPLY FOR OUR NEXT COHORT</button>
                    </Link>
                  </div>
                </Col>
                <Col md="6" className="makingDivs">
                  <div className="purpleBox">
                    <h5 className="font-weight-bold" style={{ color: colors.finlandColor }}>
                      Communication Strategies
                    </h5>
                    <p className=" ">
                      How to convey activities, materials and lesson in the class effectively to
                      achieve the desired change
                    </p>
                  </div>
                  <div className="purpleBox mt-2">
                    <h5 style={{ color: colors.finlandColor }} className="font-weight-bold">
                      Development of Skills
                    </h5>
                    <p className=" ">
                      How to help students grow, progress, and increases their developmental
                      capabilities
                    </p>
                  </div>
                  <div className="purpleBox mt-2">
                    <h5 style={{ color: colors.finlandColor }} className="font-weight-bold">
                      Strategy and Ideation
                    </h5>
                    <p className="  w-75">Use of SMART policy, self learning and much more.</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <hr />
          <div
            className="text-left bg-image-slides"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/EducationMama/13.png)`,
              height: 600,
              backgroundSize: "contain",
            }}
          >
            <Container>
              <Row>
                <Col md="6">
                  <h1
                    style={{ color: colors.finlandColor }}
                    className="display-4 font-weight-bold mb-5"
                  >
                    Management of Dynamic Online Classes Level 1
                  </h1>
                  <div className="centerOnMobile">
                    <Link to="/course-details/WZ4pK7KlyNkVvd6">
                      <button className="signUpButtonTutor mt-5">APPLY FOR OUR NEXT COHORT</button>
                    </Link>
                  </div>
                </Col>
                <Col
                  md="12"
                  className="subImageBanner"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/EducationMama/13.png)`,
                    backgroundSize: "500px",
                  }}
                ></Col>
              </Row>
            </Container>
          </div>

          <div
            className="text-left bg-image-slides"
            style={{
              backgroundColor: colors.finlandColor,
            }}
          >
            <Container>
              <Row className="justify-content-center text-left">
                <Col md="6" className="m-auto">
                  <h1 className="display-4 text-white font-weight-bold">
                    Finnish grant covers the cost of teachers' training from the these countries
                  </h1>
                  {/* <div className="centerOnMobile">
                    <button
                      onClick={() => this.props.triggerEnrollPopup()}
                      className="signUpButtonTutorWhite mt-5"
                    >
                       APPLY FOR OUR NEXT COHORT
                    </button>
                  </div> */}
                </Col>
                <Col md="6" className=" makingDivs">
                  <Link to="/course-details/WZ4pK7KlyNkVvd6">
                    <div className="whiteBox">
                      <h3 className="">PHILIPPINES</h3>
                    </div>
                  </Link>
                  <Link to="/course-details/WZ4pK7KlyNkVvd6">
                    <div className="whiteBox">
                      <h3 className="">SOUTH AFRICA</h3>
                    </div>
                  </Link>
                  <Link to="/course-details/WZ4pK7KlyNkVvd6">
                    <div className="whiteBox text-center">
                      <h3 className="">EGYPT</h3>
                    </div>
                  </Link>
                  <Link to="/course-details/WZ4pK7KlyNkVvd6">
                    <div className="whiteBox text-center">
                      <h3 className="">PAKISTAN</h3>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>

          <div
            className="text-left bg-image-slides-2"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/4.jpg)`,
              backgroundColor: colors.finlandColor,
            }}
          >
            <Container>
              <Row>
                <Col md="12">
                  <div className="priceSection d-flex flex-column">
                    <div className="text-center">
                      <h1 className="display-1 text-white font-weight-bold">$945</h1>
                      <h3 className="display-4 text-white">
                        6 Weeks Teacher Training <br />
                        <span style={{ fontSize: 15 }}>(without a grant from Finland)</span>
                      </h3>
                      <div className="centerOnMobile">
                        <Link to="/course-details/WZ4pK7KlyNkVvd6">
                          <button className="signUpButtonTutorWhite mt-5">
                            APPLY FOR OUR NEXT COHORT
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                {/* <Col
                  md="12"
                  className="subImageBanner"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/TrainTutor/4.jpg)`,
                  }}
                ></Col> */}
              </Row>
            </Container>
          </div>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    );
  }
}
function mapStateToProps(state) {
  return {
    allTutorsInfo: state.webRed.allTutorsInfo,
  };
}
export default connect(mapStateToProps, {
  setTeacherImgAndId,
  triggerEnrollPopup,
})(ZambianTeachers);
