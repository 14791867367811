import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
   
        .PhoneNumber{
            position: relative;
            overflow: hidden;
            background: ${colors.bg2};
            // color: #fec63d;
            // border: 3px solid ${colors.bg1};
            min-height: 620px;

            .teacherSection {
                padding: 10vh 0px;
                span {
                    margin-top: 20px;
                    font-weight: 600;
                    color: ${colors.bg1};
                    font-size: 16px;
                }
                label {
                    font-weight: 600;
                    color: ${colors.green};
                    // margin: 10vh 0;
                }
                img.tutorImg {
                    margin-bottom: 10px;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                }
            }
            
            h3 {
                color: ${colors.green};
                text-align: center;
                margin: 5vh 0;
                padding: 0 5px;
                font-size: 24px;
            }
            
            h4 {
                color: ${colors.bg1};
                text-align: center;
                // margin: 10vh 0;
                padding: 0 35px;
                margin-bottom: 10px;
            }

            .phoneStyle {
                width: 100%;
                margin: 0 !important;
                margin-top: 15px !important;
              font-family: 'Open Sans', sans-serif;
        
                .col-4 {
                    padding: 0;
                    input {
                        text-align: right;
                        background: #fff;
                        border-right: none;
                        border-radius: 0;
                        height: 47px;
                        color: ${colors.bg1};
                        font-size: 18px;
                        padding-right: 0;
                        font-weight: bold;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        border-color: ${colors.green};
                    }
        
                    .flagImg {
                        position: absolute;
                        left: 20px;
                        top: 15px;
                        width: 24px;
                        // height: 24px;
                        // border-radius: 50%;
                        // padding: 6px 0;
                        // margin: 5px 0;
                        // background: #ccc;
                    }
        
                }
                .col-8 {
                    padding: 0;
                    position: relative;
                    .form-group{
                        margin: 0;
                        // background: #fff;
                    }
                    input{
                        border-radius: 0;
                        border-left: none !important;
                        box-shadow: none;
                        height: 47px;
                        color: ${colors.bg1};
                        font-size: 18px;
                        padding-left: 5px;
                        font-weight: bold;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        border-color: ${colors.green};
                        
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        
                    }
                    hr{
                        margin: 0;
                        width: 90%;
                        position: relative;
                        top: -10px;
                        left: 3px;
                        height: 1px;
                        background: ${colors.bg1};
                    }
                }
            }

            .nextButtonNew {

                &.btn {
                    width: 90%;
                    margin: 5px auto 30px auto;
                    background: transparent !important;
                    border: none;
                    box-shadow: none !important;
                    text-align: center;
                    font-size: 2rem;
                    font-family: 'Open Sans', sans-serif;
                    color: ${colors.bg1};
            
                    i {
                        padding: 10px;
                        width: 46px;
                        height: 46px;
                        background: ${colors.bg1} !important;
                        margin-left: 15px;
                        position: relative;
                        top: 1px;
                        border-radius: 50%;
                        color: #fff;
                    }
                }
                
            }

            @media(max-width: 767px) {
                min-height: calc(100vh);    
            }
        }
        .playStoreBtn {
            background: #000;
            color: #fff;
            border-radius: 8px;
            border: 1px solid #fff;
            padding: 5px 8px;
            cursor: pointer;
            margin: 5px;
            @media screen and (max-width: "767px") {
              margin: 10px;
            }
        
            .playBtn {
              width: 20px;
              height: 20px;
            }
            i {
              font-size: 25px;
              cursor: pointer;
            }
            label {
              text-align: left;
              cursor: pointer;
              margin-left: 10px;
              font-size: 10px;
        
              span {
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
`;