import React, { Component } from 'react';
import HeaderTwo from './HeaderTwo.js';
import { BreadcrumbBox } from './common/Breadcrumb.js';
import FooterTwo from './FooterTwo.js';
import { Styles } from "./styles/privacyPolicy.js";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';

class TermsSchools extends Component {

    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper privacy-policy-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Terms & Conditions" />

                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <div className="event-details-content">
                                        <div className="heading">
                                            <h4>Lincoln Method Education Partner Agreement</h4>
                                        </div>

                                        <div className="event-details-overview">

                                            <h5 className="">PLEASE READ THIS LINCOLN METHOD EDUCATION PARTNER AGREEMENT CAREFULLY:</h5>

                                            <p>This is a contract between you (the Education Partner) and us (Lincoln Method). It describes how we will work together and other aspects of our business relationship. It is a legal document, so some of the language is necessarily “legalese,” but we have tried to make it as readable as possible. These terms are so important that we cannot have you participate in our Education Partner Program unless you agree to them. By participating in our Education Partner Program, you are agreeing to these terms.</p>

                                            <br />
                                            <p>For information on our Lincoln Method Partner Program that applies to our Partners, please see our Lincoln Method Partner Program Agreement.</p>

                                            <br/>
                                            <p>We periodically update these terms, and you can find the most recent version here.</p>
                                            <br/>

                                            <h5>Definitions</h5>

                                            <p>"Academic Portal" means all of our web-based educational applications, tools, and platforms that we may make available to you, developed, operated, and maintained by us, accessible via <Link to={"/"}>http://lincolnmethod.com</Link>  or another designated URL, and any ancillary products and services, including website hosting, that we provide to you for your use in accordance with the terms of this Agreement.</p>

                                            <br />0
                                            <p>"Academy Content" means all information, data, text, messages, software, sound, music, video, photographs, graphics, images, and tags that we incorporate into our Lincoln Method resources and make available to you under this Agreement.</p>
                                            <br/>
                                            <p>"Agreement" means this Lincoln Method Education Partner Agreement and all materials referred or linked to in here.</p>
                                            <br/>
                                            <p>“Confidential Information” means all confidential information disclosed by a party ("Disclosing Party") to the other party ("Receiving Party"), whether orally or in writing, that is designated as confidential. Whether or not marked or designated as confidential, Confidential Information shall include all information concerning: (a) Disclosing Party's customer and prospect information, including Customer Data and Customer Materials, as defined in the Customer Terms of Service (b) Disclosing Party's past, present or proposed products, marketing plans, engineering and other designs, technical data, business plans, business opportunities, finances, research and development materials. Confidential Information shall not include any information that (i) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party, (ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party, (iii) is received from a third party without breach of any obligation owed to the Disclosing Party, or (iv) was independently developed by the Receiving Party.</p>
                                        
                                            <br/>
                                            <p>“Educational Environment” or “Educational Program” means an organized program of instruction that is offered and maintained by an accredited higher educational institution and leads to the award of a certificate, diploma or degree upon satisfactory completion of course work requirements. Educational Program must grant academic credit to the enrolled students and the work that students perform as part of the course work cannot replace the work of a paid employee. Accredited in this context means the educational institution meets the requirements and standards of a higher learning establishment as set by the Department of Education of an applicable jurisdiction.</p>
                                            <br/>
                                            <p>“Education Partner Program” means our partner program as described in this Agreement.</p>
                                            <br/>
                                            <p>“End User” means the authorized and actual user of the Academic Portal, which may include students enrolled in educational programs with Partner.</p>
                                            <br/>
                                            <p>"We", "us", “our”, and “Lincoln Method” means Lincoln Method, Inc.</p>
                                            <br/>
                                            <p>“You” and “Partner” means the party, other than Lincoln Method, entering into this Agreement and participating in the Education Partner Program. For the Education Partner Program, this means the academic entity, school, college, or university that applied to participate in this Education Partner Program.</p>
                                            <br/>

                                            <h5>2.  Non-Exclusivity</h5>

                                            <p>This Agreement does create an exclusive agreement between you and us. We will have the right to recommend similar products and services of third parties and to work with other parties in the same or similar capacity.</p>

                                            <br />

                                            <h5>3.  Partner Acceptance, Rights, and Obligations</h5>

                                            <p>a. Partner Acceptance. Once you complete an application to become a Lincoln Method Education Partner, we will review your application and notify you have been accepted to participate in the Education Partner Program, or not. If we do not notify you that you are accepted to participate in the Education Partner Program within thirty (30) days from your application, your application is considered to be rejected.</p>

                                            <br />
                                            <p>If we notify you that you have been accepted into the Education Partner Program, the first thirty (30) days following acceptance is considered the “Provisional Acceptance Period”. During the Provisional Acceptance Period, we may desire to review your course(s) or learn more about your educational program. If you do not provide this information, or if we, in our sole discretion, otherwise decide not to move forward beyond the Provisional Acceptance Period, then we may terminate this Agreement in accordance with the ‘Provisional Acceptance Termination’ section below. If your participation is not terminated prior to the conclusion of the Provisional Acceptance Period, then you will continue to be considered a participant in the Education Partner Program, until one of us terminates the Agreement.</p>
                                            <br/>
                                            <p>If you are accepted to participate in the Education Partner Program, then upon notification of acceptance the terms and conditions of this Agreement shall apply in full force and effect, until terminated pursuant to the terms set forth below.</p>
                                            <br/>
                                            <p>b. Partner Rights and Obligations. We grant you, subject to the limitations set forth below, a non-transferable, non-exclusive right to use, demonstrate and promote the Academy Content solely in an educational environment, like a classroom setting, where the main purpose is to benefit the student and advance the student’s education.</p>
                                            <br/>
                                            <p>You may not resell, lease, rent or otherwise use the Academy Content for profit, provided that the foregoing will not restrict you from charging tuition fees generally, which tuition fees would apply regardless of the inclusion of the Academy Content in your curriculum. You may not include the Academy Content in materials that are distributed outside of your classroom or organization (for example, for inclusion in a published book), without our review and express prior written consent. Please reach out to <a href='mailto:hello@lincolnmethod.com'>hello@lincolnmethod.com</a> for review and approval.</p>
                                            <br/>
                                            <p>You may not alter the appearance of the Academy Content or remove our name or logo from the Academy Content. “Whitelabeling” is strictly prohibited. We determine the Academy Content that we make available to you under this Agreement, and there is certain content that may not be accessible by you unless you are a customer of ours or pay a fee.</p>
                                            <br/>
                                            <p>You agree to abide by the Lincoln Method Academy Terms, and to instruct students using the Academy Content to do the same. You will take all reasonable steps to ensure that students using the Academy Content do not use the Academy Content in violation of the Lincoln Method Academy Terms. If you discover or have reason to believe that any student is making use of the Academy Content in violation of the Lincoln Method Academy Terms, then you will immediately notify us in writing.</p>
                                            <br/>

                                            <h5>4.  Partner Support</h5>


                                            <p>We will make the Academy Content available to you, subject to the terms set forth in this Agreement.</p>
                                            <br/>
                                            <p>We will also provide you with reasonable technical support (email, chat, or telephone) for the Academy Content in accordance with the support policy made available to you, and as updated from time to time.</p>
                                            <br/>

                                            <br />

                                            <h5>5.  Intellectual Property Rights</h5>

                                            <p>Lincoln Method Intellectual Property. We own all right, title and interest, including all intellectual property rights, in and to the Academy Content, and all modifications, improvements, customizations, enhancements, or other derivative works made to the Academy Content.</p>


                                            <br />
                                            <p>Partner Intellectual Property. You own all right, title and interest, including all intellectual property rights, in and to your trademarks, service marks, trade names, logos, and domain names. You hereby grant us a non-exclusive, royalty-free, worldwide license to use your trademarks, service marks, trade names, logos, and domain names (collectively, “Partner Marks”) in connection with this Agreement and the Education Partner Program. Such license shall extend only to those uses that we both may agree upon from time to time, such as press releases, and will automatically terminate upon termination of this Agreement.</p>
                                            <br/>

                                            <h5>6.  Confidentiality</h5>

                                            <p>Each party agrees that it will not disclose to any third party Confidential Information of the other party, except as required in the course of performance under this Agreement, or as otherwise authorized in writing by the disclosing party. Each party agrees to take all reasonable measures to protect the other party’s Confidential Information from unauthorized use or disclosure. Each party agrees that it will treat the other party’s Confidential Information with the same degree of care as it accords to its own Confidential Information, but in no event with less than reasonable care.</p>

                                            <br />

                                            <h5>7.  Miscellaneous</h5>

                                          <p>a. Publicity. Subject to our prior written approval (which may be withheld for any or no reason), you may state publicly that you are participating in the Education Partner Program. We may use your name and logo in connection with providing information about the Education Partner Program and in other marketing or promotional materials. Any goodwill generated by the use of our name and logo will inure to our benefit.</p>

                                            <br />
                                            <p>b. Entire Agreement; Order of Precedence. This Agreement, including any documents incorporated herein by reference, sets forth the entire agreement between the parties with respect to the subject matter hereof, and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, whether written or oral, with respect to such subject matter. In the event of any conflict between the provisions of this Agreement and any other documents, this Agreement will control.</p>
                                            <br/>
                                            <p>c. Notices. All notices, requests, consents, claims, demands, waivers, and other communications hereunder (each, a “Notice”) must be in writing and addressed to the parties at the addresses set forth in the applicable Order Form or to such other address that may be designated by the receiving party in writing. All Notices must be delivered by personal delivery, nationally recognized overnight courier (with all fees pre-paid), facsimile, or email (with confirmation of transmission), or certified or registered mail (in each case, return receipt requested, postage prepaid). Except as otherwise provided in this Agreement, a Notice is effective only (a) upon receipt of the receiving party, and (b) if the party giving the Notice has complied with the requirements of this Section.</p>
                                            <br/>
                                            <p>d. No Waivers. The failure by either party to enforce any provision of this Agreement will not constitute a waiver of future enforcement of that or any other provision.</p>
                                            <br/>
                                            <p>e. Severability. If any provision of this Agreement is invalid, illegal, or unenforceable under applicable law, it will not affect the validity or enforceability of the remainder of the terms of this Agreement.</p>
                                            <br/>
                                            <p>f. Assignment. Neither party may assign any of its rights or obligations hereunder, whether by operation of law or otherwise, without the prior written consent of the other party (not to be unreasonably withheld). Notwithstanding the foregoing, either party may assign this Agreement in its entirety, without consent of the other party, to its affiliate or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets not involving a direct competitor of the other party. A party’s sole remedy for any purported assignment by the other party in breach of this paragraph will be, at the non-assigning party’s election, termination of this Agreement upon written notice to the assigning party. Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties, their respective successors, and permitted assigns.</p>
                                            <br/>
                                            <p>g. Governing Law. This Agreement is governed by and construed in accordance with the internal and international laws without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or proceeding arising out of or related to this Agreement or the Application shall be instituted exclusively.Each party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action, or proceeding</p>
                                            <br/>
                                            <p>h. Entire Agreement. This Agreement, including any exhibits and addenda hereto and the documents incorporated herein by reference, constitutes the sole and entire agreement of the parties with respect to the subject matter of this Agreement and supersedes all prior and contemporaneous understandings, agreements, and representations and warranties, both written and oral, with respect to such subject matter. In the event of any inconsistency between the statements made in the body of this Agreement, the Exhibits, and any Addenda, the following order of precedence governs: (1) first, the terms of any applicable Addenda, (2) second, the body of this Agreement, and (3) third, the terms of any applicable Exhibits.</p>
                                            <br/>
                                            <p>IF YOU ACCEPT THIS AGREEMENT OR BY EXECUTING AN ORDER FORM THAT REFERENCES THIS AGREEMENT, YOU AGREE TO THE TERMS OF THIS AGREEMENT. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERMS “YOU” OR “YOUR” SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICES.</p>

                                        </div>
                                    </div>
                                </Col>

                                {/* <Col lg="3" md="4" sm="12">
                                    <div className="event-details-sidebar">
                                        <Row>
                                            <Col md="12">
                                                <div className="event-sidebar-info">
                                                    <div className="event-sidebar-timer text-center">
                                                        <Timer initialTime={1040 * 970 * 980} direction="backward">
                                                            <p><Timer.Days /><span>Days</span></p>
                                                            <p><Timer.Hours /><span>Hours</span></p>
                                                            <p><Timer.Minutes /><span>Minutes</span></p>
                                                        </Timer>
                                                    </div>
                                                    <ul className="list-unstyled event-info-list">
                                                        <li>Start Date: <span>Aug 21, 2020</span></li>
                                                        <li>Time: <span>08:30am</span></li>
                                                        <li>Seat: <span>220</span></li>
                                                        <li>Place: <span>Ontario,CA</span></li>
                                                        <li>Organizer: <span>David Young</span></li>
                                                        <li>Phone: <span>+1 (396) 486 4709</span></li>
                                                        <li>Email: <span>email@me.com</span></li>
                                                        <li>Website: <span>www.mydomain.com</span></li>
                                                    </ul>
                                                    <button type="button" className="buy-btn">Buy Ticket</button>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <PopularCourse />
                                            </Col>
                                            <Col md="12">
                                                <CourseTag />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </section>
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default TermsSchools