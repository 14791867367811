import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .counter-area {
        background-size    : cover;
        background-position: center;
        background-repeat  : no-repeat;
        padding            : 100px 0 70px;
        position           : relative;

        &:before {
            position  : absolute;
            content   : '';
            background: ${colors.bg1};
            // opacity   : 0.98;
            width     : 100%;
            height    : 100%;
            top       : 0;
            left      : 0;
        }

        .playStoreBtn {
            background: #000;
            color: #fff;
            border-radius: 8px;
            border: 1px solid #fff;
            padding: 5px 8px;
            cursor: pointer;
            margin: 5px;
            @media screen and (max-width: "767px") {
              margin: 10px;
            }
        
            .playBtn {
              width: 20px;
              height: 20px;
            }
            i {
              font-size: 25px;
              cursor: pointer;
            }
            label {
              text-align: left;
              cursor: pointer;
              margin-left: 10px;
              font-size: 10px;
        
              span {
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;
              }
            }
          }

        .custom-select {
            height: 40px !important;
        }

        .findButton {
                font-size  : 14px;
                color      : #fff;
                background : ${colors.green};
                width      : 30%;
                height     : 40px;
                font-weight: 500;
                border     : none;
                border-radius : 5px;
                text-transform: uppercase;
                margin-top: 20px;

                &:hover {
                    background: ${colors.black1};
                    border: #fff 2px solid;

                    i {
                        color: #ffffff;
                    }
                }
                @media(max-width: 575px) {
                    width      : 50%;
                    height     : 40px;
                    font-size  : 12px;
                }
        }
        .findButtonDisabled {
                font-size  : 14px;
                color      : #fff;
                background : ${colors.green};
                width      : 30%;
                height     : 40px;
                font-weight: 500;
                border     : none;
                border-radius : 5px;
                text-transform: uppercase;
                margin-top: 20px;

                @media(max-width: 575px) {
                    width      : 50%;
                    height     : 40px;
                    font-size  : 12px;
                }
        }

        .ticker {
            span {
                color: ${colors.green};
            }
            label{
                color: #fff
            }
        }
        

        .sec-title {
            h4 {
                color        : #ffffff;
                line-height  : 35px;
                font-weight  : 600;
                // max-width    : 550px;
                margin       : auto;
                margin-bottom: 5px;

                @media(max-width: 575px) {
                    // margin-bottom: 15px;
                    font-size: 18px;
                }
            }
            h3 {
                color        : #ffffff;
                line-height  : 35px;
                font-weight  : 600;
                // max-width    : 550px;
                margin       : auto;
                margin-bottom: 5px;
                font-size: 45px;

                @media(max-width: 575px) {
                    // margin-bottom: 15px;
                    font-size: 25px;
                }
            }
        }

        .counter-box {
            position: relative;
            justify-content: center;
            text-align: center;

            .counter-icon {
                // border      : 1px dashed ${colors.green};
                border-right: none;
                // width       : 95px;
                height      : 85px;
                border-radius : 5px;
                text-align : center;
                padding-top: 17px;
                position   : relative;

                i {
                    font-size: 52px;
                    color    : ${colors.green};

                    @media(max-width: 991px) {
                        font-size: 36px;
                        margin-left: -10px;
                    }

                    @media(max-width: 767px) {
                        margin-left: 0;
                    }
                }

                &::before {
                    // position    : absolute;
                    content     : '';
                    // border-right: 1px dashed ${colors.green};
                    width       : 1px;
                    height      : 12px;
                    top         : 2px;
                    right       : 0;

                    @media(max-width: 991px) {
                        height: 8px;
                    }
                }

                &::after {
                    // position    : absolute;
                    content     : '';
                    // border-right: 1px dashed ${colors.green};
                    width       : 1px;
                    height      : 12px;
                    bottom      : 2px;
                    right       : 0;

                    @media(max-width: 991px) {
                        height: 8px;
                    }
                }

                @media(max-width: 991px) {
                    // width: 70px;
                    height: 30px;
                    padding-top: 17px;
                }
            }

            .counter-number {
                // position: absolute;
                // top     : 19px;
                // left    : 34%;

                h4 {
                    color: #ffffff;
                    font-size: 70px;

                    span {
                        letter-spacing: 3px;

                        @media(max-width: 575px) {
                            font-size: 40px;
                            letter-spacing: 1px;
                        }
                    }
                }

                p {
                    font-size  : 15px;
                    color      : ${colors.border3};
                    font-weight: 500;

                    @media(max-width: 991px) {
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: 0;
                    }

                    @media(max-width: 575px) {
                        font-size: 11px;
                    }
                }

                @media(max-width: 1199px) {
                    left: 36%;
                }

                @media(max-width: 991px) {
                    left: 33%;
                    top: 10px;
                }

                @media(max-width: 575px) {
                    left: 65px;
                }
            }

            @media(max-width: 767px) {
                // margin-bottom: 20px;
            }
        }

        @media(max-width: 767px) {
            padding: 70px 0 90px;
        }
    }
`;