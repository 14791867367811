import {

	getCoursesSuccess,
	getCoursesSuccessOnlineSchool,
	GET_ALL_CLIENTS,
	getCourseDetailSuccess,
	getQuestionHtmlSuccess,
	_handleSocialLoginSuccess,
	_loginSuccess,
	_loginSuccess2,
	_signupSuccess,
	GET_SIGNUP_FORM_TYPE,
	SHOW_LOGIN_POPUP,
	SHOW_ENROLLMENT_POPUP,
	SHOW_FORGOT_PASS,
	SHOW_CHECKOUT_POPUP,
	SHOW_CONGRATULATIONS,
	GET_EXTRA_STORE_FIELDS,
	_loginSessionEncrypted,
	_checkoutCoupon,
	_checkoutCashOnDevliverySuccess,
	_checkoutJazzCashSuccess,
	_forgotPasswordMessage,
	_validateEnroll,
	_validateEmailExists,
	_addiotionalJazzCheckoutDetails,
	_checkPhoneNumber,
	_validatePhoneVerification,
	_checkIfUsersExists,
	_triggerNewUserType,
	_checkIfUserNameExists,
	_triggerNewUserPassword,
	_triggerNewUserDetails,
	_triggerNewUserEmail,
	_signupSuccessPhone,
	_checkIfThisColumnExists,
	_getTutorsInfo,
	_getSingleTutorInfo,
	_setTeacherIdImg,
	_getPreviousCardsInfo,
	_getDemoGrades

} from '../actions/types';

const initialState = {


	coursesData: '',
	coursesDataOnlineSchool: '',
	courseDetail: '',
	questionHtml: '',
	successData: '',
	loginSuccessNormal : '',
	loginSuccessWithCourse: '',
	signupSuccess : '',
	SignUpFormType: '',
	showLoginPopupRed : '',
	showEnrollmentPopupRed : '',
	showForgotPasswordRed : '',
	showCheckoutPopupRed : '',
	showCongratulationsRed : '',
	allclientslist : '',
	extraFieldsArray : [],
	loginSession : '',
	checkoutCouponResponse : '',
	checkoutCashOnDevliveryResponse : '',
	checkoutJazzCashResponse : '',
	forgotPasswordResponse : '',
	validateEnrollButton : '',
	validateEmailExistsRes : '',
	addiotionalJazzCheckoutDetailsRes : '',
	phoneNumberSentRes : '',
	phoneNumberVerificationRes : '',
	registeredUsersPhoneList : '',
	newUserType : '',
	isUserNameExists : '',
	newUserPassword : '',
	newUserDetails : '',
	newUserEmail : '',
	signupSuccessWithPhone : '',
	validateColumnResponse : '',
	allTutorsInfo: '',
	singleTutorInfo: '',
	setTutorImgID: '',
	getPrevCards: '',
	demoGrades: ''
};

export default function (state = initialState, action) {
	switch (action.type) {
		case getCoursesSuccess:
			return {
				...state,
				coursesData: action.payload
			};
		case getCoursesSuccessOnlineSchool:
			return {
				...state,
				coursesDataOnlineSchool: action.payload
			}
		case getCourseDetailSuccess:
			return {
				...state,
				courseDetail: action.payload
			};
		case getQuestionHtmlSuccess:
			return {
				...state,
				questionHtml: action.payload
			};
		case _handleSocialLoginSuccess:
			return {
				...state,
				successData: action.payload
			};
		case _loginSuccess:
			return {
				...state,
				loginSuccessWithCourse: action.payload
			};
		case _loginSuccess2:
			return {
				...state,
				loginSuccessNormal: action.payload
			};
		case GET_SIGNUP_FORM_TYPE:
			return {
				...state,
				SignUpFormType: action.payload
			};
			
		case SHOW_LOGIN_POPUP:
			return {
				...state,
				showLoginPopupRed: action.payload
			};
			
		case SHOW_ENROLLMENT_POPUP:
			return {
				...state,
				showEnrollmentPopupRed: action.payload
			};
	

		case SHOW_FORGOT_PASS:
			return {
				...state,
				showForgotPasswordRed: action.payload
			};
			
		case SHOW_CHECKOUT_POPUP:
			return {
				...state,
				showCheckoutPopupRed: action.payload
			};
		case GET_ALL_CLIENTS:
			return {
				...state,
				allclientslist: action.payload
			}
		case GET_EXTRA_STORE_FIELDS:
			return {
				...state,
				extraFieldsArray: action.payload
			}
		case _signupSuccess:
			return {
				...state,
				signupSuccess: action.payload
			}
		case SHOW_CONGRATULATIONS:
			return {
				...state,
				showCongratulationsRed: action.payload
			}
		case _loginSessionEncrypted:
			return {
				...state,
				loginSession: action.payload
			}
		case _checkoutCoupon:
			return {
				...state,
				checkoutCouponResponse: action.payload
			}
		case _checkoutCashOnDevliverySuccess:
			return {
				...state,
				checkoutCashOnDevliveryResponse: action.payload
			}
		case _checkoutJazzCashSuccess:
			return {
				...state,
				checkoutJazzCashResponse: action.payload
			}
		case _addiotionalJazzCheckoutDetails:
			return {
				...state,
				addiotionalJazzCheckoutDetailsRes: action.payload
			}
		case _forgotPasswordMessage:
			return {
				...state,
				forgotPasswordResponse: action.payload
			}
		case _validateEnroll:
			return {
				...state,
				validateEnrollButton: action.payload
			}
		case _validateEmailExists:
			return {
				...state,
				validateEmailExistsRes: action.payload
			}
		case _checkPhoneNumber:
			return {
				...state,
				phoneNumberSentRes: action.payload
			}
		case _validatePhoneVerification:
			return {
				...state,
				phoneNumberVerificationRes: action.payload
			}
		case _checkIfUsersExists:
			return {
				...state,
				registeredUsersPhoneList: action.payload
			}
		case _triggerNewUserType:
			return {
				...state,
				newUserType : action.payload
			}
		case _checkIfUserNameExists:
			return {
				...state,
				isUserNameExists : action.payload
			}
		case _triggerNewUserPassword:
			return {
				...state,
				newUserPassword : action.payload
			}
		case _triggerNewUserDetails:
			return {
				...state,
				newUserDetails : action.payload
			}
		case _triggerNewUserEmail:
			debugger;
			return {
				...state,
				newUserEmail : action.payload
			}
		
		case _signupSuccessPhone:
			return {
				...state,
				signupSuccessWithPhone: action.payload
			}

		case _checkIfThisColumnExists:
			return {
				...state,
				validateColumnResponse: action.payload
			}
		case _getTutorsInfo:
			return {
				...state,
				allTutorsInfo: action.payload
			}
		case _getSingleTutorInfo:
			return {
				...state,
				singleTutorInfo : action.payload
			}
		case _setTeacherIdImg:
			return {
				...state,
				setTutorImgID : action.payload
			}
		case _getPreviousCardsInfo:
			return {
				...state,
				getPrevCards : action.payload
			}
		case _getDemoGrades:
			return {
				...state,
				demoGrades : action.payload
			}

			
			
		default:
			return state;
	}
}
