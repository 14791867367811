import styled from "styled-components";

import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
 .grade-subject-selection {
        padding: 100px 0;
        background: ${colors.bg1};
        color: #FFF;
    }
 .grade-subject-selection-2 {
        padding: 40px 0;
        background: #fff;
        color: #000;

        .sec-title {
            h4 {
                color        : ${colors.black1};
                line-height  : 35px;
                font-weight  : 600;
                max-width    : 550px;
                margin       : auto;

                span {
                    color    : ${colors.green};
                }
                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 18px;
                }
            }
        }

        .boxForGrades {
            background: #fff;
            border: 1px solid #3563a8;
            color: #3563a8;
            margin-top: 20px;
            text-align: center;
            border-radius: 10px;
            cursor: pointer;
            padding: 10px;

            label {
                font-size: 16px;
                margin-bottom: 0;
            }
            &:hover {
                color: #fff;
                background: #3563a8;
            }

            @media(max-width: 767px) {
                font-size : block;
            }
        }
        button.allCourses {
            font-size  : 14px;
            color      : #fff;
            background : ${colors.green};
            width      :100%;
            height     : 48px;
            font-weight: 500;
            border     : none;
            border-radius : 5px;
            text-transform: uppercase;
            margin-top: 10px;

            &:hover {
                background: ${colors.black1};

                i {
                    color: #ffffff;
                }
            }
        }
    }
    .grade-subject-selection-3 {
        padding: 40px 0;
        background: #fff;
        color: #000;
            .card {
                margin-top: 10px;
                cursor: pointer;
            }
            .card-header {
                background: ${colors.green};
                color: #fff;
                text-align: center;
                font-size: 16px;
                border-radius : 2%;

                label {
                    cursor: pointer;
                }
            }
            .card-body {
                background: ${colors.bg1};
                padding: 0.1rem !important;
            }
            button.subject {
                font-size  : 14px;
                color      : #000;
                background : #fff;
                width      :80%;
                height     : 48px;
                font-weight: 500;
                // border     : 2px solid ${colors.green};
                border-radius : 2%;
                text-transform: uppercase;
                margin-top: 10px;
    
                &:hover {
                    // background: ${colors.green};
                    text-decoration: underline;
                    // border     : 4px solid ${colors.green};

                    color: #000;
                    font-weight: 600;
    
                    i {
                        color: #ffffff;
                    }
                }
            }
            .content-section {
                border-radius: 1%;

            
            .course-content {
                background: ${colors.bg1};
                max-height: 0;
                overflow  : hidden;
                transition: max-height 0.2s ease-in-out;
                ul {
                    li {
                        border-bottom : 1px solid ${colors.border3};
                        margin-left: 25px;
                        padding: 12px 0;
                        span.play-icon {
                            font-size : 14px;
                            color: ${colors.text3};
                            margin-right: 20px;
                            i {
                                // color: ${colors.green};
                                color: #fff;
                                background: ${colors.green};
                                // border: 1px solid ${colors.green};
                                font-size: 22px;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                padding-left: 2px;
                                text-align: center;
                                line-height: 29px;
                                vertical-align: middle;
                                margin-right: 5px;
                            }
                        }
                        span.lecture-title {
                            font-size : 15px;
                            color: ${colors.black2};

                            @media(max-width: 575px) {
                                font-size : 14px;
                            }
                        }
                        button.lecture-duration {
                            background: transparent;
                            float: right;
                            font-size: 14px;
                            color: ${colors.text3};
                            line-height: 28px;
                            font-weight: 400;

                            &:hover {
                                background: ${colors.text3};
                                color: #fff;
                                border-radius: 2px;
                            }
                        }
                    }
                }
            }

            .course-content.show {
                max-height: 100%;
                margin-bottom: 40px;
            }
        }
        .sec-title {
            h4 {
                color        : ${colors.black1};
                line-height  : 35px;
                font-weight  : 600;
                max-width    : 550px;
                margin       : auto;

                span {
                    color    : ${colors.green};
                }
                @media(max-width: 575px) {
                    // margin-bottom: 15px;
                    font-size: 18px;
                }
            }
        }

        .boxForGrades {
            background: #fff;
            border: 1px solid #3563a8;
            color: #3563a8;
            margin-top: 20px;
            text-align: center;
            border-radius: 10px;
            cursor: pointer;
            padding: 10px;

            label {
                font-size: 16px;
                margin-bottom: 0;
            }
            &:hover {
                color: #fff;
                background: #3563a8;
            }

            @media(max-width: 767px) {
                font-size : block;
            }
        }
        button.allCourses {
            font-size  : 14px;
            color      : #fff;
            background : ${colors.green};
            width      :100%;
            height     : 48px;
            font-weight: 500;
            border     : none;
            border-radius : 1%;
            text-transform: uppercase;
            margin-top: 10px;

            &:hover {
                background: ${colors.black1};

                i {
                    color: #ffffff;
                }
            }
        }
        button.allCoursesSubject {
            font-size  : 14px;
            color      : #fff;
            background : ${colors.green};
            width      :100%;
            height     : 48px;
            font-weight: 500;
            border     : none;
            border-radius : 5px;
            text-transform: uppercase;
            margin-top: 10px;
            margin-bottom: 10px;

            // &:hover {
            //     background: ${colors.black1};

            //     i {
            //         color: #ffffff;
            //     }
            // }
        }
    }
`;