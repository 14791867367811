import React, { Component } from 'react';
import Datas from '../data/course/slider.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/courseSlider.js";

class CourseSlider extends Component {
    render() {
        const settings = {
            slidesPerView: 3,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                }
            }
        };

        return (
            <Styles>
                {/* Course Slider */}
                <section className="course-slider-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>{Datas.secTitle}</h4>
                                </div>
                            </Col>
                            <Col md="12" className="course-slider">
                                <Swiper {...settings}>
                                    {
                                        Datas.dataList.map((data, i) => (
                                            <div className="course-item" key={i}>
                                                <Link to={process.env.PUBLIC_URL + data.courseLink}>
                                                    <div className="course-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/LM/${data.imgUrl})` }}>
                                                        {/* <div className="author-img d-flex">
                                                            <div className="img">
                                                                <img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" />
                                                            </div>
                                                            <div className="title">
                                                                <p>{data.authorName}</p>
                                                                <span>{data.authorCourses}</span>
                                                            </div>
                                                        </div> */}
                                                        <div className="course-price">
                                                            <p>{data.price}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="course-content">
                                                    <h6 className="heading"><Link to={process.env.PUBLIC_URL + data.courseLink}>{data.courseTitle}</Link></h6>
                                                    {/* <p className="desc">{data.courseDesc}</p> */}
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="course-info">
                                                                <ul className="list-unstyled">
                                                                    <li>
                                                                        <i className="fas fa-book"></i>
                                                                        <label>15 Chapters</label>
                                                                    </li>
                                                                    <li>
                                                                        <i className="fas fa-video"></i>
                                                                        <label>154 Curated online videos</label>
                                                                    </li>
                                                                    <li>
                                                                        <i className="fas fa-wifi"></i>
                                                                        <label>Online</label>
                                                                    </li>
                                                                    <li>
                                                                        <i className="fas fa-calendar-alt"></i>
                                                                        <label>Course timetable</label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="course-instructor-img">
                                                                <ul className="list-unstyled">
                                                                    <li>
                                                                        <img src={`/assets/images/LM/Teachers/Ayesha Khalid.png`} alt="teacher-img" className="img-fluid" />
                                                                        <label>Taught by: Ayesha Khalid</label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {/* <div className="course-face d-flex justify-content-between">
                                                        <div className="duration">
                                                            <p><i className="las la-clock"></i>120</p>
                                                        </div>
                                                        <div className="rating">
                                                            <ul className="list-unstyled list-inline">
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                                </li>
                                                                <li className="list-inline-item">(4.5)</li>
                                                            </ul>
                                                        </div>
                                                        <div className="student">
                                                            <p><i className="las la-chair"></i>60</p>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div style={{ backgroundColor: "#8C95A4" }} className="">


                                                    <button className="btn text-light" style={{ width: "40%", height: 50 }}>
                                                        View Class Details
                                                            </button>
                                                    <button className="btn" style={{ backgroundColor: "#676c75", width: "60%", height: 50 }}>
                                                        <span className="text-light">
                                                            <span className="outer" style={{ color: "red", textDecoration: 'line-through' }}>
                                                                <span style={{ color: "#fff" }}>
                                                                    PKR 7,500
                                                                </span>
                                                            </span>
                                                            <span style={{ fontSize: 16, margin: 5 }}>
                                                                PKR 3,700
                                                            </span>
                                                        </span>
                                                        {/* <span class="outer" style={{ color: "red", textDecoration: 'line-through' }}>
                                                                <span class="inner" style={{ color: "green" }}>foo bar</span>
                                                            </span> */}
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Swiper>
                            </Col>
                            <Col md="12" className="text-center">
                                <Link to="/course-grid">
                                    <button className="allCourses">View All Courses</button>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default CourseSlider
