import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .languageBtn {
        margin: -1px;
        label {
            margin-right: 10px;
        }
        button {
            background: ${colors.border1} !important;
            margin-right: 2px;
            margin-top: -2px;
            border-radius: 3px;
            &:hover {
                background: ${colors.green} !important;
                color     : #ffffff;
            }
        }
        @media(max-width: 767px) {
            // padding: 30px 0 25px;
        }
    }
    .languageBtn2 {
        margin: -1px;
        label {
            margin-right: 10px;
        }
        button {
            background: ${colors.border1} !important;
            margin-right: 2px;
            margin-top: -2px;
            border-radius: 3px;
            &:hover {
                background: ${colors.green} !important;
                color     : #ffffff;
            }
        }
        @media(max-width: 767px) {
            // padding: 30px 0 25px;
        }
    }
`;