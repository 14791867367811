import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
   
.UsernameField {
    position: relative;
    overflow: hidden;
    background: #fff;
    color: ${colors.bg1};
    min-height: 570px;
    // border: 3px solid ${colors.bg1};

    @media(max-width: 767px){
        min-height: calc(100vh);
    }
    
    // .wrapper {
    //     position: absolute;
    //     width: 200%;
    //     height: 600px;
    //     left: -50%;
    //     top: 25vh;
    //     @media screen and (max-width: '767px'){
    //         top: 30vh;
    //     }
    //     background-color: #24c4ff;
    //     border-radius: 50%;
    // }


    input {
        // border: none;
        padding: 28px 0;
        width: 90%;
        margin: auto;
        
         &::-webkit-input-placeholder { /* Edge */
            color: #ccc;
            font-size: 1rem;
          }
          
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #ccc;
          }
          
          &::placeholder {
            color: #ccc;
          }
    }

    .nextButtonNew {

        &.btn {
            width: 90%;
            margin: 30px auto 30px auto;
            background: transparent !important;
            border: none;
            box-shadow: none !important;
            text-align: center;
            font-size: 2rem;
            font-family: 'Open Sans', sans-serif;
            color: ${colors.bg1};
    
            i {
                padding: 10px;
                width: 46px;
                height: 46px;
                background: ${colors.bg1} !important;
                margin-left: 15px;
                position: relative;
                top: 1px;
                border-radius: 50%;
                color: #fff;
            }
        }
        
    }

}
`;