import React, { useState } from "react";
import { Styles } from "./styles/careerPageSnippetComponent.js";
import { Button, Container, Row, Col } from "react-bootstrap";
import TutorSignupForm from "../../components/TutorSignupForm.js";

const CareerPageSnippetComponent = (props) => {
  const [state, setState] = useState({
    heading: props.propData.heading,
    para: props.propData.para,
    btn_text: props.propData.btn_text,
    btn_text_url: props.propData.btn_text_url,
    image_url: props.propData.image_url,
    background: props.propData.background,
    theme: props.propData.themeColor,
    position: props.propData.position,
  });
  const [showPopup, setshowPopup] = useState(false);

  return (
    <Styles>
      <div
        className={"CareerPageSnippetComponent " + state.theme}
        style={{
          background:
            "url(" +
            process.env.PUBLIC_URL +
            state.image_url +
            ")" +
            state.background +
            " bottom center / cover",
        }}
      >
        <Container>
          {
            <Row>
              <Col
                style={{ textAlign: "center", padding: state.theme !== "white" ? "12vh 15px" : "" }}
                md={state.position == "full" ? 12 : 7}
              >
                <h1
                  className="BannerWithText_heading"
                  dangerouslySetInnerHTML={{ __html: state.heading }}
                ></h1>

                {state.para && state.para != "" ? (
                  <p
                    className="BannerWithText_paragraph"
                    dangerouslySetInnerHTML={{ __html: state.para }}
                  ></p>
                ) : null}

                {state.btn_text || state.btn_text !== "" ? (
                  <Button
                    onClick={() => {
                      setshowPopup(true);
                    }}
                    className="BannerWithText_btn"
                    size="lg"
                  >
                    {state.btn_text}
                  </Button>
                ) : null}
              </Col>
            </Row>
          }
        </Container>
        <TutorSignupForm showPopup={showPopup} setshowPopup={setshowPopup} />
      </div>
    </Styles>
  );
};

export default CareerPageSnippetComponent;
