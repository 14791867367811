import React, { Component } from 'react';
import Datas from '../../data/partnership/unitedNation.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/unitedNations.js';
import ModalVideo from 'react-modal-video';

class FasionDesignComponent extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isOpen2: false,
            isOpen3: false,
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }
    render() {

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper unitednations-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Fashion & Design Shoutouts" backgroundImage="/assets/images/LM/partnerships/bhdi/bannerBig.jpg" />

                    {/* Gallery Area */}
                    <section className="unitednations-page-area" style={{paddingTop: 0}}>

                        <div className="fasionContent">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <p>Lincoln Method and Beverly Hills Design Institute (<a href="https://www.bhdi.edu/">BHDI</a>) have partnered together in promoting the ‘Fashion for Peace’ cause. Lincoln Method is grateful to <a href="https://www.facebook.com/ETESonia">Sonia Ete</a> of <a href="https://www.bhdi.edu/">BHDI</a> for engaging with her network and jump-starting this casue. Fashion for Peace is an amazing cause that brings people together and gives a fun platform that supports the United Nations Sustainable Development Goals.</p>

                                        <br />
                                        <br />
                                        <br />

                                        <p>Special thanks to actress, Emily Drake, for the shout-out on Fashion For Peace and for supporting online learning. Click on the video to watch what she has to say.</p>

                                        <br />

                                        <p>The Lincoln Method online learning technology will now be used by Beverly Hills Design Institute to teach Fashion & Design.</p>

                                        <br />

                                        <p>Now anyone can login online from home, and can take these courses and learn about Fashion & Design. Certification will be provided directly by Beverly Hills Design Institute.</p>
                                    </Col>
                                </Row>

                            </Container>
                        </div>

                        <div className="row-divider">
                            <Container>
                                
                                <Row style={{ padding: "5vh 0" }}>
                                    <Col lg="12">
                                        <div className="heading text-center">
                                            <h4>Watch Shirin Malekzadeh's message on Fashion for Peace</h4>
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="video-player" style={{ backgroundImage: "url(/assets/images/LM/partnerships/bhdi/Shirin.png)" }}>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen2} videoId='_FIWTf9ZfQE' onClose={() => this.setState({ isOpen2: false })} />
                                            <button onClick={() => this.setState({ isOpen2: true })} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default FasionDesignComponent