import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .gallery-page {
        .gallery-page-area {
            padding : 60px 0 70px;

            .buttonAllView{
                text-align: center;
    
                button {
                    font-size  : 14px;
                    color      : #fff;
                    background : ${colors.green};
                    width      : 30%;
                    height     : 48px;
                    font-weight: 500;
                    border     : none;
                    border-radius : 5px;
                    text-transform: uppercase;
                    margin-top: 40px;
    
                    &:hover {
                        background: ${colors.black1};
    
                        i {
                            color: #ffffff;
                        }
                    }
                    @media(max-width: 575px) {
                        width      : 50%;
                        height     : 40px;
                        font-size  : 12px;
                    }
                }
            }
             h4 {
                color        : #182B49;
                line-height  : 35px;
                font-weight  : 600;
                // max-width    : 550px;
                margin       : auto;
                margin-bottom: 20px;

                @media(max-width: 575px) {
                    // margin-bottom: 15px;
                    font-size: 18px;
                }
            }
            h3 {
                color        : ${colors.bg1};
                line-height  : 35px;
                font-weight  : 600;
                // max-width    : 550px;
                margin       : auto;
                margin-bottom: 30px;
                font-size: 45px;

                @media(max-width: 575px) {
                    // margin-bottom: 15px;
                    font-size: 25px;
                }
            }
            .gallery-box {
                border-radius : 5px;
                overflow: hidden;
                margin-bottom: 30px;
                position: relative;
                img {
                    max-width: 100%;
                    max=height: 100%;
                    transition : all 0.3s ease;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            @media(max-width: 767px) {
                padding: 30px 0;
            }
        }
    }
`;