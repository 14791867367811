import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    /* Course Details */
    .course-details-area {
        padding : 10px 0px;

        .about-content {
            h4.about-title {
                color        : ${colors.black1};
                line-height  : 35px;
                font-weight  : 600;
                margin-bottom: 25px;

                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }

            p.about-para {
                font-size    : 15px;
                color        : ${colors.text3};
                line-height  : 25px;
                margin-bottom: 40px;

                span {
                    display   : block;
                    margin-top: 20px;
                }

                @media(max-width: 575px) {
                    margin-bottom : 20px;
                }
            }

            .counter-box {
                h3 {
                    margin-bottom: 10px;

                    span {
                        font-weight: 600;
                    }

                    i {
                        font-size     : 20px;
                        vertical-align: middle;
                    }
                }

                p {
                    font-size  : 14px;
                    color      : ${colors.text3};
                    font-weight: 500;
                }

                // @media(max-width: 575px) {
                //     display : none;
                // }
            }

            .counter-box.box1 {
                h3 {
                    color: ${colors.green};
                }
            }

            .counter-box.box2 {
                h3 {
                    color: ${colors.purple};
                }
            }

            .counter-box.box3 {
                h3 {
                    color: ${colors.blue};
                }
            }

            a.readmore-btn {
                font-size : 14px;
                color     : #fff;
                background: ${colors.gr_bg};
                display   : inline-block;
                width     : 145px;
                height    : 40px;
                text-align: center;
                padding   : 11px;
                border-radius : 5px;
                margin-top: 40px;

                &:hover {
                    background: ${colors.gr_bg2};
                }

                @media(max-width: 575px) {
                    margin-top : 0;
                }
            }
        }

        @media(max-width: 767px) {
            padding: 30px 0 40px;
        }

        .buttonForScrollSticky {
                position: initial;
                top: 0;
                z-index: 11;

                @media(max-width: 767px) {
                    position: sticky;
                    top: 0;
                    z-index: 11;
                }
            
                    .nav {
                        display: inline-block;
                        border-radius: 5px;
                        box-shadow: 0 8px 25px rgba(0,0,0,0.1);
                        margin-bottom: 35px;
                        .nav-item {
                            display: inline-block;
                            a.nav-link {
                                font-size: 14px;
                                color: ${colors.black2};
                                font-weight: 500;
                                text-transform : uppercase;
                                padding: 12px 30px 10px;
                                border-radius: 5px;

                                @media(max-width: 991px) {
                                    padding: 12px 16px 9px;
                                    font-size: 12px;
                                }
                            }
                            a.nav-link.active {
                                background : ${colors.gr_bg};
                                color : #ffffff;
                            }
                        }
                        @media(max-width: 767px) {
                            background: #edeaea;
                            width: 100%;
                            text-align: center;
                            box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                            -webkit-box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                            -moz-box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                        }
                    }
        }
        .buttonForScroll {
            position: initial;
            top: 0;
            z-index: 11;

            @media(max-width: 767px) {
                position: initial;
                display: none !important;
                top: 0;
                z-index: 11;
            }
        
                .nav {
                    display: inline-block;
                    border-radius: 5px;
                    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
                    margin-bottom: 35px;
                    .nav-item {
                        display: inline-block;
                        a.nav-link {
                            font-size: 14px;
                            color: ${colors.black2};
                            font-weight: 500;
                            text-transform : uppercase;
                            padding: 12px 30px 10px;
                            border-radius: 5px;

                            @media(max-width: 991px) {
                                padding: 12px 16px 9px;
                                font-size: 12px;
                            }
                        }
                        a.nav-link.active {
                            background : ${colors.gr_bg};
                            color : #ffffff;
                        }
                    }
                    @media(max-width: 767px) {
                        background: #edeaea;
                        width: 100%;
                        text-align: center;
                        box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                        -webkit-box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                        -moz-box-shadow: -4px 4px 22px -8px rgba(0,0,0,0.75);
                    }
                }
        }
        .overview-tab {
            h5 {
                color: ${colors.black2};
                font-weight: 600;
                padding-bottom: 10px;
                margin-bottom: 20px;
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    background: ${colors.green};
                    width: 50px;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                }

                @media(max-width: 575px) {
                    font-size : 17px;
                }                            
            }
            p {
                font-size: 15px;
                color: ${colors.text2};
                line-height: 25px;

                @media(max-width: 575px) {
                    font-size : 14px;
                }
            }

            .course-desc {
                margin-bottom: 40px;
            }
        }

        .curriculum-tab {
            .course-curriculum {
                margin-bottom: 40px;
                h5 {
                    color: ${colors.black2};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }

                    @media(max-width: 575px) {
                        font-size : 17px;
                    }  
                }
                p {
                    font-size: 15px;
                    color: ${colors.text2};
                    line-height: 25px;

                    @media(max-width: 575px) {
                        font-size : 14px;
                    }
                }
            }
            .course-element {
                h5 {
                    color: ${colors.black2};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    margin-top: 40px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }

                    @media(max-width: 575px) {
                        font-size : 17px;
                    }
                }
                .course-item {
                    margin-bottom: 4px;
                    // border-style: solid;
                    // border-width: 1;
                    button.course-button {
                        border: none;
                        background: #8c95a4;
                        // margin-bottom: 15px;
                        display: block;
                        width: 100%;
                        text-align : left;
                        padding: 10px 10px;
                        font-size : 17px;
                        // color: ${colors.black2};
                        color: #fff;
                        // font-weight: 500;
                        span {
                            float: right;
                            font-size: 14px;
                            color: #fff;
                            font-weight: 400;
                        }
                    }

                    .course-content {
                        max-height: 0;
                        overflow  : hidden;
                        transition: max-height 0.2s ease-in-out;
                        ul {
                            li {
                                border-bottom : 1px solid ${colors.border3};
                                margin-left: 25px;
                                padding: 12px 0;
                                span.play-icon {
                                    font-size : 14px;
                                    color: ${colors.text3};
                                    margin-right: 20px;
                                    i {
                                        // color: ${colors.green};
                                        color: #fff;
                                        background: ${colors.green};
                                        // border: 1px solid ${colors.green};
                                        font-size: 22px;
                                        width: 30px;
                                        height: 30px;
                                        border-radius: 50%;
                                        padding-left: 2px;
                                        text-align: center;
                                        line-height: 29px;
                                        vertical-align: middle;
                                        margin-right: 5px;
                                    }
                                }
                                span.lecture-title {
                                    font-size : 15px;
                                    color: ${colors.black2};

                                    @media(max-width: 575px) {
                                        font-size : 14px;
                                    }
                                }
                                button.lecture-duration {
                                    background: transparent;
                                    float: right;
                                    font-size: 14px;
                                    color: ${colors.text3};
                                    line-height: 28px;
                                    font-weight: 400;

                                    &:hover {
                                        background: ${colors.text3};
                                        color: #fff;
                                        border-radius: 2px;
                                    }
                                }
                            }
                        }
                    }

                    .course-content.show {
                        max-height: 100%;
                        margin-bottom: 40px;
                    }

                    &:last-child {
                        margin-bottom: 40px;
                    }
                }
            }
                    }

                    .course-content.show {
                        max-height: 100%;
                        margin-bottom: 40px;
                    }

                    &:last-child {
                        margin-bottom: 40px;
                    }
                }
            }
            .timeTable-element {
                h5 {
                    color: ${colors.black2};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-top: 30px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }

                    @media(max-width: 575px) {
                        font-size : 17px;
                    }
                }
                    
                button.button1 {
                    background-color: ${colors.bg1};
                    border: 1px solid ${colors.green};
                    color: #fff;
                    padding: 8px;
                }
                button.button2 {
                    background    : ${colors.gr_bg};
                    border: 1px solid #fff;
                    border : none;
                    color: #000;
                    padding: 8px;
                    font-size: 16px;
                }
        
                .course-item {
                    margin-bottom: 10px;
                    background-color: ${colors.bg1};
                    border-radius: 1%;
                    padding: 10px 10px;
                    padding-bottom: 1px;
                    button.course-button {
                        border: none;
                        background: transparent;
                        margin-bottom: 15px;
                        margin-left: 5px;
                        display: block;
                        width: 100%;
                        text-align : left;
                        padding: 0;
                        font-size : 14px;
                        // color: ${colors.black2};
                        color: #fff;
                        // font-weight: 500;
                        span {
                            margin-top: 15px;
                            float: right;
                            font-size: 14px;
                            // margin-right: 15px;
                            // color: ${colors.text3};
                            color: #fff;
                            font-weight: 400;
                        }
                        span.tName {
                            margin-top: 0px;
                            float: right;
                            font-size: 14px;
                            margin-left: 5px;
                            // color: ${colors.text3};
                            color: #fff;
                            font-weight: 400;
                        }
                        label {
                            font-size: 15px;
                        }
                    }
                    img {
                        border-radius: 50%;
                        width: 50px;
                        height: 50px;

                        @media(max-width: 575px) {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    .course-content {
                        max-height: 0;
                        overflow  : hidden;
                        transition: max-height 0.2s ease-in-out;
                        ul {    
                            li {
                                border-bottom : 1px solid ${colors.green};
                                // margin-left: 25px;
                                padding: 12px 10px;
                                span.play-icon {
                                    font-size : 14px;
                                    // color: ${colors.text3};
                                    color: #fff;
                                    margin-right: 20px;
                                    i {
                                        // color: ${colors.green};
                                        color: #fff;
                                        // border: 1px solid ${colors.green};
                                        background: ${colors.green};
                                        font-size: 22px;
                                        width: 30px;
                                        height: 30px;
                                        border-radius: 50%;
                                        padding-left: 3px;
                                        text-align: center;
                                        line-height: 29px;
                                        vertical-align: middle;
                                        margin-right: 10px;
                                    }
                                }
                                span.lecture-title {
                                    font-size : 15px;
                                    color: #fff;
                                    // color: ${colors.black2};

                                    @media(max-width: 575px) {
                                        font-size : 14px;
                                    }
                                }
                                span.lecture-duration {
                                    float: right;
                                    font-size: 14px;
                                    // color: ${colors.text3};
                                    color: #fff;
                                    line-height: 28px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    .course-content.show {
                        max-height: 100%;
                        margin-bottom: 40px;
                    }

                    &:last-child {
                        margin-bottom: 40px;
                    }

                    .teacher-info-timetable {
                        // text-align: right;
                        padding-top: 20px;

                        .cardPrice {
                            text-align: right;
                            padding: 5px;
                            border-radius: 2%;
                            background: #485d7c;

                            @media(max-width: 575px) {
                                text-align: center;
                            }
                        }
                        button {
                            min-width: 100px;
                            // padding: 15px 5px;
                        }
                        .priceCourse {
                            h6 {
                                color: #fff;
                                font-size: 18px;
                            }
                            p {
                                color: #fff;
                                font-size: 20px;
                            }
                        }
                        img {
                            border-radius: 50%;
                            width: 80px;
                            height: 80px;

                            @media(max-width: 575px) {
                                width: 60px;
                                height: 60px;
                            }
                        }
                        label{
                            margin: 10px;
                            color: #fff;
                        }
                        span {
                            color: #fff;
                            // color: ${colors.brightYellow};
                        }
                        .img-col {
                            // text-align: left;
                            padding-left: 30px; 
                        }
                        .belowApply{
                            label {
                                color: ${colors.brightYellow};
                                margin-top: 10px;
                                margin-left: 0px !important;
                                
                            }
                        }
                        .apply-btn {
                            // margin-left: 70px;
                            margin-top : 10px;
            
                            a {
                                font-size     : 12px;
                                color         : #ffffff;
                                background    : ${colors.gr_bg};
                                display       : inline-block;
                                width         : 100%;
                                height        : 40px;
                                text-align    : center;
                                text-transform: uppercase;
                                font-weight   : 500;
                                padding       : 11px;
                                border-radius : 5px 5px 5px 5px;
                                position: relative;
                                cursor: pointer;
            
                                i {
                                    position : absolute;
                                    font-size: 20px;
                                    left     : -40px;
                                    top      : 0;
                                    padding  : 10px;
                                    width    : 40px;
                                    height   : 100%;
                                    border-radius : 5px 0 0 5px;
                                    background-color: ${colors.green2};
                                }
            
                                &:hover {
                                    background: ${colors.gr_bg2};
                                }
                            }
            
                            // @media(max-width: 1199px) {
                            //     margin-left: 45px;
                            // }
            
                            // @media(max-width: 991px) {
                            //     margin-top: 10px;
                            // }
                        }
                    }
                }
            }
        }

        p.demo-box {
            position: fixed;
            bottom: 10px;
            right: 20px;
            width: 130px;
            height: 130px;
            border-radius: 50%;
            background: ${colors.gr_bg2};
            padding-top: 33px;
            color: #fff;
            text-align : center;
            cursor: pointer;
            // border: 1px solid #000;
      
            &:hover {
              background: ${colors.yellow};
            }
             
            @media (max-width: 767px) {
              display: none;
            }
          }

        .EnrollButton {
            background: ${colors.green};
            border: none;
            display: none;
            // text-align: center;
            // padding: 15px;
            color: #fff;
            // -webkit-box-shadow: -1px -3px 6px 0px rgba(50, 50, 50, 0.75);
            // -moz-box-shadow:    -1px -3px 6px 0px rgba(50, 50, 50, 0.75);
            // box-shadow:         -1px -3px 6px 0px rgba(50, 50, 50, 0.75);

            .movingArrow {
                position: absolute;
                top: -20px;
                right: 150px;
                background: ${colors.green};
                padding: 2px;
                width: 100px;
                border-radius: 10px;
                i {
                    color: #fff;
                }
            }
            @media(max-width: 767px) {
                display: block;
                position: fixed;
                left: 0;
                bottom: 0;
                margin: 0;
                border-radius: 0;
                z-index: 999;
                width: 100%;

            }
        }

        .course-details-top {
            .heading {
                h4 {
                    color : ${colors.black1};
                    font-weight: 600;
                    line-height: 35px;
                    margin-bottom: 25px;

                    @media(max-width: 575px) {
                        font-size : 20px;
                    }
                }
            }

            .course-element {
                h5 {
                    color: ${colors.black2};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }

                    @media(max-width: 575px) {
                        font-size : 17px;
                    }
                }
                .course-item {
                    margin-bottom: 4px;
                    // border-style: solid;
                    // border-width: 1;
                    button.course-button {
                        border: none;
                        background: #f2f2f2;
                        // margin-bottom: 15px;
                        display: block;
                        width: 100%;
                        text-align : left;
                        padding: 10px 10px;
                        font-size : 14px;
                        color: ${colors.bg1};
                        // color: #fff;
                        // font-weight: 500;

                        i {
                            float: right;
                            color: ${colors.green};
                        }
                        span {
                            float: right;
                            font-size: 14px;
                            color: #d8d8d8;
                            font-weight: 400;
                        }
                    }

                    .course-content {
                        max-height: 0;
                        overflow  : hidden;
                        transition: max-height 0.2s ease-in-out;
                        ul {
                            li {
                                border-bottom : 1px solid ${colors.border3};
                                margin-left: 25px;
                                padding: 12px 0;
                                span.play-icon {
                                    font-size : 10px;
                                    color: ${colors.text3};
                                    margin-right: 20px;
                                    i {
                                        // color: ${colors.green};
                                        color: #fff;
                                        background: ${colors.green};
                                        // border: 1px solid ${colors.green};
                                        font-size: 12px;
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                        padding-left: 2px;
                                        text-align: center;
                                        line-height: 22px;
                                        vertical-align: middle;
                                        margin-right: 5px;
                                    }
                                }
                                span.lecture-title {
                                    font-size : 15px;
                                    color: ${colors.black2};

                                    @media(max-width: 575px) {
                                        font-size : 14px;
                                    }
                                }
                                button.lecture-duration {
                                    background: transparent;
                                    float: right;
                                    font-size: 14px;
                                    color: ${colors.green};
                                    line-height: 28px;
                                    font-weight: 400;

                                    &:hover {
                                        background: ${colors.text3};
                                        color: #fff;
                                        border-radius: 2px;
                                    }
                                }
                            }
                        }
                    }

                    .course-content.show {
                        max-height: 100%;
                        margin-bottom: 40px;
                    }

                    &:last-child {
                        margin-bottom: 40px;
                    }
                }
            }
                    }

                    .course-content.show {
                        max-height: 100%;
                        margin-bottom: 40px;
                    }

                    &:last-child {
                        margin-bottom: 40px;
                    }
                }
            }

            .course-details-banner {
                margin-bottom: 40px;
                img {
                    border-radius : 5px;
                }
            }
            

            @media(max-width: 767px) {
                margin-bottom: 40px;
            }
        }

        .instructor-tab {
            h5 {
                color: ${colors.black2};
                font-weight: 600;
                padding-bottom: 10px;
                margin-bottom: 25px;
                margin-top: 50px;
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    background: ${colors.green};
                    width: 50px;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                }

                @media(max-width: 575px) {
                    font-size : 17px;
                    margin-top: 10px;
                }
            }

            .instructor-item {
                margin-bottom: 30px;

                .instructor-img {
                    img {
                        border-radius : 5px;
                    }
                }

                .instructor-content {
                    position: relative;

                    .instructor-box {
                        box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                        padding   : 25px;
                        background: #ffffff;
                        border-radius : 5px;
                        // position: absolute;
                        top     : 32px;
                        left    : -11%;
                        z-index : 1;

                        .top-content {
                            margin-bottom: 20px;

                            .instructor-name {
                                h6 {
                                    color      : ${colors.black2};
                                    font-weight: 600;
                                    text-transform: uppercase;
                                    margin-bottom: 12px;

                                    @media(max-width: 575px) {
                                        font-size : 14px;
                                    }
                                }
                                p {
                                    font-size  : 14px;
                                    color      : ${colors.text3};
                                    font-weight: 500;
                                }
                                label {
                                    font-weight: 500;
                                    font-size  : 14px;
                                }
                                span {
                                    cursor: pointer;
                                    color: ${colors.blue};
                                    margin-left: 10px;
                                    &:hover {
                                        color: ${colors.green}
                                    }
                                }
                            }
                            .instructor-social {
                                margin-top: 25px;
                                ul.social {
                                    li {
                                        a {
                                            text-align: center;
                                            position  : relative;

                                            &:before {
                                                content           : "";
                                                position          : absolute;
                                                border-width      : 8px 14px;
                                                border-style      : solid;
                                                border-top-color  : transparent;
                                                border-right-color: transparent;
                                                border-left-color : transparent;
                                                top               : -16px;
                                                left              : 0;
                                                z-index           : 1;
                                                transition : all 0.2s ease;
                                            }

                                            &:after {
                                                content            : "";
                                                position           : absolute;
                                                border-width       : 8px 14px;
                                                border-style       : solid;
                                                border-right-color : transparent;
                                                border-bottom-color: transparent;
                                                border-left-color  : transparent;
                                                bottom             : -16px;
                                                left               : 0;
                                                z-index            : 1;
                                                transition : all 0.2s ease;
                                            }

                                            i {
                                                font-size: 13px;
                                                color    : #ffffff;
                                                width    : 28px;
                                            }

                                            &:hover {
                                                background-color: ${colors.green} !important;

                                                &:before {
                                                    border-bottom-color: ${colors.green} !important;
                                                }

                                                &:after {
                                                    border-top-color: ${colors.green} !important;
                                                }
                                            }
                                        }

                                        &:nth-child(1) {
                                            a {
                                                background-color: #4267B2;

                                                &:before {
                                                    border-bottom-color: #4267B2;
                                                }

                                                &:after {
                                                    border-top-color: #4267B2;
                                                }
                                            }
                                        }

                                        &:nth-child(2) {
                                            a {
                                                background-color: #1DA1F2;

                                                &:before {
                                                    border-bottom-color: #1DA1F2;
                                                }

                                                &:after {
                                                    border-top-color: #1DA1F2;
                                                }
                                            }
                                        }

                                        &:nth-child(3) {
                                            a {
                                                background-color: #2867B2;

                                                &:before {
                                                    border-bottom-color: #2867B2;
                                                }

                                                &:after {
                                                    border-top-color: #2867B2;
                                                }
                                            }
                                        }

                                        &:nth-child(4) {
                                            a {
                                                background-color: #DD1343;

                                                &:before {
                                                    border-bottom-color: #DD1343;
                                                }

                                                &:after {
                                                    border-top-color: #DD1343;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .instructor-desk {
                            p {
                                font-size : 15px;
                                color      : ${colors.text2};
                                line-height: 25px;

                                @media(max-width: 575px) {
                                    font-size : 14px;
                                }
                            }
                            span {
                                cursor: pointer;
                                &:hover {
                                    color: ${colors.green}
                                }
                            }
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .course-details-images {
            // background: ${colors.bg1};
            padding-top: 10px;
            h5 {
                // color: ${colors.black2};
                font-weight: 600;
                padding-bottom: 10px;
                margin-bottom: 35px;
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    background: ${colors.green};
                    width: 50px;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                }

                @media(max-width: 575px) {
                    font-size : 17px;
                }
            }
            .gallery-box {
                border-radius : 5px;
                overflow: hidden;
                margin-bottom: 30px;
                position: relative;
                img {
                    transition : all 0.3s ease;
                    &:hover {
                        transform: scale(1.1);
                    }
                    @media(max-width: 767px) {
                        // width: 40px;
                    }
                }
            }
        }
        .single-details-sidbar {
            .course-details-feature {
                box-shadow: 0 8px 25px rgba(0,0,0,0.1);
                border-radius: 5px;
                padding: 15px 20px 20px;
                margin-bottom: 30px;
                h5.title {
                    color: ${colors.black1};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }

                    @media(max-width: 575px) {
                        font-size : 17px;
                    }
                }

                ul.feature-list {
                    margin-bottom: 20px;
                    li {
                        border-top: 1px dashed ${colors.border3};
                        padding: 12px 0;
                        font-size : 14px;
                        color : ${colors.black2};
                        font-weight: 500;
                        i {
                            font-size : 20px;
                            color: ${colors.green};
                            vertical-align: top;
                            margin-right : 2px;
                        }
                        .crown {
                            color: #DAA520;
                            font-size : 17px;
                        }
                        span {
                            float : right;
                            font-size: 13px;
                            color: ${colors.text3};
                            font-weight: 400;
                            line-height: 20px;
                        }
                        &:first-child {
                            border-top : none;
                            padding-top : 0;
                        }
                        &:last-child {
                            padding-bottom : 0;
                        }
                    }
                }

                button.enroll-btn {
                    font-size: 16px;
                    color: #fff;
                    background: ${colors.gr_bg};
                    display: inline-block;
                    width: 100%;
                    height: 40px;
                    font-weight : 500;
                    border : none;
                    padding: 9px;
                    border-radius: 5px;
                    text-transform : uppercase;
                    &:hover {
                        background: ${colors.gr_bg2};
                    }

                    @media(max-width: 575px) {
                        font-size : 14px;
                    }
                }

                @media(max-width: 1199px) {
                    padding: 12px 15px 15px;
                }
            }
        }

        @media(max-width: 767px) {
            // padding: 20px 0 5px;
        }
    }
`;

