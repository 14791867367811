import React, { Component, Fragment } from 'react';
import Datas from '../../../data/course/item.json';
import { Link } from 'react-router-dom';
import { Row, Col, Modal, Table } from 'react-bootstrap';
import Pagination from './../../../components/Pagination';
import { formatPrice } from './../../../actions/utility';

class CourseItemGrid extends Component {
    showTeacherDetails = (details, name) => {
        let teachersArary = [];

        var details = JSON.parse(details);

        Object.keys(details).map((id, key) => {
            var teacher = details[id];
            let item = <Fragment> <img src={teacher.img_src && teacher.img_src !== '' && teacher.img_src !== "https://lincolnmethod.s3.us-east-1.amazonaws.com/profileImages/" ? teacher.img_src : 'https://pngimage.net/wp-content/uploads/2018/06/no-user-image-png.png'} alt="teacher-img" className="img-fluid shadow-lg" />
                <label>Taught by: <br /> {
                    teacher.name && teacher.name != '' ?
                        teacher.name
                        : ""
                }</label>
            </Fragment>

            teachersArary.push(item);
        })

        return teachersArary;
    }
    returnListTimings = (gradeId, courseId) => {
        debugger;
        let daysArary = [];

        var timings = this.props.sortedCourses[gradeId].courses[courseId].timing;
        timings = JSON.parse(timings);

        Object.keys(timings).map((id, key) => {
            var day = timings[id];
            if (day.start != '' && day.end != '') {
                var start_time = day.start;
                var firstCharStart = start_time.split("");
                if (firstCharStart[0] == '0') {
                    start_time = start_time.substring(1);
                }

                var end_time = day.end;
                var firstCharEnd = end_time.split("");
                if (firstCharEnd[0] == '0') {
                    end_time = end_time.substring(1);
                }

                var dayName = day.day.split("");

                let dayData = <tr key={key}>
                    <th style={{ padding: '5px 0' }}>{dayName[0] + dayName[1] + dayName[2]}</th>
                    <td style={{ padding: '5px 0' }}>
                        <i className="fas fa-clock mr-4 text-primary"></i>
                        {start_time} - {end_time}
                    </td>
                </tr>;
                daysArary.push(dayData);
            }
        })
        console.log(daysArary);
        return daysArary;
    }
    render() {
        const { sortedCourses, visibleGradeList, showPopupTime, popOverCourseId, popOverGradeId, toggleTimeTableModal } = this.props;
        console.log("visibleGradeList", visibleGradeList);
        console.log("popvercourseId", popOverCourseId);
        console.log("popOverGradeId", popOverGradeId);
        return (
            <Fragment>
                {sortedCourses && sortedCourses.length ?
                    <Modal className="htmlModalMain"
                        show={showPopupTime} onHide={e => toggleTimeTableModal(0, 0)} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Time Table</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table>
                                <tbody>
                                    {
                                        sortedCourses[popOverGradeId].courses[popOverCourseId].timing &&
                                            sortedCourses[popOverGradeId].courses[popOverCourseId].timing != '' ?
                                            this.returnListTimings(popOverGradeId, popOverCourseId)
                                            : null
                                    }
                                </tbody>
                            </Table>
                        </Modal.Body>
                        {/* <Modal.Footer>
                                            <Button variant="secondary" onClick={this.openTimeTableModal}>
                                            Close
                                         </Button>
                                        <Button variant="primary" onClick={this.openTimeTableModal}>
                                            Save Changes
                                        </Button>
                                        </Modal.Footer> */}
                    </Modal>
                    : null
                }
                {/* Course Item */}
                {
                    sortedCourses && sortedCourses.map((course, key) =>
                        <Fragment>
                            {
                                !visibleGradeList.length || visibleGradeList.includes(course.grade) ?
                                    <Col md="12">
                                        <h5 className="heading">
                                            {course.grade}
                                        </h5>
                                        <hr />
                                    </Col>
                                    :
                                    null
                            }
                            {
                                !visibleGradeList.length || visibleGradeList.includes(course.grade) ?
                                    course.courses && course.courses.map((_course, _key) =>
                                        <Col lg="4" md="12" key={_key}>
                                            <div className="course-item">
                                                <Link to={`/course-details/${_course.id}`}>
                                                    <div className="course-image" style={{ backgroundImage: _course.course_img && _course.course_img != null ? "url(" + "https://dashboard.lincolnmethod.com/uploads/" + _course.course_img + ")" : null }}>
                                                        {/* <div className="author-img d-flex">
                                            <div className="img">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" />
                                            </div>
                                            <div className="title">
                                                <p>{data.authorName}</p>
                                                <span>{data.authorCourses}</span>
                                            </div>
                                        </div> */}
                                                        <div className="course-price">
                                                            <p>{_course.grade}</p>
                                                        </div>
                                                    </div>

                                                </Link>
                                                <div className="course-content" style={{ minHeight: 200 }}>
                                                    <h6 className="heading"><Link to={`/course-details/${_course.id}`}>{_course.course_name}</Link></h6>
                                                    {/* <p className="desc">{data.courseDesc}</p> */}
                                                    <Row>
                                                        <Col md="8">
                                                            <div className="course-info">
                                                                <ul className="list-unstyled">
                                                                    {
                                                                        _course.chapters && _course.chapters != 0 ?
                                                                            <li>
                                                                                <i className="fas fa-book"></i>
                                                                                <label>{_course.chapters} Chapters</label>
                                                                            </li>
                                                                            : null
                                                                    }
                                                                    {
                                                                        _course.videos && _course.videos != 0 ?
                                                                            <li>
                                                                                <i className="fas fa-video"></i>
                                                                                <label>{_course.videos} Video tutorials</label>
                                                                            </li>
                                                                            : null
                                                                    }
                                                                    {
                                                                        _course.is_online && _course.is_online != 0 ?
                                                                            <li>
                                                                                <i className="fas fa-wifi"></i>
                                                                                <label>Online</label>
                                                                            </li>
                                                                            : null
                                                                    }
                                                                    {
                                                                        sortedCourses[key].courses[_key].timing &&
                                                                            sortedCourses[key].courses[_key].timing != '' ?
                                                                            <li onClick={e => toggleTimeTableModal(key, _key)}>
                                                                                <i className="fas fa-calendar-alt"></i>
                                                                                <label>View Class timings</label>
                                                                            </li>
                                                                            : null
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                        <Col md="4">
                                                            <div className="course-instructor-img">
                                                                {
                                                                    _course.teachers_info && _course.teachers_info != undefined && _course.teachers_info != '' ?
                                                                        this.showTeacherDetails(_course.teachers_info, _course.course_name)
                                                                        : null
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {/* <div className="course-face d-flex justify-content-between">
                                        <div className="duration">
                                            <p><i className="las la-clock"></i>120</p>
                                        </div>
                                        <div className="rating">
                                            <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                </li>
                                                <li className="list-inline-item">(4.5)</li>
                                            </ul>
                                        </div>
                                        <div className="student">
                                            <p><i className="las la-chair"></i>60</p>
                                        </div>
                                    </div> */}
                                                </div>
                                                <div style={{ backgroundColor: "#8C95A4" }} className="">

                                                    <Link to={`/course-details/${_course.id}`}>
                                                        <button className="btn text-light" style={{ width: "40%", height: 50 }}>
                                                            View Class Details
                                                    </button>
                                                    </Link>
                                                    <button className="btn" style={{ backgroundColor: "#676c75", width: "60%", height: 50 }}>
                                                        {_course.course_discount_price && (_course.course_discount_price != 0 || _course.course_discount_price == 0) ?
                                                            <span className="text-light">
                                                                <span className="outer" style={{ color: "red", textDecoration: 'line-through' }}>
                                                                    <span style={{ color: "#fff" }}>
                                                                        PKR {formatPrice(_course.course_price)}
                                                                    </span>
                                                                </span>
                                                                <span style={{ fontSize: 16, margin: 5 }}>
                                                                    PKR {formatPrice(_course.course_discount_price)}
                                                                </span>
                                                            </span>
                                                            :
                                                            <span style={{ fontSize: 16, margin: 5, color: "#FFF" }}>
                                                                PKR {formatPrice(_course.course_price)}
                                                            </span>
                                                        }
                                                        {/* <span class="outer" style={{ color: "red", textDecoration: 'line-through' }}>
                                                <span class="inner" style={{ color: "green" }}>foo bar</span>
                                            </span> */}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>

                                    )
                                    : null
                            }
                        </Fragment>



                    )
                }

                {/* <Col md="12" className="text-center">
                    <Pagination />
                </Col> */}
            </Fragment>
        )
    }
}

export default CourseItemGrid
